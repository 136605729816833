import type { ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { Checkbox, Form } from 'antd';
import type { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { last } from 'ramda';
import { type FC, useMemo } from 'react';

import { useActiveWidgetInstance, useEditor } from '../hooks';

export type IAddingBtnSetting = Pick<ViewList.ViewInfo, 'hideAdd'>;

const options = [
  {
    label: '全部隐藏',
    value: 'all',
  },
  {
    label: '隐藏分组新增',
    value: 'group',
  },
];

const EMPTY_ARRAY = [] as const;
export const AddingBtnSetting: FC = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IAddingBtnSetting>();

  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig<IAddingBtnSetting>;

  const onChange = (events: CheckboxValueType[]) => {
    updateConfig((c) => {
      // 二选一
      const result = events as ViewList.ViewInfo['hideAdd'][];
      if (result.length > 1 && c.hideAdd) {
        c.hideAdd = result.filter((i) => i !== c.hideAdd)[0];
      } else c.hideAdd = last(result);
    });
  };
  const value = useMemo(() => {
    if (widgetInstance.config.hideAdd) {
      return [widgetInstance.config.hideAdd];
    }
    return EMPTY_ARRAY;
  }, [widgetInstance.config.hideAdd]);

  return (
    <Form.Item label="隐藏新增按钮">
      <Checkbox.Group
        options={options}
        value={value as any}
        onChange={(e) => {
          onChange(e);
        }}
      />
    </Form.Item>
  );
};
