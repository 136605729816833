import cls from 'classnames';
import { isNil } from 'ramda';
import { type FC, useCallback, useSyncExternalStore } from 'react';

import { dragItemClassName } from '../constants';
import { useEditor } from '../hooks';
import type { IGroup, IWidget } from '../models/WidgetManager';

import styles from './WidgetBox.less';

export const WidgetBox: FC<
  Pick<IWidget<string, any>, 'icon' | 'title' | 'id' | 'basic'> &
    Pick<IGroup, 'layout'>
> = ({ id, icon, title, layout, basic }) => {
  const editor = useEditor();

  const getInstanceCount = useCallback(() => {
    const list = editor.store.getWidgetInstancesByWidgetId(id);

    return list.length;
  }, [editor.store, id]);

  const instanceCount = useSyncExternalStore(
    editor.store.subscribeCreator('widgetInstanceChanged'),
    getInstanceCount,
  );

  const disabled = isNil(basic.maxCount)
    ? false
    : instanceCount >= basic.maxCount;

  return (
    <div
      data-widget={id}
      data-nested={basic.nested || false}
      data-max-width={basic.maxWidth}
      data-min-width={basic.minWidth}
      data-max-height={basic.maxHeight}
      data-min-height={basic.minHeight}
      data-dynamic-height={basic.dynamicHeight || false}
      // eslint-disable-next-line react/no-unknown-property
      gs-h={basic.defaultHeight}
      // eslint-disable-next-line react/no-unknown-property
      gs-w={basic.defaultWidth}
      className={cls(
        styles.wrapper,
        layout === 'grid' ? styles.grid : styles.list,
        disabled || 'grid-stack-item',
        disabled || dragItemClassName,
        disabled && styles.disabled,
      )}
    >
      {typeof icon === 'function' ? icon() : icon}
      <span className={styles.title}>{title}</span>
    </div>
  );
};
