import Emittery from 'emittery';

// event debug toggle
// Emittery.isDebugEnabled = true;

export class BaseModel<
  T extends Record<string, unknown> = Record<string, unknown>,
> {
  protected emitter: Emittery<T>;

  constructor(name: string) {
    this.emitter = new Emittery({ debug: { name } });
  }

  public subscribeCreator = (event: keyof T) => {
    const cb = (listener: (...args: any[]) => void) => {
      this.emitter.on(event, listener);
      return () => this.emitter.off(event, listener);
    };

    return cb;
  };

  public on = (event: keyof T, listener: (...args: any[]) => void) => {
    this.emitter.on(event, listener);
  };

  public off = (event: keyof T, listener: (...args: any[]) => void) => {
    this.emitter.off(event, listener);
  };

  public dispose() {
    this.emitter.clearListeners();
  }
}
