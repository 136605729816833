import { RegularIcon } from '@/components/IconFont';

import statistic from '../../assets/statistic.png';
import type { IStatisticSetting } from '../../components';
import {
  showStatisticSettingModal,
  StatisticPreview,
  StatisticSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IIndicatorWidget = IStatisticSetting<'more'> & {
  statTempId?: string;
};

const Preview: WidgetPreivew = ({ id }) => {
  return (
    <StatisticPreview id={id}>
      <div style={{ display: 'block', height: '100%', overflow: 'hidden' }}>
        <img
          src={statistic}
          style={{ display: 'block', height: '100%', minHeight: 60 }}
        />
      </div>
    </StatisticPreview>
  );
};

const Setting: WidgetSetting = () => {
  return <StatisticSetting type="more" />;
};

export const IndicatorWidget = defineSystemWidget<IIndicatorWidget>()({
  id: 'Indicator',
  title: '指标/统计',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#FF962C"
        icon={<RegularIcon type="iconshitu-zhibiao" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 4,
    defaultWidth: 3,
    minWidth: 3,
    minHeight: 4,
    settingWidth: 540,
  },
  metadata: {
    matchings: [],
  } as any,
  preview: Preview,
  setting: Setting,
  onCreate: () =>
    showStatisticSettingModal<'more'>({ title: '添加指标/统计', type: 'more' }),
});
