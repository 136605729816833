import { assertExists } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import type { Draft } from 'immer';

import type { IDashboardComponentFrom } from '@/pages/home/components/View/DashBoard/ComponentForm';

import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { IStatisticForm, IStatisticFormValue } from './Form';
import { StatisticForm } from './Form';

export type IStatisticSetting<T extends IDashboardComponentFrom['type']> = {
  statistic: IStatisticFormValue<T>;
};
export const StatisticSetting = <T extends IDashboardComponentFrom['type']>(
  props: Pick<IStatisticForm<T>, 'type' | 'viewType'>,
) => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IStatisticSetting<T>>();
  assertExists(widgetInstance);
  const onChange = useMemoizedFn((v: IStatisticFormValue<T>) => {
    editor.updateActiveWidgetInstanceConfig<IStatisticSetting<T>>((c) => {
      c.statistic = v as Draft<IStatisticFormValue<T>>;
    });
  });

  return (
    <StatisticForm<T>
      {...props}
      mode="edit"
      defaultValue={widgetInstance.config.statistic}
      onChange={onChange}
    />
  );
};
