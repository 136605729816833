import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import type { FC } from 'react';

export type AlignType = 'left' | 'center' | 'right';

export type AlignRadioProp = {
  value?: AlignType;
  onChange?: (v: RadioChangeEvent) => void;
};

const options = [
  { label: '左对齐', value: 'left' },
  { label: '居中对齐', value: 'center' },
  { label: '右对齐', value: 'right' },
];

export const AlignRadio: FC<AlignRadioProp> = ({ value, onChange }) => {
  return <Radio.Group options={options} value={value} onChange={onChange} />;
};
