import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { getTemplateDataTableHeader } from '@/services/dataManager/tableHeader';

import { useCurrentOrgId } from './useOrg';

export const useTemplateDataTableHeaderMap = (templateId?: string) => {
  const orgId = useCurrentOrgId();

  // eslint-disable-next-line @tanstack/query/no-rest-destructuring
  const { data, ...rest } = useQuery({
    queryFn: () => getTemplateDataTableHeader(orgId, templateId!),
    queryKey: ['getTableHeader', orgId, templateId],
    retry: false,
    enabled: !!templateId,
  });

  type Result = typeof rest;

  return {
    isOpen: data?.isOpen,
    header: data?.header,
    headerMap: data?.headerMap,
    ...(rest as Result & { status: 'success' }),
  };
};

export const useSuspenseTemplateDataTableHeaderMap = (templateId?: string) => {
  const orgId = useCurrentOrgId();

  const { data, ...rest } = useSuspenseQuery({
    queryFn: () => getTemplateDataTableHeader(orgId, templateId || 'empty'),
    queryKey: ['getTableHeader', orgId, templateId],
    retry: false,
  });

  type Result = typeof rest;

  return {
    isOpen: data?.isOpen,
    header: data?.header,
    headerMap: data?.headerMap,
    ...(rest as Result & { status: 'success' }),
  };
};
