import type { ApiResponse } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import { useSelector } from '@umijs/max';
import { memoize } from 'lodash';
import { useMemo } from 'react';
import isEqual from 'react-fast-compare';

export const useOrgTemplatesSettingInfo = () => {
  const templates = useSelector((state: any) => state.space?.templates);

  return [templates as ApiResponse.CurrentUser.TemplateInfo[]];
};

const buildTempMap = memoize(
  toRecord((t: ApiResponse.CurrentUser.TemplateInfo) => ({
    [t.template_id]: t,
  })),
);

/**
 * NOTE
 * 获取当前用户可见的主题类型配置，
 * 主题类型的属性可见权限根据当前用户在空间中的角色决定
 */
export const useOrgTemplatesSettingInfoMap = () => {
  const [templates] = useOrgTemplatesSettingInfo();

  const tempMap = useMemo(() => {
    return buildTempMap(templates);
  }, [templates]);

  return tempMap;
};

/**
 * 配置界面获取当前正在编辑的主题类型
 */
export const useCurrentTemplateSetting = () => {
  const currentTemplate = useSelector(
    (state: any) => state.space?.currentTemplate,
    isEqual,
  );

  return [currentTemplate as ApiResponse.CurrentUser.TemplateInfo] as const;
};
