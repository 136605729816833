import useUrlState from '@ahooksjs/use-url-state';
import type { GetterPiNode } from '@linkpi/core/web';
import { Spin } from 'antd';
import type { FC } from 'react';
import { lazy, memo, Suspense, useMemo } from 'react';

import { useCurrentUser, useTemplateInfo } from '@/hook';

const View = lazy(() => import('@/pages/home/components/View'));
const TabContent = lazy(() => import('@/pages/home/components/TabContent'));
const TabContentWrapper = lazy(() =>
  import('../../Workbench/NodePageModel').then((res) => ({
    default: res.TabContentWrapper,
  })),
);

const showNodeSaved = () => {};

type WorkbenchContentPropsType = {
  selectViewId: string;
  node: GetterPiNode;
  viewList: any[];
  leftViewCollapse: boolean;
  leftViewWidth: number;
  showMessage: boolean;
  getViewList: any;
  authMap: any;
  orgInfo: any;
  readOnly: boolean;
  isDrafts: boolean;
  linkRef: React.MutableRefObject<any>;
};

const WorkbenchContent: FC<WorkbenchContentPropsType> = ({
  selectViewId,
  leftViewCollapse,
  getViewList,
  authMap,
  leftViewWidth,
  showMessage,
  node,
  viewList,
  orgInfo,
  readOnly,
  isDrafts,
  linkRef,
}) => {
  console.log('WorkbenchContent readOnly', readOnly);
  const currentUser = useCurrentUser();
  const [{ pmid }] = useUrlState();

  const isValidViewId = useMemo(() => {
    return viewList.find((x) => x.view_id === selectViewId);
  }, [selectViewId, viewList]);

  const templateInfo = useTemplateInfo(node?.value.tempInfo.id);

  if (!node) {
    return <div />;
  }

  if (selectViewId === 'content') {
    if (templateInfo?.custom_view?.contentType === 'pageModelConfig')
      return (
        <Suspense fallback={<Spin size="large" className="full-w" />}>
          <TabContentWrapper
            isDrafts={isDrafts}
            key={node.value.id}
            data={node}
            showNodeSaved={showNodeSaved}
            readOnly={readOnly}
            linkRef={linkRef}
            pageModelConfigId={pmid}
          />
        </Suspense>
      );

    return (
      <Suspense fallback={<Spin size="large" className="full-w" />}>
        <TabContent
          isDrafts={isDrafts}
          data={node}
          showNodeSaved={showNodeSaved}
          readOnly={readOnly}
          linkRef={linkRef}
        />
      </Suspense>
    );
  }

  if (isValidViewId) {
    return (
      <Suspense fallback={<Spin size="large" className="full-w" />}>
        <View
          node={node}
          orgInfo={orgInfo}
          viewId={selectViewId}
          currentUser={currentUser}
          leftCollapse={leftViewCollapse}
          leftWidth={leftViewWidth}
          showMessage={showMessage}
          viewList={viewList}
          getViewList={getViewList}
          authMap={authMap}
          showNodeSaved={showNodeSaved}
          readOnly={readOnly}
        />
      </Suspense>
    );
  }

  return <Spin style={{ width: '100%', lineHeight: '300px' }} />;
};

export default memo(WorkbenchContent);
