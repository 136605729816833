import type { PageModelConfig, WidgetInstanceData } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { mapValues } from 'lodash';
import { nanoid } from 'nanoid';
import { memoize } from 'proxy-memoize';
import { defaultTo, includes, isNil, keys, omit, values, without } from 'ramda';
import { injectable } from 'tsyringe-neo';
import type { JsonObject } from 'type-fest';

import { traverseTreeCreator } from '@/utils/tree';

import { defaultStoreVersion, initalPageConfig } from '../constants';
import { getLayoutStatus } from '../utils';
import { BaseModel } from './BaseModel';

const validNum = (v: number) => {
  if (v === 0) return 0;
  return v;
};

export type WidgetInstance<
  T extends JsonObject = JsonObject,
  P extends string = string,
> = Omit<WidgetInstanceData<T, P>, 'children'> & {
  children: string[] | null;
  deleted: boolean;
  // 是否编辑过
  touched: boolean;
};

export type WidgetInstanceConfigUpdater<T extends JsonObject = JsonObject> =
  | WidgetInstance<T>['config']
  | ((draftConfig: Draft<WidgetInstance<T>['config']>) => void);

type IEvents = {
  [k in `widgetInstanceChanged@${string}`]: undefined;
} & {
  widgetInstanceChanged: string;
  sessionIdChanged: string;
  pageConfigChanged: never;
};

export const widgetInstanceEventKeyCreator = (id: string) =>
  `widgetInstanceChanged@${id}` as const;

const genWidgetTree = memoize(
  ({
    list,
    nodeMap,
  }: {
    list: string[];
    nodeMap: Record<string, WidgetInstance | undefined>;
  }) => {
    const getWidgetInstanceDataTree = (ids: string[] | null) => {
      if (!ids) return null;

      return ids
        .filter((id) => nodeMap[id])
        .map((id): WidgetInstanceData => {
          const instance = nodeMap[id];
          assertExists(instance);

          return {
            ...instance,
            children: getWidgetInstanceDataTree(instance.children),
          };
        });
    };

    return getWidgetInstanceDataTree(list);
  },
);

export type LayoutPositionType = 'default' | 'stickyTop' | 'stickyBottom';

@injectable()
export class Store extends BaseModel<IEvents> {
  #data!: Omit<
    PageModelConfig,
    'widgetTree' | 'topWidgetTree' | 'bottomWidgetTree'
  > & {
    // immutable
    widgetTree: string[];
    // immutable
    topWidgetTree: string[];
    // immutable
    bottomWidgetTree: string[];
  };
  // immutable
  #widgetMap!: Record<string, WidgetInstance | undefined>;
  sessionId!: string;

  constructor() {
    super('store');
    this.#init();
  }

  #init() {
    this.#data = {
      version: defaultStoreVersion,
      pageConfig: { ...initalPageConfig },
      widgetTree: [],
      topWidgetTree: [],
      bottomWidgetTree: [],
    };
    this.sessionId = nanoid();
    this.#widgetMap = {};
  }

  /**
   * @note 每次返回都是新的对象（引用）
   */
  get data(): PageModelConfig {
    const getWidgetInstanceDataTree = (ids: string[] | null) => {
      if (!ids) return null;

      return ids
        .filter((id) => this.getWidgetInstance(id))
        .map((id): WidgetInstanceData => {
          const instance = this.getWidgetInstance(id);
          assertExists(instance);

          return {
            ...instance,
            children: getWidgetInstanceDataTree(instance.children),
          };
        });
    };

    const { hasBottom, hasTop } = getLayoutStatus(this.#data.pageConfig);

    return {
      ...this.#data,
      widgetTree: defaultTo(
        [],
        getWidgetInstanceDataTree(this.#data.widgetTree),
      ),
      topWidgetTree: hasTop
        ? defaultTo([], getWidgetInstanceDataTree(this.#data.topWidgetTree))
        : [],
      bottomWidgetTree: hasBottom
        ? defaultTo([], getWidgetInstanceDataTree(this.#data.bottomWidgetTree))
        : [],
    };
  }

  get widgetTree() {
    return genWidgetTree({
      list: this.#data.widgetTree,
      nodeMap: this.#widgetMap,
    });
  }

  get topWidgetTree() {
    return genWidgetTree({
      list: this.#data.topWidgetTree,
      nodeMap: this.#widgetMap,
    });
  }

  get bottomWidgetTree() {
    return genWidgetTree({
      list: this.#data.bottomWidgetTree,
      nodeMap: this.#widgetMap,
    });
  }

  get rootWidgets() {
    return this.#data.widgetTree;
  }

  public initData(newData: PageModelConfig) {
    this.#data.pageConfig = newData.pageConfig;
    this.#data.version = newData.version;

    const oldWidgetMap = this.#widgetMap || {};
    this.#widgetMap = {};

    const widgetTree: string[] = [];
    traverseTreeCreator<WidgetInstanceData>((w, _, parent) => {
      if (typeof parent === 'string') {
        widgetTree.push(w.id);
      }

      this.#widgetMap[w.id] = {
        ...omit(['children'], w),
        parentId: typeof parent === 'string' ? null : parent.id,
        children: defaultTo(null)(w.children?.map((subW) => subW.id)),
        deleted: false,
        touched: false,
      };
    })(newData.widgetTree);
    this.#data.widgetTree = widgetTree;

    if (
      includes(newData.pageConfig.contentLayoutMode, [
        'stickyTop',
        'stickyTopAndBottom',
      ])
    ) {
      const topWidgetTree: string[] = [];

      traverseTreeCreator<WidgetInstanceData>((w, _, parent) => {
        if (typeof parent === 'string') {
          topWidgetTree.push(w.id);
        }

        this.#widgetMap[w.id] = {
          ...omit(['children'], w),
          parentId: typeof parent === 'string' ? null : parent.id,
          children: defaultTo(null)(w.children?.map((subW) => subW.id)),
          deleted: false,
          touched: false,
        };
      })(newData.topWidgetTree || []);

      this.#data.topWidgetTree = topWidgetTree;
    }

    if (
      includes(newData.pageConfig.contentLayoutMode, [
        'stickyBottom',
        'stickyTopAndBottom',
      ])
    ) {
      const bottomWidgetTree: string[] = [];

      traverseTreeCreator<WidgetInstanceData>((w, _, parent) => {
        if (typeof parent === 'string') {
          bottomWidgetTree.push(w.id);
        }

        this.#widgetMap[w.id] = {
          ...omit(['children'], w),
          parentId: typeof parent === 'string' ? null : parent.id,
          children: defaultTo(null)(w.children?.map((subW) => subW.id)),
          deleted: false,
          touched: false,
        };
      })(newData.bottomWidgetTree || []);

      this.#data.bottomWidgetTree = bottomWidgetTree;
    }

    this.sessionId = nanoid();

    keys({
      ...oldWidgetMap,
      ...this.#widgetMap,
    }).forEach((id) => {
      if (typeof id === 'string')
        this.emitter.emit(widgetInstanceEventKeyCreator(id));
    });
    this.emitter.emit('widgetInstanceChanged', '$all$');
    this.emitter.emit('sessionIdChanged', this.sessionId);
    this.emitter.emit('pageConfigChanged');
  }

  public updatePageConfig(config: PageModelConfig['pageConfig']) {
    this.#data.pageConfig = {
      ...config,
    };
    this.emitter.emit('pageConfigChanged');
  }

  private updateWidgetInstance(
    id: string,
    updater: (d: Draft<WidgetInstance>) => void,
    slient = false,
  ) {
    if (!this.getWidgetInstance(id)) {
      console.warn('widget id: ', id, ' not exists');
      return;
    }
    const instance = this.getWidgetInstance(id);
    assertExists(instance);

    const newInstance = produce(instance, (draft) => {
      updater(draft);
      if (!slient) draft.touched = true;
    });
    assertExists(newInstance);

    this.#widgetMap = {
      ...this.#widgetMap,
      [id]: newInstance,
    };

    this.emitter.emit(widgetInstanceEventKeyCreator(id));
    this.emitter.emit('widgetInstanceChanged', id);
  }

  public getDeletedWidgetInstance<T extends JsonObject = JsonObject>(
    id: string,
  ) {
    if (!this.#widgetMap[id]?.deleted) {
      return undefined;
    }

    return this.#widgetMap[id] as WidgetInstance<T> | undefined;
  }

  public getWidgetInstance<T extends JsonObject = JsonObject>(id: string) {
    if (this.#widgetMap[id]?.deleted) {
      return undefined;
    }

    return this.#widgetMap[id] as WidgetInstance<T> | undefined;
  }

  public getWidgetInstanceList() {
    return values(this.#widgetMap).filter(
      (w) => w && !w.deleted,
    ) as WidgetInstance[];
  }

  public getWidgetInstancesByWidgetId<T extends JsonObject = JsonObject>(
    widgetId: string,
  ) {
    const result: WidgetInstance<T>[] = [];
    values(this.#widgetMap).forEach((w) => {
      if (!w) return;
      if (w.deleted) return;
      if (w.widgetId === widgetId) result.push(w as WidgetInstance<T>);
    });

    return result;
  }

  private _getWidgetTree(type: LayoutPositionType = 'default') {
    if (type === 'stickyTop') {
      return this.#data.topWidgetTree;
    } else if (type === 'stickyBottom') {
      return this.#data.bottomWidgetTree;
    } else {
      return this.#data.widgetTree;
    }
  }

  private _updateWidgetTree(
    type: LayoutPositionType = 'default',
    newList: string[],
  ) {
    if (type === 'stickyTop') {
      this.#data.topWidgetTree = newList;
    } else if (type === 'stickyBottom') {
      this.#data.bottomWidgetTree = newList;
    } else {
      this.#data.widgetTree = newList;
    }
  }

  private _removeWidgetTreeId(
    type: LayoutPositionType = 'default',
    id: string,
  ) {
    const list = this._getWidgetTree(type);
    this._updateWidgetTree(type, without([id], list!));
  }

  private _addWidgetTreeId(type: LayoutPositionType = 'default', id: string) {
    const list = this._getWidgetTree(type);
    this._updateWidgetTree(type, [...list, id]);
  }

  public addWidgetInstance(
    w: Omit<WidgetInstance, 'parentId' | 'id' | 'deleted' | 'touched'> & {
      id?: string;
      type?: LayoutPositionType;
    },
    parentId?: string | null,
  ) {
    const newId = w.id || nanoid();

    this.#widgetMap[newId] = {
      ...w,
      id: newId,
      parentId: defaultTo(null)(parentId),
      deleted: false,
      touched: true,
    };

    if (parentId) {
      this.updateWidgetInstance(parentId, (parentW) => {
        if (parentW.children) parentW.children.push(newId);
        else parentW.children = [newId];
      });
    } else {
      this._addWidgetTreeId(w.type, newId);
    }
    this.emitter.emit(widgetInstanceEventKeyCreator(newId));
    this.emitter.emit('widgetInstanceChanged', newId);

    return this.getWidgetInstance(newId)!;
  }

  public resizeWidgetInstance = (
    id: string,
    options: Partial<WidgetInstance['rect']>,
  ) => {
    this.updateWidgetInstance(
      id,
      (w) => {
        w.rect = {
          ...w.rect,
          ...mapValues(options, validNum),
        };
      },
      true,
    );
  };

  public moveWidgetInstance(
    id: string,
    options: Partial<
      Pick<WidgetInstance, 'parentId'> & WidgetInstance['rect']
    > & {
      type?: LayoutPositionType;
    },
  ) {
    this.updateWidgetInstance(id, (w) => {
      if (options.parentId !== w.parentId) {
        // 前父
        if (w.parentId) {
          this.updateWidgetInstance(w.parentId, (p) => {
            p.children = without([w.id], p.children!);
          });
        } else {
          this._removeWidgetTreeId(options.type, w.id);
        }

        // 继父
        if (options.parentId) {
          this.updateWidgetInstance(options.parentId, (p) => {
            if (p.children) p.children.push(w.id);
            else p.children = [id];
          });
        } else {
          this._addWidgetTreeId(options.type, w.id);
        }

        w.parentId = options.parentId || null;
      }
    });
    this.resizeWidgetInstance(id, omit(['parentId'], options));
  }

  public removeWidgetInstance(
    id: string,
    options?: { type?: LayoutPositionType },
  ) {
    const widgetInstance = this.getWidgetInstance(id);
    console.log('💎', id, widgetInstance);
    assertExists(widgetInstance);

    this.updateWidgetInstance(id, (w) => {
      w.deleted = true;
    });

    if (widgetInstance.parentId) {
      this.updateWidgetInstance(widgetInstance.parentId, (w) => {
        w.children = without([widgetInstance.id], w.children!);
      });
    } else {
      this._removeWidgetTreeId(options?.type, widgetInstance.id);
    }
    this.emitter.emit(widgetInstanceEventKeyCreator(id));
    this.emitter.emit('widgetInstanceChanged', id);

    widgetInstance.children?.forEach((wid) => {
      this.removeWidgetInstance(wid, { type: options?.type });
    });
  }

  public updateWidgetInstanceConfig = <T extends JsonObject = JsonObject>(
    id: string,
    config: WidgetInstanceConfigUpdater<T>,
  ) => {
    this.updateWidgetInstance(id, (w) => {
      if (typeof config === 'function') {
        config(w.config as Draft<WidgetInstance<T>['config']>);
      } else {
        w.config = config;
      }
    });
  };

  /**
   * 获取当前编辑器底部的位置
   */
  public getBottom = () => {
    let bottom = 0;

    for (let index = 0; index < this.#data.widgetTree.length; index++) {
      const widgetId = this.#data.widgetTree[index];

      const widgetInstance = this.getWidgetInstance(widgetId);
      const instanceBottom = widgetInstance!.rect.y + widgetInstance!.rect.h;
      if (instanceBottom > bottom) {
        bottom = instanceBottom;
      }
    }

    return bottom;
  };

  public getWidgetCount() {
    const result: Record<string, number> = {};
    values(this.#widgetMap).forEach((w) => {
      if (!w) return;
      if (w.deleted) return;

      if (isNil(result[w.widgetId])) {
        result[w.widgetId] = 0;
      }

      result[w.widgetId] += 1;
    });

    return result;
  }

  public getWidgetLayoutContent(widgetId: string) {
    if (this.#data.topWidgetTree.includes(widgetId)) return 'stickyTop';

    if (this.#data.bottomWidgetTree.includes(widgetId)) return 'stickyBottom';

    if (this.#data.widgetTree.includes(widgetId)) return 'default';

    return 'default';
  }

  public getWidgetMapForDebug() {
    return this.#widgetMap;
  }

  public override dispose() {
    super.dispose();
    this.#init();
  }
}
