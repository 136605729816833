import { notEmpty } from '@linkpi/utils';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import {
  useApiSourceMap,
  useDBMap,
  useTableHeaderMapListByTemplate,
} from '@/hook';

export const useTableHeaderMapOptions = (templateId?: string) => {
  const { dbMap, isPending: isDBPending } = useDBMap();
  const { apiSourceMap, isPending: isApiSourcePending } = useApiSourceMap();

  const { data: result, isPending } =
    useTableHeaderMapListByTemplate(templateId);

  const isLoading = isDBPending || isApiSourcePending || isPending;

  const options = useMemo(() => {
    if (isLoading) return [];
    if (!result) return [];
    if (result.status === 'error') return [];

    return result.data
      .map((item) => {
        const label = match(item)
          .with(
            { source_type: 'table', table: { db_id: P.string } },
            ({ table }) => dbMap[table.db_id].name,
          )
          .with(
            { source_type: 'api', api: { query: { api_id: P.string } } },
            ({ api }) => apiSourceMap[api.query.api_id].name,
          )
          .otherwise(() => null);

        if (!label) return null;

        return {
          label,
          value: item.thm_id,
        };
      })
      .filter(notEmpty);
  }, [isLoading, result, dbMap, apiSourceMap]);

  return { options, isLoading };
};
