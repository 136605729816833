import type { SysBtnAndTitleAndStatus } from '@linkpi/core';
import { Form, Radio } from 'antd';
import { match } from 'ts-pattern';

import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import { WidgetIcon } from '../../components';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type INodeTitleConfig = Pick<SysBtnAndTitleAndStatus, 'textAlign'>;

const Preview: WidgetPreivew = ({ id }) => {
  const widgetIntance = useWidgetInstance<INodeTitleConfig>(id)!;
  return (
    <div
      className={cn(
        'w-full py-1 text-[30px] font-bold',
        match(widgetIntance?.config.textAlign)
          .with('center', () => 'text-center')
          .otherwise(() => 'text-left'),
      )}
    >
      标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题标题
    </div>
  );
};

const options = [
  { label: '左对齐', value: 'left' },
  { label: '居中对齐', value: 'center' },
];

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance<INodeTitleConfig>()!;

  const setNameVisible = (r: string) => {
    editor.store.updateWidgetInstanceConfig(widgetIntance.id, {
      ...widgetIntance?.config,
      textAlign: r,
    });
  };

  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <Form.Item label="标题文字对齐方式">
        <Radio.Group
          options={options}
          value={widgetIntance?.config.textAlign}
          onChange={(e) => setNameVisible(e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};

export const NodeTitleWidget = defineSystemWidget<INodeTitleConfig>()({
  id: 'NodeTitle',
  title: '标题',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#9283F7"
        icon={
          <RegularIcon type="iconneirongyepeizhi-biaoti" color={'#ffffffcc'} />
        }
      />
    );
  },
  basic: {
    defaultHeight: 2,
    defaultWidth: 12,
    minHeight: 2,
    minWidth: 6,
    maxCount: 1,
    maxHeight: 5,
    dynamicHeight: true,
  },
  metadata: {
    textAlign: 'left',
  },
  preview: Preview,
  setting: Setting,
});
