import ProForm from '@ant-design/pro-form';
import type { ViewList } from '@linkpi/core';
import { useMount } from 'ahooks';
import { isEmpty } from 'ramda';
import type { FC } from 'react';

import { ConditionSetting } from '../ConditionSetting';
import { showSettingModal } from '../SettingModal';

import styles from './styles.less';

const CreationSetting: FC = () => {
  const form = ProForm.useFormInstance();
  const value = form.getFieldsValue();

  ProForm.useWatch(['conditions']);
  ProForm.useWatch(['parentType']);

  useMount(() => {
    form.setFieldsValue({
      parentType: 'current',
      conditions: [
        {
          op: 'intersect',
          key: 'templateId',
          input: [],
        },
      ],
    });
  });

  return (
    <>
      {/* HACK 拉平数据 */}
      {isEmpty(value) || (
        <ConditionSetting
          type="pure"
          value={value}
          onChange={(v) => {
            form.setFieldsValue(v);
          }}
        />
      )}
      <ProForm.Item name={['parentType']} hidden />
      <ProForm.Item
        name={['conditions']}
        className={styles.hiddeContent}
        rules={[
          {
            required: true,
            validator(rule, _value: ViewList.ViewconditionV2, callback) {
              const templateItem = _value.find((i) => i.key === 'templateId');
              if (!templateItem) {
                callback('请选择主题类型！');
                return;
              }
              if (isEmpty(templateItem?.input)) {
                callback('请选择主题类型！');
                return;
              }
              callback();
            },
          },
        ]}
      />
    </>
  );
};

export function showViewSettingModal<T>({ title }: { title: string }) {
  return showSettingModal<T>({ title, formContent: CreationSetting });
}
