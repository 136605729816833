import { ProFormGroup, ProFormRadio } from '@ant-design/pro-form';
import type { CurrentUser } from '@linkpi/core';
import { THEME_STATUS } from '@linkpi/core';
import {
  Button,
  Checkbox,
  InputNumber,
  message,
  Select,
  Switch,
  TimePicker,
  TreeSelect,
} from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';

import TreeTableCondition from '@/components/TreeTableCondition';
import TreeTableMatch from '@/components/TreeTableMatch';
import {
  getMatchingPropOption,
  getMatchingTypeOption,
} from '@/pages/space/components/TemplateProp/components/getMatchingPropOption';
import MatchingConditions from '@/pages/space/components/TemplateProp/components/MatchingConditions';
import QuoteFilter from '@/pages/space/components/TemplateProp/components/QuoteFilter';
import type {
  BasicProp,
  PropOption,
  QuotePropOption,
  TemplateInfo,
  TreeNode,
} from '@/pages/space/components/TemplateProp/components/TempPropModal/types';
import {
  STATUS_QUOTE_PROPS,
  SYS_QUOTE_PROPS,
} from '@/pages/space/components/TemplateProp/constants';
import {
  outerTriggerUrl,
  queryDbConfig,
  queryTableHeader,
  queryTableHeaderMap,
} from '@/services/template';
import { genCondition } from '@/utils/utils';

import { useTempPropConfig } from '../hooks';
import { ConditionMatchingEnum } from './ConditionMatchingEnum';

interface IConditionMatching {
  openConditionMatching: (p: BasicProp) => void;
  matchingNodes: TreeNode[];
  rootId: string;
  templates: CurrentUser.TemplateInfo[];
  orgId: string;
  conditionProps: PropOption[];
  tempMap: Record<string, TemplateInfo>;
  vipLevel: number;
  currentTemplate: any;
  setConditionForTreeTable: any;
}

export const ConditionMatching: FC<IConditionMatching> = (props) => {
  const {
    setChanged,
    propParams: params,
    setPropParams: setParams,
    spaceUserList,
    noPermissionUserCanRead,
    setNoPermissionUserCanReadAtom,
  } = useTempPropConfig();
  const {
    openConditionMatching,
    matchingNodes,
    rootId,
    templates,
    orgId,
    conditionProps,
    tempMap,
    vipLevel,
    currentTemplate,
    setConditionForTreeTable,
  } = props;
  const [hasRequested, setHasRequested] = useState(false);
  const [tableHeaders, setTableHeaders] = useState<any>([]);
  const [tableHeaderMaps, setTableHeaderMaps] = useState<any>([]);
  const [dbConfig, setDbConfig] = useState<any>([]);
  const [externalDataMatchProp, setExternalDataMatchProp] = useState<any>([]);
  const [externalDataMatchingType, setExternalDataMatchingType] = useState<any>(
    [],
  );
  const [url, setUrl] = useState<string>('');
  const [previousThId, setPreviousThId] = useState<string | null>(null);
  const [previousTableId, setPreviousTableId] = useState<string | null>(null);

  // genCondition(treeTableConditions, treeTableMatchs, getTable(), currentTemplate.template_id)
  const [treeTableConditions, setTreeTableConditions] = useState<any[]>([]);
  const [treeTableMatchs, setTreeTableMatchs] = useState<any[]>([]);

  interface MatchingTypeOption {
    label: string;
    value: string;
  }

  interface ExternalDataMatchingTypeSource {
    user: MatchingTypeOption[];
    datetime: MatchingTypeOption[];
    enum: MatchingTypeOption[];
    number: MatchingTypeOption[];
    text: MatchingTypeOption[];
    formula_text: MatchingTypeOption[];
    formula_number: MatchingTypeOption[];
    attachment: MatchingTypeOption[];
    positioning: MatchingTypeOption[];
    address: MatchingTypeOption[];
    auto_inc: MatchingTypeOption[];
    currencyV2: MatchingTypeOption[];
    rich_text: MatchingTypeOption[];
  }

  const externalDataMatchingTypeSource: ExternalDataMatchingTypeSource = {
    user: [{ label: '匹配原值', value: '' }],
    datetime: [
      { label: '最大', value: 'max' },
      { label: '最小', value: 'min' },
      { label: '平均', value: 'avg' },
      { label: '计数', value: 'count' },
    ],
    enum: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    number: [
      { label: '求和', value: 'sum' },
      { label: '最大', value: 'max' },
      { label: '最小', value: 'min' },
      { label: '平均', value: 'avg' },
      { label: '计数', value: 'count' },
    ],
    text: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    formula_text: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    formula_number: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    attachment: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    positioning: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    address: [
      { label: '最大', value: 'max' },
      { label: '最小', value: 'min' },
      { label: '平均', value: 'avg' },
      { label: '计数', value: 'count' },
    ],
    auto_inc: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    currencyV2: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
    rich_text: [
      { label: '匹配原值', value: '' },
      { label: '计数', value: 'count' },
    ],
  };

  const dataType = [
    {
      label: '引用主题类型',
      value: 0,
    },
    {
      label: '引用数据表',
      value: 1,
    },
  ];

  const updateCycleOptions = [
    {
      label: '每小时更新',
      value: 0,
    },
    {
      label: '每天更新',
      value: 1,
    },
    {
      label: '自定义',
      value: 2,
    },
  ];
  const customUpdateCycleOptions = [
    {
      label: '分钟',
      value: 0,
    },
    {
      label: '小时',
      value: 1,
    },
    {
      label: '天',
      value: 2,
    },
  ];

  useEffect(() => {
    const requestQueryTableHeader = async () => {
      if (params.trigger_outer_url) setUrl(params.trigger_outer_url);

      const res = await queryTableHeader(orgId);
      if (res.status === 'ok') {
        setTableHeaders(res.data);
        setHasRequested(true);
        requestQueryDbConfig(res.data);
      }
    };
    if (params.dataType === 1 && !hasRequested) {
      requestQueryTableHeader();
    }

    if (params.dataType === 0 && params.th_id) {
      setPreviousThId(params.th_id);
      setPreviousTableId(params.table_id);
      setParams({ ...params, th_id: null, table_id: null });
    }
    if (params.dataType === 1 && !params.th_id && previousThId) {
      setParams({
        ...params,
        th_id: previousThId ?? params.th_id,
        table_id: previousTableId ?? params.table_id,
      });
      requestQueryTableHeaderMap(previousThId ?? params.th_id);
    }
  }, [params.dataType, hasRequested]);

  const matchingTemplate = useMemo(() => {
    if (params.matchingTemplate && tempMap[params.matchingTemplate])
      return tempMap[params.matchingTemplate];
    return null;
  }, [params.matchingTemplate, tempMap]);

  const [matchingPropOptions, matchingProps] = useMemo(() => {
    if (!matchingTemplate) return [[], []];
    const mp: QuotePropOption[] = [];
    // 标题 index-3
    const po = [
      {
        index: '-3',
        type: 'text',
        name: matchingTemplate.title_setting?.titleAlias || '标题',
        sort: -1,
      },
      {
        index: '-1000',
        type: 'text',
        name: '状态',
        sort: -2,
      },
    ];
    // 属性
    matchingTemplate.prop.forEach((p, i) => {
      if (p?.type) {
        mp.push({
          ...p,
          index: matchingTemplate.template_id.concat('-', String(i)),
          sort: p.sort ?? i,
        });
      }
    });
    // @ts-ignore
    mp.sort((b, a) => b.sort - a.sort);
    return [[...po, ...SYS_QUOTE_PROPS, ...STATUS_QUOTE_PROPS, ...mp], mp];
  }, [matchingTemplate]);

  const matchingPropOptionByCurrentType = useMemo(
    // @ts-ignore
    () => getMatchingPropOption(params.type, matchingPropOptions, tempMap),
    [params.type, matchingPropOptions],
  );
  const matchingTypeOption = useMemo(() => {
    const _matchingProp = Array.isArray(params.matchingProp)
      ? params.matchingProp?.[0] || ''
      : params.matchingProp;
    // @ts-ignore
    return getMatchingTypeOption(
      params.type,
      _matchingProp,
      matchingTemplate?.prop,
      tempMap,
    );
  }, [params.type, params.matchingProp, matchingTemplate]);

  const handleTemplateChange = (template_id: string) => {
    setParams({
      ...params,
      matchingTemplate: template_id,
      quoteConditions: [],
      conditionProp: [],
      quoteProp: [],
      matchingProp: [],
      conditionCascade: {},
      matchingType: undefined,
      selectType: 0,
      enumColumns: undefined,
    });
  };

  const showSetNoPermissionUserCanRead = () => {
    return (
      params.type === 'enum' &&
      params.conditionMatching &&
      params.selectType === 1
    );
  };

  const handleMatchingProp = (e: string) => {
    let auxiliaryProp = undefined;
    if (e && typeof e === 'string') {
      const [tempId, propIndex] = e.split('-');
      const matchingProp = matchingTemplate?.prop[Number(propIndex)];
      if (tempId === matchingTemplate?.template_id && matchingProp) {
        auxiliaryProp = matchingProp.defAuxProp;
        if (
          (params.type === 'number' || params.type === 'currency') &&
          matchingProp?.type === 'number' &&
          params.type === 'number' &&
          matchingProp.number?.unit !== params.number?.unit
        ) {
          message.warn('引用的属性单位和当前单位不一致！');
        }
      }
    }

    // @ts-ignore
    const types = getMatchingTypeOption(
      params.type,
      e,
      matchingTemplate?.prop,
      tempMap,
    );
    setParams({
      ...params,
      matchingProp: [e],
      matchingType: types.length ? types[0].value : undefined,
      auxiliaryProp: auxiliaryProp,
    });
  };

  const requestQueryTableHeaderMap = async (
    th_id: string,
    dbConfigData?: any,
    tempTableHeaders?: any,
  ) => {
    const configData = dbConfigData?.data || dbConfig?.data;
    const res = await queryTableHeaderMap(orgId, th_id);
    if (res.status === 'ok') {
      // @ts-ignore
      const matchedData = res.data.flatMap((item: any) => {
        const dbItem = configData?.find(
          (db: any) => db.db_id === item.table.db_id,
        );
        if (!dbItem) {
          return [];
        }
        const { name, tables } = dbItem;
        return tables
          .filter((table: any) => table.table_id === item.table.table_id)
          .map((table: any) => ({
            label: `${name} - ${table.comment || table.table_name}`,
            value: table.table_id,
            table: item.table,
            th_id: th_id,
          }));
      });
      setTableHeaderMaps(matchedData);
      if (dbConfigData) {
        handleTableHeaderMapsChange(
          params.table_id,
          matchedData,
          tempTableHeaders,
        );
      }
    }
  };

  const requestQueryDbConfig = async (tempTableHeaders: any) => {
    const res = await queryDbConfig(orgId);
    if (res.status === 'ok') {
      setDbConfig(res.data);
      if (
        params.dataType === 1 &&
        tableHeaderMaps.length === 0 &&
        params.th_id
      ) {
        requestQueryTableHeaderMap(params.th_id, res.data, tempTableHeaders);
      }
    }
  };

  const getTableHeaders = () => {
    const { th_id } = params;
    if (!th_id) return [];

    const t = tableHeaders.find((i: any) => i.th_id === th_id);
    if (!t) return [];

    return t.headers;
  };
  const getTable = () => {
    const { table_id } = params;
    if (!table_id) return undefined;
    const v = tableHeaderMaps.find((i: any) => i.value === table_id);
    if (!v) return undefined;

    return v.table;
  };
  const treeTableConditionCb = (v: any) => {
    setConditionForTreeTable(
      genCondition(v, treeTableMatchs, getTable(), currentTemplate.template_id),
    );
    setTreeTableConditions(v);
  };

  const treeTableMatchCb = (v: any) => {
    setConditionForTreeTable(
      genCondition(
        treeTableConditions,
        v,
        getTable(),
        currentTemplate.template_id,
      ),
    );
    setTreeTableMatchs(v);
  };

  const handleTableHeaderChange = (th_id: string) => {
    if (th_id !== params.th_id) {
      setParams({ ...params, table_id: null, th_id });
      setPreviousTableId(params.table_id);
      requestQueryTableHeaderMap(th_id);
    }
  };
  const handleTableHeaderMapsChange = (
    table_id: string,
    tempTableHeaderMaps?: any,
    tempTableHeaders?: any,
  ) => {
    const tableHeaderMapsData = tempTableHeaderMaps || tableHeaderMaps;
    const tableHeadersData = tempTableHeaders || tableHeaders;
    const item = tableHeaderMapsData?.find(
      (table: any) => table.value === table_id,
    );

    if (item) {
      const tableHeader = tableHeadersData.find(
        (e: any) => e.th_id === item.th_id,
      );
      const filteredHeaderMap = Object.fromEntries(
        Object.entries(item.table.header_map).filter(
          // @ts-ignore
          ([key, value]) => Object.keys(value).length > 0,
        ),
      );
      const result = tableHeader.headers
        .filter((header: any) => filteredHeaderMap.hasOwnProperty(header.id))
        .map((header: any) => ({
          label: header.name,
          // @ts-ignore
          value: filteredHeaderMap?.[header.id]?.column,
          type: header.type,
          id: header.id,
          db_id: item.table.db_id,
          table_id: table_id,
        }))
        .filter((header: any) => header.value);
      setExternalDataMatchProp(result);
      if (tempTableHeaderMaps) {
        handleMatcProp(params.column_id, result);
      }
    }
    setParams({ ...params, table_id });
  };

  const handleMatcProp = (column: string, tempExternalDataMatchProp?: any) => {
    const newParams = {
      ...params,
      column_id: column,
    };
    const requestOuterTriggerUrl = async (
      db_id: string,
      table_id: string,
      column_id: string,
    ) => {
      const res = await outerTriggerUrl(orgId, db_id, table_id, column_id);
      if (res.status === 'ok') {
        // @ts-ignore
        setUrl(res.url);
        newParams.trigger_outer_url = res.url as string;
      }
    };
    const externalDataMatchPropData =
      tempExternalDataMatchProp || externalDataMatchProp;
    const item = externalDataMatchPropData.find(
      (item: any) => item.value === column,
    );
    if (item.type in externalDataMatchingTypeSource) {
      setExternalDataMatchingType(
        externalDataMatchingTypeSource[
          item.type as keyof ExternalDataMatchingTypeSource
        ],
      );
      requestOuterTriggerUrl(item.db_id, item.table_id, item.value);
    } else {
      console.error('Invalid type:', item.type);
    }
    if (params.externalDataRemoteRefresh !== false) {
      newParams.trigger_id = item.table_id;
      newParams.trigger_range = 'table';
    }
    setParams(newParams);
  };

  const copyUrl = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        message.success('复制成功');
      })
      .catch((error) => {
        console.error('复制失败:', error);
      });
  };
  const handleTimePickerChange = (time: moment.Moment | null) => {
    const timestamp = time ? time.valueOf() : null;
    setParams({ ...params, firstExecutionTime: timestamp });
  };
  return (
    <Fragment>
      <div className={'prop-divider'} />
      {params.type !== 'quote' ? (
        <div className={'prop-item horizontal'}>
          <div className={'prop-item-label'}>一次性引用</div>
          <Switch
            size={'small'}
            checked={params.conditionMatching}
            onChange={(e) => {
              const p = { ...params, conditionMatching: e };
              if (p.conditionMatching) openConditionMatching(p);
              setParams(p);
            }}
          />
        </div>
      ) : null}

      {params.conditionMatching || params.dataType === 1 ? (
        <div className={'prop-condition'}>
          <ProFormGroup size={120}>
            <ProFormRadio.Group
              value={params.dataType ?? 0}
              options={dataType}
              onChange={(e) =>
                setParams({ ...params, dataType: e.target.value })
              }
            />
          </ProFormGroup>

          {params.dataType === 1 && (
            <>
              <div className={'prop-condition-item'}>
                <div className={'prop-condition-item-label'}>表头</div>
                <div className={'prop-condition-item-input'}>
                  <Select
                    placeholder={`请选择表头`}
                    showSearch
                    onChange={handleTableHeaderChange}
                    value={params.th_id}
                    filterOption={(input, option: any) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    options={tableHeaders.map((header: any) => ({
                      label: header.name,
                      value: header.th_id,
                    }))}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
              <div className={'prop-condition-item'}>
                <div className={'prop-condition-item-label'}>表头映射</div>
                <div className={'prop-condition-item-input'}>
                  <Select
                    placeholder={`请选择表头映射`}
                    showSearch
                    filterOption={(input, option: any) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(table_id) =>
                      handleTableHeaderMapsChange(table_id)
                    }
                    value={params.table_id}
                    options={tableHeaderMaps}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
              <div className={'prop-condition-item'}>
                <div className={'prop-condition-item-label'}>筛选条件</div>
                {tableHeaderMaps.length ? (
                  <TreeTableCondition
                    cb={treeTableConditionCb}
                    table={getTable()}
                    headers={getTableHeaders()}
                    params={params}
                  />
                ) : null}
              </div>
              <div className={'prop-condition-item'}>
                <div className={'prop-condition-item-label'}>匹配条件</div>
                {tableHeaderMaps.length ? (
                  <TreeTableMatch
                    cb={treeTableMatchCb}
                    template={currentTemplate}
                    headers={getTableHeaders()}
                    params={params}
                  />
                ) : null}
              </div>
              <div className={'prop-condition-item'} key={'matchingProp'}>
                <div className={'prop-condition-item-label'}>引用属性</div>
                <div className={'prop-condition-item-input'}>
                  <Select
                    placeholder={`请选择引用的属性`}
                    value={params.column_id}
                    onChange={(column) => handleMatcProp(column)}
                    options={externalDataMatchProp}
                    filterOption={(input, option: any) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
              <div className={'prop-condition-item'} key={'matchingType'}>
                <div className={'prop-condition-item-label'}>引用方式</div>
                <div className={'prop-condition-item-input'}>
                  <Select
                    placeholder={`请选择引用方式`}
                    value={params.externalDataMatchingType}
                    onChange={(e) =>
                      setParams({ ...params, externalDataMatchingType: e })
                    }
                    options={externalDataMatchingType}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
            </>
          )}
          {(params.dataType !== 1 || params.dataType === null) && (
            <>
              <div className={'prop-condition-item'} key={'matchingSource'}>
                <div className={'prop-condition-item-label'}>引用来源</div>
                <div className={'prop-condition-item-input'}>
                  <TreeSelect
                    placeholder={`请选择引用的主题`}
                    treeData={matchingNodes}
                    showSearch={true}
                    filterTreeNode={(e, treeNode) =>
                      typeof treeNode.title === 'string' &&
                      !!~treeNode.title.toLowerCase().indexOf(e.toLowerCase())
                    }
                    value={params.matchingSource || rootId}
                    onChange={(e) => {
                      if (e !== rootId) {
                        message.warn('引用来源将只引用第一层子主题');
                      }
                      setParams({ ...params, matchingSource: e });
                    }}
                  />
                </div>
              </div>

              <div className={'prop-condition-item'} key={'matchingTemplate'}>
                <div className={'prop-condition-item-label'}>主题类型</div>
                <div className={'prop-condition-item-input'}>
                  <Select
                    placeholder={`请选择引用的主题类型`}
                    showSearch
                    onChange={(e) => handleTemplateChange(e)}
                    filterOption={(input, option) =>
                      typeof option?.label === 'string' &&
                      option?.label
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    value={params.matchingTemplate}
                    options={templates
                      .filter(
                        (t) =>
                          t.status === THEME_STATUS.init ||
                          t.status === THEME_STATUS.lock,
                      )
                      .map((template) => {
                        return {
                          label: template.name,
                          value: template.template_id,
                        };
                      })}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
              <div className={'prop-condition-item'} key={'quote'}>
                <div className={'prop-condition-item-label'}>筛选条件</div>
                <div className={'prop-condition-item-input'}>
                  <QuoteFilter
                    conditions={params.quoteConditions || []}
                    setConditions={(
                      e: CurrentUser.TemplateProp['quoteConditions'],
                    ) => setParams({ ...params, quoteConditions: e })}
                    template={tempMap[params.matchingTemplate || '']}
                    tempMap={tempMap}
                    orgUsers={spaceUserList}
                    orgId={orgId}
                  />
                </div>
              </div>

              <MatchingConditions
                params={params}
                conditionProps={conditionProps}
                matchingProps={matchingProps}
                setParams={(p: BasicProp) => setParams(p)}
                tempMap={tempMap}
              />
              <div className={'prop-condition-item'} key={'matchingProp'}>
                <div className={'prop-condition-item-label'}>引用属性</div>
                <div className={'prop-condition-item-input'}>
                  <Select
                    placeholder={`请选择引用的属性`}
                    value={params.matchingProp?.[0]}
                    onChange={(e) => handleMatchingProp(e)}
                    options={matchingPropOptionByCurrentType}
                    filterOption={(input, option: any) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
              <div className={'prop-condition-item'} key={'matchingType'}>
                <div className={'prop-condition-item-label'}>引用方式</div>
                <div className={'prop-condition-item-input'}>
                  <Select
                    placeholder={`请选择引用方式`}
                    value={params.matchingType}
                    onChange={(e) => setParams({ ...params, matchingType: e })}
                    options={matchingTypeOption}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </div>
              </div>
              {(params.type === 'enum' || params.type === 'tag') &&
              (params.matchingType === 0 || params.matchingType === -1) ? (
                <ConditionMatchingEnum
                  matchingTemplate={matchingTemplate}
                  matchingProps={matchingProps}
                />
              ) : null}
            </>
          )}

          {params.type === 'enum' ||
          params.type === 'tag' ||
          params.type === 'address' ? (
            <div className={'prop-condition-check'}>
              <Checkbox
                checked={params.matchingAdd}
                onChange={(e) =>
                  setParams({ ...params, matchingAdd: e.target.checked })
                }
              >
                可新增选项（同时在来源主题下新增一个子主题）
              </Checkbox>
            </div>
          ) : null}
          {params.dataType === 1 && (
            <div className={'data-type-parent'}>
              {params.type === 'quote' ? (
                <div className={'prop-condition-check'}>
                  <Checkbox
                    checked={params.externalDataRemoteRefresh !== false}
                    onChange={(e) =>
                      setParams({
                        ...params,
                        externalDataRemoteRefresh: e.target.checked,
                      })
                    }
                  >
                    实时更新
                  </Checkbox>
                </div>
              ) : null}

              <div>
                <Checkbox
                  checked={params.externalTrigger}
                  onChange={(e) =>
                    setParams({ ...params, externalTrigger: e.target.checked })
                  }
                >
                  外部触发器
                </Checkbox>
              </div>
              <div style={{ marginLeft: '40px' }}>
                <Checkbox
                  checked={params.scheduleUpdate}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setParams({
                        ...params,
                        scheduleUpdate: e.target.checked,
                        timed_trigger: {
                          anchor_time: moment().valueOf(),
                          updateCycleType: 0,
                        },
                        firstExecutionTime: moment().valueOf(),
                      });
                    } else {
                      setParams({
                        ...params,
                        scheduleUpdate: e.target.checked,
                      });
                    }
                  }}
                >
                  定时更新
                </Checkbox>
              </div>
            </div>
          )}
          {(params.externalTrigger || params.scheduleUpdate) &&
            params.dataType === 1 && (
              <div
                style={{
                  marginLeft: '76px',
                  background: '#F3F4F6',
                  borderRadius: '8px',
                  padding: '16px',
                }}
              >
                {params.externalTrigger && (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '424px',
                          height: '32px',
                          background: '#F9FAFC',
                          borderRadius: '8px',
                          border: '1px solid #EBEDF0',
                          overflow: 'hidden',
                          lineHeight: '32px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          padding: '0 8px',
                        }}
                      >
                        {url}
                      </div>
                      <Button
                        onClick={copyUrl}
                        type={'default'}
                        style={{
                          background: '#316EF5',
                          borderRadius: '8px',
                          marginLeft: '16px',
                          width: '88px',
                          height: '32px',
                          fontSize: '14px',
                          color: '#FFFFFF',
                          lineHeight: '20px',
                          textAlign: 'justify',
                        }}
                      >
                        复制链接
                      </Button>
                    </div>
                  </>
                )}

                {params.scheduleUpdate && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: params.externalTrigger ? '24px' : '',
                      }}
                    >
                      <span style={{ fontSize: '14px', color: '#767C88' }}>
                        更新周期
                      </span>
                      {params.updateCycleType !== 2 && (
                        <Select
                          style={{
                            marginLeft: '54px',
                            width: '418px',
                            height: '32px',
                            background: '#FFFFFF',
                            borderRadius: '8px',
                            border: '1px solid #EBEDF0',
                          }}
                          value={params.updateCycleType ?? 0}
                          onChange={(e) =>
                            setParams({ ...params, updateCycleType: e })
                          }
                          options={updateCycleOptions}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        />
                      )}

                      {params.updateCycleType === 2 && (
                        <div>
                          <Select
                            style={{
                              marginLeft: '54px',
                              width: '140px',
                              height: '32px',
                              background: '#FFFFFF',
                              borderRadius: '8px',
                              border: '1px solid #EBEDF0',
                            }}
                            value={params.updateCycleType ?? 0}
                            onChange={(e) =>
                              setParams({ ...params, updateCycleType: e })
                            }
                            options={updateCycleOptions}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                          />
                          <span
                            style={{
                              marginLeft: '48px',
                              fontSize: '14px',
                              color: '#767C88',
                            }}
                          >
                            每
                          </span>
                          <InputNumber
                            defaultValue={params.customUpdateCycleValue ?? 1}
                            min={1}
                            style={{
                              marginLeft: '6px',
                              width: '100px',
                              height: '32px',
                              background: '#FFFFFF',
                              borderRadius: '8px',
                              border: '1px solid #EBEDF0',
                            }}
                            onChange={(e) =>
                              setParams({
                                ...params,
                                customUpdateCycleValue: e,
                              })
                            }
                          />
                          <Select
                            style={{
                              marginLeft: '8px',
                              width: '100px',
                              height: '32px',
                              background: '#FFFFFF',
                              borderRadius: '8px',
                              border: '1px solid #EBEDF0',
                            }}
                            value={params.customUpdateCycle ?? 1}
                            onChange={(e) =>
                              setParams({ ...params, customUpdateCycle: e })
                            }
                            options={customUpdateCycleOptions}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                          />
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      <span style={{ fontSize: '14px', color: '#767C88' }}>
                        第一次执行时间
                      </span>
                      <TimePicker
                        style={{
                          marginLeft: '12px',
                          width: '418px',
                          height: '32px',
                          background: '#FFFFFF',
                          borderRadius: '8px',
                          border: '1px solid #EBEDF0',
                        }}
                        value={
                          params.firstExecutionTime
                            ? moment(params.firstExecutionTime)
                            : moment()
                        }
                        onChange={handleTimePickerChange}
                        format="HH:mm"
                      />
                    </div>
                  </>
                )}
              </div>
            )}

          {(params.dataType !== 1 || params.dataType === null) && (
            <>
              <div className={'prop-condition-check'}>
                <Checkbox
                  checked={params.quoteNodesVisible}
                  onChange={(e) =>
                    setParams({
                      ...params,
                      quoteNodesVisible: e.target.checked,
                    })
                  }
                >
                  可查看主题列表
                </Checkbox>
                {((params.type === 'enum' ||
                  params.type === 'tag' ||
                  params.type === 'address') &&
                  (params.matchingType === 0 || params.matchingType === -1)) ||
                (params.type === 'quote' && params.matchingType === 0) ? (
                  <Checkbox
                    checked={params.quoteOptionVisible}
                    onChange={(e) =>
                      setParams({
                        ...params,
                        quoteOptionVisible: e.target.checked,
                      })
                    }
                  >
                    点击选项可查看主题
                  </Checkbox>
                ) : null}
              </div>
              {params.type === 'quote' ? (
                <div className={'prop-condition-check'}>
                  <Checkbox
                    checked={params.remoteRefresh !== false}
                    onChange={(e) =>
                      setParams({ ...params, remoteRefresh: e.target.checked })
                    }
                  >
                    实时更新
                  </Checkbox>
                </div>
              ) : null}
              <div className={'prop-condition-check'}>
                <Checkbox
                  checked={params.quickQuote === true}
                  onChange={(e) =>
                    setParams({ ...params, quickQuote: e.target.checked })
                  }
                >
                  快速更新
                </Checkbox>
              </div>
              {showSetNoPermissionUserCanRead() && (
                <div className={'prop-condition-check'}>
                  <Checkbox
                    checked={noPermissionUserCanRead}
                    onChange={(e) =>
                      setNoPermissionUserCanReadAtom(e.target.checked)
                    }
                  >
                    无权限成员可读取主题数据（该设置对所有引用该主题类型的属性生效）
                  </Checkbox>
                </div>
              )}
            </>
          )}
        </div>
      ) : null}
    </Fragment>
  );
};
