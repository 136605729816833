import { BugPlay } from 'lucide-react';
import { type FC, memo, type PropsWithChildren } from 'react';

import type { PiButtonProps } from '../Button';
import { PiButton } from '../Button';
import type { JsonViewerModalProps } from './Modal';
import { viewJSON } from './Modal';

export const ViewJSONButton: FC<
  PropsWithChildren<JsonViewerModalProps & PiButtonProps>
> = memo(({ data, children, title, ...rest }) => {
  return (
    <PiButton onClick={() => viewJSON(data, { title })} {...rest}>
      {children || (
        <div className="inline-flex items-center h-full leading-none">
          <BugPlay size={14} />
        </div>
      )}
    </PiButton>
  );
});
