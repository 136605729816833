import { Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';
import { TemplateSelect } from '@/components/LinkPiForm';

import imgSrc from '../../assets/mainBodyLeft.png';
import imgRightSrc from '../../assets/mainBodyRight.png';
import { WidgetIcon } from '../../components';
import { BasicSetting } from '../../components/BasicSetting';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type ISubThemeTreeWidget = {
  templateCondition?: string[];
};

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <img src={imgSrc} style={{ display: 'block', height: 424 }} />
      <img src={imgRightSrc} style={{ display: 'block', height: 424 }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ISubThemeTreeWidget>()!;
  const setTemplate = (ids: string[]) => {
    editor.updateActiveWidgetInstanceConfig<ISubThemeTreeWidget>((c) => {
      c.templateCondition = ids;
    });
  };

  return (
    <Form layout="vertical">
      <BasicSetting type="component" />
      <TemplateSelect
        label="筛选主题类型"
        mode="multiple"
        fieldProps={{
          value: widgetInstance?.config.templateCondition,
          onChange: setTemplate,
        }}
      />
    </Form>
  );
};

export const SubThemeTreeWidget = defineSystemWidget<ISubThemeTreeWidget>()({
  id: 'SubThemeTree',
  title: '子主题树',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#D55EDA"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zizhutishu"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 8,
    defaultWidth: 12,
    minWidth: 4,
    maxCount: 1,
    dynamicHeight: true,
  },
  metadata: {},
  preview: Preview,
  setting: Setting,
});
