import type { WidgetInstanceData } from '@linkpi/core';

import type { WidgetInstance } from '../models/Store';
import type { IWidget } from '../models/WidgetManager';
import * as widgets from '../widgets';

const widgetList = Object.values(widgets);

export type IWidgetType = (typeof widgetList)[number];
export type IWidgetIdType = IWidgetType['id'];
type IWidgetTypeToWidgetInstanceData<T extends IWidgetType> =
  T extends IWidget<any, any> ? WidgetInstanceData<T['metadata'], T['id']> : never;

export type IWidgetInstanceData = IWidgetTypeToWidgetInstanceData<IWidgetType>;

type IWidgetTypeToWidgetInstance<T extends IWidgetType> =
  T extends IWidget<any, any> ? WidgetInstance<T['metadata'], T['id']> : never;
export type IWidgetInstance = IWidgetTypeToWidgetInstance<IWidgetType>;
