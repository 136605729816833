import type { WidgetInstanceData } from '@linkpi/core';
import { useMountEffect, useThrottledEffect } from '@react-hookz/web';
import { useImmerState } from '@shrugsy/use-immer-state';
import { useMemoizedFn } from 'ahooks';
import { Select, Space } from 'antd';
import { freeze } from 'immer';
import { type FC, useEffect, useMemo, useState } from 'react';
import { match } from 'ts-pattern';

import { PiButton } from '@/components/Button';
import { ConditionFilterValue } from '@/components/ConditionFilter';
import { cn } from '@/utils/utils';

import { useSetGlobalConditionConfig } from './hook';
import type {
  GlobalConditionFilterValue,
  IGlobalConditionFilterConfig,
} from './types';

type GlobalConditionFilterProps = {
  widgetInstance: WidgetInstanceData<IGlobalConditionFilterConfig>;
  value?: GlobalConditionFilterValue;
  onChange?: (value: GlobalConditionFilterValue) => void;
};

export const GlobalConditionFilter: FC<GlobalConditionFilterProps> = ({
  widgetInstance,
  onChange,
  value: _value,
}) => {
  const setGlobalConditionConfig = useSetGlobalConditionConfig();

  useEffect(() => {
    setGlobalConditionConfig(
      widgetInstance.id,
      widgetInstance.config.conditionList,
    );
  }, [
    widgetInstance.id,
    widgetInstance.config.conditionList,
    setGlobalConditionConfig,
  ]);

  const defaultValue = useMemo(() => {
    // return {};
    const result = widgetInstance.config.conditionList?.reduce((acc, item) => {
      acc[item.id] = {
        id: item.id,
        propKey: item.propKey!,
        value: item.defaultValue,
      };
      return acc;
    }, {} as GlobalConditionFilterValue);
    return freeze(result);
  }, [widgetInstance.config.conditionList]);

  const [value, setValue] = useImmerState<GlobalConditionFilterValue>(
    _value || defaultValue,
  );

  const onItemChange = (id: string, value: any[]) => {
    setValue((draft) => {
      if (!draft[id]) {
        draft[id] = {
          id,
          propKey: widgetInstance.config.conditionList!.find(
            (item) => item.id === id,
          )!.propKey!,
          value: [],
        };
      }
      draft[id].value = value;
    });
  };

  const onSubmit = useMemoizedFn(() => {
    onChange?.(value);
  });

  const [resetKey, setResetKey] = useState(0);
  const onReset = useMemoizedFn(() => {
    setValue(defaultValue);
    onChange?.(defaultValue);
    setResetKey((prev) => prev + 1);
  });

  /**
   * 避免输入框导致频繁提交
   */
  useThrottledEffect(
    () => {
      if (widgetInstance.config.enableSubmitButton) return;

      onSubmit();
    },
    [onSubmit, value, widgetInstance.config.enableSubmitButton],
    1000,
  );

  useMountEffect(() => {
    onSubmit();
  });

  return (
    <div className="flex gap-4 flex-wrap items-baseline text-[#242d3f]">
      {widgetInstance.config.conditionList?.map((item) => (
        <div
          key={item.id}
          className={cn(
            'flex items-baseline gap-[6px] h-8 overflow-y-hidden',
            match(widgetInstance.config.arrangement)
              .with('one', () => 'w-full')
              .with('two', () => 'w-[calc(50%-16px)]')
              .otherwise(() => 'w-[calc(33.33%-10.66px)]'),
          )}
        >
          {!!item.name && <div>{item.name}</div>}
          {item.propKey && widgetInstance.config.templateId ? (
            <div className="flex-auto">
              <ConditionFilterValue
                key={resetKey + item.propKey}
                templateId={widgetInstance.config.templateId}
                propKey={item.propKey}
                // TODO 虽然语意上是受控的，但实际不受控
                value={value[item.id]?.value}
                onChange={(v) => onItemChange(item.id, v)}
                multiple={item.multiple}
                onlyShowAuxInEnumProp={item.onlyShowAuxInEnumProp}
              />
            </div>
          ) : (
            <Select placeholder="请选择" className="flex-auto" />
          )}
        </div>
      ))}
      {!!widgetInstance.config.enableSubmitButton && (
        <Space>
          <PiButton onClick={onReset}>重置</PiButton>
          <PiButton type="primary" onClick={onSubmit}>
            查询
          </PiButton>
        </Space>
      )}
    </div>
  );
};
