import { PlusOutlined } from '@ant-design/icons';
import { InlineErrorFormItem } from '@ant-design/pro-utils';
import type { ConditionRuleConfigV2 } from '@linkpi/core';
import { Button, Form, Select } from 'antd';
import type { FC } from 'react';

import type { PropRuleConditionModalProps } from '@/components';
import { ConditionRulePanel, showConditionRuleModal } from '@/components';
import { filterChecker } from '@/components/CustomButton/utils';
import { ConditionItem, SelectGroup } from '@/components/EnhanceForm';
import { selectSearchFn } from '@/components/LinkPiForm/utils';
import { getCurrentUserInfo } from '@/hook';

import type { ITabSectionContentSetting } from '../../ContentSetting';

import styles from '@/pages/space/propConditionRule/components/PropRuleConditionModal/styles.less';

const FormItem = Form.Item;
const FormList = Form.List;

const createDefaultResultValue = () => ({
  visible: 'hidden',
});

export type SubTabsRuleConditionResultFormProp = {
  tabConfig: ITabSectionContentSetting;
};

const SubTabsRuleConditionResultForm: FC<SubTabsRuleConditionResultFormProp> = ({ tabConfig }) => {
  return (
    <FormList name="results" initialValue={[createDefaultResultValue()]}>
      {(fields, { add, remove }) => [
        ...fields.map((field) => (
          <ConditionRulePanel.Card
            color="#FFFAF2"
            key={field.key}
            showDelete={fields.length > 1}
            onDelete={() => remove(field.name)}
          >
            <FormItem noStyle name={[field.name, 'type']} initialValue={'tabVisible'}>
              <Select
                className="full-w"
                allowClear={false}
                options={[
                  // TODO: 标签页
                  { value: 'tabVisible', label: '显示/隐藏标签' },
                ]}
              />
            </FormItem>
            <ConditionItem
              enble={(form) => form.getFieldValue(['results', field.name, 'type']) === 'tabVisible'}
            >
              <SelectGroup>
                <InlineErrorFormItem
                  {...field}
                  name={[field.name, 'visible']}
                  initialValue={'hidden'}
                >
                  <Select
                    className="full-w"
                    placeholder="请选择显示/隐藏"
                    options={[
                      // { value: 'show', label: '显示' },
                      { value: 'hidden', label: '隐藏' },
                    ]}
                  />
                </InlineErrorFormItem>
                <InlineErrorFormItem
                  {...field}
                  rules={[{ required: true, type: 'array', message: '请选择标签' }]}
                  name={[field.name, 'tabs']}
                >
                  <Select
                    className="full-w"
                    placeholder="请选择标签"
                    mode="multiple"
                    filterOption={selectSearchFn}
                    options={tabConfig.tabs.map((t) => ({
                      label: t.name,
                      value: t.id,
                    }))}
                  />
                </InlineErrorFormItem>
              </SelectGroup>
            </ConditionItem>
          </ConditionRulePanel.Card>
        )),
        <div
          key="addResult"
          className={styles['add-result']}
          onClick={() => add(createDefaultResultValue())}
        >
          <Button icon={<PlusOutlined color="#316EF5" />} type="dashed">
            添加操作
          </Button>
        </div>,
      ]}
    </FormList>
  );
};

export const showTabConditionRuleModal = ({
  tabConfig,
  ...props
}: Omit<PropRuleConditionModalProps, 'resultForm'> & SubTabsRuleConditionResultFormProp) =>
  showConditionRuleModal<ConditionRuleConfigV2.TabSectionConditionResults>({
    resultForm: <SubTabsRuleConditionResultForm tabConfig={tabConfig} />,
    ...props,
  });

export const checkTabsConditionRule = (options: {
  ruleConfig: ConditionRuleConfigV2.ConditionRuleConfigV2<ConditionRuleConfigV2.TabSectionConditionResults>;
  tempInfo: TemplateInfo;
  tempMap: Record<string, TemplateInfo>;
  // 属性值
  nodeMetaData: PiNodeMetaData | {};
}): boolean => {
  const { ruleConfig, nodeMetaData } = options;

  const currentUserInfo = getCurrentUserInfo();
  if (!('e' in nodeMetaData)) return false;
  return filterChecker(ruleConfig.condition)(nodeMetaData, {
    roles: currentUserInfo?.group_ids || [],
  });
};
