import { useMemoizedFn } from 'ahooks';
import { Form } from 'antd';
import { type FC, memo, useEffect } from 'react';

import { PiButton } from '@/components/Button';

import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { IConditionSetting } from '../ConditionSetting';
import type { IDisplaySetting } from '../DisplaySetting';
import type { TableRule } from './RuleListModal';
import { getSetChildThemeRules } from './RuleListModal';

export type ITableConditionRuleSetting = IConditionSetting &
  IDisplaySetting & {
    conditionRules?: TableRule[];
  };

const _TableConditionRuleSetting: FC = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ITableConditionRuleSetting>()!;

  const setConditionRules = useMemoizedFn((r: TableRule[]) => {
    editor.store.updateWidgetInstanceConfig<ITableConditionRuleSetting>(widgetInstance.id, {
      ...widgetInstance?.config,
      conditionRules: r,
    });
  });

  const templateId = widgetInstance?.config.conditions.find(
    (i: any) => i.key === 'templateId',
  )?.input;

  useEffect(() => {
    setConditionRules([]);
  }, [setConditionRules, templateId]);

  return (
    <Form.Item label="子表格条件规则">
      <PiButton
        className="full-w"
        onClick={() =>
          getSetChildThemeRules({
            initialValue: widgetInstance.config.conditionRules,
            tableConfig: widgetInstance.config,
          }).then(setConditionRules)
        }
      >
        点击配置
      </PiButton>
    </Form.Item>
  );
};

export const TableConditionRuleSetting = memo(_TableConditionRuleSetting);
