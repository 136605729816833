import { Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import previewImg from '../../assets/verticalTable.png';
import type {
  IConditionSetting,
  IDisplaySetting,
  IMatchingSetting,
  INodeOrderSetting,
  ITableRowSetting,
} from '../../components';
import {
  ConditionSetting,
  DisplaySetting,
  MatchingSetting,
  NameSetting,
  NodeOrderSetting,
  showViewSettingModal,
  TableRowSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';
import type { IOtherSetting } from './OtherSetting';
import OtherSetting from './OtherSetting';

export type IVerticalTableConfig = IConditionSetting &
  ITableRowSetting &
  IDisplaySetting &
  INodeOrderSetting &
  IMatchingSetting &
  IOtherSetting;

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">
      <NameSetting defaultName={'转置表格'} />
      <ConditionSetting />
      <MatchingSetting />
      <TableRowSetting />
      <DisplaySetting />
      <NodeOrderSetting />
      <OtherSetting />
    </Form>
  );
};

const Preview: WidgetPreivew = () => {
  return (
    <div className="flex h-full overflow-hidden justify-center">
      <img src={previewImg} className="block h-[473px]" />
    </div>
  );
};

export const VerticalTableWidget = defineSystemWidget<IVerticalTableConfig>()({
  id: 'VerticalTable',
  title: '转置表格',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#5ABCB0"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zhuanzhibiaoge"
            color="#ffffffcc"
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 6,
    defaultWidth: 6,
    minHeight: 4,
    minWidth: 3,
    dynamicHeight: true,
    settingWidth: 540,
  },
  metadata: {
    // viewable: true,
    parentType: 'current',
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: [],
      },
    ],
    orderBy: '_sys_createTime',
    orderDesc: true,
    matchings: [],
    displayAttrs: [],
    pagination: true,
  },
  preview: Preview,
  setting: Setting,
  onCreate: () =>
    showViewSettingModal<IVerticalTableConfig>({ title: '添加转置表格' }),
});
