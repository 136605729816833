import { assertExists } from '@linkpi/utils';
import { Form, Radio } from 'antd';

import { useActiveWidgetInstance, useEditor } from '../../hooks';

const PaginationOptions = [
  {
    label: '显示',
    value: true,
  },
  {
    label: '不显示',
    value: false,
  },
];

export type IOtherSetting = {
  pagination: boolean;
};

const OtherSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IOtherSetting>();
  assertExists(widgetInstance);

  return (
    <Form.Item label="分页">
      <Radio.Group
        options={PaginationOptions}
        value={widgetInstance.config.pagination ?? true}
        onChange={(e) => {
          editor.updateActiveWidgetInstanceConfig<IOtherSetting>((v) => {
            v.pagination = e.target.value;
          });
        }}
      />
    </Form.Item>
  );
};

export default OtherSetting;
