import type { CurrentUser, OrgUser, ViewList } from '@linkpi/core';
import { getQuoteOriginProp, QUOTE_SYS_PROP } from '@linkpi/core';
import { Select } from 'antd';
import cls from 'classnames';
import { type FC, useMemo } from 'react';

import { cn } from '@/utils/utils';

import type { FilterConditionConfigMapType } from '../ConditionFilter';
import { useConditionFilterConfigContext } from '../hook/useConditionFilterOption';
import ConditionOperator, { getPropTypeOptions } from './ConditionOperator';
import { ConditionValue } from './ConditionValue';

import styles from '../styles.less';

type ConditionItemProp = {
  entry: 'view' | 'propSet';
  condition: ViewList.ViewconditionV2Item;
  filterConditionOptions: {
    label: string;
    options: { label: string; value: string }[];
  }[];
  filterConditionConfigMap: FilterConditionConfigMapType;
  disabled?: boolean;
  orgInfo: CurrentUser.OrgInfo;
  curTemplate: CurrentUser.TemplateInfo;
  tempMap: Record<string, CurrentUser.TemplateInfo>;
  userMap: Record<string, OrgUser.OrgUserItem>;
  getTreeData?: () => any[];
  onChange?: (condition: ConditionItemProp['condition'] | any) => void;
  remove?: () => void;
  userGroups?: any;
  provData?: CurrentUser.AreaData[];
  sysTags: any;
  allowTempIds?: string[];
  nodeSelectExtraCurrent?: string;
  hideDelete?: boolean;
};

const ConditionItem: FC<ConditionItemProp> = (props) => {
  const {
    entry,
    condition,
    filterConditionOptions: _filterConditionOptions,
    filterConditionConfigMap: _filterConditionConfigMap,
    orgInfo,
    getTreeData,
    onChange = () => {},
    remove,
    disabled,
    curTemplate,
    tempMap,
    userGroups = [],
    provData = [],
    userMap,
    sysTags,
    allowTempIds,
    nodeSelectExtraCurrent,
    hideDelete = false,
  } = props;

  const { key, op, input } = condition;

  const { experimental_enableUserProperty } = useConditionFilterConfigContext();

  const filterConditionOptions = useMemo(() => {
    if (experimental_enableUserProperty)
      return [
        ..._filterConditionOptions,
        {
          label: '当前用户相关',
          options: [{ label: '所属角色', value: 'currentUserRoles' }],
        },
      ];
    return _filterConditionOptions;
  }, [_filterConditionOptions, experimental_enableUserProperty]);

  const filterConditionConfigMap = useMemo(() => {
    if (experimental_enableUserProperty) {
      return {
        ..._filterConditionConfigMap,
        currentUserRoles: {
          name: '用户角色',
          type: 'userRoles',
          options: [],
          data: { multiple: true },
        },
      };
    }
    return _filterConditionConfigMap;
  }, [_filterConditionConfigMap, experimental_enableUserProperty]);

  const getFilterConditionInput = () => {
    if (key === 'ancestor') {
      return (
        <Select
          disabled
          options={[]}
          value={'范围'}
          style={{ width: '100%' }}
        />
      );
    }
    if (key === 'templateId') {
      return (
        <Select
          disabled
          options={[]}
          value={'主题类型'}
          style={{ width: '100%' }}
        />
      );
    }
    if (key === 'templateIds') {
      return (
        <Select
          disabled
          options={[]}
          value={'主题类型'}
          style={{ width: '100%' }}
        />
      );
    }

    return (
      <Select
        className="w-full"
        disabled={disabled}
        options={filterConditionOptions}
        value={key}
        placeholder="请选择筛选项"
        showSearch // 单选可搜索
        filterOption={(inputValue, option) => {
          let filterText = '';
          // 忽略分组
          if (option?.label && !option?.options) {
            filterText = option?.label;
          }
          if (!filterText) return false;

          if (option?.options) return false;

          return (
            (filterText as string)
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) >= 0
          );
        }}
        onChange={(e) => {
          const { conditionConfigType, multiple, cascadeConfig } =
            getConditionCondifType(
              filterConditionConfigMap,
              e,
              curTemplate,
              tempMap,
            );

          const opOption = getPropTypeOptions(
            conditionConfigType,
            multiple,
            entry === 'view',
            cascadeConfig,
          );

          // 默认op
          const changed: any = {
            key: e,
            op: opOption[0].value,
            input: [],
          };

          if (e.startsWith('prop_')) {
            const [mark, propIndex] = e.split('prop_');
            changed.index = Number(propIndex);
            changed.extends = {
              type: conditionConfigType,
            };
          }

          if (e.startsWith('statusProp_')) {
            const [mark, propIndex] = e.split('statusProp_');
            changed.index = Number(propIndex);
            changed.extends = {
              type: conditionConfigType,
            };
          }

          if (e === 'status') {
            changed.op = 'beIncluded';
          }

          onChange(changed);
        }}
      />
    );
  };

  const {
    conditionConfig,
    conditionConfigType,
    cascadeConfig,
    multiple,
    conditionConfigOptions,
    originalProp,
  } = getConditionCondifType(
    filterConditionConfigMap,
    key,
    curTemplate,
    tempMap,
  );

  const opOption = getPropTypeOptions(
    conditionConfigType,
    multiple,
    entry === 'view',
    cascadeConfig,
  );

  // 多行 拿里面第一个
  let selectKey = '';
  if (op === 'or' || op === 'and') {
    selectKey = input[0]?.op;
  } else {
    selectKey = op;
  }

  const curOpConfig = opOption.find((x) => x.value === selectKey);

  return (
    <div
      className={cls(
        'condition-filter-item',
        styles.conditionItem,
        disabled && 'condition-filter-item-disabled',
      )}
    >
      {/* 筛选类型 */}
      <div className={cls('condition-filter-item-field', styles.field)}>
        {getFilterConditionInput()}
      </div>

      {/* 操作符 */}
      <ConditionOperator
        disabled={disabled}
        opOption={opOption}
        curOpConfig={curOpConfig}
        condition={condition}
        fieldType={conditionConfigType}
        multiple={multiple}
        onChange={onChange}
      />

      {/* 筛选值 */}
      <ConditionValue
        entry={entry}
        opOption={opOption}
        curOpConfig={curOpConfig}
        condition={condition}
        orgInfo={orgInfo}
        getTreeData={getTreeData}
        onChange={onChange}
        disabled={disabled}
        curTemplate={curTemplate}
        filterConditionConfigMap={filterConditionConfigMap}
        tempMap={tempMap}
        userMap={userMap}
        userGroups={userGroups}
        provData={provData}
        sysTags={sysTags}
        allowTempIds={allowTempIds}
        nodeSelectExtraCurrent={nodeSelectExtraCurrent}
        conditionConfig={conditionConfig}
        conditionConfigType={conditionConfigType}
        cascadeConfig={cascadeConfig}
        multiple={multiple}
        conditionConfigOptions={conditionConfigOptions}
        originalProp={originalProp}
      />

      {/* 删除按钮 */}
      {hideDelete || key === 'ancestor' || !remove || disabled ? null : (
        <div
          className={cn('condition-filter-item-btn', styles.btn)}
          onClick={remove}
        >
          <i className="iconfont iconButton_cancel" />
        </div>
      )}
    </div>
  );
};

export default ConditionItem;

export const getConditionCondifType = (
  filterConditionConfigMap: any,
  key: any,
  curTemplate: CurrentUser.TemplateInfo | null,
  tempMap: Record<string, CurrentUser.TemplateInfo>,
) => {
  const conditionConfig = filterConditionConfigMap[key];
  let conditionConfigType = conditionConfig?.type;
  let multiple = conditionConfig?.data?.multiple || false;
  let originalProp = conditionConfig?.data;
  // 多级选值配置
  let cascadeConfig: CurrentUser.cascadeSetting | null | void = null;
  let conditionConfigOptions: { label: string; value: string }[] =
    conditionConfig?.options || [];

  // 转成label value
  if (conditionConfigOptions.length) {
    if (typeof conditionConfigOptions[0] === 'string') {
      conditionConfigOptions = conditionConfigOptions.map((x) => ({
        label: x as unknown as string,
        value: x as unknown as string,
      }));
      // conditionConfigOptions.push({
      //   value: FILTER_UNSET,
      //   label: '【未定义】',
      // });
    } else {
      conditionConfigOptions = conditionConfigOptions.map((x: any) => {
        if (x.label) return x;

        return {
          label: x.name,
          value: x.key,
        };
      });
    }
  }

  if (conditionConfigType) {
    if (conditionConfigType === 'cascade') {
      const [mark, propIndex] = (key as string).split('_');
      cascadeConfig = curTemplate?.prop[Number(propIndex)]?.cascade;
    }
    // 数字公式
    if (conditionConfig.data?.type === 'formula') {
      conditionConfigType =
        conditionConfig.data?.formulaFormat === 0 ? 'number' : 'text';
    }
    if (key === 'sysTag') {
      multiple = true;
    }
    if (conditionConfigType === 'auto_inc') {
      conditionConfigType = 'number';
    }
    // 引用属性
    if (conditionConfigType === 'quote') {
      originalProp = getQuoteOriginProp(conditionConfig.data || null, tempMap);
      if (!originalProp) {
        conditionConfigType = 'text';
      } else if (typeof originalProp === 'string') {
        // @ts-ignore
        const prop = QUOTE_SYS_PROP[originalProp];
        conditionConfigType = (prop?.type as CurrentUser.propType) || 'text';
        multiple = (prop as any)?.multiple || false;
      } else if (
        originalProp.conditionMatching &&
        originalProp.matchingType > 0
      ) {
        conditionConfigType = 'number';
        // 引用数字公式属性
      } else if (originalProp.type === 'formula') {
        conditionConfigType =
          originalProp.formulaFormat === 0 ? 'number' : 'text';
      } else {
        conditionConfigType = originalProp.type;
        multiple = originalProp.multiple;
        // 属性的特殊配置
        if (conditionConfigType === 'cascade') {
          cascadeConfig = originalProp?.cascade;
        }
        if (conditionConfigType === 'enum' || conditionConfigType === 'tag') {
          const opt = originalProp?.extend || [];
          conditionConfigOptions =
            opt.map((x: string) => ({
              label: x,
              value: x,
            })) || [];
        }
        if (conditionConfigType === 'auto_inc') {
          conditionConfigType = 'number';
        }
      }
    }
  }

  return {
    conditionConfig,
    multiple,
    conditionConfigType,
    cascadeConfig,
    conditionConfigOptions,
    originalProp,
  };
};
