import { GridStack } from 'gridstack';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

import { dragItemClassName } from '../constants';

const _rootRef: Map<HTMLElement, Root> = new Map();

// @ts-ignore
window._rootRef = _rootRef;

export const getRoot = (id: string) => {
  const divElement = document.getElementById(id)!;
  if (!_rootRef.has(divElement)) {
    _rootRef.set(divElement, createRoot(divElement));
  }
  return _rootRef.get(divElement)!;
};
export const clearRoots = () => {
  _rootRef.forEach((root) => root.unmount());
  _rootRef.clear();
};

export const setupDragIn = () =>
  GridStack.setupDragIn('.' + dragItemClassName, {
    appendTo: 'body',
    helper: 'clone',
  });

export const limitWarn = '板块内存在有添加数量限制的组件， 请检查';

export const getWidgetElement = (el: Element) => {
  type WidgetElement = Element & {
    dataset: {
      dynamicHeight?: 'true' | 'false';
      nested?: 'true' | 'false';
    };
  };

  if (!el.classList.contains('page-model-component-drag-item'))
    return el.querySelector('.comp') as WidgetElement | null;

  return el as WidgetElement;
};
