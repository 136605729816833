import { RegularIcon } from '@/components/IconFont';

import bar from '../../assets/bar.png';
import type { IStatisticSetting } from '../../components';
import {
  showStatisticSettingModal,
  StatisticPreview,
  StatisticSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IBarChartWidget = IStatisticSetting<'chart'>;

const Preview: WidgetPreivew = ({ id }) => {
  return (
    <StatisticPreview id={id}>
      <div style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
        <img src={bar} style={{ display: 'block', height: '100%' }} />
      </div>
    </StatisticPreview>
  );
};

const Setting: WidgetSetting = () => {
  return <StatisticSetting type="chart" viewType={1} />;
};

export const BarChartWidget = defineSystemWidget<IBarChartWidget>()({
  id: 'BarChart',
  title: '柱状图',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#D55EDA"
        icon={<RegularIcon type="iconshitu-zhuzhuangtu" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 6,
    defaultWidth: 6,
    minWidth: 3,
    minHeight: 3,
    settingWidth: 540,
  },
  metadata: {} as any,
  preview: Preview,
  setting: Setting,
  onCreate: () =>
    showStatisticSettingModal<'chart'>({
      title: '添加柱状图',
      type: 'chart',
      viewType: 1,
    }),
});
