import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { useAtomValue } from 'jotai';
import { createElement } from 'react';

import { cn } from '@/utils/utils';

import type { CommonWidgetProps } from '../../constants';
import { formatsAtom } from '../../store';

interface AlignWidgetProps extends CommonWidgetProps {
  defaultValue?: string | false;
  value?: string | false;
  onChange?: (value: string | false) => void;
}

const AlignList = [
  {
    key: 'left',
    value: false,
    icon: AlignLeftOutlined,
  },
  {
    key: 'center',
    value: 'center',
    icon: AlignCenterOutlined,
  },
  {
    key: 'right',
    value: 'right',
    icon: AlignRightOutlined,
  },
] as const;

const AlignWidget = (props: AlignWidgetProps) => {
  const { value, onChange, defaultValue } = props;
  const formats = useAtomValue(formatsAtom);

  const [_value, _onChange] = useControllableValue<string | false>({
    value: formats[AlignWidgetName] ?? 'left',
    onChange,
  });

  return (
    <div
      className={cn(
        'h-8 flex items-center border border-solid border-[#ebedf0] rounded-[8px]',
      )}
    >
      {AlignList.map((item) => {
        return (
          <div
            className={cn(
              'w-[28px] h-[28px] flex items-center justify-center cursor-pointer rounded-[8px]',
              {
                'bg-[#316EF5]': _value === item.key,
              },
            )}
            key={item.key}
            onClick={() => {
              _onChange(item.value);
            }}
          >
            {createElement(item.icon, {
              style: {
                fontSize: 16,
                color: _value === item.key ? '#fff' : '#000',
              },
            })}
          </div>
        );
      })}
    </div>
  );
};

export const AlignWidgetName = 'align';
export default AlignWidget;
