/**
 * dom
 *
 * pi-gantt-wrapper
 *      main  canvas
 *          date column
 *          node grid
 *      overlayer             可能包含业务 考虑往react拓展
 *      scrollbar horizontal  x轴滚动
 *      scrollbar vertical    y轴滚动
 *      ...
 */

/**
 * module
 *
 * 左
 * 列首（全选、标题名）
 * list
 *
 * 右
 * x轴配置栏 工具栏
 * main
 * scrollbar vertical
 * scrollbar horizontal
 *
 */

/**
 * 滚动触发render
 *      list
 *      main canvas
 *      vertical
 *
 *
 *
 */

import type { ApiResponse } from '@linkpi/core';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { positionType } from './components/_baseComponent';
import type NodeRow from './components/nodeRow';
import { dayjsMinMax } from './helper/utils';
import DateColumnManager from './modules/dateColumnManager';
import Draw from './modules/draw';
import Event from './modules/event';
import ImageLoader from './modules/imageLoader';
import Listeners from './modules/listeners';
import type { nodeRowData } from './modules/nodeRowManager';
import NodeRowManager from './modules/nodeRowManager';
import Overlayer from './modules/overlayer';
import Render from './modules/render';
import Scrollbar from './modules/scrollbar';

dayjs.extend(dayjsMinMax);

export interface configType {
  container: string;
  tempConfig: ApiResponse.CurrentUser.TemplateInfo;
  groupData: any;
  onPreview: (nodeId: string, allNodeIds: string[]) => void;
  getGroupName: (groupInfo: any) => string;
  onDisplayAddBtn: (node: NodeRow | false) => void;
  onAdd: (nodeRowData: nodeRowData<'addRow'>) => void;
  onStatusIconClick: (node: PiNode, position: positionType) => void;
  onMove: (node: PiNode, toNode: PiNode, relation: 'prev' | 'next') => void;
  onDateChange: (node: PiNode, startDate: Dayjs, endDate: Dayjs) => void;
  readOnlyMode: boolean;
  curViewData: any; // 视图数据
  isDark?: boolean;
  backgroundColor: string;
  afterCollapseGroup: any;
  onProgressChange: (node: PiNode, percent: number) => void;
}

export interface PiGanttModules {
  NodeRowManager: NodeRowManager;
  DateColumnManager: DateColumnManager;
  Render: Render;
  Draw: Draw;
  Listeners: Listeners;
  VerticalScrollbar: Scrollbar;
  HorizontalScrollbar: Scrollbar;
  Overlayer: Overlayer;
  Event: Event;
  ImageLoader: ImageLoader;
  [key: string]: any;
}

class Core {
  public config: configType;

  // @ts-ignore
  public moduleInstances: PiGanttModules = {};

  constructor(config: configType) {
    this.config = config;

    this.init();
    this.start();

    this.moduleInstances.ImageLoader.bootstrap();

    this.moduleInstances.Render.render();
  }

  private init() {
    this.instantiationModules();
    this.injectModules();
  }

  /**
   * 初始化所有模块
   */
  private instantiationModules() {
    this.moduleInstances.Listeners = new Listeners(this);
    this.moduleInstances.Render = new Render(this);
    this.moduleInstances.Draw = new Draw(this);
    this.moduleInstances.Overlayer = new Overlayer(this);
    this.moduleInstances.NodeRowManager = new NodeRowManager(this);
    this.moduleInstances.DateColumnManager = new DateColumnManager(this);
    this.moduleInstances.VerticalScrollbar = new Scrollbar(this, 'vertical');
    this.moduleInstances.HorizontalScrollbar = new Scrollbar(
      this,
      'horizontal',
    );
    this.moduleInstances.Event = new Event(this);
    this.moduleInstances.ImageLoader = new ImageLoader(this);
  }
  /**
   * 注入moduleInstances到所有模块
   */
  private injectModules() {
    for (const name in this.moduleInstances) {
      if (this.moduleInstances[name].inject) {
        this.moduleInstances[name].inject(this.moduleInstances);
      }
    }
  }

  private start() {
    Object.keys(this.moduleInstances).map((moduleKey) => {
      if (this.moduleInstances[moduleKey].prepare) {
        this.moduleInstances[moduleKey].prepare();
      }
    });
  }

  public setData(groupData: any) {
    this.config.groupData = groupData;
    this.moduleInstances.NodeRowManager.updateData();
  }

  public updateCurViewData(v: any) {
    this.config.curViewData = v;
    this.moduleInstances.NodeRowManager.updateData();
  }

  public setTempConfig(tempConfig: ApiResponse.CurrentUser.TemplateInfo) {
    this.config.tempConfig = tempConfig;
    this.moduleInstances.Render.render();
  }

  public getCurrentIds() {
    return this.moduleInstances.NodeRowManager.virtualNodeIdList;
  }

  public setIsDark(isDark: boolean) {
    this.config.isDark = isDark;
    this.moduleInstances.Render.render();
  }

  public ganttCollapse(v: boolean) {
    this.moduleInstances.NodeRowManager.ganttCollapse(v);
  }

  public destroy() {
    Object.values(this.moduleInstances).forEach((moduleInstance) => {
      if (moduleInstance.destroy) {
        moduleInstance.destroy();
      }
    });
  }
}

export default Core;
