import { Space } from 'antd';
import type { FC, PropsWithChildren } from 'react';
import { match } from 'ts-pattern';

import { PiButton } from '@/components/Button';
import { RegularIcon } from '@/components/IconFont';

import { useWidgetInstance } from '../../hooks';
import type { StatisticWidgetType } from '../../utils';

import styles from './Preview.less';

export const StatisticPreview: FC<
  PropsWithChildren<{
    id: string;
  }>
> = ({ children, id }) => {
  const widgetInstance = useWidgetInstance(id) as StatisticWidgetType;
  const name = match(widgetInstance.config.statistic)
    .with({ type: 'more' }, (v) => v.name)
    .with({ type: 'chart', filterType: 'relation' }, (v) => v.name)
    .with({ type: 'chart', filterType: 'custom' }, (v) => v.chartInfo?.viewName)
    .exhaustive();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>{name}</div>
        <Space>
          {/* <PiButton
            size="small"
            type="text"
            icon={<RegularIcon type="iconneirongye-dakai2" size={12} color="#242D3F" />}
          />
          <PiButton
            size="small"
            type="text"
            icon={<RegularIcon type="iconchangyong-tiaozhuan" size={12} color="#242D3F" />}
          /> */}
        </Space>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
