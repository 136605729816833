/* eslint-disable react-refresh/only-export-components */
import ProForm, { ProFormRadio, ProFormText } from '@ant-design/pro-form';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { Checkbox, Modal } from 'antd';
import { produce } from 'immer';
import { nanoid } from 'nanoid';
import { type FC, useMemo, useState } from 'react';

import { ConditionFilterValue } from '@/components/ConditionFilter';
import { DataPropSelect } from '@/components/LinkPiForm';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useOrgTemplatesSettingInfoMap } from '@/hook';

import { FilterTargetList } from './FilterTargetList';
import type { IGlobalConditionFilterCondition } from './types';
import { getTemplateIdByDatasourceId } from './utils';

export type FilterItemModalProps = {
  templateId?: string;
  value?: IGlobalConditionFilterCondition;
};

const PropValueInput: FC<{ templateId: string }> = ({ templateId }) => {
  const prop = ProForm.useWatch(['propKey']) as string;
  const multiple = ProForm.useWatch(['multiple']) as boolean;
  const onlyShowAuxInEnumProp = ProForm.useWatch([
    'onlyShowAuxInEnumProp',
  ]) as boolean;

  const tempMap = useOrgTemplatesSettingInfoMap();
  const templateInfo = tempMap[templateId];

  const isEnumProp = useMemo(() => {
    if (!prop) return false;

    const [key, index] = prop.split('_');

    if (key !== 'prop') return false;

    if (!index) return false;

    const propIndex = Number(index);
    const propInfo = templateInfo?.prop[propIndex];

    if (!propInfo) return false;

    const result = ['enum', 'tag'].includes(propInfo.type);

    return result;
  }, [prop, templateInfo]);

  if (!prop) return null;

  return (
    <>
      <ProFormRadio.Group
        hidden={!isEnumProp}
        label=" "
        name={['multiple']}
        options={[
          {
            label: '单选',
            value: false,
          },
          {
            label: '多选',
            value: true,
          },
        ]}
        initialValue={true}
      />
      <ProForm.Item
        hidden={!isEnumProp}
        label=" "
        name={['onlyShowAuxInEnumProp']}
        initialValue={false}
        valuePropName="checked"
      >
        <Checkbox>展示使用辅助属性</Checkbox>
      </ProForm.Item>
      <ProForm.Item
        label="显示属性默认值"
        name={['defaultValue']}
        initialValue={[]}
      >
        <ConditionFilterValue
          onlyShowAuxInEnumProp={onlyShowAuxInEnumProp}
          multiple={multiple}
          propKey={prop}
          templateId={templateId}
          key={prop + multiple + onlyShowAuxInEnumProp}
        />
      </ProForm.Item>
    </>
  );
};

const FilterTargetInput: FC<{ templateId: string }> = ({ templateId }) => {
  const prop = ProForm.useWatch(['propKey']);

  return (
    <ProForm.Item name={['targetList']}>
      <FilterTargetList templateId={templateId} propKey={prop} />
    </ProForm.Item>
  );
};

const labelCol = { span: 6 };

export const FilterItemModal: FC<FilterItemModalProps> = ({
  templateId,
  value,
}) => {
  const modal = useModal();
  const modalProps = antdModalV5(modal);

  const content = useMemo(() => {
    if (!templateId)
      return (
        <div className="py-[20px] text-center text-gray-500">
          当前组件暂未选择数据源，请先选择数据源
        </div>
      );
    return (
      <>
        <ProForm.Item label="显示属性" name={['propKey']}>
          <DataPropSelect
            templateId={templateId}
            placeholder="请选择显示属性"
          />
        </ProForm.Item>
        <PropValueInput templateId={templateId} />
        <FilterTargetInput templateId={templateId} />
      </>
    );
  }, [templateId]);
  const [form] = ProForm.useForm();

  const initialValues = useMemo(() => {
    return (
      value || {
        name: '',
        id: nanoid(),
      }
    );
  }, [value]);

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const result = await form.validateFields();

    const newValue = produce(
      {
        ...initialValues,
        ...result,
      } as IGlobalConditionFilterCondition,
      (draft) => {
        draft.targetList?.forEach((target) => {
          const latestTargetTemplateId = getTemplateIdByDatasourceId(target.id);

          target.templateId = latestTargetTemplateId!;
        });
      },
    );

    modal.resolve(newValue);
    modalProps.onOk?.();
    setLoading(false);
  };

  return (
    <Modal
      {...modalProps}
      title={
        <span className="font-bold">
          {(value ? '编辑' : '添加') + '筛选项'}
        </span>
      }
      centered
      destroyOnClose
      width={600}
      onOk={onSubmit}
      confirmLoading={loading}
    >
      <div className="h-[720px]">
        <ScrollArea className="h-full">
          <ProForm
            layout="horizontal"
            form={form}
            submitter={false}
            initialValues={initialValues}
            colon={false}
            labelCol={labelCol}
            labelAlign="left"
            onValuesChange={(v) => {
              if ('propKey' in v) {
                form.setFieldValue('defaultValue', []);
                form.setFieldValue('targetList', []);
              }
            }}
          >
            <ProFormText
              label="筛选项名称"
              name={['name']}
              fieldProps={{ max: 200 }}
            />
            {content}
          </ProForm>
        </ScrollArea>
      </div>
    </Modal>
  );
};

const FilterItemNiceModal = create(FilterItemModal);

export const getFilterItemConfig = (props: FilterItemModalProps) => {
  return show(
    FilterItemNiceModal,
    props,
  ) as Promise<IGlobalConditionFilterCondition>;
};
