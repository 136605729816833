import type { ViewList } from '@linkpi/core';
import { useWidgetSharedState } from '@mylinkpi/widget-react';
import { useMemoizedFn } from 'ahooks';
import { produce } from 'immer';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import {
  ExtraGlobalConditionsMapAtom,
  GlobalConditionConfigMapAtom,
} from '@/components/PageModelEditor/src/widgets/GlobalConditionFilterWidget/hook';
import type { GlobalConditionFilterValue } from '@/components/PageModelEditor/src/widgets/GlobalConditionFilterWidget/types';

import { useComponentId } from './useComponentId';

/**
 * 添加组件额外筛选条件
 */
export const useSetExtraGlobalConditions = () => {
  const componentId = useComponentId();
  const setExtraGlobalConditionsMap = useSetAtom(ExtraGlobalConditionsMapAtom);

  const setGlobalConditions = useMemoizedFn(
    (targetId: string, conditions: ViewList.ViewconditionV2[]) => {
      setExtraGlobalConditionsMap((oldValue) => {
        const newValue = produce(oldValue, (draft) => {
          if (!draft[targetId]) {
            draft[targetId] = {};
          }

          const item = draft[targetId];
          item[componentId] = conditions as any;
        });
        return newValue;
      });
    },
  );

  return setGlobalConditions;
};

type ConditionConfig = {
  conditionFilterWidgetId: string;
  conditionItemId: string;
  op: ViewList.ViewconditionV2Item['op'];
  propKey: string;
};

const GlobalConditionsMapByTargetAtom = atom((get) => {
  const globalConditionMap = get(GlobalConditionConfigMapAtom);

  const mapByTarget: Record<string, ConditionConfig[]> = {};

  Object.keys(globalConditionMap).forEach((widgetId) => {
    const configs = globalConditionMap[widgetId];

    for (let index = 0; index < configs.length; index++) {
      const config = configs[index];

      if (!config.targetList) continue;

      for (
        let targetIndex = 0;
        targetIndex < config.targetList.length;
        targetIndex++
      ) {
        const target = config.targetList[targetIndex];

        if (!mapByTarget[target.id]) {
          mapByTarget[target.id] = [];
        }

        if (!target.matchingList) continue;

        target.matchingList.forEach((item) => {
          mapByTarget[target.id].push({
            conditionFilterWidgetId: widgetId,
            conditionItemId: config.id,
            op: item.op,
            propKey: item.propKey,
          });
        });
      }
    }
  });

  return mapByTarget;
});

export const useGlobalConditions = (targetId: string) => {
  const globalConditionsMapByTarget = useAtomValue(
    GlobalConditionsMapByTargetAtom,
  );

  const [sharedState] = useWidgetSharedState();

  const value = useMemo(() => {
    return match(sharedState)
      .with(
        { globalConditions: P.nonNullable },
        ({ globalConditions }) =>
          globalConditions as Record<string, GlobalConditionFilterValue>,
      )
      .otherwise(() => ({}));
  }, [sharedState]);

  const extraGlobalConditionsMap = useAtomValue(ExtraGlobalConditionsMapAtom);

  const extraConditions = useMemo(() => {
    const cMap = extraGlobalConditionsMap[targetId] || {};
    return Object.values(cMap).flat();
  }, [extraGlobalConditionsMap, targetId]);

  const conditions = useMemo(() => {
    const conditionConfigs = globalConditionsMapByTarget[targetId];
    const globalConditions =
      conditionConfigs
        ?.filter((item) => item.propKey)
        .map((item) => {
          const [key, _index] = item.propKey.split('_');
          const input =
            value[item.conditionFilterWidgetId]?.[item.conditionItemId]
              ?.value || [];

          const condition: ViewList.ViewconditionV2Item = {
            op: item.op,
            key,
            input,
          };

          if (_index) {
            condition.index = Number(_index);
          }

          return condition;
        }) || [];

    return [...globalConditions, ...extraConditions];
  }, [extraConditions, globalConditionsMapByTarget, targetId, value]);

  return conditions;
};
