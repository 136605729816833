import { PlusOutlined } from '@ant-design/icons';
import { assertExists } from '@linkpi/utils';
import { Form, Input, Radio, Select } from 'antd';
import { match } from 'ts-pattern';

import { CustomUpload } from '@/components/PublishTemplateModal/config';
import { useOrgTemplatesSettingInfoMap } from '@/hook';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';
import { cn } from '@/utils/utils';

import { DataSourceSettingCore } from '../../components/DataSourceSetting/Setting';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetSetting } from '../../models/WidgetManager';

import Styles from './index.less';

export type IPictureConfig = {
  type: 'upload' | 'link' | 'prop';
  value?: string;
  fit: 'fill' | 'none';
  align: 'top' | 'bottom' | 'left' | 'right' | 'center';
};

const TypeOptions: { label: string; value: IPictureConfig['type'] }[] = [
  {
    label: '上传图片',
    value: 'upload',
  },
  {
    label: '图片链接',
    value: 'link',
  },
  {
    label: '显示附件',
    value: 'prop',
  },
];

interface RadioButtonProps {
  value?: IPictureConfig['type'];
  onChange?: (value: IPictureConfig['type']) => void;
}

const RadioButton = (props: RadioButtonProps) => {
  const { value, onChange } = props;

  return (
    <div className="w-full h-9 flex rounded-[8px] bg-[#FAFAFA] p-[2px]">
      {TypeOptions.map((o) => (
        <span
          className={cn(
            'flex-1 flex items-center justify-center rounded-[8px] cursor-pointer',
            {
              ['bg-white text-[#316EF5] font-bold']: o.value === value,
            },
          )}
          key={o.value}
          onClick={() => onChange?.(o.value)}
        >
          {o.label}
        </span>
      ))}
    </div>
  );
};

const AlignOptions: { label: string; value: IPictureConfig['align'] }[] = [
  {
    label: '上',
    value: 'top',
  },
  {
    label: '下',
    value: 'bottom',
  },
  {
    label: '左',
    value: 'left',
  },
  {
    label: '右',
    value: 'right',
  },
  {
    label: '居中',
    value: 'center',
  },
];

interface RadioCardProps {
  value?: IPictureConfig['fit'];
  onChange?: (value: IPictureConfig['fit']) => void;
}

const RadioCard = (props: RadioCardProps) => {
  const { value, onChange } = props;

  return (
    <div className="w-full flex">
      {FitOptions.map((o, i) => (
        <span
          className={cn(
            'pr-3 pl-4 h-10 flex-1 flex items-center justify-between rounded-[8px] bg-[#F8F9FB] cursor-pointer',
            {
              ['mr-3']: i !== FitOptions.length - 1,
            },
          )}
          key={o.value}
          onClick={() => onChange?.(o.value)}
        >
          {o.label}
          <Radio checked={value === o.value} />
        </span>
      ))}
    </div>
  );
};

const FitOptions: { label: string; value: IPictureConfig['fit'] }[] = [
  {
    label: '铺满覆盖',
    value: 'fill',
  },
  {
    label: '实际尺寸',
    value: 'none',
  },
];

const Setting: WidgetSetting = () => {
  const tempMap = useOrgTemplatesSettingInfoMap();

  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IPictureConfig>();
  assertExists(widgetInstance);
  const matchOriginConfig: any =
    widgetInstance?.config?.matchOriginConfig || {};

  const setPictureValue = (value: Partial<IPictureConfig>) => {
    editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
      ...widgetInstance?.config,
      ...value,
    });
  };

  const template = useCurrentTemplate();

  const getTemplate = () => {
    const conditions = matchOriginConfig.conditions || [];
    const tempId = conditions.find((i: any) => i.key === 'templateId')
      ?.input[0];
    if (tempId) return tempMap[tempId];
  };

  const getPropOptions = () => {
    const t = getTemplate() || template;

    return t.prop.reduce<{ label: string; value: string }[]>(
      (result, p, index) => {
        if (p.type === 'attachment') {
          return [
            ...result,
            {
              label: p.name,
              value: `${index}`,
            },
          ];
        } else {
          return result;
        }
      },
      [],
    );
  };

  const valueRender = () => {
    return match(widgetInstance.config.type)
      .with('upload', () => {
        return widgetInstance.config.value ? (
          <div
            className={cn(
              'group w-full h-[180px] rounded-[8px] relative overflow-hidden',
              Styles[''],
            )}
          >
            <img
              className="w-full h-full object-contain"
              src={widgetInstance.config.value}
              alt="上传的图片"
            />
            <div className="group-hover:flex absolute w-full h-full top-0 left-0 hidden items-center justify-center bg-[rgba(0,0,0,0.3)]">
              <span
                className="text-white cursor-pointer"
                onClick={() => {
                  setPictureValue({
                    value: undefined,
                  });
                }}
              >
                删除
              </span>
            </div>
          </div>
        ) : (
          <CustomUpload
            showUploadList={false}
            className={cn('w-full', Styles['picture-upload'])}
            onSuccess={(url) =>
              setPictureValue({
                value: url,
              })
            }
          >
            <div className="w-full flex-col flex items-center justify-center">
              <PlusOutlined size={18} />
              <div className="text-[#939393] text-[14px] mt-3">上传图片</div>
            </div>
          </CustomUpload>
        );
      })
      .with('link', () => {
        return (
          <Input
            value={widgetInstance.config.value}
            onChange={(e) =>
              setPictureValue({
                value: e.target.value,
              })
            }
            placeholder="请粘贴图片链接"
          />
        );
      })
      .with('prop', () => {
        return (
          <div>
            <div style={{ color: '#242D3F' }}>匹配源</div>
            <Radio.Group
              style={{ margin: '12px 0' }}
              value={widgetInstance.config.matchOrigin || 'nowNode'}
              onChange={(e) => {
                editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
                  ...widgetInstance?.config,
                  matchOrigin: e.target.value,
                  value: undefined,
                  matchOriginConfig: {},
                });
              }}
            >
              <Radio value={'nowNode'}>当前节点</Radio>
              <Radio value={'otherNode'}>其他节点</Radio>
            </Radio.Group>
            {widgetInstance.config.matchOrigin === 'otherNode' && (
              <DataSourceSettingCore
                value={{
                  thmId: matchOriginConfig.thmId,
                  type: matchOriginConfig.type,
                  matchings: matchOriginConfig.matchings,
                  parentType: matchOriginConfig.parentType,
                  conditions: matchOriginConfig.conditions,
                }}
                onChange={(v: any) => {
                  editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
                    ...widgetInstance?.config,
                    matchOriginConfig: v,
                  });
                }}
              />
            )}
            <Select
              value={widgetInstance.config.value}
              onChange={(e) =>
                setPictureValue({
                  value: e,
                })
              }
              disabled={
                !getTemplate() &&
                widgetInstance.config.matchOrigin === 'otherNode'
              }
              style={{ marginTop: 12 }}
              options={getPropOptions()}
              placeholder="请选择附件属性"
            />
          </div>
        );
      })
      .exhaustive();
  };

  return (
    <Form layout="vertical">
      <Form.Item label="组件名称">
        <Input
          onChange={(e) => {
            editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
              ...widgetInstance?.config,
              name: e.target.value,
            });
          }}
          defaultValue={widgetInstance.config.name || '图片'}
        />
      </Form.Item>
      <Form.Item>
        <RadioButton
          value={widgetInstance.config.type}
          onChange={(type) => setPictureValue({ type, value: undefined })}
        />
        <div className="mt-2">{valueRender()}</div>
      </Form.Item>
      <Form.Item label="图片布局">
        <RadioCard
          value={widgetInstance.config.fit}
          onChange={(fit) => setPictureValue({ fit })}
        />
        <div className="mt-4">
          <Select
            options={AlignOptions}
            value={widgetInstance.config.align}
            onChange={(align) => setPictureValue({ align })}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default Setting;
