import { SearchOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Checkbox, Empty, Input } from 'antd';
import { type FC, memo, useMemo } from 'react';

import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/utils/utils';

import {
  useWidgetInstanceList,
  useWidgetInstancesByWidgetId,
} from '../../hooks';
import type { WidgetInstance } from '../../models/Store';
import type { IDataIndicatorWidget } from '../DataIndicatorWidget';
import { getTemplateIdByWidgetInstance } from './utils';

import styles from './TargetWidgetInstanceSelect.module.less';

/**
 * 关联筛选组件选择器
 */

export const TargetWidgetInstanceSelect: FC<{
  value?: string[];
  onChange?: (value: string[]) => void;
}> = memo(({ value, onChange }) => {
  useWidgetInstancesByWidgetId('');

  const [_value, _onChange] = useControllableValue<string[]>({
    value,
    onChange,
  });

  const widgetInstanceList = useWidgetInstanceList();

  /**
   *
   */
  const options = useMemo(() => {
    const list: { label: string; value: string }[] = [];

    widgetInstanceList.forEach((w) => {
      if (w.widgetId === 'dataIndicator') {
        const config = (w as WidgetInstance<IDataIndicatorWidget>).config;

        config.indexSort.forEach((itemId) => {
          const item = config.indexes[itemId];

          list.push({
            label: item.name,
            value: itemId,
          });
        });
        return;
      }

      const templateId = getTemplateIdByWidgetInstance(w);

      if (!templateId) return null;

      list.push({
        label: w.config.name || '未知组件',
        value: w.id,
      });
    });

    return list;
  }, [widgetInstanceList]);

  const isAllSelected = useMemo(() => {
    const _valueSet = new Set(_value);
    return options.every((o) => _valueSet.has(o.value));
  }, [options, _value]);

  const indeterminate = useMemo(() => {
    const _valueSet = new Set(_value);
    return !isAllSelected && _valueSet.size > 0;
  }, [isAllSelected, _value]);

  return (
    <div
      className={cn(
        'flex flex-col gap-2 w-[168px]',
        styles['target-widget-instance-select'],
      )}
    >
      <Input
        className="mt-2"
        placeholder="搜索关联筛选的组件"
        suffix={<SearchOutlined className="text-[#B6B8B9]" />}
      />
      <ScrollArea className={cn('h-[250px]', styles['scroll-area'])}>
        <div className="flex flex-col gap-2">
          {options.length ? (
            <>
              <Checkbox
                checked={isAllSelected}
                indeterminate={indeterminate}
                onChange={(e) => {
                  _onChange?.(
                    e.target.checked ? options.map((o) => o.value) : [],
                  );
                }}
              >
                全选
              </Checkbox>
              <Checkbox.Group
                options={options}
                value={_value}
                onChange={_onChange as any}
              />
            </>
          ) : (
            <Empty description="暂无组件" className="py-4" />
          )}
        </div>
      </ScrollArea>
    </div>
  );
});
