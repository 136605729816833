import cls from 'classnames';
import type { FC } from 'react';

import styles from './Title.less';

export const GroupTitle: FC<
  React.PropsWithChildren<{ type?: 'secondary'; className?: string }>
> = ({ className, children, type }) => {
  return (
    <div
      className={cls(
        styles.wrapper,
        type === 'secondary' && styles.secondary,
        className,
      )}
    >
      {children}
    </div>
  );
};
