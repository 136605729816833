import { CloseOutlined } from '@ant-design/icons';
import type { OrgGroups } from '@linkpi/core';
import {
  DEFAULT_AVATAR,
  getDefaultValueType as _getDefaultValueType,
} from '@linkpi/core';
import { useControllableValue, useMemoizedFn } from 'ahooks';
import type { SpaceProps } from 'antd';
import { Avatar, Divider, Space, Typography } from 'antd';
import cls from 'classnames';
import { isNil, take } from 'ramda';
import type { FC } from 'react';
import { useMemo } from 'react';

import { RegularIcon } from '@/components';
import { useOrgUserGroup, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { cn, getUserNickName, toArray, toRecord } from '@/utils/utils';

import { useStatusUserCustomPropSelectOptions } from '../CustomPropSelect';
import { getDynamicDepartmentLabel } from '../DynamicDepartmentSelect';
import { getSupervisorLabel } from '../SupervisorSelect';

import styles from './styles.module.less';

export const getDefaultValueType = _getDefaultValueType;

export const TagList: FC<{
  templateId?: string;
  layout?: 'inline' | 'list';
  value: ('creator' | 'updater' | `prop${number}` | `group_${number}`)[];
  onChange?: (v: any[]) => void;
  size?: 'normal' | 'small';
  disabled?: boolean;
  maxTag?: number;
  disabledIds?: string[];
}> = ({
  layout = 'inline',
  templateId,
  disabled,
  size,
  maxTag,
  disabledIds,
  ...rest
}) => {
  const [_value, onChange] = useControllableValue(rest);
  const value = toArray(_value);

  const propOptions = useStatusUserCustomPropSelectOptions({ templateId });
  const propOptionMap = useMemo(() => {
    return toRecord<{ label: string; value: string }>((o) => ({
      [o.value]: o,
    }))(propOptions.map((o) => o.options).flat());
  }, [propOptions]);

  const userMap = useOrgUserMap();

  const groups = useOrgUserGroup();
  const groupOptionMap = useMemo(() => {
    return toRecord<OrgGroups.OrgGroupsItem>((o) => ({ [o.group_id]: o }))(
      groups,
    );
  }, [groups]);

  const departmentNodeMap = useOrgDepartmentNodesMap();

  const renderLabel = useMemoizedFn(
    (
      v:
        | 'creator'
        | 'updater'
        | `prop${number}`
        | `group_${number}`
        | `dynamicDepartment_${string}`,
    ) => {
      const type = getDefaultValueType(v, {
        userMap,
        groupMap: groupOptionMap,
      });

      if (v === 'updater') {
        return (
          <>
            <Avatar
              src={<RegularIcon type="iconOrg_Member" size={14} color="#fff" />}
              size={20}
              style={{ marginRight: 4, background: '#316EF5' }}
            />
            修改者
          </>
        );
      }

      if (type === 'prop') {
        const tip = v.startsWith('status')
          ? '（负责人）'
          : v.startsWith('pStatus')
            ? '（参与者）'
            : null;
        return (
          <>
            <Avatar
              src={<RegularIcon type="iconOrg_Member" size={14} color="#fff" />}
              size={20}
              style={{ marginRight: 4, background: '#316EF5' }}
            />
            {propOptionMap[v]?.label || '已删除'}
            {tip}
          </>
        );
      }

      if (type === 'user') {
        const user = userMap[v];
        return (
          <>
            <Avatar
              src={user.avatar || DEFAULT_AVATAR}
              size={20}
              style={{ marginRight: 4 }}
            />
            {getUserNickName(user)}
          </>
        );
      }

      if (type === 'group') {
        const groupId = v.replace('group_', '');
        const groupItem = groupOptionMap[groupId];
        if (groupItem)
          return (
            <>
              <Avatar
                src={
                  <RegularIcon type="iconOrg_Group" color="#fff" size={14} />
                }
                size={20}
                style={{ marginRight: 4, background: '#5ABCB0' }}
              />
              {groupItem.group_name}
            </>
          );
      }

      if (type === 'supervisor') {
        const [, propStr, levelStr] = v.split('_');
        const level = Number(levelStr);
        const propName = propOptionMap[propStr]?.label || '未知属性';

        return (
          <>
            <Avatar
              src={
                <RegularIcon type="iconOrg_Supervisor" color="#fff" size={14} />
              }
              size={20}
              style={{ marginRight: 4, background: '#FDA84A' }}
            />
            {propName}
            <Divider type="vertical" />
            {getSupervisorLabel(level)}
          </>
        );
      }

      if (type === 'department') {
        const id = v.replace('group_', '');
        const item = departmentNodeMap[id];
        if (!item) return '[已删除]';
        if (layout === 'inline')
          return (
            <>
              <Avatar
                src={
                  <RegularIcon
                    type="iconOrg_Nav_Department"
                    color="#fff"
                    size={14}
                  />
                }
                size={20}
                style={{ marginRight: 4, background: '#7869E6' }}
              />
              {item?.name || '未知部门'}
            </>
          );
        if (layout === 'list')
          return (
            <Space align="center">
              <Avatar
                src={
                  <RegularIcon
                    type="iconOrg_Nav_Department"
                    color="#fff"
                    size={16}
                  />
                }
                size={24}
                style={{ background: '#7869E6' }}
              />
              <Space direction="vertical">
                <Typography.Text>{item.name}</Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {item.pathName}
                </Typography.Text>
              </Space>
            </Space>
          );
      }

      if (type === 'dynamicDepartment') {
        const [, propStr, levelStr] = v.split('_');
        const level = Number(levelStr);
        const propName = propOptionMap[propStr]?.label || '未知属性';

        return (
          <>
            <Avatar
              src={
                <RegularIcon type="iconOrg_Supervisor" color="#fff" size={14} />
              }
              size={20}
              style={{ marginRight: 4, background: '#FDA84A' }}
            />
            {propName}
            <Divider type="vertical" />
            {getDynamicDepartmentLabel(level)}
          </>
        );
      }

      return v;
    },
  );

  const onDelete = useMemoizedFn((v) => {
    onChange(value.filter((i: string) => i !== v));
  });

  const spaceProps: SpaceProps =
    layout === 'list'
      ? { direction: 'vertical', className: 'full-w' }
      : { wrap: true };

  const moreTags = isNil(maxTag) ? 0 : value.length - maxTag;
  const displayValues = isNil(maxTag) ? value : take(maxTag, value);

  return (
    <div className={styles.wrapper}>
      <Space {...spaceProps}>
        {displayValues.map((v: string) => {
          return (
            <span
              className={cls(
                styles.tag,
                layout === 'list' && styles.row,
                size === 'small' && styles.small,
              )}
              key={v}
            >
              <span className="h-6 flex items-center">{renderLabel(v)}</span>
              {disabled || disabledIds?.includes(v) || (
                <span onClick={() => onDelete(v)} className={cn(styles.delete)}>
                  <CloseOutlined style={{ fontSize: 10, color: '#707f9a' }} />
                </span>
              )}
            </span>
          );
        })}
        {moreTags > 0 && (
          <span
            className={cls(
              styles.tag,
              layout === 'list' && styles.row,
              size === 'small' && styles.small,
            )}
          >
            <span className={styles.moreText}>+{moreTags}</span>
          </span>
        )}
      </Space>
    </div>
  );
};
