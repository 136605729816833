import { useMemo } from 'react';

import { useWidgetInstancesByWidgetId } from '../../hooks';
import type { IGlobalConditionFilterConfig } from '../../widgets/GlobalConditionFilterWidget';

/**
 * 根据目标id获取全局条件筛选组件列表
 *
 * @param id 目标id
 * @returns 全局条件筛选组件列表
 */
export const useGlobalConditionFilterListByTargetId = (id?: string) => {
  const widgetInstances =
    useWidgetInstancesByWidgetId<IGlobalConditionFilterConfig>(
      'GlobalConditionFilter',
    );

  const globalConditionFilterList = useMemo(() => {
    if (!widgetInstances) return [];
    if (!id) return [];

    return widgetInstances.filter((item) => {
      return item.config.conditionList.find((c) =>
        c.targetList?.find((t) => t.id === id),
      );
    });
  }, [id, widgetInstances]);

  return globalConditionFilterList;
};
