import type { ProFormColumnsType } from '@ant-design/pro-form';
import ProForm, { BetaSchemaForm } from '@ant-design/pro-form';
import {
  antdModalV5,
  create,
  hide,
  show,
  useModal,
} from '@ebay/nice-modal-react';
import { Modal } from 'antd';

export interface LinkModalProps {
  link?: string;
  blank?: boolean;
}

export const LinkModal = create((props: LinkModalProps) => {
  const modal = useModal();
  const antdModal = antdModalV5(modal);
  const [form] = ProForm.useForm<LinkModalProps>();

  const columns: ProFormColumnsType[] = [
    {
      title: '链接地址',
      dataIndex: 'link',
      valueType: 'text',
    },
    {
      title: '新窗口打开',
      dataIndex: 'blank',
      valueType: 'switch',
    },
  ];

  const handleOkClick = async () => {
    const values = await form.validateFields();
    modal.resolve(values);
    antdModal.onCancel();
  };

  return (
    <Modal
      title="设置链接"
      width={480}
      destroyOnClose
      {...antdModal}
      onOk={handleOkClick}
    >
      <BetaSchemaForm
        form={form}
        initialValues={props}
        submitter={false}
        layoutType="Form"
        layout="horizontal"
        labelAlign="left"
        columns={columns}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
      />
    </Modal>
  );
});

export const LinkModalId = '$pi-toolbar-link-modal';

export const openLinkModal = (props?: LinkModalProps) => show(LinkModal, props);
export const hideLinkModal = () => hide(LinkModal);
