import { Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import statusHistoryBg from '../../assets/statusHistoryBg.png';
import { WidgetIcon } from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={statusHistoryBg} style={{ display: 'block', height: 644 }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">{/* <BasicSetting type="component" /> */}</Form>
  );
};

export const StatusHistoryWidget = defineSystemWidget()({
  id: 'StatusHistoryWidget',
  title: '状态历史记录',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#D55EDA"
        icon={
          <RegularIcon
            type="iconneirongye-zhuangtailishijilu"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 2,
    defaultWidth: 6,
    minHeight: 2,
    minWidth: 3,
    maxCount: 1,
  },
  metadata: {},
  preview: Preview,
  setting: Setting,
});
