import type { CurrentUser } from '@linkpi/core';
import { Tooltip } from 'antd';
import { Provider } from 'jotai';
import { createElement } from 'react';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import type ReactQuill from 'react-quill-new';
import { Quill } from 'react-quill-new';
import { match } from 'ts-pattern';

import { cn } from '@/utils/utils';

import type { ToolWidgetType } from './components';
import { ToolbarWidgetText, ToolWidgets } from './components';
import type { Positions } from './constants';
import { ToolbarClassName } from './constants';
import { registerFontSize, registerLink, registerProp } from './register';
import {
  formatsAtom,
  positionsAtom,
  quillAtom,
  rangeAtom,
  toolbarStore,
} from './store';

import Styles from './index.less';

const Module = Quill.import('core/module');

interface PiToolbarModuleProps {
  tools?: (ToolWidgetType | ToolWidgetType[])[];
  tip?: boolean;
  currentTemplate?: CurrentUser.TemplateInfo;
  templateList?: CurrentUser.TemplateInfo[];
}

class PiToolbarModule extends Module<PiToolbarModuleProps> {
  private range: ReactQuill.Range = null;
  private cacheRange: ReactQuill.Range = null;
  static DEFAULTS: {
    tools: (ToolWidgetType | ToolWidgetType[])[];
  } = {
    tools: [
      'size',
      ['bold', 'italic', 'underline', 'strike'],
      ['color'],
      ['background'],
      'align',
      'link',
      'prop',
    ],
  };
  private toolContainerRoot: Root | null = null;

  constructor(quill: Quill, options: Partial<PiToolbarModuleProps>) {
    super(quill, options);

    this.render();

    toolbarStore.set(quillAtom, quill);
    document.addEventListener('mousedown', this.handleQuillBlur);

    this.quill.on(Quill.events.EDITOR_CHANGE, () => {
      // console.log(Quill.events.EDITOR_CHANGE, quill.selection.getRange());
      const [range] = this.quill.selection.getRange();

      this.update(range);

      // console.log(range);
    });
  }

  handleQuillBlur = (e: Event) => {
    const target = e.target;

    if (!this.quill.container.contains(target as unknown as Node)) {
      this.quill.blur();
    }
  };

  update(range: ReactQuill.Range) {
    this.range = range;
    const formats = range ? this.quill.getFormat(range) : {};
    // console.log('update', formats);
    toolbarStore.set(rangeAtom, range);
    toolbarStore.set(formatsAtom, formats);
    toolbarStore.set(positionsAtom, this.getPosition(range));
  }

  render() {
    const widgets = this.widgetsRender();
    // console.log('widgets', widgets);

    const toolContainer = document.createElement('div');
    toolContainer.classList.add(ToolbarClassName, Styles[ToolbarClassName]);
    toolContainer.onmousedown = (e) => {
      e.preventDefault();
    };

    this.toolContainerRoot = createRoot(toolContainer);
    this.toolContainerRoot.render(
      <Provider store={toolbarStore}>{widgets}</Provider>,
    );

    this.quill.container.insertBefore(toolContainer, this.quill.root);
  }

  handleWidgetEvent() {}

  widgetsRender() {
    const tools = this.options.tools ?? PiToolbarModule.DEFAULTS.tools;

    const handleWidgetRender = (name: ToolWidgetType) => {
      this.register(name);

      const comp = ToolWidgets[name];
      if (comp) {
        const node = createElement<any>(comp, {
          key: name,
          onChange: (value: any) => {
            // console.log('onChange', name, value, this.cacheRange, this.range);
            const range = this.cacheRange ?? this.range;
            if (range) {
              match(name)
                .with('prop', () => {
                  // 什么都不做
                  // 内部自己处理吧
                })
                .with('link', () => {
                  // this.quill.format(name, value);
                  // this.quill.formatText(range, name, value);
                })
                .otherwise(() => {
                  this.quill.format(name, value);
                  this.quill.formatText(range, name, value);
                });
            }
          },
          container: this.quill.container,
          currentTemplate: this.options.currentTemplate,
          templateList: this.options.templateList,
          quill: this.quill,
          beforeChange: () => {
            this.cacheRange = this.range;
          },
          endChange: () => {
            if (this.cacheRange) {
              this.quill.setSelection(this.cacheRange);
              this.cacheRange = null;
            }
          },
        });
        return this.options.tip ? (
          <Tooltip
            key={name}
            title={ToolbarWidgetText[name]}
            placement="bottom"
          >
            <>{node}</>
          </Tooltip>
        ) : (
          node
        );
      }
      return null;
    };

    return tools.map((tool, index) => {
      if (Array.isArray(tool)) {
        return (
          <div
            key={`group-${index}`}
            className={cn(
              Styles['tool-group'],
              'h-8 flex items-center border border-solid border-[#ebedf0] rounded-[8px]',
            )}
          >
            {tool.map((t) => (
              <div key={t} className={Styles['tool-group-item']}>
                {handleWidgetRender(t)}
              </div>
            ))}
          </div>
        );
      } else {
        return handleWidgetRender(tool);
      }
    });
  }

  register(name: ToolWidgetType) {
    match(name)
      .with('size', () => {
        registerFontSize();
      })
      .with('link', () => {
        registerLink();
      })
      .with('prop', () => {
        registerProp();
      })
      .otherwise(() => null);
  }

  getPosition(range: ReactQuill.Range): Positions | null {
    if (!range) {
      return null;
    }
    const bounds = this.quill.getBounds(range);
    if (!bounds) {
      return null;
    }
    return {
      left: bounds.left,
      top: bounds.top + bounds.height + 5,
    };
  }

  destroy() {
    this.quill.off(Quill.events.EDITOR_CHANGE);
    this.toolContainerRoot?.unmount();
    this.toolContainerRoot = null;

    document.removeEventListener('mousedown', this.handleQuillBlur);
  }
}

export default PiToolbarModule;
