import type { GetterPiNode } from '@linkpi/core/web';
import { useEffect, useState } from 'react';
import { match, P } from 'ts-pattern';

import type { IPictureConfig } from '@/components/PageModelEditor/src/widgets/PictureWidget/Setting';
import { useOrgInfo } from '@/hook';
import { useGlobalConditions } from '@/hook/useGlobalConditions';
import { fetchDataSource } from '@/utils/dataManager';

interface PictureProps {
  data: IPictureConfig;
  node: GetterPiNode;
  id: string;
}

export const Picture = (props: PictureProps) => {
  const { data, node, id } = props;
  const orgInfo = useOrgInfo()[0]!;
  const [url, setUrl] = useState('');

  const globalConditions = useGlobalConditions(id);

  const getMergedConditions = () => {
    const matchOriginConfig: any = data?.matchOriginConfig || {};
    const pictureConditions = matchOriginConfig.conditions || [];
    if (!globalConditions) return pictureConditions;
    if (!globalConditions?.length) return pictureConditions;
    return [...pictureConditions, ...globalConditions];
  };

  const init = async () => {
    const matchOriginConfig: any = data?.matchOriginConfig || {};

    if (data.type !== 'prop') {
      return setUrl(
        match([data.type, data.value])
          .with([P._, P.nullish], () => null)
          .with(['link', P.string], ['upload', P.string], () => {
            return data.value;
          })
          .exhaustive(),
      );
    }

    const mergedConditions = getMergedConditions();
    if (!mergedConditions.length) {
      return setUrl(node.value.prop._sys_attach?.[data.value!]?.[0]);
    }

    const { status, list } = await fetchDataSource({
      orgId: orgInfo.orgId,
      currentNodeId: node.value.id,
      page: 1,
      pageSize: 999, //不分页
      conditions: mergedConditions,
      type: matchOriginConfig.type,
      thmId: matchOriginConfig.thmId,
      parentType: matchOriginConfig.parentType,
      matchings: matchOriginConfig.matchings,
    });

    if (status === 'ok' && list && list.length) {
      setUrl(list[0].prop._sys_attach?.[data.value!]?.[0]);
    }
  };
  useEffect(() => {
    init();
  }, [node.value.id, data.type, data.value, globalConditions]);

  return (
    <div className="w-full h-full">
      {url ? (
        <img
          src={url}
          alt="picture component"
          style={{
            objectFit: data.fit || 'none',
            objectPosition: data.align,
            width: '100%',
          }}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center text-[#BFC6D2] text-[14px]">
          暂无图片
        </div>
      )}
    </div>
  );
};
