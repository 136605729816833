import { Divider, Form, Radio } from 'antd';
import { type FC, memo } from 'react';

import { useActiveWidgetInstance, useEditor } from '../hooks';

export type IPaginationSetting = {
  pagination: boolean;
};

const options = [
  { label: '显示', value: true },
  { label: '不显示', value: false },
];

const _PaginationSetting: FC = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance()!;

  const setPaginationVisible = (pagination: boolean) => {
    editor.store.updateWidgetInstanceConfig<IPaginationSetting>(widgetInstance.id, {
      ...widgetInstance.config,
      pagination,
    });
  };

  return (
    <>
      <Divider dashed />
      <Form.Item label="分页">
        <Radio.Group
          options={options}
          value={widgetInstance.config.pagination}
          onChange={(e) => setPaginationVisible(e.target.value)}
        />
      </Form.Item>
    </>
  );
};

export const PaginationSetting = memo(_PaginationSetting);
