import { Form } from 'antd';
import { nanoid } from 'nanoid';
import type { PropsWithChildren } from 'react';
import { match } from 'ts-pattern';
import { container } from 'tsyringe-neo';

import iconImg from '../../assets/tabSection.png';
import {
  type ISectionColorConfig,
  SectionColorSetting,
} from '../../components';
import { EditorModel } from '../../models';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';
import type { ITabSectionContentSetting } from './ContentSetting';
import { TabSectionContentSetting } from './ContentSetting';
import type { ITabSectionConditionRuleSetting } from './TabSectionConditionRuleSetting';
import { TabSectionConditionRuleSetting } from './TabSectionConditionRuleSetting';

export type ITabSectionConfig = ISectionColorConfig &
  ITabSectionContentSetting &
  ITabSectionConditionRuleSetting;

const Preview: WidgetPreivew<PropsWithChildren> = ({ children }) => {
  return <div>{children}</div>;
};

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">
      <TabSectionContentSetting />
      <SectionColorSetting label="选项卡底色" />
      <TabSectionConditionRuleSetting />
    </Form>
  );
};

export const TabSectionWidget = defineSystemWidget<ITabSectionConfig>()({
  id: 'TabSection',
  title: '选项卡',
  icon: () => {
    return <img src={iconImg} style={{ width: 188 }} />;
  },
  basic: { defaultHeight: 4, defaultWidth: 12, nested: true },
  metadata: {} as any,
  preview: Preview,
  setting: Setting,
  onCreate: async () => {
    const editor = container.resolve(EditorModel);
    const c = editor.store.data.pageConfig;
    return {
      bgColor: match(c.styleMode)
        .with('light', () => '#fff')
        .with('dark', () => '#1B235A')
        .exhaustive(),
      tabs: [
        {
          name: '标签名称1',
          children: [],
          id: nanoid(),
        },
        {
          name: '标签名称2',
          children: [],
          id: nanoid(),
        },
      ],
    };
  },
});
