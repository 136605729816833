/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { Modal } from 'antd';
import { allExpanded, defaultStyles, JsonView } from 'react-json-view-lite';
import type { JsonObject } from 'type-fest';

import { ScrollArea } from '../ui/scroll-area';

import styles from './Modal.module.less';

export type JsonViewerModalProps = {
  data: JsonObject;
  title?: string;
};
const JsonViewerModal = ({ data, ...rest }: JsonViewerModalProps) => {
  const modal = useModal();
  const modalProps = antdModalV5(modal);

  return (
    <Modal
      footer={null}
      {...modalProps}
      {...rest}
      title={rest.title || 'JSON 查看器'}
      centered
      bodyStyle={{ padding: 0 }}
      width={720}
    >
      <div className={styles.jsonViewer}>
        <ScrollArea className="h-[500px]">
          <JsonView
            data={data}
            shouldExpandNode={allExpanded}
            style={defaultStyles}
          />
        </ScrollArea>
      </div>
    </Modal>
  );
};

const JsonViewerNiceModal = create(JsonViewerModal);

export const viewJSON = (
  data: JsonObject,
  options?: Omit<JsonViewerModalProps, 'data'>,
) => {
  return show(JsonViewerNiceModal, { data, ...options });
};
