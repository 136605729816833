import type { CustomButton } from '@linkpi/core';
import { THEME_STATUS } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { Checkbox, Form, Radio } from 'antd';
import { produce } from 'immer';
import { defaultTo } from 'ramda';

import CustomButtonCmp from '@/components/CustomButton/Button';
import { RegularIcon } from '@/components/IconFont';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

import { WidgetIcon } from '../../components';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type ICustomButtonConfig = Pick<CustomButton, 'buttons' | 'grid'>;

const positionOptions = [
  {
    label: '居左',
    value: 'left',
  },
  {
    label: '居中',
    value: 'center',
  },
  {
    label: '居右',
    value: 'right',
  },
];

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<ICustomButtonConfig>(id);

  assertExists(widgetInstance);

  const currentTemplate = useCurrentTemplate();

  let buttonIds: string[] = [];
  let showButtons: any;
  if (
    widgetInstance &&
    widgetInstance.config.buttons &&
    widgetInstance.config.buttons.length
  ) {
    buttonIds = widgetInstance.config.buttons.map((x) => x.buttonId);
    const cn = currentTemplate.custom_button || [];

    showButtons = buttonIds.map((x) => cn.find((y) => x === y.id));
  }

  const justifyContent = widgetInstance.config?.grid ?? 'left';

  if (showButtons?.length) {
    return (
      <div
        className="btn-box"
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent,
          boxSizing: 'border-box',
          padding: '0 12px',
        }}
      >
        {showButtons.filter(Boolean).map((btn: any, index: any) => {
          const config = btn;
          const forbiddenClick = false;

          return (
            <div
              key={btn.id}
              style={{
                marginRight: index === showButtons.length - 1 ? 0 : 8,
                marginBottom: 8,
                cursor: 'pointer',
                flexShrink: 0,
              }}
            >
              <CustomButtonCmp
                config={config}
                forbiddenClick={forbiddenClick}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ICustomButtonConfig>()!;

  const currentTemplate = useCurrentTemplate();
  const custom_button = currentTemplate.custom_button;
  const isLock = currentTemplate.status === THEME_STATUS.lock;

  const checkedButtons = defaultTo([])(widgetInstance.config.buttons);

  const changeCustomButton = (
    btn: NonNullable<typeof custom_button>[number],
  ) => {
    const newConfig = produce(widgetInstance.config, (draft) => {
      if (draft.buttons.find((i: any) => i.buttonId === btn.id)) {
        draft.buttons = draft.buttons.filter((i) => i.buttonId !== btn.id);
      } else {
        draft.buttons.push({
          buttonId: btn.id,
          templateId: currentTemplate.template_id,
        });
      }
    });
    editor.store.updateWidgetInstanceConfig(widgetInstance.id, newConfig);
  };

  const handleRadioChange = (grid: string) => {
    editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
      ...widgetInstance?.config,
      grid,
    });
  };

  return (
    <Form layout="vertical">
      <Form.Item label="按钮对齐位置">
        <Radio.Group
          disabled={isLock}
          options={positionOptions}
          value={widgetInstance.config.grid || 'left'}
          onChange={(e) => handleRadioChange(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="选择按钮">
        {custom_button?.map((b) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 12,
              cursor: 'pointer',
            }}
            key={b.id}
          >
            <div onClick={() => changeCustomButton(b)} style={{ flex: 1 }}>
              {b.name}
            </div>
            <Checkbox
              disabled={isLock}
              checked={!!checkedButtons.find((i: any) => i.buttonId === b.id)}
              onChange={() => changeCustomButton(b)}
            />
          </div>
        ))}
      </Form.Item>
    </Form>
  );
};

export const ButtonWidget = defineSystemWidget<ICustomButtonConfig>()({
  id: 'CustomButton',
  title: '自定义按钮',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#FF962C"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zidingyianniu"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: { defaultHeight: 1, defaultWidth: 12, minHeight: 1, minWidth: 3 },
  metadata: {
    buttons: [],
    grid: 'left',
  },
  preview: Preview,
  setting: Setting,
});
