import type { ChartsInfo, ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import {
  useControllableValue,
  useMemoizedFn,
  usePrevious,
  useUpdateEffect,
} from 'ahooks';
import { keys } from 'ramda';
import type { ForwardedRef } from 'react';
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { match } from 'ts-pattern';

import { getViewIcon } from '@/components/LinkPiForm/PublicViewSelect/utils';
import {
  useCurrentUser,
  useOrgConnection,
  useOrgInfo,
  useOrgTempMap,
} from '@/hook';
import { useOrgViewListRequest } from '@/hook/useOrgViewListRequest';
import type { IDashboardComponentFrom } from '@/pages/home/components/View/DashBoard/ComponentForm';
import {
  DASHBOARD_COMPONENTS,
  getInitForm,
} from '@/pages/home/components/View/DashBoard/ComponentForm';
import AddForm, {
  type AddFormPropsRef,
} from '@/pages/home/components/View/DashBoard/ComponentForm/AddForm';
import OrgViewList from '@/pages/home/components/View/DashBoard/ComponentForm/ViewList';

import styles from './Form.less';

export type IStatisticFormValue<T extends IDashboardComponentFrom['type']> =
  T extends 'more'
    ? IDashboardComponentFrom & { type: 'more'; viewId?: string }
    : IDashboardComponentFrom & {
        chartInfo: ChartsInfo;
        type: 'chart';
        viewId?: string;
      };

export interface IStatisticForm<
  T extends IDashboardComponentFrom['type'],
  ValueType = IStatisticFormValue<T>,
> {
  type: T;
  mode: 'add' | 'edit';
  defaultValue?: ValueType;
  value?: ValueType;
  onChange?: (v: ValueType) => void;
  viewType?: T extends 'more' ? never : ViewList.ViewChartsType;
}

export type StatisticFormRef = { vaildateChartForm: () => Promise<void> };

const _StatisticForm = <T extends IDashboardComponentFrom['type']>(
  { type, viewType, mode, ...restProps }: IStatisticForm<T>,
  ref: ForwardedRef<StatisticFormRef>,
): React.ReactElement | null => {
  useImperativeHandle(ref, () => ({
    vaildateChartForm: async () => {
      if (type === 'more') {
        throw Error('指标不存在 chartForm');
      }
      const form = addFormRef.current?.getChartForm();

      if (!form) {
        console.error('form is null');
        return;
        // throw Error('form is null');
      }

      await form.validateFields();
      return;
    },
  }));

  const _defaultValue = restProps.defaultValue || {
    ...getInitForm(type, viewType),
    viewType,
  };
  const [componentForm, setComponentForm] = useControllableValue({
    ...restProps,
    defaultValue: _defaultValue,
  }) as [IStatisticFormValue<T>, (v: IStatisticFormValue<T>) => void];

  const currentUser = useCurrentUser();
  const orgConnection = useOrgConnection();
  const [orgInfo] = useOrgInfo();
  assertExists(orgInfo);

  const tempMap = useOrgTempMap();
  const customAllowTempIds = useMemo(
    () => keys(tempMap).filter((id) => id !== 'ff') as string[],
    [tempMap],
  );

  const getViewPath = useMemoizedFn((nodeId: string) => {
    if (!orgConnection) return '';

    const path = orgConnection.nodeManager.findChildrenPathDoc(nodeId);
    return [orgInfo.orgName, ...(path || []).map((x) => x.title)].join(' > ');
  });

  const addFormRef = useRef<AddFormPropsRef | null>(null);

  const [editType, setEditType] = useState(mode);
  const prevFilterType = usePrevious(componentForm.filterType);

  useUpdateEffect(() => {
    if (prevFilterType !== componentForm.filterType) setEditType('add');
  }, [componentForm.filterType]);

  // 修改单个
  const setComponentFormItem = useMemoizedFn(
    <U extends keyof IDashboardComponentFrom>(
      key: U,
      value: IDashboardComponentFrom[U],
    ) => {
      setComponentForm({
        ...componentForm,
        [key]: value,
      });
    },
  );

  // 批量修改
  const setComponentFromItems = useMemoizedFn(
    (items: Partial<IDashboardComponentFrom>) => {
      match(items)
        .with({ filterType: 'custom' }, () => {
          setComponentForm({
            ...componentForm,
            ...items,
            relation: undefined,
          });
        })
        .with({ filterType: 'relation' }, () => {
          setComponentForm({
            ...componentForm,
            ...items,
            chartInfo: undefined,
            relation: {
              view_id: items.relation?.view_id,
              view_name: items.relation?.view_name,
            },
            statTempId:
              (
                items.relation?.view_info as ViewList.ViewInfo | null
              )?.condition.find((i) => i.key === 'templateId')?.value || '',
            viewId: items.relation?.view_id,
            name: items.relation?.view_name,
          });
        })
        .otherwise(() =>
          setComponentForm({
            ...componentForm,
            ...items,
          }),
        );
    },
  );

  const onChartsInfoChange = useMemoizedFn((chartInfo: ChartsInfo) => {
    if (type === 'chart') {
      // @ts-ignore
      setComponentForm((v) => ({
        ...v,
        // @ts-ignore
        name: chartInfo.name,
        chartInfo,
      }));
    }
  });

  const mockViewData = useMemo(() => {
    if (editType === 'add') return undefined;

    if (!('chartInfo' in componentForm)) {
      // throw Error('缺少 chartInfo');
      return {
        create_time: 0,
        update_time: 0,
        public: 1,
        view_type: componentForm.viewType || 1,
        account_id: currentUser.userid,
        update_user: currentUser.userid,
        template_id: 'ff',
        view_name: componentForm?.name,
        view_id: 'mock',
      } as ViewList.ViewListItem<3>;
    }

    const template_id = componentForm.chartInfo?.template;

    const mock = {
      create_time: 0,
      update_time: 0,
      charts_info: componentForm.chartInfo,
      public: 1,
      account_id: currentUser.userid,
      update_user: currentUser.userid,
      template_id,
      view_name: componentForm.chartInfo?.viewName,
      view_id: 'mock',
    } as ViewList.ViewListItem<3>;
    return mock;
  }, [componentForm, currentUser.userid, editType]);

  // 获取空间所有视图
  const { data: allViewList = [] } = useOrgViewListRequest({
    org_id: orgInfo.orgId,
  });

  /**
   * 'custom': 自定义统计视图
   * 'relation': 关联统计视图
   */
  const [filterType, setFilterType] = useState<'custom' | 'relation'>('custom');

  const onViewClick = useMemoizedFn((viewInfo: ViewList.ViewListItem<any>) => {
    setComponentFromItems({
      filterType: 'relation',
      conditions: [{ key: 'templateId', value: null }],
      parentId: null,
      relation: viewInfo,
      statType: 'count',
      statProp: null,
      group: viewInfo.view_info?.group || undefined,
    });
    setFilterType('custom');
  });

  return (
    <div className={styles.wrapper}>
      {match(filterType)
        .with('custom', () => (
          <AddForm
            ref={addFormRef}
            editType={editType}
            customAllowTempIds={customAllowTempIds}
            componentForm={componentForm}
            setComponentFormItem={setComponentFormItem}
            setComponentFromItems={setComponentFromItems}
            componentTypeInfo={DASHBOARD_COMPONENTS[componentForm.type]}
            onRelation={() => setFilterType('relation')}
            getViewPath={getViewPath}
            getViewIcon={getViewIcon}
            disableChartType
            onChartsInfoChange={onChartsInfoChange}
            editViewData={mockViewData}
            viewType={viewType}
          />
        ))
        .with('relation', () => (
          <OrgViewList
            showBack
            className={styles.viewList}
            onBack={() => setFilterType('custom')}
            viewList={allViewList}
            componentType={componentForm.type}
            chartType={viewType}
            getViewPath={getViewPath}
            getViewIcon={getViewIcon}
            onViewClick={onViewClick}
          />
        ))
        .exhaustive()}
    </div>
  );
};

/**
 * Augment forwardRef
 *
 * more detail see: https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
 */
type ForwardRefWithGeneric = <T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
) => (props: P & React.RefAttributes<T>) => React.ReactNode | null;

export const StatisticForm = (forwardRef as ForwardRefWithGeneric)(
  _StatisticForm,
);
