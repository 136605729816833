import type { ResponseResult } from '@linkpi/request';
import { buildRecord } from '@linkpi/utils';
import type { QueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { memoize } from 'lodash';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { getApiSourceList } from '@/services/api';
import { getDBConfigList, getTableHeaderMapList } from '@/services/table';

import { useCurrentOrgId } from './useOrg';
import { useTemplateDataTableHeaderMap } from './useTemplateDataTableHeaderMap';

type ExtendedQueryOptions<T extends (...args: any) => any> = Omit<
  QueryOptions<Awaited<ReturnType<T>>>,
  'queryKey' | 'queryFn'
>;

type QueryResult<T extends (...args: any) => Promise<ResponseResult<any>>> =
  Awaited<ReturnType<T>>;

type QueryResultData<T> = T extends { status: 'ok'; data: infer D } ? D : never;

type DBList = QueryResultData<QueryResult<typeof getDBConfigList>>;
export const useDBConfig = (
  _orgId?: string,
  queryOptions?: ExtendedQueryOptions<typeof getDBConfigList>,
) => {
  const currentOrgId = useCurrentOrgId();
  const orgId = _orgId || currentOrgId;

  const result = useQuery({
    ...queryOptions,
    queryKey: ['dbConfig', orgId],
    queryFn: () => getDBConfigList({ org_id: orgId }),
    gcTime: 30 * 60 * 1_000,
    staleTime: 60 * 1_000,
  });

  return result;
};

const toDBMap = memoize((list: DBList) => buildRecord(list, 'db_id'));
export const useDBMap = (
  _orgId?: string,
  queryOptions?: ExtendedQueryOptions<typeof getDBConfigList>,
) => {
  const { data: dbConfig, isPending } = useDBConfig(_orgId, queryOptions);

  const dbMap = useMemo(
    () =>
      match(dbConfig)
        .with({ status: 'ok' }, ({ data }) => toDBMap(data))
        .otherwise(() => ({})),
    [dbConfig],
  );

  return { dbMap, isPending };
};

type ApiSourceList = QueryResultData<QueryResult<typeof getApiSourceList>>;
export const useApiSourceList = (
  _orgId?: string,
  queryOptions?: ExtendedQueryOptions<typeof getApiSourceList>,
) => {
  const currentOrgId = useCurrentOrgId();
  const orgId = _orgId || currentOrgId;

  return useQuery({
    ...queryOptions,
    queryKey: ['apiSourceList', orgId],
    queryFn: () => getApiSourceList({ org_id: orgId }),
    gcTime: 30 * 60 * 1_000,
    staleTime: 60 * 1_000,
  });
};

const toApiSourceMap = memoize((list: ApiSourceList) =>
  buildRecord(list, 'api_id'),
);

export const useApiSourceMap = (
  _orgId?: string,
  queryOptions?: ExtendedQueryOptions<typeof getApiSourceList>,
) => {
  const { data: apiSourceList, isPending } = useApiSourceList(
    _orgId,
    queryOptions,
  );

  const apiSourceMap = useMemo(
    () =>
      match(apiSourceList)
        .with({ status: 'ok' }, ({ data }) => toApiSourceMap(data))
        .otherwise(() => ({})),
    [apiSourceList],
  );

  return { apiSourceMap, isPending };
};

export const useTableHeaderMapList = (
  thId?: string,
  _orgId?: string,
  queryOptions?: ExtendedQueryOptions<typeof getTableHeaderMapList>,
) => {
  const currentOrgId = useCurrentOrgId();
  const orgId = _orgId || currentOrgId;

  return useQuery({
    ...queryOptions,
    queryKey: ['tableHeaderMapList', orgId, thId],
    queryFn: () => getTableHeaderMapList({ org_id: orgId, th_id: thId! }),
    gcTime: 30 * 60 * 1_000,
    staleTime: 60 * 1_000,
    enabled: !!thId,
  });
};

export const useTableHeaderMapListByTemplate = (
  tempalteId?: string,
  _orgId?: string,
) => {
  const { isOpen, header } = useTemplateDataTableHeaderMap(tempalteId);

  const result = useTableHeaderMapList(header?.th_id, _orgId);

  return { ...result, isOpen };
};
