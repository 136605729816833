import { PlusOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useControllableValue, useMemoizedFn } from 'ahooks';
import { Popconfirm } from 'antd';
import { produce } from 'immer';
import { type FC, memo, useMemo } from 'react';

import { PiButton } from '@/components/Button';
import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import { getFilterItemConfig } from './FilterItemModal';
import type { IGlobalConditionFilterCondition } from './types';

const FilterItem: FC<
  IGlobalConditionFilterCondition & {
    templateId?: string;
    onUpdate: (id: string, condition: IGlobalConditionFilterCondition) => void;
    onDelete: (id: string) => void;
    deletable?: boolean;
  }
> = memo(
  ({
    templateId,
    onUpdate,
    onDelete: _onDelete,
    deletable = true,
    ...props
  }) => {
    const { id, name } = props;
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      isDragging,
      transform,
      transition,
    } = useSortable({
      id,
    });

    const style: React.CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 1 } : {}),
    };

    const onDelete = useMemoizedFn(() => {
      _onDelete(id);
    });

    const onEdit = useMemoizedFn(async () => {
      const c = await getFilterItemConfig({ templateId, value: props });

      onUpdate(id, c);
    });

    return (
      <div
        key={id}
        className={cn(
          'flex items-center justify-between leading-none pr-3 pl-1 bg-[#F8F9FB] hover:bg-gray-100 transition-colors rounded-[8px] cursor-default group h-[40px]',
          isDragging && 'shadow-sm',
        )}
        ref={setNodeRef}
        {...attributes}
        style={style}
      >
        <div className="flex items-center gap-2">
          <span
            ref={setActivatorNodeRef}
            className={cn(isDragging ? 'cursor-grabbing' : 'cursor-grab')}
            {...listeners}
          >
            <RegularIcon type="iconchangyong-paixu" />
          </span>
          <div>{name || '未命名筛选项目'}</div>
        </div>
        <div className="items-center gap-3 hidden group-hover:flex">
          <span onClick={onEdit} className="cursor-pointer">
            <RegularIcon
              type="iconshaixuanqu-bianji"
              color="#316EF5"
              size={16}
            />
          </span>

          {deletable && (
            <Popconfirm
              title="确定删除吗？"
              onConfirm={onDelete}
              placement="topRight"
            >
              <span className="cursor-pointer">
                <RegularIcon type="iconshanchu2" color="#F0665E" size={16} />
              </span>
            </Popconfirm>
          )}
        </div>
      </div>
    );
  },
);

/**
 * 条件列表
 */
export const FilterList: FC<{
  templateId?: string;
  value?: IGlobalConditionFilterCondition[];
  onChange?: (v: IGlobalConditionFilterCondition[]) => void;
}> = ({ templateId, ...rest }) => {
  const [value = [], setValue] = useControllableValue(rest) as [
    IGlobalConditionFilterCondition[],
    (v: IGlobalConditionFilterCondition[]) => void,
  ];

  const onAdd = useMemoizedFn(async () => {
    const c = await getFilterItemConfig({ templateId });

    const newValue = produce(value, (draft) => {
      draft.push(c);
    });

    setValue(newValue);
  });

  const onUpdate = useMemoizedFn(
    (id: string, condition: IGlobalConditionFilterCondition) => {
      const newValue = produce(value, (draft) => {
        const index = draft.findIndex((item) => item.id === id);
        draft[index] = condition;
      });

      setValue(newValue);
    },
  );

  const onDelete = useMemoizedFn((id: string) => {
    setValue(value.filter((item) => item.id !== id));
  });

  const handleDragEnd = useMemoizedFn((event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over || !value) {
      return;
    }
    const activeId = active.id.toString();
    const overId = over.id.toString();

    const activeIndex = value.findIndex((item) => item.id === activeId);
    const overIndex = value.findIndex((item) => item.id === overId);

    const newValue = arrayMove(value, activeIndex, overIndex)!;

    setValue(newValue);
  });

  const idList = useMemo(() => value.map((item) => item.id), [value]);

  return (
    <div className="flex flex-col gap-2 items-start">
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={idList} strategy={verticalListSortingStrategy}>
          <div className="flex flex-col gap-2 w-full">
            {value.map((item) => (
              <FilterItem
                key={item.id}
                {...item}
                templateId={templateId}
                onUpdate={onUpdate}
                onDelete={onDelete}
                deletable={value.length > 1}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <PiButton
        className="text-[#767C88] border-[#BFC6D2] shadow-none"
        icon={<PlusOutlined />}
        type="dashed"
        onClick={onAdd}
      >
        筛选项
      </PiButton>
    </div>
  );
};
