import type { ViewList } from '@linkpi/core';
import { getViewSettingOptionWithGroup } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { Form, Radio, Select, Space } from 'antd';
import { head, toPairs } from 'ramda';
import { type FC, useMemo } from 'react';

import { RegularIcon } from '@/components/IconFont';
import {
  useCurrentOrgId,
  useOrgInfo,
  useOrgTempMap,
  useQuoteOptions,
} from '@/hook';

import { useActiveWidgetInstance, useEditor } from '../hooks';
import type { IConditionSetting } from './ConditionSetting';

export type INodeOrderSetting = Pick<
  ViewList.ViewInfo,
  'orderBy' | 'orderDesc'
>;

const orderDescOptions = [
  {
    label: (
      <Space align="center">
        <RegularIcon type="iconDescending" size={13} /> 降序
      </Space>
    ),
    value: true,
  },
  {
    label: (
      <Space align="center">
        <RegularIcon type="iconAscending" size={13} /> 升序
      </Space>
    ),
    value: false,
  },
];
const ORDER_DESC_BLACKLIST = ['treeLevel', '_sys_updateTime'];

export const NodeOrderSetting: FC = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<
    IConditionSetting & INodeOrderSetting
  >();

  assertExists(widgetInstance);

  const value = widgetInstance?.config;

  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);
  const [orgInfo] = useOrgInfo(orgId);

  assertExists(orgInfo);

  const curTemplate =
    tempMap[
      head(
        value.conditions.find((i) => i.key === 'templateId')?.input,
      ) as string
    ];

  const [quoteOptions] = useQuoteOptions(orgId, curTemplate);

  const { orderByOption } = useMemo(() => {
    return getViewSettingOptionWithGroup(
      curTemplate ? [curTemplate] : [],
      orgInfo,
      tempMap,
      quoteOptions,
    );
  }, [curTemplate, orgInfo, quoteOptions, tempMap]);

  const options = useMemo(() => {
    return toPairs(orderByOption).map(([key, o]) => {
      return {
        value: key,
        label: o.name,
      };
    });
  }, [orderByOption]);

  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<INodeOrderSetting>;

  return (
    <Form.Item label="排序">
      <Space direction="vertical" className="full-w">
        <Select
          options={options}
          value={value.orderBy}
          onChange={(v) =>
            updateConfig((c) => {
              c.orderBy = v;
            })
          }
        />
        {ORDER_DESC_BLACKLIST.includes(value.orderBy) || (
          <Radio.Group
            options={orderDescOptions}
            value={value.orderDesc}
            onChange={(e) => {
              updateConfig((c) => {
                c.orderDesc = e.target.value;
              });
            }}
          />
        )}
      </Space>
    </Form.Item>
  );
};
