import type { CurrentUser, OrgUser } from '@linkpi/core';
import { has, uniq } from 'ramda';

import { setUserList } from '@/hook';
import request, { cloudFnRequest } from '@/utils/request';

const parseToArray = (s?: string, splitChar = ',') =>
  s ? uniq(s.split(splitChar).filter(Boolean)) : [];
const handleUserList = (list: any[]) =>
  list.map((u) => ({
    ...u,
    group_ids: u.group_ids?.filter?.(Boolean) || [],
    // 注意标点，是顿号 、
    group_list: parseToArray(u.group_list, '、').join('、'),
    org_struct_ids: parseToArray(u.org_struct_ids),
    org_struct_ids_all: parseToArray(u.org_struct_ids_all),
    // 注意标点，是顿号 、
    org_struct_list: parseToArray(u.org_struct_list, '、'),
    // 注意标点，是顿号 、
    org_struct_list_all: parseToArray(u.org_struct_list_all, '、'),
    org_struct_role: parseToArray(u.org_struct_role).map((i: string) =>
      Number(i),
    ),
    org_struct_role_all: parseToArray(u.org_struct_role_all).map((i: string) =>
      Number(i),
    ),
  })) as OrgUser.OrgUserItem[];

export async function fetchUserList(params: { org_id: string }) {
  return request<any[]>('/api/organization/user', {
    method: 'POST',
    data: { ...params, avatar: true },
  }).then((res) => {
    if (res.status === 'ok') {
      const userList = handleUserList(res.data);
      setUserList(userList);
      return {
        ...res,
        data: userList,
      };
    }
    return res;
  });
}
export async function fetchSpaceTags(params: unknown) {
  return request('/docapi/getTag', {
    method: 'POST',
    data: params,
  });
}
export async function addTag(params: unknown) {
  return request('/docapi/addTag', {
    method: 'POST',
    data: params,
  });
}
export async function modifyTag(params: unknown) {
  return request('/docapi/modifyTag', {
    method: 'POST',
    data: params,
  });
}
export async function deleteTag(params: unknown) {
  return request('/docapi/deleteTag', {
    method: 'POST',
    data: params,
  });
}
export async function fetchAllUserList(params: unknown) {
  return request('/api/organization/allUser', {
    method: 'POST',
    data: params,
  });
}
export async function fetchUserPhone(params: unknown) {
  return request('/api/user/phone', {
    method: 'POST',
    data: params,
  });
}
export async function fetchGroupUserList(params: unknown) {
  return request<unknown[]>('/api/group/user', {
    method: 'POST',
    data: params,
  }).then((res) => {
    if (res.status === 'ok') {
      return {
        ...res,
        data: handleUserList(res.data),
      };
    }
    return res;
  });
}
export async function queryUserByGroups(params: unknown) {
  return request('/api/queryUserByGroups', {
    method: 'POST',
    data: params,
  });
}
export async function getTemplate(params: unknown) {
  return request('/api/organization/getTemplate', {
    method: 'POST',
    data: params,
  });
}
export async function setDefaultTemplate(params: unknown) {
  return request('/api/organization/setDefaultTemplate', {
    method: 'POST',
    data: params,
  });
}
export async function orgFeishuConfig(params: unknown) {
  return request('/api/feishu/config', {
    method: 'POST',
    data: params,
  });
}
export async function organizationSetting(params: unknown) {
  return request('/api/organization/setting', {
    method: 'POST',
    data: params,
  });
}
export async function fetchAddUserLink(params: unknown) {
  return request('/api/organization/adduser', {
    method: 'POST',
    data: params,
  });
}
export async function queryGroups(params: unknown) {
  return request('/api/organization/queryGroups', {
    method: 'POST',
    data: params,
  });
}
export async function queryTemplates(params: { org_id: string }) {
  return cloudFnRequest<CurrentUser.TemplateInfo[]>('queryTemplates', {
    method: 'POST',
    data: params,
  }).then((res) => {
    if (res.status === 'ok') {
      /**
       * 给状态补充 index
       */
      for (let index = 0; index < res.data.length; index++) {
        const templateInfo = res.data[index];
        for (
          let statusIndex = 0;
          statusIndex < templateInfo.task_status.length;
          statusIndex++
        ) {
          const statusInfo = templateInfo.task_status[statusIndex];
          if (!has('index', statusInfo)) {
            statusInfo.index = statusIndex;
          }
          if (!has('sort', statusInfo)) {
            statusInfo.sort = statusIndex;
          }
        }
      }
    }
    return res;
  });
}
export async function queryTemplateLibrary(params: unknown) {
  return request('/api/template/library', {
    method: 'POST',
    data: params,
  });
}
export async function copyTemplates(params: unknown) {
  return request('/api/template/copies', {
    method: 'POST',
    data: params,
  });
}
export async function copyTemplate(params: unknown) {
  return request('/api/template/copy', {
    method: 'POST',
    data: params,
  });
}
export async function addGroup(params: unknown) {
  return request('/api/group/create', {
    method: 'POST',
    data: params,
  });
}
export async function addTemplate(params: unknown) {
  return request('/api/template/create', {
    method: 'POST',
    data: params,
  });
}
export async function updateGroup(params: unknown) {
  return request('/api/group/update', {
    method: 'POST',
    data: params,
  });
}
export async function updateTemplate(params: unknown) {
  return request('/api/template/update', {
    method: 'POST',
    data: params,
  });
}
export async function editTemplateProp(params: unknown) {
  return request('/api/template/edit', {
    method: 'POST',
    data: params,
  });
}
export async function removeCollectRule(params: unknown) {
  return request('/docapi/removeCollectRule', {
    method: 'POST',
    data: params,
  });
}
export async function updateCollectRule(params: unknown) {
  return request('/docapi/updateCollectRule', {
    method: 'POST',
    data: params,
  });
}
export async function deleteGroup(params: unknown) {
  return request('/api/organization/delgroup', {
    method: 'POST',
    data: params,
  });
}
export async function delTemplate(params: unknown) {
  return request('/api/organization/delTemplate', {
    method: 'POST',
    data: params,
  });
}
export async function modifyGroupRole(params: unknown) {
  return request('/api/organization/modifygrouprole', {
    method: 'POST',
    data: params,
  });
}
export async function modifyUserRole(params: unknown) {
  return request('/api/organization/modifyuserrole', {
    method: 'POST',
    data: params,
  });
}
export async function addGroupUser(params: unknown) {
  return request('/api/group/adduser', {
    method: 'POST',
    data: params,
  });
}
export async function delGroupUser(params: unknown) {
  return request('/api/group/deluser', {
    method: 'POST',
    data: params,
  });
}
export async function modifyOrgNickName(params: unknown) {
  return request('/api/organization/modifyNickName', {
    method: 'POST',
    data: params,
  });
}

export async function acceptInvite(params: unknown) {
  return request('/api/organization/acceptInvite', {
    method: 'POST',
    data: params,
  });
}
// export async function getSubscribeUpdate() {
//   return request('/api/subscribe/updates', {
//     method: 'POST',
//     data: { day: 3 },
//   });
// }

export async function getSubscribeUpdate() {
  return request('/api/subscribe/sum', {
    method: 'POST',
    data: {},
  });
}

// export async function getSubscribeNodeLog({ id, time }) {
//   return request('/api/node/alllog', {
//     method: 'POST',
//     data: {
//       nodeId: id,
//       updateTimeAfter: dayjs(time).startOf('d').valueOf() / 1000, // start
//       updateTimeBefore: dayjs(time).endOf('d').valueOf() / 1000, // end
//     },
//   });
// }

export async function getAccountSubscribeNode() {
  return request('/api/subscribe/list', {
    method: 'POST',
    data: {},
  });
}

export async function getSubscribeNodeLog(params: unknown) {
  return request('/api/subscribe/allupdates', {
    method: 'POST',
    data: params,
  });
}

export async function getAllNotices(params: unknown) {
  return cloudFnRequest('noticeAll', {
    method: 'POST',
    data: params,
  });
}

export async function insertNotice(params: unknown) {
  return request('/api/notice/add', {
    method: 'POST',
    data: params,
  });
}

export async function getNewNoticesSum() {
  return cloudFnRequest('noticeSum', {
    method: 'POST',
    data: {},
  });
}

export async function getCollectViewList() {
  return request('/api/view/getsub', {
    method: 'POST',
    data: {},
  });
}

export async function collectView({
  viewId,
  nodeId,
  status,
}: {
  viewId: string;
  nodeId: string;
  status: string;
}) {
  const api = status ? '/api/view/sub' : '/api/view/unsub';
  return request(api, {
    method: 'POST',
    data: { view_id: viewId, node_id: nodeId },
  });
}

export async function getShareList() {
  return request('/api/share/records', {
    method: 'POST',
    data: {},
  });
}

export async function getSubscribeList() {
  return request('/api/subscribe/get', {
    method: 'POST',
    data: {},
  });
}

export async function subscribeNode(params: unknown) {
  return request('/docapi/nodeSubscriber', {
    method: 'POST',
    data: params,
  });
}

export async function setOrgInviteAndRole(params: unknown) {
  return request('/api/organization/setInviteAndRole', {
    method: 'POST',
    data: params,
  });
}

/**
 * @deprecated 已经废弃
 * @returns []
 */
export async function getMatchProps() {
  return Promise.resolve({ status: 'ok', data: [] });
}

export async function getPropOptions(params: unknown) {
  return request('/docapi/getViewProp', {
    method: 'POST',
    data: params,
  });
}
export async function addTempPropTag(params: unknown) {
  return request('/docapi/addTempPropTag', {
    method: 'POST',
    data: params,
  });
}
export async function fetchProvData() {
  return request('/api/getProvData', {
    method: 'POST',
  });
}
export async function fetchDistData(params: unknown) {
  return request('/api/getDistrictData', {
    method: 'POST',
    data: params,
  });
}

export interface SyncSpaceTemplateParams {
  org_id: string;
  temp_ids: string[];
  sync_org?: string[];
}

export async function syncSpaceTemplate(params: SyncSpaceTemplateParams) {
  return request('/docapi/syncOrg/template', {
    method: 'POST',
    data: params,
  });
}

export interface SyncSpaceNodeParams {
  org_id: string;
  node_ids: string[];
  sync_org?: string[];
}

export async function syncSpaceNode(params: SyncSpaceNodeParams) {
  return request('/docapi/syncOrg/node', {
    method: 'POST',
    data: params,
  });
}

export interface SyncSpaceParams {
  org_id: string;
  group_ids: string[];
  node_ids: string[];
  temp_ids: string[];
  sync_org?: string[];
}

export async function syncOrgSpace(params: SyncSpaceParams) {
  return request('/docapi/organization/sync', {
    method: 'POST',
    data: params,
  });
}
