import { match, P } from 'ts-pattern';
import { container } from 'tsyringe-neo';

import type { IConditionSetting } from '../../components';
import { EditorModel } from '../../models';
import { type WidgetInstance } from '../../models/Store';
import type { IDataIndicatorWidget } from '../DataIndicatorWidget';

export const getTemplateIdByWidgetInstance = (
  widgetInstance: WidgetInstance,
) => {
  return match(
    widgetInstance as WidgetInstance<
      IConditionSetting & {
        matchOriginConfig?: IConditionSetting;
      }
    >,
  )
    .with(
      { config: { matchOriginConfig: { conditions: P.array() } } },
      ({
        config: {
          matchOriginConfig: { conditions },
        },
      }) => {
        return conditions.find((c) => c.key === 'templateId')?.input[0];
      },
    )
    .with(
      { config: { conditions: P.array() } },
      ({ config: { conditions } }) => {
        return conditions.find((c) => c.key === 'templateId')?.input[0];
      },
    )
    .otherwise(() => '') as string | undefined;
};

const getDataIndicatorItemConfig = (itemId: string) => {
  const store = container.resolve(EditorModel).store;

  const widgetInstanceList =
    store.getWidgetInstancesByWidgetId<IDataIndicatorWidget>('dataIndicator');

  for (let index = 0; index < widgetInstanceList.length; index++) {
    const widgetInstance = widgetInstanceList[index];

    const config = widgetInstance.config;

    const itemConfig = config.indexes[itemId];

    if (itemConfig) {
      return itemConfig;
    }
  }

  return undefined;
};

export const getTemplateIdByDataIndicatorWidgetItemId = (itemId: string) => {
  const itemConfig = getDataIndicatorItemConfig(itemId);

  if (itemConfig) {
    return itemConfig.dataSource.conditions.find((c) => c.key === 'templateId')
      ?.input[0];
  }

  return undefined;
};

export const getTemplateIdByDatasourceId = (datasourceId: string) => {
  const store = container.resolve(EditorModel).store;
  const widgetInstance = store.getWidgetInstance(datasourceId);

  const latestTargetTemplateId = widgetInstance
    ? getTemplateIdByWidgetInstance(widgetInstance)!
    : getTemplateIdByDataIndicatorWidgetItemId(datasourceId)!;

  return latestTargetTemplateId;
};

export const getNameByDataIndicatorWidgetItemId = (itemId: string) => {
  const itemConfig = getDataIndicatorItemConfig(itemId);

  return itemConfig?.name;
};

export const getNameByDatasourceId = (datasourceId: string) => {
  const store = container.resolve(EditorModel).store;
  const widgetInstance = store.getWidgetInstance(datasourceId);

  const name = widgetInstance
    ? widgetInstance.config.name
    : getNameByDataIndicatorWidgetItemId(datasourceId);

  return name;
};
