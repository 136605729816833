import { RegularIcon } from '@/components';
import { cn } from '@/utils/utils';

import { WidgetIcon } from '../../components';
import { useWidgetInstance } from '../../hooks';
import type { WidgetPreivew } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';
import Setting, { type IRichTextConfig } from './Setting';

import Styles from './index.less';

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<IRichTextConfig>(id);
  const config = widgetInstance?.config;

  if (!config) {
    return <div />;
  }

  const { value } = config;

  return (
    <div
      className={cn(
        'w-full h-full ql-editor text-[#242d3f]',
        Styles['rich-text-preview'],
      )}
      style={{ padding: 0 }}
    >
      <div dangerouslySetInnerHTML={{ __html: value ?? '' }} />
    </div>
  );
};

export const RichTextWidget = defineSystemWidget<IRichTextConfig>()({
  id: 'RichText',
  title: '富文本',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#5ABCB0"
        icon={<RegularIcon type="iconwenben" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 2,
    defaultWidth: 6,
    minHeight: 1,
    minWidth: 2,
  },
  metadata: {
    name: '富文本',
    parentType: 'others',
    matchings: [],
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: [],
      },
    ],
    type: 'temp',
    value: '这里是富文本内的一段话',
    contentsValue: {
      ops: [
        {
          insert: '这里是富文本内的一段话\n',
        },
      ],
    },
  },
  preview: Preview,
  setting: Setting,
});
