import { Alert, Popover } from 'antd';
import { type FC } from 'react';

import { PiButton } from '@/components/Button';
import { RegularIcon } from '@/components/IconFont';

import { useEditor } from '../../hooks';
import { useGlobalConditionFilterListByTargetId } from './hook';

export const GlobalConditionFilterLink: FC<{
  id: string;
  onShowBtnClick?: (data: any) => void;
}> = ({ id, onShowBtnClick }) => {
  const editor = useEditor();
  const widgetInstanceList = useGlobalConditionFilterListByTargetId(id);

  if (widgetInstanceList.length === 0) return null;

  const content = (
    <div className="flex flex-col gap-2 w-[264px]">
      <Alert
        type="info"
        showIcon
        message="当前组件数据已经与筛选组件关联，点击查看筛选组件配置"
      />
      {widgetInstanceList.map((item) => (
        <div
          key={item.id}
          className="flex items-center justify-between h-[40px] px-3 rounded-[8px] bg-[#F8F9FB]"
        >
          <span>{item.config.name}</span>
          <PiButton
            type="link"
            className="pr-0"
            onClick={() => {
              if (onShowBtnClick) {
                onShowBtnClick(item);
              }
              editor.setActiveWidgetInstance(item.id);
            }}
          >
            查看
          </PiButton>
        </div>
      ))}
    </div>
  );

  return (
    <Popover content={content} trigger="click" placement="topRight">
      <PiButton type="link" className="px-[1px]">
        <RegularIcon type="iconlianjie1" size={14} />
      </PiButton>
    </Popover>
  );
};
