import { QuestionCircleOutlined } from '@ant-design/icons';
import ProForm, {
  ModalForm,
  ProFormDigit,
  ProFormText,
} from '@ant-design/pro-form';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { ApiResponse } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { Empty, Space, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { isNil } from 'ramda';
import type { FC } from 'react';
import { useMemo } from 'react';

import { useOrgTempMap } from '@/hook';

import { LinkPiDateTimePicker } from '../LinkPiDate/LinkPiDateTimePicker';
import { TemplateEnumPropSelect } from '../LinkPiForm';

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 24 },
};

type CopyNodePropSetupModalProps = {
  orgId: string;
  targetTempId: string;
  sourceTempId: string;
};
const CopyNodePropSetupModalForm: FC<CopyNodePropSetupModalProps> = (props) => {
  const tempMap = useOrgTempMap(props.orgId);
  const modal = useModal();
  const modalProps = antdModalV5(modal);

  const targetTempConfig = tempMap[props.targetTempId];
  const sourceTempConfig = tempMap[props.sourceTempId];

  const onFinish = useMemoizedFn(async ({ values }) => {
    modal.resolve(values);
    modalProps.onOk();
    return;
  });

  /**
   * NOTE:
   * 1. 可见分组，可编辑分组的判断会忽略
   * 2. 选值属性不支持「辅助显示」&「列表样式」
   * 3. 数字属性不支持格式（PS: 上下限，精度，百分比
   * 4. 均不支持默认值
   */
  const propItemList = useMemo(() => {
    const excludedPropIndex: Record<number, true> = {};

    sourceTempConfig.temp_option?.switchTemp
      .find((t) => t.temp_id === targetTempConfig.template_id)
      ?.prop.forEach((p, index) => {
        if (isNil(p) || isEmpty(p)) return;
        if (p.type !== 'prop') return;

        excludedPropIndex[index] = true;
        return;
      });

    return targetTempConfig.prop
      .map((i, propIndex) => ({ ...i, propIndex }))
      .filter((i) => {
        if (
          !['enum', 'tag', 'number', 'text', 'date', 'datetime'].includes(
            i.type,
          )
        )
          return false;
        if (excludedPropIndex[i.propIndex]) return false;

        return true;
      });
  }, []);

  const checkDisable = (
    allowManualUpdate?: ApiResponse.CurrentUser.TemplateProp['allowManualUpdate'],
  ) => {
    if (!Array.isArray(allowManualUpdate)) return false;

    return !allowManualUpdate?.includes(-2);
  };
  console.log(propItemList);

  return (
    <ModalForm
      {...formItemLayout}
      title="设置属性值"
      layout="horizontal"
      open={modalProps.open}
      initialValues={{ values: {} }}
      modalProps={{
        onCancel: () => {
          modalProps.onCancel();
          modal.reject();
        },
        afterClose: modalProps.afterClose,
      }}
      onFinish={onFinish}
    >
      {propItemList.map((propItem) => {
        switch (propItem.type) {
          case 'enum':
          case 'tag':
            return (
              <TemplateEnumPropSelect
                key={propItem.propIndex}
                name={['values', propItem.propIndex.toString()]}
                required={propItem.required}
                label={propItem.name}
                orgId={targetTempConfig.org_id}
                propIndex={propItem.propIndex}
                disabled={checkDisable(propItem.allowManualUpdate)}
                templateId={targetTempConfig.template_id}
              />
            );

          case 'number':
          case 'currency':
            return (
              <ProFormDigit
                key={propItem.propIndex}
                name={['values', propItem.propIndex.toString()]}
                required={propItem.required}
                label={propItem.name}
                disabled={checkDisable(propItem.allowManualUpdate)}
              />
            );

          case 'text':
            return (
              <ProFormText
                key={propItem.propIndex}
                name={['values', propItem.propIndex.toString()]}
                required={propItem.required}
                label={propItem.name}
                disabled={checkDisable(propItem.allowManualUpdate)}
              />
            );
          case 'date':
          case 'datetime':
            console.log(propItem);
            return (
              <ProForm.Item
                key={propItem.propIndex}
                name={['values', propItem.propIndex.toString()]}
                required={propItem.required}
                label={propItem.name}
                valuePropName="dateValue"
              >
                <LinkPiDateTimePicker
                  type={propItem.type}
                  dateFormat={propItem.dateFormat || 'YYYY/MM/DD HH:mm'}
                  disabled={checkDisable(propItem.allowManualUpdate)}
                />
              </ProForm.Item>
            );

          default:
            break;
        }
      })}
      {!propItemList.length && (
        <Empty
          description={
            <Space>
              暂无可设置的属性
              <Tooltip title="仅支持选值、数字、文本及日期属性">
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          }
        />
      )}
    </ModalForm>
  );
};
const CopyNodePropSetupModal = create(CopyNodePropSetupModalForm);

export const getPropsValue = (props: CopyNodePropSetupModalProps) => {
  return show(CopyNodePropSetupModal, props);
};
