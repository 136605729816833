import type { ChildTheme, ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { useUpdateEffect } from '@react-hookz/web';
import { useMemoizedFn } from 'ahooks';
import { Checkbox, Form, Switch } from 'antd';
import { defaultTo, head } from 'ramda';
import { type FC, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useCurrentOrgId, useOrgTempMap, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { genGridAttrs } from '@/pages/pi/grid/react/useGridAttrs';

import { useActiveWidgetInstance, useEditor } from '../hooks';
import type { IConditionSetting } from './ConditionSetting';

import styles from './ConditionSetting.less';

export type IDisplaySetting = {
  displayAttrs: NonNullable<
    NonNullable<ChildTheme['tabs'][number]['tabConfig']>['displayAttrs']
  >;
} & Pick<ViewList.ViewInfo, 'showActions' | 'actionButtons'>;

export const DisplaySetting: FC<{ allowShowActions?: boolean }> = ({
  allowShowActions,
}) => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<
    IConditionSetting & IDisplaySetting
  >();
  assertExists(widgetInstance);

  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);
  const userMap = useOrgUserMap();
  const departmentMap = useOrgDepartmentNodesMap();

  // 支持固定 和 非固定的属性分组
  const attrGroups = [
    {
      label: '固定',
      icon: 'iconfont iconguding',
      key: 'fixed',
      getAttrs: (attrs: any[]) => attrs.filter((p) => p.fixed),
    },
    {
      label: '非固定',
      icon: 'iconfont iconquxiaoguding',
      key: 'notFixed',
      getAttrs: (attrs: any[]) => attrs.filter((p) => !p.fixed),
    },
  ];

  const matchingTemplate =
    tempMap[
      head(
        widgetInstance.config.conditions.find(
          (i: any) => i.key === 'templateId',
        )?.input,
      ) as string
    ] || {};
  console.log('widgetInstance', widgetInstance);
  const displayAttrs = widgetInstance.config.displayAttrs;
  const displayAttrsMap = displayAttrs.reduce((res: any, cur: any) => {
    res[cur.key] = cur;
    return res;
  }, {});

  // 每次取一下最新的 属性
  const latestDisplayAttrs = genGridAttrs(
    matchingTemplate,
    [],
    userMap,
    tempMap,
    departmentMap,
  ).map((a) => ({
    key: a.key,
    disable: true,
    name: a.name,
  }));

  const latestDisplayAttrsMap = latestDisplayAttrs.reduce(
    (res: any, cur: any) => {
      res[cur.key] = cur;
      return res;
    },
    {},
  );

  // 过滤出新的属性
  const news = latestDisplayAttrs.filter((n: any) => !displayAttrsMap[n.key]);

  // 和老的 displayAttrs 结合
  let newDisplayAttrs: any[] = [];
  displayAttrs.forEach((oldP: any) => {
    const p = latestDisplayAttrsMap[oldP.key];
    if (p) newDisplayAttrs.push({ ...oldP, name: p.name });
  });
  newDisplayAttrs = [...newDisplayAttrs, ...news].map(
    (p: any, index: number) => ({ ...p, index }),
  );

  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig<IDisplaySetting>;

  useUpdateEffect(() => {
    updateConfig((draft) => {
      draft.displayAttrs = newDisplayAttrs;
      draft.showActions = false;
      draft.actionButtons = [];
    });
  }, [matchingTemplate.template_id]);

  const changeDisplayAttr = (attr: any) => {
    const { key } = attr;

    newDisplayAttrs.forEach((i: any) => {
      if (i.key === key) i.disable = !i.disable;
    });
    updateConfig((config: any) => {
      config.displayAttrs = newDisplayAttrs;
    });
  };

  const onDragEnd = (res: any) => {
    const { destination, source } = res;
    if (!destination) return;

    let { index: destinationIndex, droppableId: destinationType } = destination;
    const { index: sourceIndex, droppableId: sourceType } = source;

    if (destinationType === 'notFixed' && sourceType === 'fixed')
      destinationIndex--;

    const [removed] = newDisplayAttrs.splice(sourceIndex, 1);
    if (destinationType !== sourceType)
      removed.fixed = destinationType === 'fixed';
    newDisplayAttrs.splice(destinationIndex, 0, removed);
    updateConfig((config: any) => {
      config.displayAttrs = newDisplayAttrs;
    });
  };

  const getChangeDisplayAttrAllChecked = () => {
    let checked = true;
    newDisplayAttrs.forEach((i) => {
      if (i.key.slice(0, 4) === 'prop' && i.disable) checked = false;
    });
    return checked;
  };

  const changeDisplayAttrAll = (v: any) => {
    newDisplayAttrs.forEach((i: any) => {
      if (i.key.slice(0, 4) === 'prop') i.disable = !v;
    });
    updateConfig((config: any) => {
      config.displayAttrs = newDisplayAttrs;
    });
  };

  console.log(widgetInstance.config);
  const { showActions = false, actionButtons = [] } = widgetInstance.config;

  const buttons = useMemo(() => {
    return [
      { name: '查看', id: 'system_button_view' },
      { name: '编辑', id: 'system_button_edit' },
      { name: '删除', id: 'system_button_delete' },
      ...defaultTo([], matchingTemplate.custom_button),
    ];
  }, [matchingTemplate.custom_button]);

  const changeCustomButton = useMemoizedFn(
    (btn: { name: string; id: string }) => {
      let newBtns = [...actionButtons];
      if (newBtns.includes(btn.id)) {
        newBtns = newBtns.filter((i) => i !== btn.id);
      } else {
        newBtns.push(btn.id);
      }
      updateConfig((config) => {
        config.actionButtons = newBtns;
      });
    },
  );

  return (
    <>
      <Form.Item label="显示列">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 6,
          }}
        >
          <div>自定义属性</div>
          <Switch
            checked={getChangeDisplayAttrAllChecked()}
            onChange={changeDisplayAttrAll}
          />
        </div>
        <div className={styles.displayAttrs}>
          <DragDropContext onDragEnd={onDragEnd}>
            {attrGroups.map((g) => (
              <Droppable key={g.key} droppableId={g.key} type="attr">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={styles.attrs}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '6px 0',
                      }}
                    >
                      <i style={{ color: '#C9D0D9' }} className={g.icon} />
                      <span style={{ paddingLeft: 4, color: '#B6B8B9' }}>
                        {g.label}
                      </span>
                    </div>
                    {g.getAttrs(newDisplayAttrs).map((attr: any) => (
                      <Draggable
                        key={attr.key}
                        index={attr.index}
                        draggableId={attr.key}
                      >
                        {(dragProvided) => (
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                          >
                            <div className={styles.attr}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <i
                                  {...dragProvided.dragHandleProps}
                                  className={`iconfont icondrag`}
                                  style={{ fontSize: 16, color: '#6B7A96' }}
                                />

                                <div className={styles.attrName}>
                                  {attr.name}
                                </div>
                              </div>

                              <Switch
                                checked={!attr.disable}
                                onChange={() => changeDisplayAttr(attr)}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
          {allowShowActions && (
            <div>
              <div className="text-[#B6B8B9] py-2">操作</div>
              <div className="flex justify-between items-center mb-3">
                自定义按钮
                <Switch
                  size="small"
                  checked={showActions}
                  onChange={(e) => {
                    updateConfig((config) => {
                      config.showActions = e;
                    });
                  }}
                />
              </div>
              {showActions &&
                buttons?.map((b) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: 12,
                      cursor: 'pointer',
                    }}
                    key={b.id}
                  >
                    <div
                      onClick={() => changeCustomButton(b)}
                      style={{ flex: 1 }}
                    >
                      {b.name}
                    </div>
                    <Checkbox
                      checked={!!actionButtons.find((i: any) => i === b.id)}
                      onChange={() => changeCustomButton(b)}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </Form.Item>
    </>
  );
};
