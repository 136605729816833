import type { FC } from 'react';

import {
  useActiveWidgetInstance,
  useSetActiveWidgetInstanceConfig,
} from '../../hooks';
import type { IDataSourceSetting } from './Modal';
import { DataSourceSettingCore } from './Setting';

export const DataSourceSettingWidgetSetting: FC = () => {
  const widgetInstance = useActiveWidgetInstance<IDataSourceSetting>();

  const setConfig = useSetActiveWidgetInstanceConfig<IDataSourceSetting>();

  return (
    <DataSourceSettingCore
      value={widgetInstance?.config}
      onChange={(value) => {
        setConfig((c) => {
          c.conditions = value.conditions;
          c.type = value.type;
          c.matchings = value.matchings;
          c.nodeSource = value.nodeSource;
          c.parentType = value.parentType;
          c.thmId = value.thmId;
        });
      }}
    />
  );
};
