import { useControllableValue } from 'ahooks';
import { Radio } from 'antd';
import React from 'react';

interface Option {
  value: string;
  label: string;
}

interface ArrangeSelectorProps {
  value?: string;
  onChange?: (value: string) => void;
  options: Option[];
}

export const ArrangeSelector: React.FC<ArrangeSelectorProps> = ({
  options,
  ...rest
}) => {
  const [value, onChange] = useControllableValue(rest);
  return (
    <div className="flex justify-between">
      {options.map((option) => (
        <div
          key={option.label}
          className="bg-[#F8F9FB] rounded-lg p-2 flex justify-between items-center w-[calc(33.3%-8px)] cursor-pointer"
          onClick={() => onChange(option.value)}
        >
          <div className="text-[#242D3F]">{option.label}</div>
          <Radio checked={value === option.value} />
        </div>
      ))}
    </div>
  );
};
