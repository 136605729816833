import { Divider, Form, Input } from 'antd';
import { type FC, memo, useState } from 'react';

import { useActiveWidgetInstance, useEditor } from '../hooks';

const _NameSetting: FC<{ defaultName?: string }> = ({ defaultName }) => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance()!;

  const [resetKey, setResetKey] = useState(0);

  const setName = (r: string) => {
    editor.store.updateWidgetInstanceConfig(widgetIntance.id, {
      ...widgetIntance?.config,
      name: r || defaultName || '未命名',
    });
    if (!r) setResetKey((prev) => prev + 1);
  };

  return (
    <>
      <Form.Item label="组件名">
        <Input
          key={resetKey}
          defaultValue={widgetIntance.config.name}
          onChange={(e) => setName(e.target.value)}
          placeholder={`请输入组件名`}
          max={200}
        />
      </Form.Item>
      <Divider dashed />
    </>
  );
};

export const NameSetting = memo(_NameSetting);
