import { PaperClipOutlined } from '@ant-design/icons';
import { assertExists } from '@linkpi/utils';
import { Form, Input } from 'antd';

import ThirdLinkImg from '../../assets/thirdLink.png';
import { WidgetIcon } from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew } from '../../models/WidgetManager';
import type { WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IFrameConfig = {
  link: string;
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IFrameConfig>()!;
  assertExists(widgetInstance);

  const setFrameValue = (link: string) => {
    editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
      ...widgetInstance.config,
      link,
    });
  };

  return (
    <Form layout="vertical">
      <Form.Item label="视图链接">
        <Input
          placeholder="请粘贴外部视图连接"
          value={widgetInstance.config.link}
          onChange={(e) => setFrameValue(e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};

const Preview: WidgetPreivew = () => {
  return (
    <div className="w-full h-full flex items-center justify-center text-[#BFC6D2] text-[14px]">
      <img className="h-full" src={ThirdLinkImg} alt="视图链接" />
    </div>
  );
};

export const FrameWidget = defineSystemWidget<IFrameConfig>()({
  id: 'Frame',
  title: '外部视图',
  icon: () => {
    return (
      <WidgetIcon bgColor="#9A8BFF" icon={<PaperClipOutlined style={{ color: '#ffffffcc' }} />} />
    );
  },
  basic: {
    defaultHeight: 10,
    defaultWidth: 12,
    minHeight: 5,
    minWidth: 6,
    // maxCount: 1,
    // maxHeight: 5,
    dynamicHeight: false,
  },
  metadata: {
    link: '',
  },
  preview: Preview,
  setting: Setting,
});
