/* eslint-disable react-refresh/only-export-components */
import type { ApiResponse, CurrentUserPropType } from '@linkpi/core';
import { notEmpty, toRecord } from '@linkpi/utils';
import { Select } from 'antd';
import cls from 'classnames';
import { useMemo } from 'react';

import { useConditionFilterConfigContext } from '../hook/useConditionFilterOption';

import styles from '../styles.less';

// v1:true 视图只显示

const IS_EMPTY = { label: '为空', value: 'empty', v1: true };
const NOT_EMPTY = { label: '非空', value: 'notEmpty', v1: true };

export const ENUM_OPERATOR = [
  // 被包含 后端期望通过 intersect
  { label: '被包含', value: 'intersect', multiple: true, v1: true },
  { label: '等于', value: 'equal' },
  IS_EMPTY,
  NOT_EMPTY,
];
export const ENUM_OPERATOR_MUL = [
  { label: '包含', value: 'include', multiple: true, multipleRow: 'or' },
  { label: '被包含', value: 'beIncluded', multiple: true, multipleRow: 'or' },
  { label: '相交', value: 'intersect', multiple: true, v1: true },
  { label: '等于', value: 'equal', multiple: true, multipleRow: 'or' },
  IS_EMPTY,
  NOT_EMPTY,
];
export const TEXT_OPERATOR = [
  { label: '包含', value: 'textInclude', multipleRow: 'or', v1: true },
  { label: '等于', value: 'equal', multipleRow: 'or' },
  IS_EMPTY,
  NOT_EMPTY,
];

export const NUMBER_OPERATOR = [
  { label: '=', value: 'equal', multipleRow: 'or', v1: true },
  { label: '≠', value: 'notEqual', v1: true },
  { label: '>', value: 'greater', v1: true },
  { label: '≥', value: 'greaterEqual', v1: true },
  { label: '<', value: 'less', v1: true },
  { label: '≤', value: 'lessEqual', v1: true },
  { label: '处于', value: 'beIncluded', multipleRow: 'or' },
  IS_EMPTY,
  NOT_EMPTY,
];
export const DATE_OPERATOR = [
  { label: '处于', value: 'timeBein', multipleRow: 'or', v1: true },
  { label: '早于', value: 'timeBefore' },
  { label: '早于等于', value: 'timeBeforeEqual' },
  { label: '等于', value: 'timeEqual', multipleRow: 'or' },
  { label: '晚于等于', value: 'timeAfterEqual' },
  { label: '晚于', value: 'timeAfter' },
  IS_EMPTY,
];
export const ADDRESS_OPERATOR = [
  { label: '距离', value: 'distance' },
  { label: '等于', value: 'equal', multipleRow: 'or', v1: true },
  { label: '省份等于', value: 'rangeEqualProv', multipleRow: 'or' },
  { label: '城市等于', value: 'rangeEqualCity', multipleRow: 'or' },
  { label: '区县等于', value: 'rangeEqualDist', multipleRow: 'or' },
  { label: '街道等于', value: 'rangeEqualStreet', multipleRow: 'or' },
  IS_EMPTY,
];

export const CASCADE_OPERATOR = [
  { label: '等于', value: 'equal', multipleRow: 'or', v1: true },
  // { label: '段相等', value: 'rangeEqual', multipleRow: 'or' },
  // 段相等要通过配置
  IS_EMPTY,
];
export const CASCADE_OPERATOR_MUL = [
  {
    label: '等于',
    value: 'equal',
    multipleRow: 'or',
    multiple: true,
    v1: true,
  },
  // { label: '段相等', value: 'rangeEqual', multipleRow: 'or' },
  IS_EMPTY,
];

const ConditionOperator = (props: {
  opOption: ReturnType<typeof getPropTypeOptions>;
  disabled?: boolean;
  curOpConfig?: {
    label: string;
    value: any;
    multiple?: boolean | undefined;
    multipleRow?: string | undefined;
  };
  condition: ApiResponse.ViewList.ViewconditionV2Item;
  fieldType: ApiResponse.CurrentUser.propType;
  multiple: boolean;
  onChange: any;
}) => {
  const { condition, disabled, fieldType, multiple, onChange, opOption } =
    props;
  const { key, op, input } = condition;
  const { experimental_disableOpList } = useConditionFilterConfigContext();

  const opOptionList = useMemo(() => {
    if (!experimental_disableOpList) return opOption;

    const disableMap = toRecord<string>((o) => ({
      [o]: o,
    }))(experimental_disableOpList);
    return opOption.filter((o) => !disableMap[o.value]);
  }, [experimental_disableOpList, opOption]);

  // select
  const hideOpSelects = [
    'ancestor',
    'templateId',
    'templateIds',
    'statusFlow',
    'status',
  ] as (typeof key)[];
  if (~hideOpSelects.indexOf(key)) {
    return null;
  }

  let selectKey = '';
  if (op === 'or' || op === 'and') {
    selectKey = input[0]?.op;
  } else {
    selectKey = op;
  }

  return (
    <div className={cls('condition-filter-item-operator', styles.operator)}>
      <Select
        disabled={disabled}
        style={{ width: '100%' }}
        options={opOptionList}
        value={selectKey}
        onChange={(e) => {
          onChange({
            ...condition,
            op: e,
            input: [],
          });
        }}
      />
    </div>
  );
};

export default ConditionOperator;

export const getPropTypeOptions = (
  type: ApiResponse.CurrentUser.propType | CurrentUserPropType,
  multiple: boolean,
  displayV1: boolean,
  cascadeConfig: any,
) => {
  let operatorOption: {
    label: string;
    value: any;
    multiple?: boolean;
    multipleRow?: string;
    v1?: boolean;
  }[] = [];

  switch (type) {
    case 'tag':
    case 'enum':
    case 'user':
    case 'userRoles':
    case 'userDepartments':
      operatorOption = multiple ? ENUM_OPERATOR_MUL : ENUM_OPERATOR;
      break;
    case 'text':
    case 'str':
      operatorOption = TEXT_OPERATOR;
      break;
    case 'number':
    case 'currency':
    case 'auto_inc':
      operatorOption = NUMBER_OPERATOR;
      break;
    case 'date':
    case 'datetime':
      operatorOption = DATE_OPERATOR;
      break;
    case 'address':
    case 'positioning':
      operatorOption = ADDRESS_OPERATOR;
      break;
    case 'cascade': {
      const base = multiple ? CASCADE_OPERATOR_MUL : CASCADE_OPERATOR;
      // 段相等
      operatorOption = (base as any).concat(
        cascadeConfig?.titles?.map((x: string, index: number) => {
          return {
            label: `${x}等于`,
            value: `rangeEqualCascade_${index + 1}`,
            multipleRow: 'or',
          };
        }),
      );
      break;
    }
    default:
      operatorOption = [{ label: '等于', value: 'equal', v1: true }, IS_EMPTY];
      break;
  }

  return operatorOption
    .filter(notEmpty)
    .filter((x) => (displayV1 ? x.v1 == true : true));
};
