import { Form, Row } from 'antd';
import { type FC, useCallback } from 'react';

import { PiButton } from '@/components/Button';
import { ColorPicker } from '@/components/ColorPicker';

import { useActiveWidgetInstance, useEditor } from '../hooks';

export type ISectionColorConfig = {
  bgColor: string;
};

export const SectionColorSetting: FC<{
  label: string;
}> = ({ label }) => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ISectionColorConfig>()!;
  const value = widgetInstance.config;
  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<ISectionColorConfig>;

  const setAllSectionColor = useCallback(() => {
    const sections =
      editor.store.getWidgetInstancesByWidgetId('GeneralSection');
    const tabs = editor.store.getWidgetInstancesByWidgetId('TabSection');
    const dynamicHeightSections = editor.store.getWidgetInstancesByWidgetId(
      'DynamicHeightSection',
    );
    [...sections, ...tabs, ...dynamicHeightSections].forEach((item) => {
      editor.store.updateWidgetInstanceConfig<ISectionColorConfig>(
        item.id,
        (c) => {
          c.bgColor = value.bgColor;
        },
      );
    });
  }, [editor.store, value.bgColor]);

  return (
    <Form.Item
      label={
        <Row justify="space-between" align="middle">
          {label}
          <PiButton type="link" onClick={setAllSectionColor}>
            应用到全部
          </PiButton>
        </Row>
      }
    >
      <ColorPicker
        value={value.bgColor}
        onChange={(v) => {
          updateConfig((c) => {
            c.bgColor = v.toString();
          });
        }}
      />
    </Form.Item>
  );
};
