/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { assertExists } from '@linkpi/utils';
import { useToggle } from '@react-hookz/web';
import { useImmerState } from '@shrugsy/use-immer-state';
import { useMemoizedFn } from 'ahooks';
import { Alert, Button, Input, Modal, Radio } from 'antd';
import { type FC, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import ConditionFilter from '@/components/ConditionFilter';
import { RegularIcon } from '@/components/IconFont';
import { TableHeaderMapSelect } from '@/components/LinkPiForm';
import { useCurrentOrgId, useNodeTreeData, useOrgInfo } from '@/hook';
import { cn } from '@/utils/utils';

import type { IConditionSetting } from '../ConditionSetting';
import type { IMatchingSetting } from '../MatchingSetting';
import { MatchingSettingCore } from '../MatchingSetting';
import { useGlobalConditionFilterListByTargetId } from './hook';

const addButtonProps = {
  icon: null,
  className: 'text-[#242D3F]  shadow-none w-full',
  type: 'dashed',
} as any;

export type IDataSourceSetting = IMatchingSetting &
  IConditionSetting & {
    /**
     * 数据源的唯一标识
     *
     * 可以是 widgetInstanceId
     */
    id?: string;
    thmId?: string;
    type: 'temp' | 'dataSource';
  };

const radioOptions = [
  { value: 'temp', label: '主题类型' },
  { value: 'dataSource', label: '数据表类型' },
];

export const createInitialValue = (rootId: string): IDataSourceSetting => ({
  type: 'temp',
  parentType: 'others',
  matchings: [],
  conditions: [
    { key: 'ancestor', op: 'intersect', input: [rootId] },
    { key: 'templateId', op: 'intersect', input: [] },
  ],
});

export const DataSourceSettingModal: FC<{
  defaultValue?: IDataSourceSetting;
}> = ({ defaultValue }) => {
  const [orgInfo] = useOrgInfo();

  assertExists(orgInfo);

  const modal = useModal();
  const modalProps = antdModalV5(modal);

  const [advanced, toggleAdvanced] = useToggle(false);

  const initialValue = match(defaultValue)
    .with({ conditions: P.array() }, (c) => c)
    .otherwise(() => createInitialValue(orgInfo.rootId));

  const [value, setValue] = useImmerState<IDataSourceSetting>(initialValue);

  const setType = useMemoizedFn((type: IDataSourceSetting['type']) => {
    setValue((draft) => {
      draft.type = type;
      if (type === 'dataSource') {
        draft.thmId = undefined;
        draft.conditions = draft.conditions.filter(
          (item) => item.key !== 'ancestor',
        );
      } else if (type === 'temp') {
        draft.conditions.unshift({
          key: 'ancestor',
          op: 'intersect',
          input: [orgInfo.rootId],
        });
      }
    });
  });

  const setCondition = useMemoizedFn(
    (conditions: IConditionSetting['conditions']) => {
      setValue((draft) => {
        draft.conditions = conditions;
      });
    },
  );

  const setMatching = useMemoizedFn((v: IMatchingSetting) => {
    setValue((draft) => {
      draft.matchings = v.matchings;
    });
  });

  const orgId = useCurrentOrgId();
  const nodeTreeData = useNodeTreeData({ orgId });

  const onOk = useMemoizedFn(() => {
    modal.resolve(value);
    modalProps.onOk();
  });

  const conditionTemplateId = useMemo(() => {
    return value.conditions.find((item) => item.key === 'templateId')?.input[0];
  }, [value.conditions]);

  const widgetInstanceList = useGlobalConditionFilterListByTargetId(
    modal.visible ? defaultValue?.id : undefined,
  );

  return (
    <Modal
      title={
        <span className="text-[#242D3F] font-bold">
          {(defaultValue ? '编辑' : '添加') + '组件数据源'}
        </span>
      }
      {...modalProps}
      width={640}
      bodyStyle={{ padding: '0px 24px' }}
      onOk={onOk}
    >
      <div className="flex flex-col gap-3 pt-4 pb-6">
        {!!widgetInstanceList.length && (
          <Alert
            message="当前组件数据已经与筛选组件关联"
            type="info"
            showIcon
          />
        )}
        <div className="flex justify-between items-center">
          <div>
            <Radio.Group
              className={cn(advanced || 'hidden')}
              options={radioOptions}
              value={value?.type}
              onChange={(e) => setType(e.target.value)}
            />
          </div>
          <Button onClick={toggleAdvanced} type="link" className="pr-0">
            高级
            <RegularIcon
              type="iconzhankai2"
              className={cn(advanced ? 'rotate-180' : '')}
            />
          </Button>
        </div>
        <div className="rounded-[8px] bg-[#FAFAFA] py-4 px-3 flex flex-col gap-2">
          <ConditionFilter
            key={value?.parentType}
            getTreeData={() => nodeTreeData}
            displayParentId={value.type === 'temp'}
            displayTemplate
            defaultConditions={
              value?.conditions || createInitialValue(orgInfo.rootId).conditions
            }
            onConditionsChange={setCondition}
            templateUsage="fullAccess"
            addButtonProps={addButtonProps}
            editable
          />
          <MatchingSettingCore
            value={value}
            onChange={setMatching}
            style={{ background: 'none', padding: 0 }}
          />
        </div>
        {value.type === 'dataSource' && advanced && (
          <div className="px-3 py-4 rounded-[8px] bg-[#FAFAFA] flex gap-2 items-center">
            <Input value="表头映射" disabled className="w-[80px]" />
            <TableHeaderMapSelect
              className="flex-1"
              allowClear
              value={value.thmId}
              templateId={conditionTemplateId}
              onChange={(thmId) => {
                setValue((draft) => {
                  draft.thmId = thmId;
                });
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const DataSourceSettingNiceModalId = '$$DataSourceSettingNiceModal$$';

export const _DataSourceSettingNiceModal = create(DataSourceSettingModal);

export const DataSourceSettingNiceModal = () => {
  return <_DataSourceSettingNiceModal id={DataSourceSettingNiceModalId} />;
};

export const getDataSourceSettingValue = (defaultValue?: IDataSourceSetting) =>
  show(DataSourceSettingNiceModalId, {
    defaultValue,
  }) as Promise<IDataSourceSetting>;
