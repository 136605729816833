import { ClearOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import type { CurrentUser, ViewList } from '@linkpi/core';
import { assertExists, toRecord } from '@linkpi/utils';
import { useControllableValue, useToggle } from 'ahooks';
import { Row, Space } from 'antd';
import cls from 'classnames';
import { isEmpty, keys, without } from 'ramda';
import type { ForwardRefRenderFunction } from 'react';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { match } from 'ts-pattern';

import { PiButton } from '@/components';
import type {
  ConditionFilterButtonProp,
  ConditionFormRef,
  ViewConditionFormPropsType,
} from '@/components/ConditionFilter';
import ConditionFilter, {
  toConditionInternalV2Data,
  toConditionNormalV2Data,
} from '@/components/ConditionFilter';
import { getConditionCondifType } from '@/components/ConditionFilter/ConditionItem';
import { getPropTypeOptions } from '@/components/ConditionFilter/ConditionItem/ConditionOperator';
import { useConditionOptions } from '@/components/ConditionFilter/hook/useConditionOptions';
import { useNodeTreeData, useOrgInfo, useOrgTempMap } from '@/hook';

import styles from './InlineConditionFilter.less';

const genConditionV2Item = ({
  key,
  curTemplate,
  tempMap,
  filterConditionConfigMap,
}: {
  filterConditionConfigMap: unknown;
  key: string;
  curTemplate: CurrentUser.TemplateInfo;
  tempMap: Record<string, CurrentUser.TemplateInfo>;
}) => {
  const { conditionConfigType, multiple, cascadeConfig } =
    getConditionCondifType(filterConditionConfigMap, key, curTemplate, tempMap);
  const opOption = getPropTypeOptions(
    conditionConfigType,
    multiple,
    // TODO: condition v2 @yuan7 @1-week
    // entry === 'view',
    true,
    cascadeConfig,
  );

  // 默认op
  const changed: ViewList.ViewconditionV2Item = {
    key,
    op: key === 'templateId' ? 'intersect' : opOption[0].value,
    input: [],
  };

  if (key.startsWith('prop_')) {
    const [mark, propIndex] = key.split('prop_');
    changed.index = Number(propIndex);
    changed.extends = {
      type: conditionConfigType,
    };
  }

  if (key.startsWith('statusProp_')) {
    const [mark, propIndex] = key.split('statusProp_');
    changed.index = Number(propIndex);
    changed.extends = {
      type: conditionConfigType,
    };
  }

  if (key === 'status') {
    changed.op = 'beIncluded';
  }

  if (key === 'templateId' && curTemplate) {
    changed.input = [curTemplate.template_id];
  }

  return toConditionNormalV2Data([changed])[0];
};

type InlineConditionFilterProp = {
  allowKeys: ViewList.ViewconditionV2Item['key'][];
  defaultValue?: ViewList.ViewconditionV2;
  value?: ViewList.ViewconditionV2;
  onChange?: ConditionFilterButtonProp['onChange'];
  editable: ConditionFilterButtonProp['editable'];
  template?: CurrentUser.TemplateInfo;
  tools?: React.ReactNode;
} & Pick<
  ViewConditionFormPropsType,
  | 'experimental_disableHistoryStatus'
  | 'experimental_disableOpList'
  | 'nodeSelectExtraCurrent'
>;

export type InlineConditionFilterRef = {
  /**
   *
   */
  setValue: (value: ViewList.ViewconditionV2) => void;
};

const _InlineConditionFilter: ForwardRefRenderFunction<
  InlineConditionFilterRef,
  InlineConditionFilterProp
> = (
  {
    template,
    allowKeys,
    editable,
    onChange: _onChange,
    experimental_disableOpList,
    tools,
    ...restProps
  },
  ref,
) => {
  /**
   * 用于标记是否需要触发外部 onChange
   */
  const slientRef = useRef(false);
  const mockOnChange = useCallback(
    (...args: Parameters<ConditionFilterButtonProp['onChange']>) => {
      if (slientRef.current) return;

      _onChange?.(...args);
    },
    [_onChange],
  );

  const [value, onChange]: [
    ViewList.ViewconditionV2,
    ConditionFilterButtonProp['onChange'],
  ] = useControllableValue({ onChange: mockOnChange, ...restProps });

  useImperativeHandle(ref, () => ({
    setValue(_value: ViewList.ViewconditionV2) {
      slientRef.current = true;
      onChange(_value, {} as any);
      slientRef.current = false;
    },
  }));

  const tempMap = useOrgTempMap();
  const [orgInfo] = useOrgInfo();

  const nodeTreeData = useNodeTreeData({
    enableRoot: true,
  });

  const { filterConditionConfigMapV2 } = useConditionOptions({
    curTemplate: template,
    tempMap,
  });

  const { mockCondition, hiddenKeys } = useMemo(() => {
    const conditionMap = toRecord<ViewList.ViewconditionV2Item>((c) => {
      const internalData = toConditionInternalV2Data([c])[0];
      return {
        [internalData.key]: c,
      };
    })(value || []);
    const result = Object.values(conditionMap);
    const _hiddenKeys = without(allowKeys, keys(conditionMap));

    let hasTemplate = !!conditionMap.templateId;

    allowKeys?.forEach((key) => {
      if (key === 'templateId') {
        hasTemplate = true;
      }

      if (conditionMap[key]) {
        return;
      }

      if (!template) return;

      result.push(
        genConditionV2Item({
          key,
          filterConditionConfigMap: filterConditionConfigMapV2,
          tempMap,
          curTemplate: template,
        }),
      );
    });

    if (!hasTemplate && template) {
      result.push(
        genConditionV2Item({
          key: 'templateId',
          filterConditionConfigMap: filterConditionConfigMapV2,
          tempMap,
          curTemplate: template,
        }),
      );
    }

    return { mockCondition: result, hiddenKeys: _hiddenKeys };
  }, [allowKeys, filterConditionConfigMapV2, tempMap, template, value]);

  const displayTemplate = useMemo(
    () => allowKeys?.includes('templateId'),
    [allowKeys],
  );

  const conditionFilterRef = useRef<ConditionFormRef>(null);

  const [open, { toggle }] = useToggle();

  assertExists(orgInfo);

  if (isEmpty(allowKeys)) return null;

  return (
    <div className={styles.wrapper}>
      <ConditionFilter
        experimental_disableOpList={experimental_disableOpList}
        experimental_hiddenKeys={hiddenKeys}
        ref={conditionFilterRef}
        conditions={mockCondition}
        displayTemplate={displayTemplate}
        // 不许变更主题类型
        disableTemplate
        onChange={(v) => onChange(v.conditionsV2, v)}
        onChangeMode="immediate"
        displayAddBtn={false}
        hideDelete
        className={cls(styles.form, open || styles.collapse)}
        editable
        getTreeData={() => nodeTreeData}
        maxTagCount="responsive"
      />
      <Row justify="space-between" style={{ marginTop: 8 }}>
        <PiButton
          type="text"
          icon={<ClearOutlined />}
          onClick={conditionFilterRef.current?.clearCondition}
        >
          清空筛选条件
        </PiButton>
        <Space>
          {tools}
          {match(open)
            .when(
              () => allowKeys.length <= 9,
              () => null,
            )
            .with(true, () => (
              <PiButton type="text" onClick={toggle}>
                <Space>
                  收起
                  <UpOutlined />
                </Space>
              </PiButton>
            ))
            .with(false, () => (
              <PiButton type="text" onClick={toggle}>
                <Space>
                  展开
                  <DownOutlined />
                </Space>
              </PiButton>
            ))
            .exhaustive()}
        </Space>
      </Row>
    </div>
  );
};

export const InlineConditionFilter = forwardRef(_InlineConditionFilter);
