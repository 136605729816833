import { assertExists } from '@linkpi/utils';
import { Radio } from 'antd';

import { useActiveWidgetInstance, useEditor } from '../hooks';

export const NodeOpenTypeSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance();
  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig;

  const types = [
    { label: '弹窗打开', value: 'modal' },
    { label: '内容页打开', value: 'content' },
    { label: '新开页面', value: 'newWindow' },
  ];

  let { nodeOpenType } = widgetInstance.config as any;
  nodeOpenType = nodeOpenType || 'modal';

  const changeNodeOpenType = (v: string) => {
    updateConfig((config: any) => {
      config.nodeOpenType = v;
    });
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <div style={{ color: '#242D3F', fontWeight: 700 }}>主题打开方式</div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {types.map((t) => (
          <div
            style={{
              backgroundColor: '#F8F9FB',
              borderRadius: 8,
              padding: '8px 10px 8px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
              width: 'calc(50% - 7px)',
            }}
            onClick={() => changeNodeOpenType(t.value)}
            key={t.value}
          >
            <div style={{ color: '#242D3F' }}>{t.label}</div>
            <Radio checked={nodeOpenType === t.value} />
          </div>
        ))}
      </div>
    </div>
  );
};
