import type { SysBtnAndTitleAndStatus } from '@linkpi/core';
import { Form, Radio } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import nodeHeader from '../../assets/nodeHeader.png';
import { WidgetIcon } from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type INodeHeadConfig = Pick<SysBtnAndTitleAndStatus, 'textAlign'>;

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={nodeHeader} style={{ display: 'block', height: 140 }} />
    </div>
  );
};

const options = [
  { label: '左对齐', value: 'left' },
  { label: '居中对齐', value: 'center' },
];

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance<INodeHeadConfig>()!;

  const setNameVisible = (r: string) => {
    editor.store.updateWidgetInstanceConfig(widgetIntance.id, {
      ...widgetIntance?.config,
      textAlign: r,
    });
  };

  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <Form.Item label="标题文字对齐方式">
        <Radio.Group
          options={options}
          value={widgetIntance?.config.textAlign}
          onChange={(e) => setNameVisible(e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};

export const NodeHeaderWidget = defineSystemWidget<INodeHeadConfig>()({
  id: 'NodeHeader',
  title: '标题/状态',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#FF8D76"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zhuangtaibiaoti"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 5,
    defaultWidth: 12,
    minHeight: 5,
    minWidth: 6,
    maxCount: 1,
    maxHeight: 5,
    dynamicHeight: true,
  },
  metadata: {
    textAlign: 'left',
  },
  preview: Preview,
  setting: Setting,
});
