import { RegularIcon } from '@/components/IconFont';
import { CustomUpload } from '@/components/PublishTemplateModal/config';
import { cn } from '@/utils/utils';

import styles from './IndexIconForm.less';

export const IndexIconForm = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const { value, onChange } = props;

  return (
    <div className={styles.wrapper}>
      <CustomUpload
        showUploadList={false}
        className={cn('w-full h-full')}
        onSuccess={(url) => {
          onChange(url);
        }}
      >
        <div className={styles.inner}>
          <img className=" w-full h-full" src={value} />
          <div className={styles.mask}>
            <RegularIcon
              size={16}
              type="iconchangyong-tianjia"
              color="#fff"
              className=" align-middle"
            />
          </div>
        </div>
      </CustomUpload>
    </div>
  );
};
