import type { ViewList } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { atom, useSetAtom } from 'jotai';

import type { IGlobalConditionFilterCondition } from './types';

export const GlobalConditionConfigMapAtom = atom<
  Record<string, IGlobalConditionFilterCondition[]>
>({});

export const useSetGlobalConditionConfig = () => {
  const setGlobalConditionConfigMap = useSetAtom(GlobalConditionConfigMapAtom);

  const setGlobalConditionConfig = useMemoizedFn(
    (id: string, config: IGlobalConditionFilterCondition[]) => {
      setGlobalConditionConfigMap((prev) => ({ ...prev, [id]: config }));
    },
  );

  return setGlobalConditionConfig;
};

/**
 * 追加的条件
 */
export const ExtraGlobalConditionsMapAtom = atom<
  Record<string, Record<string, ViewList.ViewconditionV2>>
>({});
