import type { FC, PropsWithChildren } from 'react';

import { CustomWidgeUtilsProvider } from '@/components/CustomWidgetUtilsProvider';
import { TemplateContext } from '@/hook/useCurrentTemplate';

import { getDvaProvider } from '../../utils';

export const GridComponentProvider: FC<PropsWithChildren<{ currentTemplate: TemplateInfo }>> = ({
  children,
  currentTemplate,
}) => {
  const { Provider: DvaProvider, store } = getDvaProvider();

  return (
    <CustomWidgeUtilsProvider>
      <DvaProvider store={store}>
        <TemplateContext.Provider value={currentTemplate}>{children}</TemplateContext.Provider>
      </DvaProvider>
    </CustomWidgeUtilsProvider>
  );
};
