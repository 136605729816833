import type { ConditionRuleConfigV2, ViewList } from '@linkpi/core';
import { getChildTempAllow } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useMemoizedFn } from 'ahooks';
import { message, Space } from 'antd';
import cls from 'classnames';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import { isEmpty } from 'ramda';
import type { FC } from 'react';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { match } from 'ts-pattern';

import { ConditionFilterButton } from '@/components/ConditionFilter/ConditionFilterButton';
import { conditionV2ConvertCondition } from '@/components/ConditionFilter/utils';
import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import { checkSubTableConditionRule } from '@/components/PageModelEditor/src/components/TableConditionRuleSetting/RuleModal';
import { ENV_CONFIG } from '@/consts';
import {
  useCurrentUser,
  useDisabledEvents,
  useOrgInfo,
  useOrgUserGroup,
  useOrgUserMap,
  usePageThemeMode,
  useSectionInstanceConfig,
} from '@/hook';
import useFilterTemplateList from '@/hook/useFilterTemplateList';
import { useGlobalConditions } from '@/hook/useGlobalConditions';
import { groupingNodes } from '@/pages/home/components/TabContent/utils';
import RowDisplay from '@/pages/home/components/View/ListTable/toolbar/RowDisplay';
import { DividerList } from '@/pages/home/components/View/ViewSettingV2/components';
import { ChildrenConditionFilter } from '@/pages/home/components/View/ViewSettingV2/components/ChildrenConditionFilter';
import { ImportNodesForView } from '@/pages/home/Workbench/NodePageModel/viewsettingComponents/ImportNodesForView';
import { LineHeightForView } from '@/pages/home/Workbench/NodePageModel/viewsettingComponents/LineHeightForView';
import Grid from '@/pages/pi/grid/react';
import request from '@/utils/request';
import {
  defaultTools,
  displayGroupName,
  getGroupName,
  isShowImportMenu,
  loadNodes,
} from '@/utils/utils';

import AddButtonForView from '../../viewsettingComponents/AddButtonForView';
import CopyNodesForView from '../../viewsettingComponents/CopyNodesForView';
import CustomTableOrder from '../../viewsettingComponents/CustomTableOrder';
import DeleteNodes from '../../viewsettingComponents/DeleteNodes';
import EditNodesAclForView from '../../viewsettingComponents/EditNodesAclForView';
import EditPropsForView from '../../viewsettingComponents/EditPropsForView';
import ExportPDFForView from '../../viewsettingComponents/ExportPDFForView';
import ExportView from '../../viewsettingComponents/ExportView';
import GraphicCodesForView from '../../viewsettingComponents/GraphicCodesForView';
import GroupForView from '../../viewsettingComponents/GroupForView';
import SearchNodesForView from '../../viewsettingComponents/SearchNodesForView';
import ShowLevelInFirstCol from '../../viewsettingComponents/ShowLevelInFirstCol';
import { ToggleCollapse } from '../../viewsettingComponents/ToggleCollapse';
import TransformStatusForView from '../../viewsettingComponents/TransformStatusForView';
import { ViewerButton } from '../ComponentViewer';
import { sharedStateNodeConnect } from '../SharedStateNodeConnect/Connect';

import universalStyles from '../styles.less';
import styles from './styles.less';

const sysProps = {
  title: '标题',
  sysTag: '系统标签',
  mainbody: '正文',
  createTime: '创建时间',
  treeLevelId: '树层ID',
  repeatTask: '重复',
  status: '状态',
  order: '序号',
  index: '编号',
  statusProp_common_0: '负责人',
  statusProp_common_1: '参与者',
  statusProp_common_2: '开始时间',
  statusProp_common_3: '结束时间',
  statusProp_common_4: '备注',
} as const;

const InnerModelTable: FC<{
  data: IWidgetInstanceData & {
    widgetId: 'Table';
  };
  getterPiNode: GetterPiNode;
  isDrafts: boolean;
  inViewer: boolean;
  type?: string;
  readOnly?: boolean;
  containerId: string;
  linkRef: any;
}> = (props) => {
  const {
    data,
    getterPiNode,
    isDrafts,
    type,
    inViewer,
    readOnly,
    containerId,
    linkRef,
  } = props;
  const sectionWidgetInstanceData = useSectionInstanceConfig();

  const currentUser = useCurrentUser();
  const orgInfo = useOrgInfo()[0]!;
  const userMap = useOrgUserMap();
  const groups = useOrgUserGroup();
  const spaceUsers = useMemo(() => Object.values(userMap), [userMap]);
  const { isDark } = usePageThemeMode();
  const filterTempList = useFilterTemplateList(orgInfo);

  const childThemeRequired = data.config.childThemeRequired && isDrafts;
  const toolsConfig = data.config.toolsConfig || defaultTools;

  const tableRef = createRef<any>();

  const [tableCollapse, setTableCollapse] = useState(true);

  const [checkedNodesDetail, setCheckedNodesDetail] = useState(true);
  const [searchTitle, setSearchTitle] = useState({});
  const [checkId, setCheckId] = useState<any[]>([]);
  const [groupData, setGroupData] = useState<any>({});
  const [nodeList, setNodeList] = useState<any>([]);
  const [curViewData, setCurViewData] = useState<any>({});
  const { config, id } = data;

  const getInitOrderBy = () => {
    // customTable
    if (type === 'custom') return data.config.orderBy || 'treeLevel';

    // table
    return data.config.addType === 'inline' ? '_sys_createTime' : 'treeLevel';
  };

  const getInitOrderDesc = () => {
    if (type === 'custom') return true;

    return data.config.addType !== 'inline';
  };

  const searchDataRef = useRef<any>(undefined);
  const tLineheightRef = useRef(52);
  const childOrderByRef = useRef(data.config.childOrderBy || '');
  const orderByRef = useRef(getInitOrderBy());
  const orderDescRef = useRef(getInitOrderDesc());
  const showLevelInFirstColRef = useRef(false);
  const nowGroupRef = useRef<any>(config.group);
  const nowConditionV2Ref = useRef<any>(config.conditions);
  const childConditionRef = useRef<any>({});
  const getterPiNodeRef = useRef<any>(getterPiNode);
  getterPiNodeRef.current = getterPiNode;
  let targetTheme = nowConditionV2Ref.current.find(
    (i: any) => i.key === 'templateId',
  )?.input[0];

  const getAllowTempIds = () => {
    if (!orgInfo || !getterPiNode) return [];
    const templateList = orgInfo.templateList;
    const nodeTemplateId = getterPiNode.value.tempInfo.id;
    const allows = getChildTempAllow({
      tempId: nodeTemplateId,
      tempList: templateList,
    });

    if (getterPiNode.value.id === orgInfo.rootId) {
      return templateList.map((t: any) => t.template_id);
    }
    return allows;
  };

  const exportView = async (exportType: string) => {
    const g: string[] = [];
    const nodes: string[][] = [];

    Object.keys(groupData).map((groupKey: string) => {
      g.push(displayGroupName(groupData[groupKey], userMap, groups));
      nodes.push(groupData[groupKey].list.map((node: any) => node.id) || []);
    });

    const ad = window.localStorage.getItem('ad');
    const token = window.localStorage.getItem('token');

    const payload: any = {
      org_id: orgInfo.orgId,
      template_id: targetTheme,
      nodes,
      groups: g,
      group_name: getGroupName(curViewData.view_info.group, orgInfo),
      view_name: '--',
      file_type: exportType,
      ad: ad,
      token: token,
    };

    if (type !== 'custom')
      payload.tShow = curViewData.view_info.tShowFieldsIndex
        .filter((i: any) => !i.disable)
        .map((i: any) => i.key);

    const res = await request<string>(ENV_CONFIG.EXPORT_VIEW_NODES_FOR_MP, {
      method: 'POST',
      data: payload,
    });

    if (res.status === 'ok') window.open(res.data);
    else message.error(res.message || '导出失败');
  };

  const conditionRuleResult = useMemo(() => {
    let enableAddNode = true;
    const subTabPropsVisibleItems: ConditionRuleConfigV2.TableConditionResults[number][] =
      [];
    data.config.conditionRules?.forEach((c) => {
      if (!c.enable) return;

      if (
        checkSubTableConditionRule({
          ruleConfig: c,
          nodeMetaData: getterPiNode.value.metadata,
        })
      ) {
        c.results.forEach((r) => {
          match(r)
            .with({ type: 'subTabCreationVisible' }, () => {
              enableAddNode = false;
            })
            .with({ type: 'subTabPropsVisible' }, (i) => {
              subTabPropsVisibleItems.push(i);
            })
            .exhaustive();
        });
      }
    });
    return { enableAddNode, subTabPropsVisibleItems };
  }, [data.config.conditionRules, getterPiNode.value.metadata]);

  const getCurViewData = useMemoizedFn(() => {
    if (!orgInfo) return {};
    const displayAttrs = config.displayAttrs || [];
    const tShowFieldsIndex = [...displayAttrs];
    const template = orgInfo.templateList.find(
      (t) => t.template_id === targetTheme,
    );
    const templateProp = (template || {}).prop || [];
    templateProp.forEach((p, i) => {
      if (!tShowFieldsIndex.find((j) => j.key === `prop_${i}`))
        tShowFieldsIndex.push({
          key: `prop_${i}`,
          name: p.name,
          disable: true,
        });
    });

    Object.keys(sysProps).forEach((k) => {
      if (!tShowFieldsIndex.find((j) => j.key === k))
        tShowFieldsIndex.push({ key: k, name: sysProps[k], disable: true });
    });
    const res: ViewList.ViewListItem<2> = cloneDeep({
      view_info: {
        nodeOpenType: config.nodeOpenType,
        childOrderBy: childOrderByRef.current,
        childCondition: childConditionRef.current,
        showLevelInFirstCol: showLevelInFirstColRef.current,
        condition: conditionV2ConvertCondition(
          nowConditionV2Ref.current.find((c: any) => c.key === 'ancestor') ===
            undefined
            ? nowConditionV2Ref.current.concat({
                key: 'ancestor',
                op: 'intersect',
                input: [getterPiNode.value.id],
              })
            : nowConditionV2Ref.current,
        ).conditions,
        fixedAttrPool: tShowFieldsIndex
          .filter((p) => p.fixed)
          .map((p) => p.key),
        orderBy: orderByRef.current,
        tLineheight: tLineheightRef.current,
        stat: {},
        tShowFieldsIndex,
        group: nowGroupRef.current,
        orderDesc: orderDescRef.current,
        hideAdd: config.hideAdd,
        hideTableCheckbox: config.hideTableCheckbox,
        attrsWidth:
          orgInfo.templateList.find((t) => t.template_id === targetTheme)
            ?.temp_option?.attrsWidth || {},
        showActions: config.showActions,
        actionButtons: config.actionButtons,
        tableDisplayConfig: config.tableDisplayConfig,
      },
      view_type: 2, // table
    });

    if (type === 'custom') {
      res.view_info.tableStyle = 'custom';
      res.view_info.customTableId = config.customTableId;
      res.view_info.orderBy = orderByRef.current;
      res.view_info.orderDesc = orderDescRef.current;
      res.view_info.group = nowGroupRef.current;
    }

    if (
      type !== 'custom' &&
      conditionRuleResult.subTabPropsVisibleItems.length
    ) {
      const _curViewData = res;
      conditionRuleResult.subTabPropsVisibleItems.forEach((item) => {
        const disable = item.visible === 'hidden';
        const ary = item.props;
        _curViewData.view_info.tShowFieldsIndex.forEach((p) => {
          if (ary.includes(p.key)) p.disable = disable;
        });
      });
    }

    return res;
  });

  const getNodeList = (nodes: any[]) => {
    if (searchDataRef.current === undefined) return nodes;
    if (searchDataRef.current.length === 0) return [];

    return nodes.filter((n: any) => searchDataRef.current.includes(n.id));
  };

  const sortNodes = (nodes: any[]) => {
    const _nodeList = [...getNodeList(nodes)];

    if (orderByRef.current === 'treeLevel' && _nodeList.length) {
      const node = _nodeList[0];
      const treeLevelMap = node.nodeManager.getNodeTreeSerialNumber(_nodeList);
      _nodeList.forEach((n) => {
        n.treeLevelId = treeLevelMap[n.id];
      });
    }
    // 更新时间排序
    if (orderByRef.current === '_sys_updateTime') {
      _nodeList.sort((nodeA, nodeB) => nodeB.metadata.m - nodeA.metadata.m);
    }
    // 系统属性
    if (orderByRef.current === '_sys_createTime') {
      _nodeList.sort((nodeA, nodeB) => {
        return orderDescRef.current
          ? nodeB.createTime - nodeA.createTime
          : nodeA.createTime - nodeB.createTime;
      });
    }

    setGroupData(
      groupingNodes(
        _nodeList,
        nowGroupRef.current,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
  };

  const globalConditions = useGlobalConditions(data.id);

  const conditions = useMemo(() => {
    if (!globalConditions) return nowConditionV2Ref.current;
    if (!globalConditions?.length) return nowConditionV2Ref.current;
    return [...nowConditionV2Ref.current, ...globalConditions];
  }, [globalConditions]);

  const reInit = useMemoizedFn(async () => {
    const payload = {
      ...config,
      conditions,
    };
    const ancestor = (payload.conditions || []).find(
      (i: any) => i.key === 'ancestor',
    );
    if (ancestor && ancestor.input[0] === orgInfo.rootId) {
      payload.conditions = payload.conditions.filter(
        (i: any) => i.key !== 'ancestor',
      );
    }
    const nodes = await loadNodes(getterPiNodeRef.current.value, {
      ...payload,
      currentUserId: currentUser.userid,
    });

    if (linkRef && linkRef.current.nodesMap)
      linkRef.current.nodesMap[data.id] = nodes.length;

    setNodeList(nodes);
    sortNodes(nodes);
  });

  useEffect(() => {
    reInit();
  }, [globalConditions, reInit]);

  const init = async () => {
    const payload = {
      ...config,
      conditions,
    };

    const ancestor = (payload.conditions || []).find(
      (i: any) => i.key === 'ancestor',
    );
    if (ancestor && ancestor.input[0] === orgInfo.rootId) {
      payload.conditions = payload.conditions.filter(
        (i: any) => i.key !== 'ancestor',
      );
    }
    const nodes = await loadNodes(getterPiNode.value, {
      ...payload,
      currentUserId: currentUser.userid,
    });

    if (linkRef) {
      if (linkRef.current.nodesMap)
        linkRef.current.nodesMap[data.id] = nodes.length;
      else {
        linkRef.current.nodesMap = {};
        linkRef.current.nodesMap[data.id] = nodes.length;
      }
    }

    const _nodeList = [...getNodeList(nodes)];
    // treeId
    if (orderByRef.current === 'treeLevel' && _nodeList.length) {
      const node = _nodeList[0];
      const treeLevelMap = node.nodeManager.getNodeTreeSerialNumber(_nodeList);
      _nodeList.forEach((n) => {
        n.treeLevelId = treeLevelMap[n.id];
      });
    }

    // 更新时间排序
    if (orderByRef.current === '_sys_updateTime') {
      _nodeList.sort((nodeA, nodeB) => nodeB.metadata.m - nodeA.metadata.m);
    }
    // 系统属性
    if (orderByRef.current === '_sys_createTime') {
      _nodeList.sort((nodeA, nodeB) => {
        return orderDescRef.current
          ? nodeB.createTime - nodeA.createTime
          : nodeA.createTime - nodeB.createTime;
      });
    }

    setNodeList(_nodeList);
    setGroupData(
      groupingNodes(
        _nodeList,
        nowGroupRef.current,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
    setCurViewData(getCurViewData());
  };

  useEffect(() => {
    showLevelInFirstColRef.current = false;
    nowGroupRef.current = config.group;
    nowConditionV2Ref.current = config.conditions;
    targetTheme = nowConditionV2Ref.current.find(
      (i: any) => i.key === 'templateId',
    )?.input[0];
    init();
  }, [getterPiNode.value.id]);

  const changeLineHeight = (v: any) => {
    tLineheightRef.current = v;
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info = {
      ...newCurViewData.view_info,
      tLineheight: v,
    };

    tableRef.current.onNodeHeightChange(v);
    setCurViewData(newCurViewData);
  };

  const changeCondition = (v: any) => {
    nowConditionV2Ref.current = v;
    init();
  };

  const changeOrderDesc = (v: any) => {
    orderDescRef.current = v;
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info = {
      ...newCurViewData.view_info,
      orderDesc: v,
    };
    sortNodes(nodeList);
    setCurViewData(newCurViewData);
  };
  const changeChildNodesOrderBy = (v: any) => {
    childOrderByRef.current = v;
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info = {
      ...newCurViewData.view_info,
      childOrderBy: v,
    };
    setCurViewData(newCurViewData);
  };
  const changeOrderBy = (v: any) => {
    orderByRef.current = v;
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info = {
      ...newCurViewData.view_info,
      orderBy: v,
    };

    if (['treeLevel', '_sys_updateTime'].includes(v)) {
      orderDescRef.current = true;
      newCurViewData.view_info.orderDesc = true;
    }
    sortNodes(nodeList);
    setCurViewData(newCurViewData);
  };

  const changeGroup = (g: string) => {
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info.group = g;
    nowGroupRef.current = g;
    setGroupData(
      groupingNodes(
        getNodeList(nodeList),
        g,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
    setCurViewData(newCurViewData);
  };

  const changeRowDisplay = (v: any) => {
    const { tShowFieldsIndex, fixedAttrPool, tableDisplayConfig } = v;
    const newCurViewData = { ...curViewData };

    if (fixedAttrPool) newCurViewData.view_info.fixedAttrPool = fixedAttrPool;

    newCurViewData.view_info = {
      ...newCurViewData.view_info,
      tShowFieldsIndex,
      tableDisplayConfig,
    };
    if ('showActions' in v) {
      newCurViewData.view_info.showActions = v.showActions;
    }

    if ('actionButtons' in v) {
      newCurViewData.view_info.actionButtons = v.actionButtons;
    }
    setCurViewData(newCurViewData);
  };

  const changeShowLevelInFirstCol = (v: boolean) => {
    showLevelInFirstColRef.current = v;
    const newCurViewData = { ...curViewData };
    newCurViewData.view_info = {
      ...newCurViewData.view_info,
      showLevelInFirstCol: v,
    };
    setCurViewData(newCurViewData);
  };

  const changeSearchData = (v: any) => {
    setSearchTitle({});
    searchDataRef.current = v;
    setGroupData(
      groupingNodes(
        getNodeList(nodeList),
        nowGroupRef.current,
        spaceUsers,
        groups,
        targetTheme,
        orgInfo.templateList,
      ),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let uniqueCanvasId = useMemo(() => 'talbe_' + id + nanoid(), []);

  if (isDrafts) uniqueCanvasId = 'drafts_' + uniqueCanvasId;
  if (inViewer) uniqueCanvasId = 'viewer_' + uniqueCanvasId;

  const [pointerEvents, onMouseDown] = useDisabledEvents(readOnly);

  if (isEmpty(curViewData) || !orgInfo || !getterPiNode) return null;

  // 如果工具栏所有的action都关闭的话，header应该去掉
  const ifHideHeader = () => {
    if (toolsConfig.length === 1 && toolsConfig[0] === 'sort') return true;
    return toolsConfig.length === 0;
  };

  return (
    <div
      className={styles.container}
      onMouseDown={onMouseDown}
      style={{ pointerEvents }}
    >
      <div
        style={{ display: ifHideHeader() ? 'none' : 'flex' }}
        className={styles.header}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {childThemeRequired && (
            <div className={styles.childThemeRequired}>必填*</div>
          )}
          <div className={styles.componentName}>{config.name}</div>
          {!isDrafts && toolsConfig.includes('search') && (
            <SearchNodesForView
              isDark={isDark}
              orgInfo={orgInfo}
              changeSearchData={changeSearchData}
            />
          )}
        </div>

        <div
          style={{ display: 'flex', alignItems: 'center', overflow: 'auto' }}
          className="dividerListWrapper"
        >
          <DividerList>
            {typeof checkedNodesDetail !== 'object' && (
              <GraphicCodesForView
                orgInfo={orgInfo}
                getterPiNode={getterPiNode}
                checkId={checkId}
                viewName={''}
                template={orgInfo.templateList.find(
                  (t: any) => t.template_id === targetTheme,
                )}
              />
            )}
            <ExportPDFForView
              orgInfo={orgInfo}
              getterPiNode={getterPiNode}
              checkId={checkId}
              setCheckId={setCheckId}
              templateList={orgInfo.templateList}
              userMap={userMap}
            />
            <CopyNodesForView
              orgInfo={orgInfo}
              getterPiNode={getterPiNode}
              checkId={checkId}
              setCheckId={setCheckId}
              checkedNodesDetail={checkedNodesDetail}
            />
            {typeof checkedNodesDetail !== 'object' && (
              <EditPropsForView
                template={orgInfo.templateList.find(
                  (t: any) => t.template_id === targetTheme,
                )}
                checkId={checkId}
                groupData={groupData}
                getterPiNode={getterPiNode}
                orgInfo={orgInfo}
                userMap={userMap}
                setCheckId={setCheckId}
                templateList={orgInfo.templateList}
              />
            )}
            <EditNodesAclForView
              orgInfo={orgInfo}
              getterPiNode={getterPiNode}
              checkId={checkId}
            />
            {typeof checkedNodesDetail !== 'object' && (
              <TransformStatusForView
                currentUser={currentUser}
                template={orgInfo.templateList.find(
                  (t: any) => t.template_id === targetTheme,
                )}
                userMap={userMap}
                setCheckId={setCheckId}
                orgInfo={orgInfo}
                getterPiNode={getterPiNode}
                checkId={checkId}
              />
            )}
            <DeleteNodes
              orgInfo={orgInfo}
              getterPiNode={getterPiNode}
              checkId={checkId}
              setCheckId={setCheckId}
            />
            {!isDrafts && toolsConfig.includes('condition') && (
              <ConditionFilterButton
                className={cls(
                  universalStyles.forModelView,
                  isDark && universalStyles.darkBtn,
                )}
                orgId={orgInfo.orgId}
                value={nowConditionV2Ref.current}
                onChange={changeCondition}
                enableRoot
                allowTempIds={getAllowTempIds()}
                extraPopcontent={
                  <ChildrenConditionFilter
                    template={orgInfo.templateList.find(
                      (t: any) => t.template_id === targetTheme,
                    )}
                    getterPiNode={getterPiNode}
                    editable
                    allowTempIds={getAllowTempIds()}
                    curViewData={curViewData}
                    onChildConditionChange={(tempId, value) => {
                      const newCurViewData = cloneDeep(curViewData);

                      if (!newCurViewData.view_info.childCondition) {
                        newCurViewData.view_info.childCondition = {};
                      }
                      newCurViewData.view_info.childCondition[tempId] = value;

                      childConditionRef.current = cloneDeep(
                        newCurViewData.view_info.childCondition,
                      );
                      setCurViewData(newCurViewData);
                    }}
                    experimental_disableOpList={['empty']}
                  />
                }
              />
            )}
            {!isDrafts && toolsConfig.includes('group') && (
              <GroupForView
                template={orgInfo.templateList.find(
                  (t: any) => t.template_id === targetTheme,
                )}
                curViewData={curViewData}
                orgInfo={orgInfo}
                templateList={orgInfo.templateList}
                onChange={changeGroup}
                isDark={isDark}
              />
            )}
            {type !== 'custom' &&
              !isDrafts &&
              toolsConfig.includes('displayRow') && (
                <RowDisplay
                  tableProps={curViewData.view_info.tShowFieldsIndex.filter(
                    (i: any) => i.name,
                  )}
                  template={orgInfo.templateList.find(
                    (t: any) => t.template_id === targetTheme,
                  )}
                  curViewData={curViewData}
                  getViewList={() => {}}
                  onChange={changeRowDisplay}
                  isDark={isDark}
                />
              )}
            {type !== 'custom' &&
              !isDrafts &&
              toolsConfig.includes('lineHeight') && (
                <LineHeightForView
                  value={curViewData.view_info.tLineheight}
                  onChange={changeLineHeight}
                  isDark={isDark}
                />
              )}
            {type === 'custom' && !isDrafts && (
              <CustomTableOrder
                curViewData={curViewData}
                isDark={isDark}
                changeOrderBy={changeOrderBy}
                changeChildNodesOrderBy={changeChildNodesOrderBy}
                changeOrderDesc={changeOrderDesc}
                orgInfo={orgInfo}
                template={orgInfo.templateList.find(
                  (t: any) => t.template_id === targetTheme,
                )}
              />
            )}
            {type !== 'custom' &&
              !isDrafts &&
              toolsConfig.includes('treeLevel') && (
                <ShowLevelInFirstCol
                  isDark={isDark}
                  onChange={changeShowLevelInFirstCol}
                  curViewData={curViewData}
                />
              )}
            <Space>
              {toolsConfig.includes('collapse') && (
                <ToggleCollapse
                  onToggle={(e) => {
                    tableRef.current?.onCollapse(e);
                    setTableCollapse(e);
                  }}
                  isDark={isDark}
                  tableCollapse={tableCollapse}
                />
              )}
              {isShowImportMenu(
                getterPiNode.value.nodeManager.getRoot(),
                orgInfo,
              ) &&
                toolsConfig.includes('import') && (
                  <ImportNodesForView
                    getterNode={getterPiNode}
                    templateList={filterTempList}
                    orgInfo={orgInfo}
                    curViewData={curViewData as any}
                    isDark={isDark}
                  />
                )}
              {!isDrafts && toolsConfig.includes('export') && (
                <ExportView isDark={isDark} exportView={exportView} />
              )}
              <ViewerButton />
            </Space>
            {conditionRuleResult.enableAddNode &&
              toolsConfig.includes('addBtn') && (
                <AddButtonForView
                  template={orgInfo.templateList.find(
                    (t: any) => t.template_id === targetTheme,
                  )}
                  curViewData={curViewData}
                  getterPiNode={getterPiNode}
                  templateList={orgInfo.templateList}
                  userMap={userMap}
                />
              )}
          </DividerList>
        </div>
      </div>
      <div style={{ flex: 1, height: 0 }}>
        <Grid
          nodeOpenType={data.config.nodeOpenType || 'modal'}
          ref={tableRef}
          pagination={data.config.pagination}
          node={getterPiNode}
          showNodeSaved={() => {}}
          getViewList={() => {}}
          setGridAttrs={() => {}}
          searchTitle={searchTitle}
          leftCollapse={true} // 暂时不变
          leftWidth={1} // 暂时不变
          orgInfo={orgInfo}
          templateList={orgInfo.templateList}
          checkId={checkId}
          setCheckId={setCheckId}
          uniqueCanvasId={uniqueCanvasId}
          userMap={userMap}
          groups={groups}
          curViewData={curViewData}
          groupData={groupData}
          contentPageGeneralTableConfig={{
            isDrafts,
            refresh: reInit,
            tab: { tabConfig: config },
            containerBgColor:
              sectionWidgetInstanceData.config.bgColor || '#fff',
          }}
          forbiddenAddNode={!conditionRuleResult.enableAddNode}
          styleMode={isDark ? 'darkness' : ''}
          setCheckedNodesDetail={setCheckedNodesDetail}
          containerId={containerId}
          afterCollapseGroup={(v) => setTableCollapse(v)}
        />
      </div>
    </div>
  );
};

export const ModelTable = sharedStateNodeConnect(InnerModelTable);
