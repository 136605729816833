import { atom } from 'jotai';
import { container } from 'tsyringe-neo';

import { EditorModel } from '../models';

export const editorAtom = atom(container.resolve(EditorModel));
editorAtom.debugLabel = 'editorAtom';

export const widgetManagerAtom = atom((get) => {
  const editor = get(editorAtom);
  return editor.widgetManager;
});
widgetManagerAtom.debugLabel = 'widgetManagerAtom';

export const storeAtom = atom((get) => {
  const editor = get(editorAtom);
  return editor.store;
});
storeAtom.debugLabel = 'storeAtom';
