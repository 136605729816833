import { generateHtmlAttributes } from '@linkpi/utils';
import { useMountEffect, useUnmountEffect } from '@react-hookz/web';
import cls from 'clsx';
import { GridStack } from 'gridstack';
import { type FC, useEffect, useRef } from 'react';

import { RegularIcon } from '@/components/IconFont';
import SimpleTooltip from '@/components/SimpleTooltip';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

import { GridComponentProvider } from '../../components';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { LayoutPositionType } from '../../models/Store';
import { getRoot, getWidgetElement, limitWarn, setupDragIn } from '../../utils';
import { WidgetComponent } from './WidgetComponent';

import styles from './styles.less';

// 动高板块
export const DynamicHeightSection: FC<{
  gridId: string;
  parentGridStack: GridStack;
  type: LayoutPositionType;
}> = ({ gridId, parentGridStack, type }) => {
  const subGridStackContainerMargin = 8;
  const subGridStackCellHeight = 40;

  const item = useWidgetInstance(gridId);
  const editor = useEditor();
  // 当前激活的类
  const activeWidgetInstance: any = useActiveWidgetInstance() || {};

  const comps = (item?.children || []).map((i) =>
    editor.store.getWidgetInstance(i),
  ); // 基础组件
  const compsRef = useRef<any>([]);
  compsRef.current = comps;
  const compsMap = useRef<any>({}); // 确保stack只add一次
  const subGridStackContainerRef = useRef<HTMLDivElement | null>(null);
  const subGridStackRef = useRef<GridStack | null>(null);
  const selectDynamicHeightSection = () => {
    editor.setActiveWidgetInstance(gridId);
  };

  // 操作
  const copyDynamicHeightSection = () => {
    if (editor.cloneWidgetInstance) editor.cloneWidgetInstance(gridId);
  };

  const delDynamicHeightSection = () => {
    editor.removeWidgetInstance(gridId, { type });
    const parentElement =
      document.getElementById(gridId)?.parentElement?.parentElement;
    if (parentElement) parentGridStack.removeWidget(parentElement, true);
  };

  const renderTools = () => {
    if (activeWidgetInstance.id !== gridId) return null;

    const canClone = editor.checkWidgetClonable(gridId);
    const extraClassName = canClone ? '' : ' ' + styles.notClone;

    return (
      <div className={styles.dynamicHeightSectionToolsContainer}>
        <SimpleTooltip title={limitWarn} showTooltip={!canClone}>
          <div
            onClick={() => {
              if (canClone) copyDynamicHeightSection();
            }}
            className={styles.copy + extraClassName}
          >
            <RegularIcon type="iconfuzhi2" color="#6B7A96" />
            <span className="text-[#6B7A96] pl-1">复制</span>
          </div>
        </SimpleTooltip>
        <div
          onClick={delDynamicHeightSection}
          className={cls(styles.del, 'ml-[6px]')}
        >
          <RegularIcon type="iconshanchu2" color="#6B7A96" />
        </div>
      </div>
    );
  };

  // 板块名称
  const renderName = () => {
    const { config } = item || {};
    const { showName, name } = config || {};
    if (!showName || !name) return null;
    return (
      <div className={styles.title}>
        <div>{name}</div>
      </div>
    );
  };

  const getStyle = () => {
    const style: any = {};
    const { config } = item || {};
    const { showName, name } = config || {};
    if (!showName || !name) style.height = '100%';
    else style.height = 'calc(100% - 40px)'; // 存在title时去掉滚动条

    if (comps.length === 0) {
      style.display = 'flex';
      style.alignItems = 'center';
    }
    return style;
  };

  const addSubEvents = () => {
    const subGridStack = subGridStackRef.current!;

    subGridStack.on('added change', async (event: any, items: any[]) => {
      if (!items) return;

      const item: any = items[0];
      const { x, y, w, h, el } = item;

      // 新增
      if (!('id' in item)) {
        subGridStack.removeWidget(el, true, false);
        try {
          // left
          await editor.addWidgetInstanceAsync(el.dataset.widget, {
            x,
            y,
            parentId: gridId,
          });
        } catch (error) {
          console.log('🏮 取消新建');
        }
        return;
      }

      // 移动
      if (!editor.store.getWidgetInstance(item.id)) {
        // 别的容器过去的comp
        editor.addWidgetInstance(item.widgetId, {
          id: item.id,
          x,
          y,
          w,
          h,
          parentId: gridId,
        });
        subGridStack.removeWidget(el, true, false);
        return;
      }

      // 更新
      items.forEach((i: any) => {
        editor.store.resizeWidgetInstance(i.id, {
          x: i.x,
          y: i.y,
          h: i.h,
          w: i.w,
        });
      });
    });

    // 删除事件
    subGridStack.on('removed', (event: any, items: any[]) => {
      const item = items[0];
      compsMap.current[item.id] = false;
      if (editor.getWidgetInstance(item.id)) {
        editor.removeWidgetInstance(item.id, { type });
      }
      // remove
    });
  };

  useMountEffect(() => {
    subGridStackRef.current = GridStack.addGrid(
      subGridStackContainerRef.current!,
      {
        margin: subGridStackContainerMargin,
        draggable: {
          scroll: false,
          handle: '.grid-stack-item-content',
          appendTo: 'body',
        },
        acceptWidgets: (el) => {
          const widgetElement = getWidgetElement(el);
          return (
            widgetElement?.dataset?.dynamicHeight === 'true' &&
            compsRef.current.length < 1
          );
        },
        cellHeight: subGridStackCellHeight, // 120 - masterMargin * 2 - padding * 2
        disableOneColumnMode: true,
        column: 12,
        sizeToContent: true,
      },
    );

    setupDragIn();
    addSubEvents();
  });

  useUnmountEffect(() => {
    subGridStackRef.current?.offAll();
    subGridStackRef.current?.destroy();
  });

  const currentTemplate = useCurrentTemplate();

  useEffect(() => {
    comps.forEach((i: any) => {
      if (compsMap.current[i.id]) return;

      const widgetConfig = editor.widgetManager.getWidget(i.widgetId);
      // FIXME： widgetConfig 可能存在不存在
      const basic = widgetConfig?.basic || {};

      const htmlAttrs = generateHtmlAttributes({
        'data-nested': false,
        'data-dynamic-height': true,
        class: cls('comp', styles.compContainer),
        id: i.id,
      });

      const content = `<div ${htmlAttrs}></div>`;
      compsMap.current[i.id] = true;

      const payload = { ...i, ...i.rect, content };

      if (basic.maxWidth) payload.maxW = basic.maxWidth;
      if (basic.minWidth) payload.minW = basic.minWidth;

      payload.minH = basic.minWidth || 6;

      subGridStackRef.current!.addWidget(payload);

      setTimeout(() => {
        getRoot(i.id).render(
          <GridComponentProvider currentTemplate={currentTemplate}>
            <WidgetComponent
              parentGridStack={subGridStackRef.current!}
              compId={i.id}
            />
          </GridComponentProvider>,
        );
      }, 120);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comps.length]);

  return (
    <div
      className={cls(
        activeWidgetInstance.id === gridId && styles.activeDynamicHeightSection,
        styles.subGridStack,
      )}
      onClick={selectDynamicHeightSection}
      style={{ backgroundColor: (item?.config.bgColor as string) || 'white' }}
    >
      {renderTools()}
      {renderName()}
      {/* 适当的给padding */}
      <div
        style={getStyle()}
        ref={subGridStackContainerRef}
        className="grid-stack"
      >
        {comps.length < 1 && (
          <div className={styles.tip}>
            请从左侧选择标题/状态、自定义属性、正文组件拖入
          </div>
        )}
      </div>
    </div>
  );
};
