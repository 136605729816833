import type {
  ChildTheme,
  ConditionRuleConfig,
  CurrentUser,
  PageModelConfig,
  ViewList,
  WidgetInstanceData,
} from '@linkpi/core';
import { generateAddOpId } from '@linkpi/core';
import { getDvaApp, Provider } from '@umijs/max';
import { diff, same } from 'fast-array-diff';
import { produce } from 'immer';
import { random } from 'lodash';
import { nanoid } from 'nanoid';
import { groupBy, includes, omit, pick, toPairs } from 'ramda';
import { match, P } from 'ts-pattern';

import { getBuildNumber } from '@/components/VersionInfo/utils';
import type { InitContentPageConfig } from '@/pages/space/components/PageFix/contentPage';
import { lagecyRuleToRule } from '@/pages/space/propConditionRule/components/utils';
import { createView, deleteView, updateViewConfig } from '@/services/view';
import { traverseTreeCreator } from '@/utils/tree';
import { dashboardCardContentStatNumColors, takeOne } from '@/utils/utils';

import type { ITableConditionRuleSetting } from '../components';
import { initalPageConfig } from '../constants';
import type { EditorModel } from '../models';
import type {
  IWidgetIdType,
  IWidgetInstance,
  IWidgetInstanceData,
} from '../types';
import type { ITabSectionConditionRuleSetting } from '../widgets/TabSectionWidget/TabSectionConditionRuleSetting';
import { showDiff } from './debugTools';

const getColor = () => {
  const index = random(dashboardCardContentStatNumColors.length - 1, false);
  return dashboardCardContentStatNumColors[index];
};

export const getDvaProvider = () => {
  const app = getDvaApp();
  return {
    Provider,
    store: app._store,
  };
};

const StatisticWidgetIds = [
  'PieChart',
  'LineChart',
  'BarChart',
  'HorizontalBarChart',
  'Indicator',
  'MultidimensionalStatistic',
] as const;
type StatisticWidgetIdType = (typeof StatisticWidgetIds)[number];
export type StatisticWidgetType = IWidgetInstance & {
  widgetId: StatisticWidgetIdType;
};

const widgetInstanceEq = (
  older: StatisticWidgetType,
  newer: StatisticWidgetType,
) => {
  return older.id === newer.id;
};

/**
 * 用作 diff 时，a 是旧数据，b 是新数据,
 * 用作 same 时，b 是旧数据，a 是新数据。
 * 因为 same 返回的是就旧数据引用
 */
const editedWidgetInstanceEq = (
  a: StatisticWidgetType,
  b: StatisticWidgetType,
) => {
  return (
    a.id === b.id &&
    a.config.statistic.filterType === b.config.statistic.filterType &&
    b.touched !== a.touched
  );
};

const groupByFn = (a: StatisticWidgetType) => {
  return a.config.statistic.filterType;
};
/**
 *
 * @param editor
 */
export const diffStatisticWidget = (editor: EditorModel) => {
  return StatisticWidgetIds.map((widgetId) => {
    const widgets = editor.store.getWidgetInstancesByWidgetId(
      widgetId,
    ) as unknown as StatisticWidgetType[];
    const groupedWidgets = groupBy(groupByFn)(widgets);

    const oldWidgets = editor.backupStore.getWidgetInstancesByWidgetId(
      widgetId,
    ) as unknown as StatisticWidgetType[];
    const groupedOldWidgets = groupBy(groupByFn)(oldWidgets);

    const relationList = diff(
      groupedOldWidgets.relation || [],
      groupedWidgets.relation || [],
      widgetInstanceEq,
    );
    const customList = diff(
      groupedOldWidgets.custom || [],
      groupedWidgets.custom || [],
      widgetInstanceEq,
    );
    /**
     * NOTE
     * same 方法会返回首个参数的引用
     */
    const updatedRelationList = same(
      groupedWidgets.relation || [],
      groupedOldWidgets.relation || [],
      editedWidgetInstanceEq,
    );
    const updatedCustomList = same(
      groupedWidgets.custom || [],
      groupedOldWidgets.custom || [],
      editedWidgetInstanceEq,
    );

    return {
      widgetId,
      custom: {
        ...customList,
        updated: updatedCustomList,
      },
      relation: {
        ...relationList,
        updated: updatedRelationList,
      },
    };
  });
};

const MOCK_DASHVIEW_ID = '$$NodePageModal$$';
export const StatisticWidgetPatcher = async (
  editor: EditorModel,
  patchList: ReturnType<typeof diffStatisticWidget>,
  orgInfo: CurrentUser.OrgInfo,
  skipConfirm = false,
) => {
  const [, visible] = getBuildNumber();
  if (visible && !skipConfirm) {
    try {
      await showDiff(patchList);
    } catch (error) {
      throw Error('取消保存');
    }
  }
  for (let patchIndex = 0; patchIndex < patchList.length; patchIndex++) {
    const patch = patchList[patchIndex];

    // 新建
    for (let index = 0; index < patch.custom.added.length; index++) {
      const {
        id,
        config: { statistic: componentForm },
      } = patch.custom.added[index];
      const createViewReq = match(componentForm)
        .with({ type: 'more' }, () => ({
          node_id: orgInfo.rootId,
          org_id: orgInfo.orgId,
          view_name: componentForm.name,
          view_type: componentForm.viewType || (1 as const),
          public: false,
          public_groups: ['-1'],
          modify_permission: 2,
          view_info: {
            condition: componentForm.conditions,
            group: componentForm.group || undefined,
            parentId: componentForm.parentId,
            numColor: getColor(),
            matchings: componentForm.matchings,
          },
          template_id: componentForm.conditions.find(
            (i) => i.key === 'templateId',
          )?.value,
          dash_view_id: MOCK_DASHVIEW_ID,
        }))
        .with({ type: 'chart' }, (i) => {
          const values = i.chartInfo;
          const charts_info = {
            ...values,
            verticalTerms: values.verticalTerms || [],
            conditions: values.conditions || [],
            verticalAxes: values.verticalAxes || {},
          };

          return {
            template_id: values.template,
            org_id: orgInfo.orgId,
            charts_info,
            node_id: orgInfo.rootId,
            view_type: 3 as const,
            view_name: values.viewName,
            dash_view_id: MOCK_DASHVIEW_ID,
            public: true,
            public_groups: ['-1'],
            modify_permission: 2,
          };
        })
        .exhaustive();

      const res = await createView(createViewReq);
      if (res.status === 'ok')
        editor.store.updateWidgetInstanceConfig<StatisticWidgetType['config']>(
          id,
          (config) => {
            config.statistic.viewId = res.data;
          },
        );
      else {
        throw Error('视图保存失败');
      }
    }
    // 删除
    for (let index = 0; index < patch.custom.removed.length; index++) {
      const widgetInstance = patch.custom.removed[index];
      if (widgetInstance.config.statistic.viewId)
        await deleteView({ view_id: widgetInstance.config.statistic.viewId });
    }
    // 更新
    for (let index = 0; index < patch.custom.updated.length; index++) {
      const widgetInstance = patch.custom.updated[index];

      const req = match(widgetInstance.config.statistic)
        .with({ type: 'more' }, (componentForm) => ({
          org_id: orgInfo.orgId,
          view_id: componentForm.viewId!,
          view_name: componentForm.name,
          view_info: {
            condition: componentForm.conditions,
            group: componentForm.group || undefined,
            parentId: componentForm.parentId,
            matchings: componentForm.matchings,
            getEmptyGroup: !!componentForm.getEmptyGroup,
          },
          template_id: componentForm.conditions.find(
            (x) => x.key === 'templateId',
          )?.value,
          view_type: componentForm.viewType || 1,
        }))
        .with({ type: 'chart' }, (componentForm) => {
          const values = componentForm.chartInfo;
          const charts_info = {
            ...values,
            verticalTerms: values.verticalTerms || [],
            conditions: values.conditions || [],
            verticalAxes: values.verticalAxes || {},
            verticalTermsGroupMap: values?.verticalTermsGroupMap || [],
          };

          return {
            template_id: values.template,
            public: values.viewPublic,
            org_id: orgInfo.orgId,
            charts_info,
            view_id: componentForm.viewId!,
            view_name: values.viewName,
          };
        })
        .exhaustive();
      const res = await updateViewConfig(req);
      if (res.status === 'error') {
        throw Error('更新视图失败');
      }
    }
  }
};

const genWidget = (
  widgetId: IWidgetIdType,
  {
    h,
    w = 10,
    x = 1,
    y = 0,
  }: { h: number; w?: number; y?: number; x?: number },
  config: any = {},
) => {
  const data: WidgetInstanceData = {
    parentId: null,
    widgetId,
    id: nanoid(),
    rect: {
      y: -1 * -1 * y,
      x,
      w,
      h,
    },
    config,
    children: [],
  };
  return data;
};

const genTableConfig = (
  c: ChildTheme['tabs'][number],
  legacyConfig: ChildTheme['childThemeConditions'],
) => {
  const tabId = c.tabId;

  const parentType = c.tabConfig?.filterChilds ? 'current' : 'others';
  const conditions: ViewList.ViewconditionV2Item[] = [
    {
      op: 'intersect',
      key: 'templateId',
      input: [c.tabConfig?.targetTheme].filter(Boolean),
    },
  ];
  if (parentType === 'others') {
    conditions.unshift({
      op: 'intersect',
      key: 'ancestor',
      input: [c.tabConfig?.nodePath],
    });
  }
  c.tabConfig?.conditions?.filter(Boolean).forEach(({ key, value }) => {
    if (!key) return;
    match(key)
      .with('status', () => {
        conditions.push({
          key: 'status',
          op: 'intersect',
          input: value,
        });
      })
      .otherwise(() => {
        const [, index] = key.split('-');
        conditions.push({
          key: 'prop',
          index: Number(index),
          op: 'intersect',
          input: value,
        });
      });
  });

  /**
   * 表格的条件规则配置
   */
  const conditionRules: NonNullable<
    ITableConditionRuleSetting['conditionRules']
  > = [];
  legacyConfig?.forEach((item) => {
    const validResults: ConditionRuleConfig.TableConditionResults = [];
    for (let index = 0; index < item.config.results.length; index++) {
      const result = item.config.results[index];

      match(result)
        .with({ type: 'subTabCreationVisible' }, (r) => {
          if (r.targetSubTabs.includes(tabId)) {
            validResults.push(omit(['targetSubTabs'], r));
          }
        })
        .with({ type: 'subTabPropsVisible' }, (r) => {
          // 装修表格不能显示隐藏列
          if (
            r.targetSubTab === tabId &&
            c.tabConfig?.customConfig?.tableStyle !== 'custom'
          ) {
            validResults.push(omit(['targetSubTab'], r));
          }
        })
        .otherwise(() => null);
    }

    if (validResults.length) {
      const newRule = produce(item, (draft) => {
        // @ts-ignore
        draft.config.results = validResults;
      });
      // @ts-ignore
      conditionRules.push(lagecyRuleToRule(newRule));
    }
  });

  return { parentType, conditions, viewable: true, conditionRules };
};

/**
 * 将「页面装修」配置转换成「页面大模型」配置,
 *
 * 将子表格放在「选项卡版块」，
 * 标题，正文，自定义放在「动态高度版块」
 * 将其余组件放在「通用版块」
 */
export const legacyNodePageContentToNodePageModel = (
  _pageContent: InitContentPageConfig,
  tempInfo: CurrentUser.TemplateInfo,
): PageModelConfig => {
  const pageContent = produce(_pageContent, (draft) => {
    const props = tempInfo?.prop?.filter((prop) => prop.type && prop.name);
    if (!props?.length) {
      draft.customAttribute = null;
    }
  });

  let y = 0;

  const genGeneralSection = (children: any[], _h: number) => {
    const h = _h;
    const currentY = y;
    y += h;
    const id = nanoid();
    const data: IWidgetInstanceData & {
      widgetId: 'GeneralSection';
    } = {
      parentId: null,
      widgetId: 'GeneralSection',
      id,
      rect: {
        w: 12,
        h,
        x: 0,
        y: currentY,
      },
      config: {
        showName: false,
        bgColor: '#fff',
        bgImgFit: 'cover',
        bgImgPosition: 'left top',
      },
      children: children.map((i) => ({
        ...i,
        parentId: id,
      })),
    };
    return data;
  };

  const genDynamicHeightSection = (children: any[], _h: number) => {
    const h = _h;
    const currentY = y;
    y += h;
    const id = nanoid();
    const data: IWidgetInstanceData & {
      widgetId: 'DynamicHeightSection';
    } = {
      parentId: null,
      widgetId: 'DynamicHeightSection',
      id,
      rect: {
        w: 12,
        h,
        x: 0,
        y: currentY,
      },
      config: {
        showName: false,
        bgColor: '#fff',
        bgImgFit: 'cover',
        bgImgPosition: 'left top',
      },
      children: children.map((i) => ({
        ...i,
        parentId: id,
      })),
    };
    return data;
  };

  const genTabsSection = (
    children: any[],
    _h: number,
    tabs: any[],
    legacyConfig: ChildTheme['childThemeConditions'],
  ) => {
    const h = _h + 5;
    const currentY = y;
    y += h;

    const conditionRules: NonNullable<
      ITabSectionConditionRuleSetting['conditionRules']
    > = [];
    legacyConfig?.forEach((item) => {
      /**
       * NOTE： 默认老的装修配置使用 v1 条件规则
       */
      const validResults: ConditionRuleConfig.TabSectionConditionResults = [];
      for (let index = 0; index < item.config.results.length; index++) {
        const result = item.config.results[index];

        match(result)
          .with({ type: 'subTabVisible' }, (r) => {
            validResults.push({
              type: 'tabVisible',
              visible: 'hidden',
              tabs: r.targetSubTabs,
            });
          })
          .otherwise(() => null);
      }

      if (validResults.length) {
        const newRule = produce(item, (draft) => {
          // @ts-ignore
          draft.config.results = validResults;
        });
        /**
         * 转换成 v2 条件规则
         */
        // @ts-ignore
        conditionRules.push(lagecyRuleToRule(newRule));
      }
    });

    const id = nanoid();
    const data: IWidgetInstanceData & {
      widgetId: 'TabSection';
    } = {
      parentId: null,
      widgetId: 'TabSection',
      id,
      rect: {
        w: 10,
        h: h,
        x: 1,
        y: currentY,
      },
      config: {
        showName: false,
        bgColor: '#fff',
        tabs,
        conditionRules,
      },
      children: children.map((i) => ({
        ...i,
        parentId: id,
      })),
    };
    return data;
  };

  const widgetTree: PageModelConfig['widgetTree'] = [];
  // console.log(toPairs(pageContent))

  toPairs(pageContent)
    .sort((a, b) => {
      // @ts-ignore
      const aSort = takeOne(a[1])?.sort ?? 100;
      // @ts-ignore
      const bSort = takeOne(b[1])?.sort ?? 100;

      // console.log(takeOne(a[1]), takeOne(b[1]))
      return aSort - bSort;
    })
    .forEach((item) => {
      console.log(item);
      match(item)
        // 自定义按钮
        .with(['customButton', P.not(P.nullish)], ([, config]) => {
          const h = 2;

          widgetTree.push(
            genGeneralSection(
              [
                genWidget(
                  'CustomButton',
                  { h, y: 0 },
                  {
                    ...pick(['buttons', 'grid'], config[0] || {}),
                  },
                ),
              ],
              h,
            ),
          );
        })
        // 节点头
        .with(['sysBtnAndTitleAndStatus', P.not(P.nullish)], ([, config]) => {
          const h = 6;

          widgetTree.push(
            genDynamicHeightSection(
              [
                genWidget(
                  'NodeHeader',
                  { h, y: 0 },
                  { textAlign: config[0].textAlign },
                ),
              ],
              8,
            ),
          );
        })
        // 状态流程图
        .with(['statusProcess', P.not(P.nullish)], () => {
          const h = 2;

          widgetTree.push(
            genGeneralSection(
              [genWidget('NodeStatusLine', { h, y: 0 }, {})],
              h,
            ),
          );
        })
        // 自定义属性
        .with(['customAttribute', P.not(P.nullish)], ([, config]) => {
          const h = 10;

          widgetTree.push(
            genDynamicHeightSection(
              [
                genWidget(
                  'CustomProps',
                  { h, y: 0 },
                  {
                    ...omit(['id', 'sort'], config[0]),
                  },
                ),
              ],
              h,
            ),
          );
        })
        // 正文
        .with(['mainBody', P.not(P.nullish)], () => {
          const h = 6;

          widgetTree.push(
            genDynamicHeightSection(
              [genWidget('NodeMainBody', { h, y: 0 }, {})],
              8,
            ),
          );
        })
        // 子表格
        .with(['childTheme', P.any], ([, config]) => {
          if (!config) return;
          const h = 10;

          const tabs: {
            name: string;
            children: string[];
            id: string;
          }[] = [];
          const children = config.tabs
            // .filter((i) => i.tabType === 'generalTable')
            .map((i) =>
              match(i)
                .with({ tabType: 'tree' }, (c) => {
                  const subThemeTree = genWidget(
                    'SubThemeTree',
                    { h, w: 12, x: 0 },
                    {
                      templateCondition: c.tabConfig?.templateCondition,
                    },
                  );

                  tabs.push({
                    name: c.tabName,
                    id: c.tabId,
                    children: [subThemeTree.id],
                  });

                  return subThemeTree;
                })
                // 装修表格
                .with(
                  { tabConfig: { customConfig: { tableStyle: 'custom' } } },
                  (c) => {
                    const table = genWidget(
                      'CustomTable',
                      { h, w: 12, x: 0 },
                      {
                        ...pick(['matchings'], c.tabConfig || {}),
                        ...genTableConfig(c, config.childThemeConditions),
                        customTableId: c.tabConfig.customConfig.customTableId,
                      },
                    );
                    tabs.push({
                      name: c.tabName,
                      id: c.tabId,
                      children: [table.id],
                    });
                    return table;
                  },
                )
                // 普通表格
                .otherwise((c) => {
                  const baseConfig = genTableConfig(
                    c,
                    config.childThemeConditions,
                  );

                  const table = genWidget(
                    'Table',
                    { h, w: 12, x: 0 },
                    {
                      ...pick(
                        [
                          'childThemeRequired',
                          'addType',
                          'displayAttrs',
                          'matchings',
                        ],
                        c.tabConfig || {},
                      ),
                      ...baseConfig,
                      group: c.tabConfig?.groupBy,
                    },
                  );

                  tabs.push({
                    name: c.tabName,
                    id: c.tabId,
                    children: [table.id],
                  });

                  return table;
                }),
            );

          widgetTree.push(
            genTabsSection(children, h, tabs, config.childThemeConditions),
          );
        })
        .otherwise(() => null);
    });

  return {
    version: '1.0.0',
    pageConfig: { ...initalPageConfig },
    widgetTree,
  };
};

export const getDefaultPageConfig = (
  templateInfo: CurrentUser.TemplateInfo,
) => {
  const defaultContentConfig: InitContentPageConfig = {
    customButton: null,
    sysBtnAndTitleAndStatus: [
      {
        id: generateAddOpId(),
        type: 'sysBtnAndTitleAndStatus',
        sort: 1,
      },
    ],
    customAttribute: [
      {
        id: generateAddOpId(),
        sort: 3,
        type: 'customAttribute',
        style: 'default',
        showGroup: false,
        propConfig: null,
        propGrid: null,
      },
    ],
    mainBody: [
      {
        id: generateAddOpId(),
        sort: 4,
        type: 'mainBody',
      },
    ],
    relevance: [
      {
        id: generateAddOpId(),
        sort: 6,
        type: 'relevance',
      },
    ],
    childTheme: null,
    statusProcess: null,
  };
  return legacyNodePageContentToNodePageModel(
    defaultContentConfig,
    templateInfo,
  );
};

/**
 * 清空 widget 上的内部字段
 */
export const clearPageModalConfig = (config: PageModelConfig) => {
  return produce(config, (draft) => {
    traverseTreeCreator<
      (typeof config.widgetTree)[number] & {
        deleted?: boolean;
        touched?: boolean;
      }
    >((w) => {
      delete w.deleted;
      delete w.touched;
    })(draft.widgetTree as any);

    traverseTreeCreator<
      (typeof config.widgetTree)[number] & {
        deleted?: boolean;
        touched?: boolean;
      }
    >((w) => {
      delete w.deleted;
      delete w.touched;
    })(draft.bottomWidgetTree || ([] as any));

    traverseTreeCreator<
      (typeof config.widgetTree)[number] & {
        deleted?: boolean;
        touched?: boolean;
      }
    >((w) => {
      delete w.deleted;
      delete w.touched;
    })((draft.topWidgetTree || []) as any);
  });
};

export * from './previewRender';

export const getLayoutStatus = (config: PageModelConfig['pageConfig']) => {
  const hasTop = includes(config.contentLayoutMode || 'default', [
    'stickyTop',
    'stickyTopAndBottom',
  ] as const);

  const hasBottom = includes(config.contentLayoutMode || 'default', [
    'stickyBottom',
    'stickyTopAndBottom',
  ] as const);

  return { hasTop, hasBottom };
};
