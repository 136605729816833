import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

type ValueType = {
  bold: boolean;
  italic: boolean;
  underline: boolean;
};

const FontSize = 16;

const textStyleOPtions = [
  {
    icon: (color: string) => (
      <RegularIcon type="icona-rongqi5" size={FontSize} color={color} />
    ),
    key: 'bold',
  },
  {
    icon: (color: string) => (
      <RegularIcon type="iconqingxie" size={FontSize} color={color} />
    ),
    key: 'italic',
  },
  {
    icon: (color: string) => (
      <RegularIcon type="icona-xiahuaxian1" size={FontSize} color={color} />
    ),
    key: 'underline',
  },
] as const;

export const FontStyle = (props: {
  value: ValueType;
  onChange: (value: ValueType) => void;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const { value, onChange, style, className } = props;

  const getColor = (status: boolean) => {
    return status ? '#fff' : '#242D3F';
  };

  const onFieldChange = (fieldName: keyof ValueType, value: any) => {
    onChange({ ...value, [fieldName]: value });
  };

  return (
    <div
      style={style}
      className={cn(
        'h-10 border rounded-[8px] border-solid border-[#EBEDF0] flex items-center p-[2px] gap-[5px]',
        className,
      )}
    >
      {textStyleOPtions.map((item) => (
        <span
          className={cn(
            'w-[28px] h-full flex items-center justify-center rounded-[8px] hover:bg-[#F7F7F9] cursor-pointer',
            {
              'bg-[#316EF5] hover:bg-[#316EF5]': value[item.key],
            },
          )}
          key={item.key}
          onClick={() => onFieldChange(item.key, !value[item.key])}
        >
          {item.icon(getColor(value[item.key]))}
        </span>
      ))}
    </div>
  );
};
