import { Divider, Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import customTable from '../../assets/customTable.png';
import type {
  IAddingBtnSetting,
  IChooseCustomTable,
  IConditionSetting,
  IMatchingSetting,
} from '../../components';
import {
  AddingBtnSetting,
  AddSetting,
  ChooseCustomTable,
  ConditionSetting,
  CustomTableOrder,
  MatchingSetting,
  NodeGroupSetting,
  showViewSettingModal,
  ViewerSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type ICustomTableWidget = IConditionSetting &
  IAddingBtnSetting &
  IMatchingSetting &
  IChooseCustomTable;

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <img src={customTable} style={{ height: 424, display: 'block' }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <ConditionSetting />
      <MatchingSetting />
      <AddSetting />
      <ChooseCustomTable />
      <NodeGroupSetting />
      <CustomTableOrder />
      <Divider dashed />
      <AddingBtnSetting />
      <ViewerSetting />
    </Form>
  );
};

export const CustomTableWidget = defineSystemWidget<ICustomTableWidget>()({
  id: 'CustomTable',
  title: '定制表格',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#FF962C"
        icon={
          <RegularIcon type="iconshitu-dingzhibiaoge" color={'#ffffffcc'} />
        }
      />
    );
  },
  basic: { defaultHeight: 8, defaultWidth: 12, minWidth: 3, settingWidth: 540 },
  metadata: {
    // @ts-ignore
    viewable: true,
    parentType: 'current',
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: [],
      },
    ],
    matchings: [],
    childThemeRequired: false,
    addType: 'modal',
    customTableId: undefined,
    orderBy: 'treeLevel',
    childOrderBy: '_child_treeLevel',
    orderDesc: true,
  },
  preview: Preview,
  setting: Setting,
  onCreate: () =>
    showViewSettingModal<ICustomTableWidget>({
      title: '添加定制表格',
    }),
});
