import { assertExists } from '@linkpi/utils';
import { Switch } from 'antd';

import { useActiveWidgetInstance, useEditor } from '../hooks';

export const HideTableCheckbox = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance();
  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig;

  let { hideTableCheckbox } = widgetInstance.config as any;
  hideTableCheckbox = !!hideTableCheckbox;

  const changeHideTableCheckbox = (v: boolean) => {
    updateConfig((config: any) => {
      config.hideTableCheckbox = v;
    });
  };

  return (
    <div
      style={{
        marginBottom: 24,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ color: '#242D3F', fontWeight: 700 }}>批量操作</div>
      <Switch
        checked={!hideTableCheckbox}
        onChange={(checked) => {
          changeHideTableCheckbox(!checked);
        }}
      />
    </div>
  );
};
