import type { ViewList } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { Popover } from 'antd';
import cls from 'classnames';
import type { FC, ReactNode } from 'react';

import { RegularIcon } from '@/components';
import { useNodeTreeData, useOrgInfo } from '@/hook';

import { PiButton } from '../Button';
import ConditionFilter from './ConditionFilter';
import type { ViewConditionFormPropsType } from './types';

export type ConditionFilterButtonProp = {
  className?: string;
  orgId: string;
  value: ViewList.ViewconditionV2;
  onChange: (
    v: ViewList.ViewconditionV2,
    changeData: Parameters<
      NonNullable<ViewConditionFormPropsType['onChange']>
    >[0],
  ) => void;
  type?: string;
  editable?: boolean;
  enableRoot?: boolean;
  extraPopcontent?: ReactNode;
} & Pick<
  ViewConditionFormPropsType,
  | 'allowTempIds'
  | 'allowKey'
  | 'allowPropTypes'
  | 'displayTemplate'
  | 'displayParentId'
  | 'nodeSelectExtraCurrent'
  | 'entry'
  | 'extraActionsRender'
  | 'experimental_disableHistoryStatus'
  | 'experimental_disableOpList'
>;
export const ConditionFilterButton: FC<ConditionFilterButtonProp> = ({
  className,
  value,
  onChange,
  orgId,
  type,
  editable = true,
  enableRoot,
  extraPopcontent,
  ...restProps
}) => {
  const [_value, onConditionFilterChange] = useControllableValue<
    ConditionFilterButtonProp['value']
  >({ value, onChange });

  const style = type ? { color: '#98a2b6', fontSize: 12 } : {};

  const [orgInfo] = useOrgInfo(orgId);
  const nodeTreeData = useNodeTreeData({ enableRoot });

  if (!orgInfo) return null;

  const FilterContent = (
    <div className="w-[618px]">
      <ConditionFilter
        {...restProps}
        defaultConditions={_value}
        editable={editable}
        onChange={(v) => onConditionFilterChange(v.conditionsV2, v)}
        displayHeader={true}
        getTreeData={() => nodeTreeData}
        templateUsage="fullAccess"
      />
      {extraPopcontent}
    </div>
  );
  return (
    <Popover
      destroyTooltipOnHide
      content={FilterContent}
      trigger={['click']}
      overlayClassName="view-setting-pop-condition"
      placement="bottomLeft"
      overlayStyle={{ width: 650 }}
    >
      <PiButton
        className={cls(className)}
        style={style}
        type="text"
        icon={<RegularIcon type="iconMore_Filter" size={type ? 12 : 14} />}
      >
        筛选 ({_value.length - 1})
      </PiButton>
    </Popover>
  );
};
