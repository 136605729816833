import { Popover } from 'antd';

import '../GroupForView/styles.less';
export default (props: any) => {
  const { template, curViewData, onChange, isDark } = props;

  const renderNumericalFormatProps = () => {
    const showProgressByProp = curViewData.view_info.showProgressByProp;
    const numericalFormatProps = template.prop
      .map((p: any, index: number) => ({ ...p, index }))
      .filter(
        (p: any) =>
          p.name &&
          p?.number?.numericalFormat &&
          ['number', 'formula'].includes(p.type),
      );
    if (numericalFormatProps.length === 0)
      return (
        <div
          style={{
            cursor: 'not-allowed',
            color: '#C9D0D9',
            padding: '6px 12px',
          }}
        >
          暂无相关属性
        </div>
      );
    return (
      <ul style={{ maxHeight: 400 }} className="view-setting-list">
        {numericalFormatProps.map((p: any) => (
          <li
            key={p.index}
            className={`view-setting-item text-omit${
              showProgressByProp === `prop-${p.index}` ? ' active' : ''
            }`}
            children={p.name}
            onClick={() => {
              onChange(`prop-${p.index}`);
            }}
          />
        ))}
      </ul>
    );
  };

  const renderShowProgressByProp = () => {
    const showProgressByProp = curViewData.view_info.showProgressByProp;
    if (!showProgressByProp) return '无';

    const index = showProgressByProp.split('-')[1];
    return template.prop[index].name;
  };

  return (
    <Popover
      content={renderNumericalFormatProps()}
      trigger={['click']}
      overlayClassName="view-setting-pop"
      placement={'bottomRight'}
    >
      <div
        className={`view-setting-block${isDark ? ' view-setting-block-dark' : ''}`}
      >
        <div className="text-omit">显示进度： {renderShowProgressByProp()}</div>
        {curViewData.view_info.showProgressByProp && (
          <i
            className={'iconfont iconButton_cancel icon-right'}
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          />
        )}
        <i className={`iconfont iconxiala icon-right`} />
      </div>
    </Popover>
  );
};
