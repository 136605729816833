import { getWidgetSettingContext } from '@mylinkpi/widget-react';
import cls from 'classnames';
import type { GridStack } from 'gridstack';
import type { FC } from 'react';
import { createElement, useMemo } from 'react';
import { match } from 'ts-pattern';

import { RegularIcon } from '@/components/IconFont';
import SimpleTooltip from '@/components/SimpleTooltip';

import { useActiveWidgetInstance, useEditor } from '../../hooks';
import { limitWarn } from '../../utils';

import styles from './styles.less';

// 组件h * cellHeight - 32
export const WidgetComponent: FC<{
  compId: string;
  parentGridStack: GridStack;
}> = (props) => {
  const { compId, parentGridStack } = props;

  const editor = useEditor();
  // 当前激活的类
  const activeWidgetInstance: any = useActiveWidgetInstance() || {};
  const widgetInstance = editor.store.getWidgetInstance(compId);
  const widgetConfig = widgetInstance
    ? editor.widgetManager.getWidget(widgetInstance.widgetId)
    : null;

  const WidgetSettingContextValue = useMemo(
    () => ({
      value: widgetInstance?.config || {},
      setValue: editor.updateActiveWidgetInstanceConfig as any,
    }),
    [editor.updateActiveWidgetInstanceConfig, widgetInstance?.config],
  );

  const selectComp = (e: any) => {
    e.stopPropagation();
    editor.setActiveWidgetInstance(compId);
  };

  const delComp = () => {
    editor.removeWidgetInstance(compId);
    const parentElement = document.getElementById(compId)?.parentElement?.parentElement;
    if (parentElement) parentGridStack.removeWidget(parentElement, true);
  };

  const copyComp = () => {
    if (editor.cloneWidgetInstance) editor.cloneWidgetInstance(compId);
  };

  const renderTools = () => {
    if (activeWidgetInstance.id !== compId) return null;
    const canClone = editor.checkWidgetClonable(compId);
    const extraClassName = canClone ? '' : ' ' + styles.notClone;

    return (
      <div className={styles.compToolsContainer}>
        <SimpleTooltip title={limitWarn} showTooltip={!canClone}>
          <div
            onClick={() => {
              if (canClone) copyComp();
            }}
            className={styles.copy + extraClassName}
          >
            <RegularIcon type="iconfuzhi2" color="#6B7A96" />
            <span className="text-[#6B7A96] pl-1">复制</span>
          </div>
        </SimpleTooltip>
        <div onClick={delComp} className={cls(styles.del, 'ml-[6px]')}>
          <RegularIcon type="iconshanchu2" color="#6B7A96" />
        </div>
      </div>
    );
  };

  const WidgetSettingContext = getWidgetSettingContext();

  return (
    <div
      className={`${activeWidgetInstance.id === compId ? styles.activeComp : ''} ${styles.comp}`}
      onClick={selectComp}
    >
      {renderTools()}
      <div className={styles.compContent}>
        {match(widgetConfig)
          .with({ type: 'system' }, ({ preview }) => createElement(preview, { id: compId }))
          .with({ type: 'custom' }, ({ preview }) => (
            <WidgetSettingContext.Provider value={WidgetSettingContextValue}>
              {createElement(preview, { id: compId })}
            </WidgetSettingContext.Provider>
          ))
          .otherwise(() => '该组件已删除')}
      </div>
    </div>
  );
};
