import { PlusOutlined } from '@ant-design/icons';
import ProForm, { ProFormUploadDragger } from '@ant-design/pro-form';
import { assertExists } from '@linkpi/utils';
import { Form, message, Radio, Select } from 'antd';
import { head } from 'ramda';
import { type PropsWithChildren } from 'react';
import { match } from 'ts-pattern';
import { container } from 'tsyringe-neo';

import { useOrgInfo } from '@/hook';
import { uploadFileToOss } from '@/utils/utils';

import iconImg from '../../assets/genericSection.png';
import { SectionColorSetting } from '../../components';
import { BasicSetting } from '../../components/BasicSetting';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { EditorModel } from '../../models';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

import styles from './styles.less';

export type IGeneralSectionConfig = {
  bgColor: string;
  bgImg?: string;
  bgImgFit?: React.CSSProperties['objectFit'];
  bgImgPosition?: React.CSSProperties['objectPosition'];
};

const Preview: WidgetPreivew<PropsWithChildren> = ({ children }) => {
  return <div>{children}</div>;
};

const positionOptions = [
  { label: '左上', value: 'left top' },
  { label: '上', value: 'top' },
  { label: '右上', value: 'right top' },
  { label: '左', value: 'left' },
  { label: '中', value: 'center' },
  { label: '右', value: 'right' },
  { label: '左下', value: 'left bottom' },
  { label: '下', value: 'bottom' },
  { label: '右下', value: 'right bottom' },
];

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IGeneralSectionConfig>()!;
  const value = widgetInstance.config;
  const updateConfig = editor.updateActiveWidgetInstanceConfig<IGeneralSectionConfig>;
  const [orgInfo] = useOrgInfo();
  assertExists(orgInfo);

  return (
    <Form layout="vertical" className={styles.wrapper}>
      <BasicSetting type="section" />
      <ProForm.Group title="版块样式" direction="vertical">
        <SectionColorSetting label="板块底色" />
        <ProFormUploadDragger
          icon={<PlusOutlined />}
          label="背景图"
          title=""
          description="上传图片"
          width={180}
          accept=".png,.jpg,.jpeg,.svg,.gif"
          allowClear
          // @ts-expect-error
          value={[{ url: value.bgImg, uid: value.bgImg }].filter((i) => i.uid)}
          max={1}
          onChange={({ fileList }) => {
            if (fileList.length) return;
            updateConfig((c) => {
              c.bgImg = undefined;
            });
          }}
          fieldProps={{
            listType: 'picture-card',
            customRequest: async (o) => {
              const res = await uploadFileToOss(o.file, orgInfo.orgId);
              if (!res) {
                return message.error('上传失败');
              }

              updateConfig((c) => {
                c.bgImg = res;
              });
              o.onSuccess?.(res);
            },
          }}
        />
        <Form.Item label="图片布局">
          <Radio.Group
            value={value.bgImgFit}
            onChange={(e) => {
              updateConfig((c) => {
                c.bgImgFit = e.target.value;
              });
            }}
            options={[
              { label: '铺满覆盖', value: 'cover' },
              { label: '实际尺寸', value: 'none' },
            ]}
          />
        </Form.Item>
        <Select<React.CSSProperties['objectPosition']>
          value={value.bgImgPosition}
          onChange={(e) => {
            updateConfig((c) => {
              c.bgImgPosition = e;
            });
          }}
          options={positionOptions}
          style={{ width: 300 }}
        />
      </ProForm.Group>
    </Form>
  );
};

export const GeneralSectionWidget = defineSystemWidget<IGeneralSectionConfig>()({
  id: 'GeneralSection',
  title: '通用',
  icon: () => {
    return <img src={iconImg} style={{ width: 188 }} />;
  },
  basic: { defaultHeight: 4, defaultWidth: 12, nested: true },
  metadata: {
    bgColor: '#fff',
    bgImgFit: 'cover',
    bgImgPosition: 'left top',
  },
  preview: Preview,
  setting: Setting,
  onCreate: async () => {
    const editor: EditorModel = container.resolve('EditorModel');
    const c = editor.store.data.pageConfig;
    return {
      bgColor: match(c.styleMode)
        .with('light', () => '#fff')
        .with('dark', () => '#1B235A')
        .exhaustive(),
      bgImgFit: 'cover',
      bgImgPosition: 'left top',
    };
  },
});
