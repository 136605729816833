import type { CurrentUser } from '@linkpi/core';
import { STATUS_ICON, tempValueDisplay } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import type Delta from 'quill-delta';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { type FC, useMemo } from 'react';
import { Quill } from 'react-quill-new';
import { match, P } from 'ts-pattern';

import type { IRichTextConfig } from '@/components/PageModelEditor/src/widgets/RichTextWidget/Setting';
import {
  useCurrentUser,
  useOrgInfo,
  useOrgUserMap,
  useTemplateList,
  useTemplateMap,
} from '@/hook';
import { useGlobalConditions } from '@/hook/useGlobalConditions';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { fetchDataSource } from '@/utils/dataManager';
import {
  convertDeltaToPureText,
  filterFlowSetting,
  getCurrentFlowSetting,
  getUserNickName,
  handleQuillOps,
  isNodeDisabled,
} from '@/utils/utils';

interface RichTextProps {
  data: IRichTextConfig;
  getterPiNode: GetterPiNode;
  id: string;
}

export const RichText: FC<RichTextProps> = ({ data, getterPiNode, id }) => {
  const [orgInfo] = useOrgInfo();
  assertExists(orgInfo);
  const currentUser = useCurrentUser();
  const templateList = useTemplateList();
  const templateMap = useTemplateMap();
  const userMap = useOrgUserMap();
  const departmentMap = useOrgDepartmentNodesMap();

  /**
   * 全局筛选条件
   */
  const globalConditions = useGlobalConditions(id);

  const conditions = useMemo(() => {
    if (!globalConditions) return data.conditions;
    if (!globalConditions?.length) return data.conditions;
    return [...data.conditions, ...globalConditions];
  }, [data.conditions, globalConditions]);

  const { data: nodeList } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['rich-text', orgInfo.rootId, id, conditions, data],
    queryFn: async () => {
      const res = await fetchDataSource({
        orgId: orgInfo.orgId,
        currentNodeId: getterPiNode.value.id,
        page: 1,
        pageSize: 1,
        ...data,
        parentType: 'others',
        conditions,
      });
      if (res.status !== 'ok') {
        return [];
      }
      return res.list;
    },
  });

  const [node] = nodeList ?? [];

  const getCommonStatusPropValue = (
    template: CurrentUser.TemplateInfo | undefined,
    _node: PiNode,
    index: number,
  ) => {
    const status = template?.task_status?.[_node.tempInfo.status];
    const config = status ? status.prop[index] : null;
    return config && config.display && config.type !== 'quote'
      ? _node.tempInfo.statusProp[index]
      : null;
  };

  const renderPropBlot = (
    value: any,
  ): string | { type: 'text' | 'other'; value: string }[] => {
    let { label, fontColor, fontSize, themeAttrLink, isAttachment, index } =
      value;

    let name = label;
    if (node) {
      match(value)
        .with({ index: 'title' }, () => {
          name = node.title || '无标题';
        })
        .with({ index: 'sysTag' }, () => {
          name = (
            node.prop._sys_tag && typeof node.prop._sys_tag === 'object'
              ? Object.keys(node.prop._sys_tag)
              : []
          ).join('、');
        })
        .with({ index: 'createTime' }, () => {
          name = node.createTime
            ? dayjs(node.createTime).format('YYYY-MM-DD HH:mm')
            : '';
        })
        .with({ index: 'status' }, (v) => {
          const status = node.tempInfo.status;
          const task_status = node.template?.task_status || [];
          const statusInfo = task_status[status];

          if (statusInfo && !statusInfo.delete) {
            const iconInfo =
              STATUS_ICON[statusInfo.icon as keyof typeof STATUS_ICON];
            if (iconInfo) {
              // 按钮样式
              if (value.statusStyle === 'button') {
                const f =
                  node.template?.task_status[node.tempInfo.status]
                    .flowSetting || [];

                name = `<span class="button"><i class="iconfont ${statusInfo.icon}" style="color: ${iconInfo.color}" />${statusInfo.name}</span>`;
                return {
                  propType: 'buttonStatus',
                  value: {
                    statusName: statusInfo.name,
                    statusIcon: {
                      unicode: iconInfo.unicode,
                      color: iconInfo.color,
                    },
                    buttons: f.length
                      ? filterFlowSetting(node, orgInfo, currentUser).filter(
                          (f) => !isNodeDisabled(currentUser, node),
                        )
                      : getCurrentFlowSetting(node, orgInfo, currentUser),
                    button: true,
                  },
                };
              }
              name = `<span>
              <i class="iconfont ${statusInfo.icon}" style="color: ${iconInfo.color}"></i>
              ${statusInfo.name}
              </span>`;
              name = [
                {
                  type: 'other',
                  value: `<i class="iconfont ${statusInfo.icon}" style="color: ${iconInfo.color}"></i>`,
                },
                {
                  type: 'text',
                  value: `${statusInfo.name}`,
                },
              ];
              return {
                value: {
                  statusName: statusInfo.name,
                  statusIcon: {
                    unicode: iconInfo.unicode,
                    color: iconInfo.color,
                  },
                },
                propType: 'status',
              };
            }
          }
        })
        .with({ index: 'creator' }, () => {
          name = getUserNickName(userMap[node.prop._sys_creator]);
        })
        .with({ index: 'mainbody' }, () => {
          name = convertDeltaToPureText(node.metadata.content);
        })
        .with({ index: 'treeLevelId' }, () => {
          // TODO
          name = '无';
        })
        .with({ index: 'statusProp_common_0' }, () => {
          const _data = getCommonStatusPropValue(node.template, node, 0);
          if (_data) {
            const __data = Array.isArray(data) ? data : [data];
            name = __data
              .filter((x: any) => x)
              .map((x: any) => getUserNickName(userMap[x]));
          } else {
            name = '无';
          }
        })
        .with({ index: 'statusProp_common_1' }, () => {
          const _data = getCommonStatusPropValue(node.template, node, 1);
          if (_data) {
            const __data = Array.isArray(data) ? data : [data];
            name = __data
              .filter((x: any) => x)
              .map((x: any) => getUserNickName(userMap[x]));
          } else {
            name = '无';
          }
        })
        .with({ index: 'statusProp_common_2' }, () => {
          const _data = getCommonStatusPropValue(node.template, node, 2);
          name = _data ? dayjs(_data).format('YYYY-MM-DD HH:mm') : '';
        })
        .with({ index: 'statusProp_common_3' }, () => {
          const _data = getCommonStatusPropValue(node.template, node, 3);
          name = _data ? dayjs(_data).format('YYYY-MM-DD HH:mm') : '';
        })
        .with({ index: 'statusProp_common_4' }, () => {
          name = getCommonStatusPropValue(node.template, node, 4) || '无备注';
        })
        .with({ templateId: P.string }, () => {
          const propValue = tempValueDisplay({
            propConfig: node.template?.prop[index],
            propValue: node.tempInfo.prop[index],
            propIndex: index,
            userMap,
            tempMap: templateMap,
            departmentMap,
          }) as string | number | { r: string }[];

          name = match(propValue)
            .with(P.string, (p) => p)
            .with(P.number, (p) => p.toString())
            .with(P.array({ r: P.string }), (list) => {
              return list.map((x) => x.r).join(', ');
            })
            .otherwise(() => null);
        })
        .otherwise(() => null);
    }

    if (isAttachment === 'true') {
      label = label.slice(0, -1) + `(${fontSize}px)` + '}';
      fontSize = 14;
    }

    // return `<span style="color: ${
    //   fontColor || 'black'
    // }; font-size: ${fontSize}px; ">${name}</span>`;
    return name;
  };

  const handleOpsAttr = (ops: Delta['ops']) => {
    return ops.map((_op) => {
      const op = cloneDeep(_op);
      match(op.attributes)
        .with({ link: { href: P.string } }, (v) => {
          const link = v.link as { href: string; target: string };
          op.attributes!.link = link.href;
          op.attributes!.target = link.target;
        })
        .otherwise(() => null);

      return op;
    });
  };

  const handelHtmlToDelta = (html: string) => {
    const div = document.createElement('div');
    div.setAttribute('id', 'htmlToDelta');
    div.innerHTML = `<div id="quillEditor" style="display:none">${html}</div>`;
    document.body.appendChild(div);
    const quill = new Quill('#quillEditor');
    const delta = quill.getContents();
    document.getElementById('htmlToDelta')?.remove();
    return delta;
  };

  const handleDeltaToHTML = (ops: Delta['ops']) => {
    const divEle = document.createElement('div');
    const tempQuill = new Quill(divEle);
    const Size = Quill.import('attributors/style/size') as Record<string, any>;
    Size.whitelist = Array.from({ length: 100 }).map((_, i) => i + 1 + 'px');
    Quill.register(Size, true);
    try {
      tempQuill.setContents(ops);
      return tempQuill.root.firstElementChild?.innerHTML;
    } catch (error) {
      console.warn('quill error: ', error);
      console.log(ops);
      return '';
    }
  };

  const renderContent = (content: any) => {
    let ops = content?.ops || [];
    ops = handleQuillOps(ops, templateList);
    ops = handleOpsAttr(ops);

    // const customTag = (format: string) => {
    //   if (format === 'ThemeLink') return 'a';
    // };

    const converter = new QuillDeltaToHtmlConverter(ops, {
      inlineStyles: {
        size: (v) => `font-size: ${v || '12px'}`,
        // bold: (deltaOps, children) => `<strong>${children}</strong>`, // 加粗
        // italic: (deltaOps, children) => `<em>${children}</em>`, // 斜体
        // underline: (deltaOps, children) => `<u>${children}</u>`, // 下划线
        // strike: (deltaOps, children) => `<del>${children}</del>`, // 删除线
      },
      customCssStyles: (op) => {
        //
      },
      customTagAttributes: (op) => {
        //
      },
    });

    // TODO 现在这里处理的方式过于复杂了
    converter.renderCustomWith((customOp) => {
      const { insert } = customOp;
      const { type } = insert;

      if (type === 'ThemeAttrSpan') {
        const propValue = renderPropBlot(insert.value);
        if (Array.isArray(propValue)) {
          return propValue.reduce((result, item) => {
            const v =
              item.type === 'text'
                ? handleDeltaToHTML([
                    {
                      ...(customOp as any),
                      insert: item.value,
                    },
                  ])
                : item.value;
            return result + v;
          }, '');
        } else {
          return propValue
            ? (handleDeltaToHTML([
                {
                  ...(customOp as any),
                  insert: propValue,
                },
              ]) ?? '')
            : '';
        }
        // return label;
      }

      return '未定义的文件类型';
    });

    return converter.convert();
  };

  return (
    <div className="w-full h-full break-all text-[#242d3f]">
      <div
        dangerouslySetInnerHTML={{
          __html: match(data)
            .with({ contentsValue: P.nonNullable }, (_data) =>
              renderContent(_data.contentsValue),
            )
            .with({ value: P.nonNullable }, (_data) => _data.value)
            .otherwise(() => ''),
        }}
      />
    </div>
  );
};
