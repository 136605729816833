import { assertExists, generateHtmlAttributes } from '@linkpi/utils';
import { usePrevious } from '@react-hookz/web';
import { useMemoizedFn, useMount, useUnmount } from 'ahooks';
import cls from 'clsx';
import { diff } from 'fast-array-diff';
import { GridStack } from 'gridstack';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { ScrollArea } from '@/components/ui/scroll-area';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';
import { cn } from '@/utils/utils';

import { GridComponentProvider } from '../../components';
import { dragItemClassName } from '../../constants';
import { useEditor, useEditorWidgetTree, usePageConfig } from '../../hooks';
import type { LayoutPositionType } from '../../models/Store';
import { clearRoots, getRoot, setupDragIn } from '../../utils';
import { DynamicHeightSection } from './DynamicHeightSection';
import { GeneralSection } from './GeneralSection';
import { TabSection } from './TabSection';

import 'gridstack/dist/gridstack-extra.min.css';
import 'gridstack/dist/gridstack.min.css';
import styles from './styles.less';

// main grid stack
const PiGridStack: FC<{ type: LayoutPositionType; className?: string }> = ({
  type,
  className,
}) => {
  const mainGridStackContainerPadding = 80;
  const mainGridStackContainerMargin = 8;
  const mainGridStackCellHeight = 32;

  const editor = useEditor();
  const data = useEditorWidgetTree(type) || [];
  const pageConfig = usePageConfig();
  console.log('🧲 ' + type, data);

  const addWidgetInstance = useMemoizedFn(
    (...args: Parameters<typeof editor.addWidgetInstance>) =>
      editor.addWidgetInstance(args[0], {
        ...args[1],
        type,
      }),
  );
  const resizeWidgetInstance = editor.store.resizeWidgetInstance;

  // main-gridStackContainerRef
  const mainGridStackContainerRef = useRef<HTMLDivElement | null>(null);
  const mainGridStackRef = useRef<GridStack | null>(null);

  // 点击触发删除，todo
  const addMainEvents = () => {
    const mainGridStack = mainGridStackRef.current;
    assertExists(mainGridStack);

    mainGridStack.on('added change', async (event: any, items: any[]) => {
      if (!items) return;

      const item = items[0];
      const { el, x, y, h, w } = item;
      // nested false 组件
      // widget 组件类型；
      const { widget, nested } = el.dataset;

      // 左侧拖拽过去
      if (!('id' in item)) {
        // DynamicHeightSection
        if (widget === 'DynamicHeightSection') {
          addWidgetInstance('DynamicHeightSection', { x, y, w: 12 });
          mainGridStack.removeWidget(el, true);
          return;
        }

        // TabSection
        if (widget === 'TabSection') {
          await editor.addWidgetInstanceAsync('TabSection', { x, y });
          mainGridStack.removeWidget(el, true);
          return;
        }
        // 增加一个通用版块
        const generalSection = addWidgetInstance('GeneralSection', {
          x,
          y,
          w,
          h: h + 1,
        });
        // 删除 stack dom
        mainGridStack.removeWidget(el, true);

        if (nested === 'false') {
          try {
            // 组件
            await editor.addWidgetInstanceAsync(widget, {
              x: 0,
              y,
              parentId: generalSection?.id,
              w: 12,
            });
          } catch (error) {
            console.log('🏮 取消新建');
            // 新建组件失败，删除无用的通用板块
            if (generalSection?.id) {
              console.log('🏮 删除通用版块');
              editor.removeWidgetInstance(generalSection.id, { type });
              const parentElement = document.getElementById(generalSection.id)
                ?.parentElement?.parentElement;
              if (parentElement)
                mainGridStack.removeWidget(parentElement, true);
            }
          }
        }
      } else {
        // change事件
        items.forEach((i: any) => {
          resizeWidgetInstance(i.id, { x: i.x, y: i.y, h: i.h, w: i.w });
        });
      }
    });
  };

  useMount(() => {
    mainGridStackRef.current = GridStack.init(
      {
        margin: mainGridStackContainerMargin, // 右下角的 icon 的距离
        acceptWidgets: (el) => {
          const classList = Array.from(el.classList);
          // 包括组件， 通用板块, tabs
          return classList.includes(dragItemClassName);
        },
        cellHeight: mainGridStackCellHeight, // 实际的尺寸只有 h * mainGridStackCellHeight - margin * 2
        column: 12,
        disableOneColumnMode: true,
      },
      mainGridStackContainerRef.current!,
    );

    setupDragIn();
    addMainEvents();
  });

  const currentTemplate = useCurrentTemplate();

  const oldData = usePrevious(data);
  const rerender = useMemoizedFn(() => {
    if (!mainGridStackRef.current) return [];
    const result = diff(oldData || [], data, (a, b) => a.id === b.id);

    console.log('🛣️ diff result', result);
    result.added.forEach((i) => {
      const htmlAttrs = generateHtmlAttributes({
        'data-nested': true,
        class: cls('comp', styles.sectionGridStackContainer),
        id: i.id,
      });
      const content = `<div ${htmlAttrs}></div>`;

      const widgetPayload: any = { ...i, ...i.rect, content };
      if (i.widgetId === 'DynamicHeightSection') {
        widgetPayload.minW = 12;
        widgetPayload.minH = 8;
        // widgetPayload.maxH = 8;
      }

      mainGridStackRef.current!.addWidget(widgetPayload);

      return setTimeout(() => {
        /**
         * 渲染版块
         *
         * TODO 将版块组件移入至 Widget 维护
         */
        const root = getRoot(i.id);
        // DynamicHeightSection
        if (i.widgetId === 'DynamicHeightSection')
          root.render(
            <GridComponentProvider currentTemplate={currentTemplate}>
              <DynamicHeightSection
                parentGridStack={mainGridStackRef.current!}
                gridId={i.id}
                type={type}
              />
            </GridComponentProvider>,
          );

        if (i.widgetId === 'GeneralSection')
          root.render(
            <GridComponentProvider currentTemplate={currentTemplate}>
              <GeneralSection
                parentGridStack={mainGridStackRef.current!}
                gridId={i.id}
                type={type}
              />
            </GridComponentProvider>,
          );

        if (i.widgetId === 'TabSection')
          root.render(
            <GridComponentProvider currentTemplate={currentTemplate}>
              <TabSection
                parentGridStack={mainGridStackRef.current!}
                gridId={i.id}
                type={type}
              />
            </GridComponentProvider>,
          );
      }, 120);
    });
  });

  useUnmount(() => {
    clearRoots();

    const mainGridStack = mainGridStackRef.current;
    assertExists(mainGridStack);
    mainGridStack.offAll();
    mainGridStack.destroy();
  });

  useEffect(() => {
    const timerList = rerender();

    return () => {
      timerList?.forEach(clearInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  return (
    <div
      className="h-full"
      style={{
        backgroundColor: data.length ? pageConfig.bgColor : 'transparent',
      }}
    >
      <ScrollArea className="h-full">
        <div className={cn(styles.gridStackContainer, className)}>
          {/* 这个padding让拖进来的物体有放置空间 */}
          <div
            style={{ padding: data.length ? 0 : mainGridStackContainerPadding }}
            ref={mainGridStackContainerRef}
            className={cn('grid-stack', 'min-h-screen')}
          />
        </div>
      </ScrollArea>
    </div>
  );
};

export default PiGridStack;
