import { filterChecker, NODE_PERMISSION, tempValueDisplay } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useMeasure } from '@react-hookz/web';
import { message, Modal, Pagination, Table, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { NewLineText } from '@/components';
import { conditionV2ConvertCondition } from '@/components/ConditionFilter/utils';
import CustomButton from '@/components/CustomButton';
import { useComponentId, useOrgInfo, useOrgUserMap } from '@/hook';
import { useGlobalConditions } from '@/hook/useGlobalConditions';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { useOrgTempMap } from '@/hook/useTemplate';
import {
  triggerCustomButton,
  TriggerCustomButtonNiceModal,
} from '@/pages/home/components/TriggerCustomButton';
import { viewNodeContent } from '@/pages/home/components/View/ViewContent';
import { fetchDataSource } from '@/utils/dataManager';
import { getCustomBtn, getSysBtn, notAdminDelNodeAcl } from '@/utils/utils';

import AddButtonForView from '../../viewsettingComponents/AddButtonForView';
import DeleteNodes from '../../viewsettingComponents/DeleteNodes';

import styles from './styles.less';

interface IProps {
  getterPiNode: GetterPiNode;
  data: any;
  id: string;
}
const defaultWidth = 120;

export const ElewattTable: FC<IProps> = (props) => {
  const { id } = props;
  const { config } = props.data;
  const orgInfo = useOrgInfo()[0]!;
  const tempMap = useOrgTempMap(orgInfo.orgId);
  const userMap = useOrgUserMap();
  const departmentNodeMap = useOrgDepartmentNodesMap();
  const customButtonTriggerId = useComponentId();
  const [domRect = { width: 0, height: 0 }, domRef] = useMeasure();
  const [containerRect = { width: 0, height: 0 }, containerRef] = useMeasure();

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [dataSource, setDataSource] = useState<PiNode[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    titleStyle,
    contentStyle,
    fixedCol,
    propsConfig: _propsConfig,
    specialProp,
    allowBatchAction,
    tools,
    thmId,
    type,
    matchings,
    parentType,
    conditions: _conditions,
    bgConfig: { titleBg, contentBg, gapBg },
    horizontalBorder,
    verticalBorder,
    actions,
    pagination,
    titleLineHeight = 16,
    contentLineHeight = 16,
  } = config;

  const globalConditions = useGlobalConditions(id);
  const conditions = useMemo(() => {
    if (!globalConditions) return _conditions;
    if (!globalConditions.length) return _conditions;
    return [..._conditions, ...globalConditions];
  }, [_conditions, globalConditions]);

  const templateId = conditions.find((i: any) => i.key === 'templateId')
    ?.input[0];

  const getPropsConfig = () => {
    const prop = tempMap[templateId].prop
      .map((p: any, index: number) => ({ propIndex: index, ...p }))
      .filter((p: any) => p.type)
      .sort((p1, p2) => {
        if ('sort' in p1) return p1.sort - p2.sort;
        return 1;
      });

    return [
      {
        title: '序号',
        key: 'index',
        show: true,
        align: 'left',
        width: 50,
      },
      ...prop.map((p: any) => ({
        title: p.name,
        key: p.propIndex,
        show: true,
        align: 'left',
        width: undefined,
      })),
    ];
  };

  const freshPropsConfig = () => {
    const prop = tempMap[templateId].prop
      .map((p: any, index: number) => ({ propIndex: index, ...p }))
      .filter((p: any) => p.type)
      .sort((p1: any, p2: any) => {
        if ('sort' in p1) return p1.sort - p2.sort;
        return 1;
      });
    // 新老对比
    const propMap = prop.reduce((res: any, cur: any) => {
      res[cur.propIndex] = cur;
      return res;
    }, {});

    const news: any[] = [];
    prop.forEach((p: any) => {
      let exist = false;

      _propsConfig.forEach((i: any) => {
        if (i.children)
          i.children.forEach((j: any) => {
            if (j.key === p.propIndex) exist = true;
          });
        else if (i.key === p.propIndex) exist = true;
      });

      if (!exist)
        news.push({
          title: p.name,
          key: p.propIndex,
          show: true,
          align: 'left',
          width: undefined,
        });
    });

    // 清除删除的属性
    const newPropsConfig = cloneDeep(_propsConfig.concat(news)).filter(
      (i: any) => String(i.key).length > 4 || propMap[i.key],
    );

    newPropsConfig.forEach((p: any) => {
      if (p.key === 'index') return;
      if (p.children) {
        p.children = p.children.filter((i: any) => propMap[i.key]);
        p.children.forEach((i: any) => {
          i.title = propMap[i.key].name;
        });
      } else {
        p.title = propMap[p.key].name;
      }
    });
    return newPropsConfig;
  };
  const propsConfig = (
    _propsConfig ? freshPropsConfig() : getPropsConfig()
  ).filter((p: any) => p.children || p.show);

  const nowTemplate = tempMap[templateId];
  // 属性条件规则
  const propCondition = nowTemplate?.temp_option?.propCondition || [];
  const ablePropCondition = propCondition.filter((p: any) => p.enable);

  const fixedColIndex = propsConfig.findIndex((p: any) => p.key === fixedCol);

  let scrollX = 0;
  propsConfig.forEach((i: any) => {
    if (!i.children) {
      const w = i.width === undefined ? defaultWidth : i.width;
      scrollX += w;
    } else {
      i.children.forEach((c: any) => {
        const w = c.width === undefined ? defaultWidth : c.width;
        scrollX += w;
      });
    }
  });

  const renderTitle = (v: string) => {
    return (
      <div
        className="text-omit"
        style={{
          fontSize: titleStyle.size,
          color: titleStyle.color,
          fontWeight: titleStyle.decoration === 'bold' ? 700 : 400,
          fontStyle: titleStyle.decoration === 'italic' ? 'italic' : 'normal',
          textDecoration:
            titleStyle.decoration === 'underline' ? 'underline' : 'none',
        }}
      >
        {v}
      </div>
    );
  };

  const renderValue = (record: any, p: any, index: number) => {
    const size = (pagination || {}).size;
    if (p.key === 'index')
      return size ? (currentPage - 1) * size + index + 1 : index + 1;

    const v = ablePropCondition.filter((p: any) =>
      filterChecker(p.condition)(record.metadata),
    );

    const map: any = {};
    v.forEach((p: any) => {
      p.results.forEach((i: any) => {
        if (i.type === 'property_color') {
          const { propIndex, propColor, propBg } = i;
          map[propIndex] = { propColor, propBg };
        }
      });
    });

    const propValue = tempValueDisplay({
      propConfig: tempMap[templateId]?.prop[p.key],
      propValue: record.tempInfo.prop[p.key],
      propIndex: p.key,
      sysCascade: record.prop._sys_cascade,
      userMap: userMap,
      tempMap,
      departmentMap: departmentNodeMap,
    });

    return (
      <Tooltip overlayClassName={styles.tooltip} title={propValue}>
        <div
          className="text-omit"
          style={{
            fontSize: contentStyle.size,
            color: map[p.key] ? map[p.key].propColor : contentStyle.color,
            fontWeight: contentStyle.decoration === 'bold' ? 700 : 400,
            fontStyle:
              contentStyle.decoration === 'italic' ? 'italic' : 'normal',
            textDecoration:
              contentStyle.decoration === 'underline' ? 'underline' : 'none',
          }}
        >
          {propValue}
        </div>
      </Tooltip>
    );
  };

  propsConfig.forEach((p: any, index: number) => {
    p.title = renderTitle(p.title);
    if (index <= fixedColIndex) p.fixed = 'left';
    if (!p.children) {
      // prop
      p.render = (_: any, v: any, i: number) => renderValue(v, p, i);
      p.width = p.width || defaultWidth;
    } else {
      //group
      p.children.forEach((c: any) => {
        c.title = renderTitle(c.title);
        c.width = c.width || defaultWidth;
        c.render = (_: any, v: any, i: number) => renderValue(v, c, i);
        if (index <= fixedColIndex) c.fixed = 'left';
      });
    }
  });
  const getBtns = () => {
    const sys: string[] = [];
    const notSys: string[] = [];

    const sortMap: any = {
      system_button_delete: 2,
      system_button_edit: 1,
      system_button_view: 0,
    };

    actions.forEach((i: string) => {
      if (i.startsWith('system_button')) sys.push(i);
      else notSys.push(i);
    });

    sys.sort((a, b) => sortMap[a] - sortMap[b]);
    return [...sys, ...notSys]
      .map((id: string) => {
        return id.startsWith('system_button')
          ? getSysBtn(templateId, id, orgInfo.templateList)
          : getCustomBtn(templateId, id, orgInfo.templateList);
      })
      .filter((i: any) => !!i);
  };
  const actionsCol = {
    title: '操作',
    key: 'action',
    fixed: 'right',
    width: domRect.width + 33,
    render: (record: any) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getBtns().map((btnConfig: any) => (
            <div
              style={{ marginRight: 8 }}
              onClick={() => {
                if (btnConfig.opType === 'viewNode') {
                  const ids = dataSource.map((n: any) => n.id);
                  const index = ids.indexOf(record.id);

                  viewNodeContent({
                    nodeIndex: index,
                    dataList: ids,
                    showNodeSaved: () => {},
                  });
                  return;
                }

                if (btnConfig.opType === 'deleteNode') {
                  const allowDelete =
                    notAdminDelNodeAcl(orgInfo, record) &&
                    record.acl === NODE_PERMISSION.管理 &&
                    !record.prop._sys_protect;
                  if (!allowDelete) {
                    message.warning('当前主题无权限删除');
                    return;
                  }
                  Modal.confirm({
                    title: '提示',
                    content: `是否确定删除该数据？`,
                    onOk: async (closeModal) => {
                      props.getterPiNode.value.nodeManager
                        .getNode(record.id)
                        .deleteNode();
                      init(1);
                      closeModal();
                    },
                  });
                  return;
                }
                triggerCustomButton(
                  {
                    config: btnConfig,
                    node: record,
                    force: true,
                  },
                  customButtonTriggerId,
                );
              }}
              key={btnConfig.id}
            >
              {btnConfig.id.startsWith('system_button') ? (
                <span
                  style={{
                    cursor: 'pointer',
                    color:
                      btnConfig.id === 'system_button_delete'
                        ? '#F0665E'
                        : '#316EF5',
                  }}
                >
                  {btnConfig.text}
                </span>
              ) : (
                <CustomButton
                  loading={false}
                  config={btnConfig}
                  forbiddenClick={false}
                />
              )}
            </div>
          ))}
        </div>
      );
    },
  };

  const columns: any[] = propsConfig;

  if (actions.length) columns.push(actionsCol);

  const init = async (p: number) => {
    const size = (pagination || {}).size;

    const { status, total, list, page } = await fetchDataSource({
      orgId: orgInfo.orgId,
      currentNodeId: props.getterPiNode.value.id,
      page: p,
      pageSize: size || 999, //不分页
      conditions,
      type,
      thmId,
      parentType,
      matchings,
    });
    if (status === 'ok' && !size) return setDataSource(list);
    if (status === 'ok') {
      setTotal(total);
      setCurrentPage(p);
      setDataSource(list);
    }
  };

  const delNodes = () => {};

  useEffect(() => {
    init(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getterPiNode.value.id, conditions]);

  if (!propsConfig) return null;
  const tableProps: any = {
    expandable: {
      childrenColumnName: '--',
    },
  };
  if (specialProp !== undefined) {
    tableProps.expandable.expandedRowRender = (record: any) => {
      const value = tempValueDisplay({
        propConfig: tempMap[templateId]?.prop[specialProp],
        propValue: record.tempInfo.prop[specialProp],
        propIndex: specialProp,
        sysCascade: record.prop._sys_cascade,
        userMap: userMap,
        tempMap,
        departmentMap: departmentNodeMap,
      });

      return <NewLineText value={value.toString()} />;
    };
  }

  const getScrollY = () => {
    let headerHeight = 16;
    let footerHeight = 0;
    const tableHeight = 85;
    if (selectedRowKeys.length > 0 || tools.length > 0) headerHeight = 48;
    if (pagination) footerHeight = 40;
    if (!containerRect.height) return 9999;

    return containerRect.height - footerHeight - headerHeight - tableHeight;
  };
  return (
    <div style={{ height: '100%' }} ref={containerRef}>
      {actions.length > 0 && (
        <div
          ref={domRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            position: 'absolute',
            top: -9999,
          }}
        >
          {getBtns().map((btnConfig: any) => (
            <div style={{ marginRight: 8 }} key={btnConfig.id}>
              {btnConfig.id.startsWith('system_button') ? (
                <span>{btnConfig.text}</span>
              ) : (
                <CustomButton
                  loading={false}
                  config={btnConfig}
                  forbiddenClick={false}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          padding: '8px 0',
        }}
      >
        <div>
          {selectedRowKeys.length > 0 && (
            <DeleteNodes
              orgInfo={orgInfo}
              getterPiNode={props.getterPiNode}
              checkId={selectedRowKeys}
              setCheckId={setSelectedRowKeys}
            />
          )}
        </div>
        {tools.length > 0 && (
          <AddButtonForView
            template={tempMap[templateId]}
            curViewData={{
              view_info: {
                condition: conditionV2ConvertCondition(
                  conditions.find((c: any) => c.key === 'ancestor') ===
                    undefined
                    ? conditions.concat({
                        key: 'ancestor',
                        op: 'intersect',
                        input: [props.getterPiNode.value.id],
                      })
                    : conditions,
                ).conditions,
              },
            }}
            getterPiNode={props.getterPiNode}
            templateList={orgInfo.templateList}
            userMap={userMap}
          />
        )}
      </div>
      <div
        style={{
          '--title-lineHeight': titleLineHeight + 'px',
          '--content-lineHeight': contentLineHeight + 'px',
          '--title-bg': titleBg,
          '--content-bg': contentBg,
          '--gap-bg': gapBg,
          '--horizontal-thborder': horizontalBorder || titleBg,
          '--vertical-thborder': verticalBorder || titleBg,
          '--horizontal-tdborder': horizontalBorder || contentBg,
          '--vertical-tdborder': verticalBorder || contentBg,
        }}
        className={styles.tableContainer}
      >
        <Table
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          bordered
          sticky
          rowSelection={
            allowBatchAction
              ? {
                  selectedRowKeys,
                  onChange: setSelectedRowKeys,
                }
              : undefined
          }
          {...tableProps}
          rowKey={(v: any) => v.id}
          scroll={{ x: scrollX, y: getScrollY() }}
        />
        <div
          style={{
            display: pagination ? 'flex' : 'none',
            justifyContent: 'end',
            paddingTop: 8,
            alignItems: 'center',
          }}
        >
          <div style={{ color: '#B6B8B9', marginRight: 16 }}>共{total}条</div>
          <Pagination
            showSizeChanger={false}
            onChange={(p) => init(p)}
            current={currentPage}
            total={total}
          />
        </div>
      </div>
      {/* @ts-ignore */}
      <TriggerCustomButtonNiceModal id={customButtonTriggerId} />
    </div>
  );
};
