import { assertExists } from '@linkpi/utils';
import { Archive } from 'lucide-react';
import { nanoid } from 'nanoid';

import { RegularIcon } from '@/components/IconFont';

import { WidgetIcon } from '../../components';
import { useWidgetInstance } from '../../hooks';
import type { WidgetPreivew } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';
import { GlobalConditionFilter } from './GlobalConditionFilter';
import { Setting } from './Setting';
import type { IGlobalConditionFilterConfig } from './types';

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<IGlobalConditionFilterConfig>(id);

  assertExists(widgetInstance);

  if (widgetInstance.config.conditionList.length === 0) {
    return (
      <div className="text-gray-500 gap-2 w-full pt-2 flex items-center justify-center">
        <Archive />
        暂未配置筛选条件
      </div>
    );
  }

  return <GlobalConditionFilter widgetInstance={widgetInstance as any} />;
};

export const GlobalConditionFilterWidget =
  defineSystemWidget<IGlobalConditionFilterConfig>()({
    id: 'GlobalConditionFilter',
    title: '筛选',
    icon: () => {
      return (
        <WidgetIcon
          bgColor="#FF962C"
          icon={
            <RegularIcon type="iconshaixuanqu-shaixuan" color={'#ffffffcc'} />
          }
        />
      );
    },
    basic: { defaultHeight: 2, defaultWidth: 12, minHeight: 1, minWidth: 3 },
    metadata: {
      name: '未命名筛选',
      templateId: '',
      version: 1,
      enableSubmitButton: true,
      conditionList: [{ name: '未命名', id: '1' }],
    } as any,
    preview: Preview,
    setting: Setting,
    onCreate: () =>
      Promise.resolve({
        name: '未命名筛选',
        templateId: '',
        version: 1,
        enableSubmitButton: true,
        conditionList: [{ name: '未命名', id: nanoid() }],
      }),
  });
