import { Quill } from 'react-quill-new';

import { ToolbarClassName } from '@/components/PiQuillToolbar/constants';

const Theme = Quill.import('core/theme') as any;

// 大模型下富文本 page-model
export class PMTheme extends Theme {
  constructor(quill: Quill, options: any) {
    super(quill, options);

    // react-quill wrapper
    quill.container.parentElement?.classList.add('ql-pm');
    // quill container
    quill.container.classList.add('ql-pm-container');
    // quill editor
    quill.root.classList.add('ql-pm-editor');
    // toolbar
    // 不生效 会在 toolbar 注册前执行
    quill.container
      .querySelector(`.${ToolbarClassName}`)
      ?.classList.add('ql-pm-toolbar');
  }
}
