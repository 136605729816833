import { atom, createStore } from 'jotai';
import type ReactQuill from 'react-quill-new';
import type { Quill } from 'react-quill-new';

import type { Positions } from './constants';

export const toolbarStore = createStore();

export const formatsAtom = atom<Record<string, any>>({});

export const positionsAtom = atom<Positions | null>(null);

export const rangeAtom = atom<ReactQuill.Range>(null);

export const quillAtom = atom<Quill | null>(null);
