import type { IWidget } from '@mylinkpi/widget-core';

import request from '@/utils/request';

export const getWidgetList = async (org_id: string) => {
  const res = await request<any[]>('/api/front_script/getCompList', {
    method: 'POST',
    data: { org_id },
  });

  console.log('getCompList', res);

  if (res.status === 'error') throw res;

  const list = await Promise.all(
    res.data.map(async (c) => {
      const script_id = c.script_id || c['hex(script_id)'];

      const esm = await import(
        /* webpackIgnore: true */ window.location.origin +
          '/api/front_script/download?id=' +
          script_id
      );

      console.log(esm, typeof esm);

      return {
        ...esm.default,
        type: 'custom',
        groupId: c.comp_group,
      } as IWidget<string, any> & { type: 'custom'; groupId?: string };
    }),
  );

  console.log('widget config list', list);
  return list;
};
