/* eslint-disable react-refresh/only-export-components */
import { FilterOutlined } from '@ant-design/icons';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { ConditionRuleConfigV2 } from '@linkpi/core';
import { generateAddOpId } from '@linkpi/core';
import { useImmerState } from '@shrugsy/use-immer-state';
import { Modal, Space } from 'antd';
import { cloneDeep } from 'lodash';
import type { FC } from 'react';

import { createConditionRulesTable, Image } from '@/components';
import { ConditionFilterPreview } from '@/components/ConditionFilter';
import { useOrgTemplatesSettingInfoMap } from '@/hook';

import type { SubTabsRuleConditionResultFormProp } from '../RuleModal';
import { showSubTableConditionRuleModal } from '../RuleModal';

import styles from './styles.less';

export type TableRule =
  ConditionRuleConfigV2.ConditionRuleConfigV2<ConditionRuleConfigV2.TableConditionResults>;

type TableRulesProp = {
  initialValue?: TableRule[];
} & SubTabsRuleConditionResultFormProp;

const baseMap: any = {
  order: '序号',
  index: '编号',
  title: '标题',
  sysTag: '系统标签',
  status: '状态',
  mainbody: '正文',
  statusProp_common_1: '负责人',
  statusProp_common_2: '参与者',
  statusProp_common_3: '开始时间',
  statusProp_common_4: '结束时间',
  statusProp_common_5: '备注',
  createTime: '创建时间',
  repeatTask: '重复',
  treeLevelId: '树层ID',
};

const useTableRules = (props: TableRulesProp) => {
  const { initialValue } = props;

  const [rules, setRules] = useImmerState(initialValue || []);

  // 打开多条规则
  const toggleRules = async (ruleIds: string[], checked: boolean) => {
    setRules((draft) => {
      draft?.forEach((i) => {
        if (ruleIds.includes(i.ruleId)) i.enable = checked;
      });
    });
  };

  // 删除多条规则
  const deleteRules = async (ruleIds: string[]) => {
    setRules(rules?.filter((r) => !ruleIds.includes(r.ruleId)) || []);
  };

  // 编辑规则
  const updateRule = async (record: TableRule) => {
    const res = record;

    setRules((draft) => {
      draft.forEach((i) => {
        if (i.ruleId === record.ruleId) {
          i.results = res.results;
          i.condition = res.condition;
        }
      });
    });
  };

  const addRule = async (record: TableRule, copy = false) => {
    const ruleId = generateAddOpId();
    const createTime = Date.now();
    if (copy) {
      const newRecord = { ...cloneDeep(record), ruleId, createTime };

      setRules([...rules, newRecord]);
      return;
    }

    const res = record;
    const enable = true;

    const payload = {
      ...res,
      ruleId,
      createTime,
      enable,
    };
    setRules([...rules, payload]);
  };

  return {
    loading: false,
    ruleList: rules,
    addRule,
    updateList: setRules as any,
    updateRule,
    deleteRules,
    toggleRules,
  };
};

const { ConditionRulesTable } = createConditionRulesTable(useTableRules);

const SetChildThemeRules: FC<TableRulesProp> = (props) => {
  const modal = useModal();
  const antdModal = antdModalV5(modal);
  const { tableConfig } = props;

  const ruleListInstance = useTableRules(props);

  const tempMap = useOrgTemplatesSettingInfoMap();

  // 从每条规则中找到受条件隐藏的子表格
  const getHiddenChildThemes = (results: TableRule['results']) => {
    // 不允许新增的id
    let forbidAddFlag = false;
    // 子表格属性列的展示
    const subTabPropsVisibleItems: any[] = [];

    results.forEach((i) => {
      if (i.type === 'subTabCreationVisible') forbidAddFlag = true;
      else if (i.type === 'subTabPropsVisible') {
        const { props, visible } = i;
        const item: any = {};
        if (visible === 'show') item.show = props;
        if (visible === 'hidden') item.hidden = props;
        subTabPropsVisibleItems.push(item);
      }
    });

    let resText = '';

    // 子表格隐藏
    if (forbidAddFlag) {
      resText += '不允许新增主题';
    }

    // 子表格属性列的展示
    if (subTabPropsVisibleItems.length) {
      subTabPropsVisibleItems.forEach((i) => {
        const { show, hidden } = i;
        if (!show && !hidden) return;

        const templateId: string = tableConfig.conditions.find((i) => i.key === 'templateId')
          ?.input[0];
        const template = tempMap[templateId];
        const props = template?.prop || [];
        const type = show ? '显示' : '隐藏';
        const arr = show || hidden;
        let res: any = '';

        arr.forEach((p: any) => {
          if (p.slice(0, 4) === 'prop') {
            res = res + props[p.split('_')[1]].name + ',';
          } else res = res + baseMap[p] + ',';
        });
        resText = resText + type + res;
      });
    }

    return resText;
  };

  const columns: any[] = [
    {
      title: '条件规则',
      key: 'config',
      render: (v: TableRule) => {
        const text = `${getHiddenChildThemes(v.results)}`;
        return (
          <Space>
            <Image
              src="https://www.mylinkpi.com/icon/automation_then.png"
              className="h-[22px] w-[29px] object-cover"
            />
            <div className={styles.ruleTitle}>
              当条件
              <ConditionFilterPreview value={v.condition} displayTemplate={false}>
                <span style={{ color: '#316EF5' }}>
                  <FilterOutlined />({v.condition.length - 1})
                </span>
              </ConditionFilterPreview>
              时，就{text}
            </div>
          </Space>
        );
      },
    },
  ];

  const onOk = () => {
    modal.resolve(ruleListInstance.ruleList);
    antdModal.onOk();
  };

  return (
    <Modal {...antdModal} onOk={onOk} okText="保存" centered width={1200} title="子表格的条件规则">
      <ConditionRulesTable
        immediateSorting
        title="根据属性的选值，自动展示 / 隐藏相关子表格功能。"
        ruleListInstance={ruleListInstance}
        columns={columns}
        onCreate={() => showSubTableConditionRuleModal({ mode: 'create', tableConfig })}
        onEdit={(r) => showSubTableConditionRuleModal({ mode: 'edit', config: r, tableConfig })}
      />
    </Modal>
  );
};

const SetChildThemeRulesModal = create(SetChildThemeRules);

export const getSetChildThemeRules = (options: TableRulesProp) =>
  show(SetChildThemeRulesModal, options) as Promise<TableRule[]>;
