import { useControllableValue } from 'ahooks';
import { useAtomValue } from 'jotai';

import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import type { CommonWidgetProps } from '../../constants';
import { formatsAtom } from '../../store';

interface BoldWidgetProps extends CommonWidgetProps {
  defaultValue?: boolean;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

const BoldWidget = (props: BoldWidgetProps) => {
  const { value, onChange, defaultValue = false } = props;
  const formats = useAtomValue(formatsAtom);

  const [_value, _onChange] = useControllableValue<boolean>({
    value: formats[BoldWidgetName] ?? false,
    onChange,
  });

  return (
    <div
      className={cn(
        'w-[28px] h-[28px] flex items-center justify-center rounded-[8px] cursor-pointer',
        {
          'bg-[#316EF5]': _value,
        },
      )}
      onClick={() => {
        _onChange((v) => !v);
      }}
    >
      <RegularIcon
        type="icona-rongqi5"
        size={16}
        color={_value ? '#fff' : '#000'}
      />
    </div>
  );
};

export const BoldWidgetName = 'bold';
export default BoldWidget;
