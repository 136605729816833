import { PlusOutlined } from '@ant-design/icons';
import { useControllableValue, useMemoizedFn } from 'ahooks';
import { Alert, Button, Divider, Popover, Tooltip } from 'antd';
import { diff } from 'fast-array-diff';
import { type FC, useMemo } from 'react';

import { RegularIcon } from '@/components/IconFont';
import { DataPropSelect, TemplateSelect } from '@/components/LinkPiForm';

import { MatchingItem } from './MatchingItem';
import { TargetWidgetInstanceSelect } from './TargetWidgetInstanceSelect';
import type { IGlobalConditionFilterTarget } from './types';
import { getNameByDatasourceId, getTemplateIdByDatasourceId } from './utils';

const EMPTY_ARRAY: unknown[] = [];

const FilterTargetItem: FC<{
  id: string;
  value: IGlobalConditionFilterTarget;
  templateId: string;
  propKey: string;
  onChange: (value: IGlobalConditionFilterTarget) => void;
  onDelete: () => void;
}> = ({ id, value, templateId, propKey, onChange, onDelete }) => {
  const latestTargetTemplateId = getTemplateIdByDatasourceId(id);

  const addMatching = () => {
    onChange({
      ...value,
      matchingList: [
        ...value.matchingList,
        {
          key: '',
          op: 'intersect',
          input: [],
        },
      ] as any,
    });
  };

  const updateMatching = (
    index: number,
    newV: IGlobalConditionFilterTarget['matchingList'][number],
  ) => {
    onChange({
      ...value,
      matchingList: value.matchingList.map((m, i) => (i === index ? newV : m)),
    });
  };

  const deleteMatching = (index: number) => {
    onChange({
      ...value,
      matchingList: value.matchingList.filter((_, i) => i !== index),
    });
  };

  const getWidgetInstanceName = useMemoizedFn(() => {
    return getNameByDatasourceId(id) || '未知组件';
  });

  return (
    <div className="rounded-[8px] overflow-hidden px-4 pt-2 pb-3 bg-[#F8F9FB] flex flex-col gap-2">
      <div className="flex justify-between items-center leading-none">
        <span className="font-bold">组件名称：{getWidgetInstanceName()}</span>
        <Button
          type="link"
          danger
          onClick={onDelete}
          className="pr-0"
          size="small"
        >
          <RegularIcon type="iconshanchu2" size={16} />
        </Button>
      </div>
      <Divider dashed className="my-0" />
      <div className="flex flex-col gap-3 pt-2">
        {value.templateId !== latestTargetTemplateId && (
          <Alert
            message="筛选对象修改过数据源，请检查当前匹配条件是否需要更新"
            type="warning"
            showIcon
          />
        )}
        <TemplateSelect
          noStyle
          fieldProps={{ value: latestTargetTemplateId, disabled: true }}
        />
        {value.matchingList.map((m, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="flex items-baseline">
            <MatchingItem
              templateId={latestTargetTemplateId}
              value={m}
              onChange={(v) => updateMatching(index, v)}
            />
            <DataPropSelect templateId={templateId} value={propKey} disabled />
            <Button
              className="pl-2 translate-y-[3px]"
              type="text"
              icon={
                <RegularIcon
                  type="iconshanchu1"
                  className="text-[#6B7A96]"
                  size={16}
                />
              }
              onClick={() => deleteMatching(index)}
            />
          </div>
        ))}
        <Button
          icon={<PlusOutlined />}
          onClick={addMatching}
          type="dashed"
          className="rounded-[8px] bg-transparent text-[#767C88] px-3 py-[5px] mr-6 border-[#BFC6D2] shadow-none"
        >
          匹配条件
        </Button>
      </div>
    </div>
  );
};

type FilterTargetListProps = {
  templateId: string;
  propKey: string;
  value?: IGlobalConditionFilterTarget[];
  onChange?: (v: IGlobalConditionFilterTarget[]) => void;
};

export const FilterTargetList: FC<FilterTargetListProps> = ({
  templateId,
  propKey,
  value,
  onChange,
}) => {
  const [_value, _onChange] = useControllableValue<
    FilterTargetListProps['value']
  >({ value, onChange });

  const onItemChange = useMemoizedFn(
    (id: string, item: IGlobalConditionFilterTarget) => {
      _onChange(_value?.map((t) => (t.id === id ? item : t)));
    },
  );

  const onItemDelete = useMemoizedFn((id: string) => {
    _onChange(_value?.filter((t) => t.id !== id));
  });

  const idList = useMemo(() => _value?.map((t) => t.id), [_value]);

  const onIdListChange = useMemoizedFn((newList: string[]) => {
    const diffResult = diff(idList || [], newList);
    const addList = diffResult.added;
    const removeList = diffResult.removed;

    if (removeList.length) {
      _onChange(() => {
        const l = _value?.filter((t) => !removeList.includes(t.id));
        return l;
      });
    }

    if (addList.length) {
      _onChange((v) => [
        ...(v || []),
        ...addList.map((id) => {
          const latestTargetTemplateId = getTemplateIdByDatasourceId(id);
          return {
            id,
            matchingList: [],
            templateId: latestTargetTemplateId,
          };
        }),
      ]);
    }
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <div className="flex gap-1 items-center">
          <span className="font-bold">筛选对象</span>
          <Tooltip
            title="请选择筛选对象，并对同类型属性进行关联以筛选数据"
            trigger={['hover']}
          >
            <span>
              <RegularIcon
                type="iconchangyong-bangzhu"
                color="#316EF5"
                size={16}
              />
            </span>
          </Tooltip>
        </div>
        <Popover
          content={
            <TargetWidgetInstanceSelect
              value={idList}
              onChange={onIdListChange}
            />
          }
          trigger="click"
        >
          <Button
            icon={<PlusOutlined />}
            type="dashed"
            className="rounded-[8px] text-[#767C88] px-3 py-[5px] border-[#BFC6D2] shadow-none"
          >
            筛选对象
          </Button>
        </Popover>
      </div>
      <div className="flex flex-col gap-3">
        {_value?.map((t) => (
          <FilterTargetItem
            key={t.id}
            id={t.id}
            value={t}
            templateId={templateId}
            propKey={propKey}
            onChange={(v) => onItemChange(t.id, v)}
            onDelete={() => onItemDelete(t.id)}
          />
        ))}
        {(!_value || !_value.length) && (
          <div className="flex items-center justify-center h-[136px] rounded-[8px] text-[#C9D0D9] bg-[#F8F9FB]">
            暂无筛选对象，请先添加
          </div>
        )}
      </div>
    </div>
  );
};
