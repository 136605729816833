import { useControllableValue } from 'ahooks';
import { useAtomValue } from 'jotai';

import { ColorPicker } from '@/components/ColorPicker';
import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import type { CommonWidgetProps } from '../../constants';
import { formatsAtom } from '../../store';

interface ColorWidgetProps extends CommonWidgetProps {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const ColorWidget = (props: ColorWidgetProps) => {
  const {
    value,
    onChange,
    defaultValue = false,
    beforeChange,
    endChange,
  } = props;
  const formats = useAtomValue(formatsAtom);

  const [_value, _onChange] = useControllableValue<string>({
    value: formats[ColorWidgetName] ?? '#000',
    onChange,
  });

  return (
    <div
      className={cn(
        'w-[28px] h-[28px] flex items-center justify-center cursor-pointer',
      )}
    >
      <ColorPicker
        onPopoverOpenChange={(open) => {
          if (open) {
            beforeChange?.();
          } else {
            endChange?.();
          }
        }}
        value={_value}
        onChange={(c) => {
          const _color = c as unknown as string;
          // _onChange(parseRgba(_color));
          _onChange(_color);
        }}
        itemRender={(backgroundColor) => (
          <div className="flex flex-col w-[28px] h-[28px] items-center justify-center">
            <RegularIcon type="iconwenzi" size={18} className="-mb-1.5" />
            <span
              className="w-4 h-[5px] rounded-full"
              style={{ backgroundColor }}
            />
          </div>
        )}
      />
    </div>
  );
};

export const ColorWidgetName = 'color';
export default ColorWidget;
