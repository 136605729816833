import type { PageModelConfig } from '@linkpi/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMount, useUnmount } from 'ahooks';
import { Provider } from 'jotai';
import { DevTools } from 'jotai-devtools';
import { forwardRef, memo, Suspense, useImperativeHandle } from 'react';

import PageLoading from '@/components/PageLoading';
import { useCurrentOrgId } from '@/hook';

import { DataSourceSettingNiceModal } from '../components';
import { useDebugEditor, useEditor } from '../hooks';
import type { EditorModel } from '../models';
import { getWidgetList } from '../services';
import { Layout } from './Layout';

export type PageModelEditorRef = {
  getEditor: () => EditorModel;
};

type EditorProps = {
  initialValue?: PageModelConfig;
};
const _Editor = forwardRef<PageModelEditorRef, EditorProps>(
  ({ initialValue }, ref) => {
    useImperativeHandle(ref, () => ({
      getEditor() {
        return editor;
      },
    }));

    const editor = useEditor();
    const orgId = useCurrentOrgId();

    const { data: componentList } = useSuspenseQuery({
      queryKey: ['fetchCustomComponents', orgId],
      queryFn: async () => {
        const list = await getWidgetList(orgId);
        console.log('fetchCustomComponents finished');
        return list;
      },
      staleTime: 5 * 60_000,
      gcTime: 60 * 60_000,
    });

    componentList.forEach((item) => {
      /**
       * NOTE
       *
       * 会自动去重，所以不需要额外判断
       */
      editor.widgetManager.addWidget(item, item.groupId);
    });

    useMount(() => {
      /**
       * 隐藏空间专用的分组
       */
      editor.widgetManager.updateSystemGroups((d) => {
        d.forEach((group) => {
          if (
            group.items &&
            group.orgIds &&
            Array.isArray(group.orgIds) &&
            !group.orgIds.includes(orgId)
          ) {
            group.items = [];
          }
        });
      });

      if (initialValue) {
        editor.initData(initialValue);
      }

      console.log('mounted');
    });

    useUnmount(() => {
      editor.dispose();
    });

    useDebugEditor();

    return <Layout />;
  },
);

const _EditorWrapper = forwardRef<PageModelEditorRef, EditorProps>(
  (props, ref) => {
    return (
      <Provider>
        <Suspense fallback={<PageLoading tip="加载中" />}>
          <_Editor ref={ref} {...props} />
        </Suspense>
        <DevTools />
        <DataSourceSettingNiceModal />
      </Provider>
    );
  },
);

export const PageModelEditor = memo(_EditorWrapper);
