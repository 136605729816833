import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { useMemoizedFn } from 'ahooks';
import { Modal } from 'antd';
import { useMemo, useRef, useState } from 'react';

import type { IDashboardComponentFrom } from '@/pages/home/components/View/DashBoard/ComponentForm';
import { getInitForm } from '@/pages/home/components/View/DashBoard/ComponentForm';

import type { IStatisticForm, StatisticFormRef } from './Form';
import { StatisticForm } from './Form';
import type { IStatisticSetting } from './Setting';

import styles from './Modal.less';

type SettingModalProps<T extends IDashboardComponentFrom['type']> = {
  title?: string;
} & Pick<IStatisticForm<T>, 'type' | 'viewType'>;

const _StatisticSettingModal = <T extends IDashboardComponentFrom['type']>({
  title,
  type,
  viewType,
}: SettingModalProps<T>) => {
  const modal = useModal();
  const statisticFormRef = useRef<StatisticFormRef | null>(null);

  const initComponentForm = useMemo(() => {
    const result = getInitForm(type);
    if (type === 'chart') {
      result.viewType = viewType;
    }
    return result as NonNullable<IStatisticForm<T>['value']>;
  }, [type, viewType]);

  const [componentForm, setComponentForm] =
    useState<NonNullable<IStatisticForm<T>['value']>>(initComponentForm);

  const onOk = useMemoizedFn(async () => {
    if (type === 'chart') {
      await statisticFormRef.current?.vaildateChartForm();
    }

    modal.resolve(componentForm);
    modal.hide();
  });

  const onCanel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <Modal
      {...antdModalV5(modal)}
      onOk={onOk}
      onCancel={onCanel}
      centered
      title={title}
      className={styles.modal}
      width={900}
    >
      <StatisticForm<T>
        ref={statisticFormRef}
        mode="add"
        onChange={setComponentForm}
        value={componentForm}
        viewType={viewType}
        type={type}
      />
    </Modal>
  );
};

export const StatisticSettingModalSymbol = '$$StatisticSettingModalSymbol';
export const StatisticSettingModal = create(_StatisticSettingModal);

export const showStatisticSettingModal = async <T extends IDashboardComponentFrom['type']>(
  o: SettingModalProps<T>,
) => {
  const statistic = await show(StatisticSettingModalSymbol, o);
  return { statistic } as IStatisticSetting<T>;
};
