import request from '@/utils/request';

export interface CommonParams {
  /**
   * 空间ID
   */
  org_id: string;
}

export interface CreateApiDataSourceParams extends CommonParams {
  auth?: CreateApiDataSourceParamsAuth;
  /**
   * 请求地址
   */
  base_url: string;
  body?: Body[];
  data: CreateApiDataSourceParamsData;
  headers?: CreateApiDataSourceParamsHeader[];
  /**
   * 请求方式
   */
  method: string;
  /**
   * 名称
   */
  name: string;
  params?: CreateApiDataSourceParamsParam[];
  /**
   * 路径
   */
  path: string;
  /**
   * 超时时间
   */
  timeout?: number;
}

export interface CreateApiDataSourceParamsAuth {
  apiKey?: ApiKey;
  basic?: CreateApiDataSourceParamsBasic;
  bearer?: string;
  /**
   * 是否支持查询时修改
   */
  changeable?: boolean;
  /**
   * 鉴权类型，目前支持basic, bearer, apiKey
   */
  type: string;
}

export interface ApiKey {
  /**
   * headers/params
   */
  addTo: string;
  key: string;
  value: string;
}

export interface CreateApiDataSourceParamsBasic {
  password: string;
  username: string;
  [property: string]: any;
}

export interface CreateApiDataSourceParamsBody {
  /**
   * 是否可修改（查询时）
   */
  changeable: boolean;
  /**
   * 默认值
   */
  default?: string;
  /**
   * 参数名
   */
  key: string;
  /**
   * 是否必需
   */
  required: boolean;
}

export interface CreateApiDataSourceParamsData {
  /**
   * 返回错误信息的参数路径
   */
  message?: string[];
  /**
   * 返回数据的参数路径
   */
  result: string[];
  /**
   * 返回数据总数的参数路径
   */
  total?: string[];
  result_params: ResultParam[];
}

export interface ResultParam {
  desc?: string;
  key: string;
  type: string;
  required: string;
}

export interface CreateApiDataSourceParamsHeader {
  changeable: boolean;
  default?: string;
  key: string;
  required: boolean;
}

export interface CreateApiDataSourceParamsParam {
  changeable: boolean;
  default?: string;
  key: string;
  required: boolean;
}

export const createApiDataSource = async (params: CreateApiDataSourceParams) => {
  return request<string>('/api/apiDataSource/insert', {
    method: 'POST',
    data: params,
  });
};

export interface UpdateApiDataSourceParams extends CreateApiDataSourceParams {
  api_id: string;
}

export const updateApiDataSource = async (params: UpdateApiDataSourceParams) => {
  return request<string>('/api/apiDataSource/update', {
    method: 'POST',
    data: params,
  });
};

export interface DeleteApiDataSourceParams extends CommonParams {
  api_id: string;
}

export const deleteApiDataSource = async (params: DeleteApiDataSourceParams) => {
  return request<string>('/api/apiDataSource/delete', {
    method: 'POST',
    data: params,
  });
};

export interface ApiGroupItem extends Omit<CreateApiGroupParams, 'org_id'> {
  create_time?: string;
  group_id: string;
}

export const getApiGroupList = async (params: CommonParams) => {
  return request<ApiGroupItem[]>('/api/apiGroup/query', {
    method: 'POST',
    data: params,
  });
};

export interface ApiSourceListParams extends CommonParams {
  /**
   * 分组ID
   */
  group_id?: string;
}

export interface ApiDataSourceItem extends CreateApiDataSourceParams {
  group_id: string | null;
  api_id: string;
}

export const getApiSourceList = async (params: ApiSourceListParams) => {
  return request<ApiDataSourceItem[]>('/api/apiDataSource/query', {
    method: 'POST',
    data: params,
  });
};

export interface CreateApiGroupParams extends CommonParams {
  auth?: CreateApiDataSourceParamsAuth;
  /**
   * 请求地址
   */
  base_url?: string;
  /**
   * 名称
   */
  name: string;
}

export const createApiGroup = async (params: CreateApiGroupParams) => {
  return request<string>('/api/apiGroup/insert', {
    method: 'POST',
    data: params,
  });
};

export interface UpdateApiGroupParams extends CreateApiGroupParams {
  group_id: string;
}

export const updateApiGroup = async (params: UpdateApiGroupParams) => {
  return request<string>('/api/apiGroup/update', {
    method: 'POST',
    data: params,
  });
};

export interface DeleteApiGroupParams extends CommonParams {
  group_id: string;
}

export const deleteApiGroup = async (params: DeleteApiGroupParams) => {
  return request<string>('/api/apiGroup/delete', {
    method: 'POST',
    data: params,
  });
};

export interface ApplyApiGroupParams extends CommonParams {
  group_id: string;
}

export const applyApiGroup = async (params: ApplyApiGroupParams) => {
  return request<string>('/api/apiGroup/apply', {
    method: 'POST',
    data: params,
  });
};

export interface MoveToApiGroupParams extends CommonParams {
  group_id: string;
  api_id: string[];
  apply?: boolean;
}

export const moveToApiGroup = async (params: MoveToApiGroupParams) => {
  return request<string>('/api/apiDataSource/group', {
    method: 'POST',
    data: params,
  });
};

export interface TestApiDataSourceParams extends CommonParams {
  api_id: string;
}

export const testApiDataSource = async (params: TestApiDataSourceParams) => {
  return request<boolean>('/api/apiDataSource/testConnect', {
    method: 'POST',
    data: params,
  });
};
