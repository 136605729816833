import {
  CaretDownOutlined,
  CaretRightOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Input } from 'antd';
import {
  type FC,
  Fragment,
  isValidElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';

import { PiButton } from '@/components/Button';
import { cn } from '@/utils/utils';

import { GroupTitle, WidgetBox } from '../../components';
import { useCustomWidgets } from '../../hooks';
import { setupDragIn } from '../../utils';

export const CustomWidgets: FC = () => {
  const widgetGroups = useCustomWidgets();
  const [search, setSearch] = useState('');

  const result = useMemo(() => {
    if (!search) return widgetGroups;

    return widgetGroups?.map((group) => {
      return {
        ...group,
        items: group.items.filter((item) => {
          if (!item.title) return false;

          if (typeof item.title === 'string') {
            return item.title.includes(search);
          }

          if (isValidElement(item.title)) {
            const text = reactElementToJSXString(item.title);
            return text.includes(search);
          }

          return false;
        }),
      };
    });
  }, [search, widgetGroups]);

  useEffect(() => {
    setupDragIn();
  }, [result]);

  const [collapsedGroups, setCollapsedGroups] = useState<
    Record<string, boolean>
  >({});

  const toggleGroup = (groupId: string) => {
    setCollapsedGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="px-2 flex items-center gap-1">
        <Input
          placeholder="搜索组件"
          suffix={<SearchOutlined />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <PiButton icon={<SettingOutlined />} type="ghost" />
      </div>
      <div>
        {result?.map(
          (group) =>
            !!group.items.length && (
              <Fragment key={group.id}>
                <GroupTitle type="secondary">
                  <div
                    onClick={() => toggleGroup(group.id)}
                    className="flex items-center text-[#242D3F] cursor-pointer gap-2"
                  >
                    {group.id === 'defaultCustomWidgetGroups' || (
                      <span className="font-bold">{group.title}</span>
                    )}
                    <span>
                      {collapsedGroups[group.id] ? (
                        <CaretRightOutlined />
                      ) : (
                        <CaretDownOutlined />
                      )}
                    </span>
                  </div>
                </GroupTitle>
                <div
                  className={cn(
                    collapsedGroups[group.id] &&
                      'h-0 invisible pointer-events-none',
                  )}
                >
                  {group.items.map((item) => {
                    return (
                      <WidgetBox
                        basic={item.basic}
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        icon={item.icon}
                        layout={group.layout}
                      />
                    );
                  })}
                </div>
              </Fragment>
            ),
        )}
      </div>
    </div>
  );
};
