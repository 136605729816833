import type { JsonObject } from 'type-fest';

import type { IWidget } from '../models/WidgetManager';

/**
 * 定义组件，并且做类型检查
 *
 * 如果需要给 Setting 或者 Preview 统一加上 Wrapper
 * 或者统一添加默认配置等，可以用在此处修改
 *
 * 如果你对接口的形式（curry）迟疑，take it
 * 问就是截止 2023-07-13，ts 最新版本 5.1 任然没有支持
 * partial type rgument inference
 *
 * 相关 issue 详见：
 * - https://github.com/microsoft/TypeScript/issues/26242
 * - https://github.com/microsoft/TypeScript/pull/26349
 */
export const defineSystemWidget =
  <T extends JsonObject = JsonObject>() =>
  <P extends string>(config: Omit<IWidget<P, T>, 'type'>) => {
    return { ...config, type: 'system' as const };
  };

export const shallowCloneObj = (obj: any) => {
  const newObj: any = {};

  Object.keys(obj).forEach((k) => {
    newObj[k] = obj[k];
  });

  return newObj;
};
