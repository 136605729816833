import type { PageModelConfig } from '@linkpi/core';

export const defaultStoreVersion = '1.0.0';

export const dragItemClassName = 'page-model-component-drag-item' as const;

export const initalPageConfig: PageModelConfig['pageConfig'] = {
  contentLayoutMode: 'default',
  styleMode: 'light',
  bgColor: '#fff',
};
