import type { CurrentUser } from '@linkpi/core';
import { generateId } from '@linkpi/core';
import { Button, Form, Input } from 'antd';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { RegularIcon } from '@/components/IconFont';
import { useOrgInfo } from '@/hook';

import type {
  IDataIndicatorIndexType,
  IDataIndicatorWidget,
} from '../../widgets';
import { createInitialValue as createDefaultDataSourceConfig } from '../DataSourceSetting';
import { EditSortIndexButton } from './EditSortIndexButton';
import { PreviewCards } from './PreviewCards';
import { SettingPanel } from './SettingPanel';

import styles from './Form.less';
const defaultIndexDataIconPath =
  'https://ljp-static.oss-cn-hangzhou.aliyuncs.com/defaultIndexDataIcon.png';

export const getDefaultIndexData = (orgInfo: CurrentUser.OrgInfo) => {
  const defaultIndexId = generateId();

  return {
    id: defaultIndexId,
    data: {
      name: '指标',
      dataSource: createDefaultDataSourceConfig(orgInfo.rootId),
      subitems: [getDefaultIndexSubitemData()],
      showIcon: true,
      iconPlacement: 'left',
      iconSrc: defaultIndexDataIconPath,
      styles: {
        propName: {
          fontSize: 16,
          color: {
            a: 1,
            r: 255,
            g: 255,
            b: 255,
          },
        },
        propValue: {
          fontSize: 24,
          bold: true,
          color: {
            a: 1,
            r: 255,
            g: 255,
            b: 255,
          },
        },
        statisticalProp: {
          fontSize: 14,
          color: {
            a: 0.5,
            r: 255,
            g: 255,
            b: 255,
          },
        },
        background: 'linear-gradient( 135deg, #5065F7 0%, #8198FF 100%)',
        spacingType: 'normal',
      },
    } as IDataIndicatorIndexType,
  };
};
export const getDefaultIndexSubitemData = () => {
  return {
    propIndex: undefined,

    showStatistical: false,
    statisticalName: '',
    statisticalPropIndex: undefined,

    showSuffixIcon: false,
    suffixIconPropIndex: undefined,
  };
};

/**
 * 创建和编辑 都操作这个Form
 */
const DataIndicatorFormForward: ForwardRefRenderFunction<
  {
    selectIndex: (id: string) => void;
  },
  {
    onCancel?: () => void;
  }
> = (props, ref) => {
  useImperativeHandle(ref, () => ({
    selectIndex: (id) => {
      setEditIndexId(id);
    },
  }));
  const { onCancel } = props;
  const [orgInfo] = useOrgInfo();

  const form = Form.useFormInstance<IDataIndicatorWidget>();
  const indexes = Form.useWatch('indexes', form);
  const indexSort = Form.useWatch('indexSort', form);
  console.log('form ibndexes', Object.keys(indexes || {}));
  const [editIndexId, setEditIndexId] = useState('');

  useEffect(() => {
    // 选中时  1 滚动 2 打开配置
    if (editIndexId) {
      document.querySelector('#indexCard_' + editIndexId)?.scrollIntoView();
    }
  }, [editIndexId]);

  const handleAddIndexData = () => {
    if (!orgInfo) return;

    const newIndexData = getDefaultIndexData(orgInfo);
    form.setFieldsValue({
      indexSort: [...indexSort, newIndexData.id],
      indexes: { ...indexes, [newIndexData.id]: newIndexData.data },
    });
    setTimeout(() => {
      setEditIndexId(newIndexData.id);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.head}>
          <EditSortIndexButton />

          <Button
            type="primary"
            style={{ marginLeft: 12 }}
            onClick={handleAddIndexData}
            icon={
              <RegularIcon
                size={16}
                type="iconchangyong-tianjia"
                color="#fff"
                className=" align-middle"
              />
            }
          >
            添加指标
          </Button>
        </div>
        <div className={styles.preview}>
          <div className={styles.previewWrapper}>
            {/* {indexWithSort.map((indexData) => renderIndex(indexData))} */}
            <PreviewCards
              data={{ indexes, indexSort }}
              selectable
              selectId={editIndexId}
              onSelect={(id) => setEditIndexId(id)}
            />
          </div>
        </div>
      </div>
      <SettingPanel
        editIndexId={editIndexId}
        onCancal={onCancel}
        onEditIndexIdChange={setEditIndexId}
      />

      <Form.Item name="indexSort" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="indexes" hidden>
        <Input />
      </Form.Item>
    </div>
  );
};

export const DataIndicatorForm = forwardRef(DataIndicatorFormForward);
