import { RegularIcon } from '@/components/IconFont';

import pie from '../../assets/pie.png';
import type { IStatisticSetting } from '../../components';
import {
  showStatisticSettingModal,
  StatisticPreview,
  StatisticSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IPieChartWidget = IStatisticSetting<'chart'>;

const Preview: WidgetPreivew = ({ id }) => {
  return (
    <StatisticPreview id={id}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'hidden',
          justifyContent: 'center',
        }}
      >
        <img src={pie} style={{ display: 'inline-block', height: '100%' }} />
      </div>
    </StatisticPreview>
  );
};

const Setting: WidgetSetting = () => {
  return <StatisticSetting type="chart" viewType={5} />;
};

export const PieChartWidget = defineSystemWidget<IPieChartWidget>()({
  id: 'PieChart',
  title: '饼图',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#21BF46"
        icon={<RegularIcon type="iconshitu-bingtu" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 7,
    defaultWidth: 6,
    minWidth: 6,
    minHeight: 7,
    settingWidth: 540,
  },
  metadata: {} as any,
  preview: Preview,
  setting: Setting,
  onCreate: () =>
    showStatisticSettingModal<'chart'>({
      title: '添加饼图',
      type: 'chart',
      viewType: 5,
    }),
});
