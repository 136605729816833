import { useControllableValue } from 'ahooks';
import { message } from 'antd';
import { useAtomValue } from 'jotai';
import { nanoid } from 'nanoid';
import { match } from 'ts-pattern';

import { RegularIcon } from '@/components/IconFont';
import { useActiveWidgetInstance } from '@/components/PageModelEditor/src/hooks';
import type { IRichTextConfig } from '@/components/PageModelEditor/src/widgets/RichTextWidget/Setting';
import { cn } from '@/utils/utils';

import type { CommonWidgetProps } from '../../constants';
import { formatsAtom, positionsAtom, rangeAtom } from '../../store';
import { DefaultPropEmbed } from './embed';
import { openPropModal } from './modal';

/**
 * 目前仅支持在 大模型 中使用
 */
interface PropWidgetProps extends CommonWidgetProps {
  value?: any;
  onChange?: (value: any) => void;
}

const PropWidget = (props: PropWidgetProps) => {
  const {
    value,
    onChange,
    beforeChange,
    endChange,
    currentTemplate,
    quill,
    templateList,
  } = props;
  const formats = useAtomValue(formatsAtom);
  const positions = useAtomValue(positionsAtom);
  const range = useAtomValue(rangeAtom);
  const widgetInstance = useActiveWidgetInstance<IRichTextConfig>();

  const [_value, _onChange] = useControllableValue<any | null>({
    value: formats[PropWidgetName],
    onChange,
  });

  const handleClick = async () => {
    // beforeChange?.();
    if (range) {
      const tempId = widgetInstance?.config?.conditions.find(
        (c) => c.key === 'templateId',
      )?.input?.[0];

      if (!tempId) {
        message.warn('请先配置数据源!');
        return;
      }
      const values = (await openPropModal({
        tempId,
      })) as {
        tempId: string;
        props: { name: string; value: string; type: string }[];
      };
      let insertIndex = range.index;
      values.props.forEach((attr, index) => {
        if (index > 0) {
          insertIndex += 1;
        }
        match(attr)
          .with({ type: 'custom' }, () => {
            quill.insertEmbed(insertIndex, 'ThemeAttrSpan', {
              ...DefaultPropEmbed,
              label: `{${attr.name}}`,
              index: attr.value,
              templateId: values.tempId,
              templateList,
              id: nanoid(),
            });
          })
          .otherwise(() => {
            quill.insertEmbed(insertIndex, 'ThemeAttrSpan', {
              ...DefaultPropEmbed,
              label: `{${attr.name}}`,
              index: attr.value,
              id: nanoid(),
            });
          });
      });
      quill.insertText(insertIndex + 1, ' ');
      // quill.setSelection(insertIndex + 1);
      // _onChange(values);
    }

    // endChange?.();
  };

  return (
    <>
      <div
        className={cn(
          'w-8 h-8 flex items-center justify-center rounded-[8px] cursor-pointer  border border-solid border-[#ebedf0]',
          {
            'bg-[#316EF5]': _value,
          },
        )}
        onClick={handleClick}
      >
        <RegularIcon
          type="icontianjia1"
          size={16}
          color={_value ? '#fff' : '#000'}
        />
      </div>
    </>
  );
};

export const PropWidgetName = 'prop';
export default PropWidget;
