import type { ViewList } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import type { FC, ReactNode } from 'react';
import { match } from 'ts-pattern';

import { useCurrentOrgId, useNodeTreeData, useOrgInfo } from '@/hook';
import { cn } from '@/utils/utils';

import ConditionFilter from './ConditionFilter';
import type { ViewConditionFormPropsType } from './types';

import styles from './ConditionFilterPanel.less';

export type ConditionFilterPanelProp = {
  className?: string;
  value: ViewList.ViewconditionV2;
  onChange?: (
    v: ViewList.ViewconditionV2,
    changeData: Parameters<
      NonNullable<ViewConditionFormPropsType['onChange']>
    >[0],
  ) => void;
  type?: 'vertical';
  editable?: boolean;
  enableRoot?: boolean;
  extraPopcontent?: ReactNode;
} & Pick<
  ViewConditionFormPropsType,
  | 'addText'
  | 'allowTempIds'
  | 'allowKey'
  | 'allowPropTypes'
  | 'displayTemplate'
  | 'displayParentId'
  | 'displayHeader'
  | 'entry'
  | 'addButtonProps'
  | 'displayAddBtn'
  | 'extraActionsRender'
  | 'experimental_disableHistoryStatus'
  | 'experimental_disableOpList'
  | 'experimental_userLiteMode'
  | 'experimental_enableUserProperty'
>;

/**
 * 一个会自动取数据的 ConditionFilte
 */
export const ConditionFilterPanel: FC<ConditionFilterPanelProp> = ({
  className,
  value,
  onChange,
  type,
  editable = true,
  enableRoot,
  ...restProps
}) => {
  const [_value, onConditionFilterChange] = useControllableValue<
    ConditionFilterPanelProp['value']
  >({ value, onChange });

  const orgId = useCurrentOrgId();
  const [orgInfo] = useOrgInfo(orgId);
  const nodeTreeData = useNodeTreeData({ enableRoot });

  if (!orgInfo) return null;

  return (
    <ConditionFilter
      {...restProps}
      className={cn(
        className,
        match(type)
          .with('vertical', () => styles.vertical)
          .otherwise(() => null),
      )}
      templateUsage="fullAccess"
      defaultConditions={_value}
      editable={editable}
      onChange={(v) => onConditionFilterChange(v.conditionsV2, v)}
      getTreeData={() => nodeTreeData}
    />
  );
};
