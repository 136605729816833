import type { FC } from 'react';
import { useMemo } from 'react';

import type { CheckboxSelectProp } from '@/components';
import { CheckboxSelect } from '@/components';
import { useOrgUserGroup } from '@/hook';

export const GroupSelect: FC<
  CheckboxSelectProp & { disabledIds?: string[] }
> = ({ disabledIds, ...props }) => {
  const groups = useOrgUserGroup();
  const options = useMemo(() => {
    return groups.map((i) => ({
      label: i.group_name,
      value: `group_${i.group_id}`,
      disabled: disabledIds?.includes(i.group_id),
    }));
  }, [groups]);

  return (
    <div style={{ padding: '0 8px', height: '100%', overflow: 'auto' }}>
      <CheckboxSelect {...props} options={options} />
    </div>
  );
};
