import type { CurrentUser, ViewList } from '@linkpi/core';
import { getViewSettingOptionWithGroup } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { compose, groupBy, isNil, map, toPairs } from 'ramda';
import { useMemo } from 'react';

import { useOrgInfo, useQuoteOptions } from '@/hook';
import { getRealPropConfig } from '@/utils/utils';

import { conditionKeyConvertV2Key } from '../utils';

/**
 * 构造筛选用的数据
 */
export const useConditionOptions = ({
  curTemplate,
  curTemplateList,
  tempMap,
  allowKey,
  allowPropTypes,
  experimental_onlyShowAuxInEnumProp = false,
}: {
  curTemplate?: CurrentUser.TemplateInfo;
  curTemplateList?: CurrentUser.TemplateInfo[];
  tempMap: Record<string, CurrentUser.TemplateInfo>;
  allowKey?: ViewList.ViewconditionV2Item['key'][];
  allowPropTypes?: CurrentUser.propType[];
  experimental_onlyShowAuxInEnumProp?: boolean;
}) => {
  const [orgInfo] = useOrgInfo();

  assertExists(orgInfo, 'orgInfo');

  const quoteOptionsMode = experimental_onlyShowAuxInEnumProp
    ? ('labelInValue' as const)
    : ('onlyValue' as const);

  const [quoteOptions] = useQuoteOptions(
    orgInfo.orgId,
    curTemplate,
    undefined,
    {
      mode: quoteOptionsMode,
    },
  );

  const [filterConditionConfigMap, filterConditionConfigGroupKeyMap] =
    useMemo(() => {
      // if (!curTemplate) return [{}, {}];
      const templateList = curTemplateList
        ? curTemplateList
        : ([curTemplate].filter(Boolean) as CurrentUser.TemplateInfo[]);
      const { option, fieldOptionGroup } = getViewSettingOptionWithGroup(
        templateList,
        orgInfo,
        tempMap,
        quoteOptions,
      );
      return [option, fieldOptionGroup];
    }, [curTemplateList, curTemplate, orgInfo, tempMap, quoteOptions]);

  // 转getViewSettingOptionWithGroup 的option的key
  const filterConditionConfigMapV2 = useMemo(() => {
    return Object.keys(filterConditionConfigMap).reduce(
      (a, b) => {
        const v2Key = conditionKeyConvertV2Key(b);
        a[v2Key] = filterConditionConfigMap[b];

        if (v2Key === 'status') {
          let options = a[v2Key].options.map((x) => {
            const [templateId, fixStatusOptionKey] = x.key.split('_');
            return {
              ...x,
              templateId,
              key: Number(fixStatusOptionKey),
            };
          });
          if (curTemplateList) {
            options = compose(
              map((i) => ({
                name: tempMap[i[0]].name,
                options: i[1],
              })),
              toPairs,
              groupBy((o: (typeof options)[number]) => o.templateId),
            )(options) as any;
          }
          a[v2Key].options = options as any;
        }

        return a;
      },
      {} as typeof filterConditionConfigMap,
    );
  }, [curTemplateList, filterConditionConfigMap, tempMap]);

  const filterConditionConfigGroupKeyMapV2 = useMemo(() => {
    return Object.keys(filterConditionConfigGroupKeyMap).reduce(
      (a, b) => {
        a[b] = {
          ...filterConditionConfigGroupKeyMap[b],
          optionKeys: filterConditionConfigGroupKeyMap[b].optionKeys.map((x) =>
            conditionKeyConvertV2Key(x),
          ),
        };
        return a;
      },
      {} as typeof filterConditionConfigGroupKeyMap,
    );
  }, [filterConditionConfigGroupKeyMap]);

  /**
   * 筛选条件 选项
   */
  const filterConditionOptions = useMemo(() => {
    return Object.values(filterConditionConfigGroupKeyMapV2)
      .map((x) => {
        return {
          label: x.name,
          options: x.optionKeys
            .filter((y) => {
              const [type, index] = y.split('_');
              let valid = true;

              if (allowKey && valid) {
                valid = allowKey.includes(type);
              }

              if (allowPropTypes && valid && !isNil(index) && curTemplate) {
                const config = getRealPropConfig(
                  curTemplate.prop[Number(index)],
                  tempMap,
                );

                if (typeof config !== 'string' && config?.type)
                  valid = allowPropTypes.includes(config.type);
              }

              return valid;
            })
            .map((y) => {
              return {
                label: filterConditionConfigMapV2[y].name,
                value: conditionKeyConvertV2Key(y),
              };
            }),
        };
      })
      .filter((i) => i.options.length);
  }, [
    allowKey,
    allowPropTypes,
    curTemplate,
    filterConditionConfigGroupKeyMapV2,
    filterConditionConfigMapV2,
    tempMap,
  ]);

  return {
    filterConditionOptions,
    filterConditionConfigMap,
    filterConditionConfigMapV2,
  };
};
