import { PlusOutlined } from '@ant-design/icons';
import type { ProFormInstance } from '@ant-design/pro-form';
import type {
  ApiResponse,
  ChartsInfo,
  CurrentUser,
  ViewChartsType,
  ViewList,
} from '@linkpi/core';
import {
  getQuoteOriginProp,
  getViewSettingOptionWithGroup,
} from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useMemoizedFn } from 'ahooks';
import type { SelectProps } from 'antd';
import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Segmented,
  Select,
} from 'antd';
import type { ForwardRefRenderFunction } from 'react';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { match, P } from 'ts-pattern';

import { RegularIcon } from '@/components';
import ConditionFilter from '@/components/ConditionFilter';
import { conditionConvertConditionV2 } from '@/components/ConditionFilter/utils';
import IndicatorMatchingSetting from '@/components/IndicatorMatchingSetting';
import { CustomUpload } from '@/components/PublishTemplateModal/config';
import {
  useNodeTreeData,
  useOrgInfo,
  useOrgTempMap,
  useOrgUserGroup,
  useOrgUserList,
  useOrgUserMap,
} from '@/hook';
import useFilterTemplateList from '@/hook/useFilterTemplateList';
import { useViewConfig } from '@/hook/useOrgViewListRequest';
import { useQuoteOptions } from '@/hook/useQuoteOptions';
import { getStatByPropType } from '@/pages/pi/grid/react/useGridAttrs';
import { cn, getAllowTemplates, isQuotePropCanCount } from '@/utils/utils';

import ChartForm from '../../ChartForm';
import type { ChartFormDataType } from '../../ChartForm/utils';
import type { UseStatType } from '../../ListTable/useStat';
import type { ComponentTypeInfo, IDashboardComponentFrom } from '.';

import styles from './styles.less';

const { Option } = Select;

export const DEFAULT_IMG_KEY = 'default_img';
export const DEFAULT_IMG =
  'https://test-inner-oss.linkerpi.com/storage/d9f9ef619c31420a8661cf13119de6b4.png?OSSAccessKeyId=LTAI4GJJjJfFS4KtHxuFw5tZ&Expires=4937657533&Signature=PDAeRti2IAzTOXksfMgYehMDesc%3D';

const getStatName = (statKey: UseStatType) => {
  switch (statKey) {
    case 'sum':
      return '求和';
    case 'ave':
      return '求平均';
    case 'max':
      return '求最大';
    case 'min':
      return '求最小';
    case 'count':
      return '计数';
    default:
      return '';
  }
};

type AddFormPropsType = {
  /**
   * 节点粒度
   * */
  node?: GetterPiNode;
  /**
   * 主题类型粒度
   * */
  templateId?: string;
  customAllowTempIds?: string[];
  componentTypeInfo: ComponentTypeInfo;
  componentForm: IDashboardComponentFrom & {
    // 专属于大模型
    chartInfo?: null | ChartsInfo;
  };
  editType: 'add' | 'edit';
  setComponentFormItem: <U extends keyof IDashboardComponentFrom>(
    key: U,
    value: IDashboardComponentFrom[U],
  ) => void;
  setComponentFromItems: (items: Partial<IDashboardComponentFrom>) => void;
  onRelation: () => void;
  getViewPath: (nodeId: string) => string;
  getViewIcon: (viewType: ApiResponse.ViewList.ViewType) => {
    color: string;
    icon: string;
  };
  editViewData?: ApiResponse.ViewList.ViewListItem | null;
  disableChartType?: boolean;
  /**
   * 用来监听
   */
  onChartsInfoChange?: (c: ChartsInfo) => void;
  viewType?: ViewChartsType;
};
export type AddFormPropsRef = {
  getChartForm: () => ProFormInstance<ChartFormDataType>;
  getChartFormData: () => ChartFormDataType;
};
const AddForm: ForwardRefRenderFunction<AddFormPropsRef, AddFormPropsType> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => ({
    getChartForm() {
      return chartFormRef?.current?.getForm();
    },
    getChartFormData() {
      return chartFormRef?.current?.getFormData();
    },
  }));

  const {
    componentTypeInfo,
    componentForm,
    setComponentFormItem,
    setComponentFromItems,
    onRelation,
    getViewPath,
    getViewIcon,
    node,
    templateId,
    customAllowTempIds,
    editType,
    editViewData,
    disableChartType = false,
    onChartsInfoChange,
    viewType,
  } = props;

  const userMap = useOrgUserMap();
  const orgUserList = useOrgUserList();
  const nodeTreeData = useNodeTreeData();
  const getTreeData = useMemoizedFn(() => nodeTreeData);
  const groups = useOrgUserGroup();
  const [orgInfo] = useOrgInfo() as [CurrentUser.OrgInfo, unknown];
  const tempMap = useOrgTempMap();

  const chartFormRef = useRef<any>();

  const conditionTemplateId = componentForm.conditions.find(
    (x) => x.key === 'templateId',
  )?.value;
  const customTempId = useMemo(
    () =>
      match(componentForm)
        .with({ filterType: 'custom' }, () => conditionTemplateId)
        .with(
          {
            filterType: 'relation',
            relation: {
              template_id: P.nonNullable,
            },
          },
          ({ relation: { template_id } }) => template_id,
        )
        .otherwise(({ statTempId }) => {
          return statTempId || null;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      componentForm.conditions,
      conditionTemplateId,
      componentForm.filterType,
      componentForm.relation,
      componentForm.statTempId,
    ],
  );

  const { data: relationViewInfo } = useViewConfig(
    componentForm.relation?.view_id,
  );

  const customTemplateInfo = customTempId ? tempMap[customTempId] : null;

  const filterTemplateList = useFilterTemplateList(orgInfo);

  const [quoteOptions] = useQuoteOptions(orgInfo.orgId, customTemplateInfo!);

  const { groupOption } = useMemo(() => {
    const _groupOption: ReturnType<
      typeof getViewSettingOptionWithGroup
    >['groupOption'] = {};
    if (editType === 'add') {
      return { groupOption: _groupOption };
    }

    if (!customTemplateInfo) return { groupOption: _groupOption };

    return getViewSettingOptionWithGroup(
      [customTemplateInfo],
      orgInfo,
      tempMap,
      quoteOptions,
    );
  }, [editType, customTemplateInfo, orgInfo, tempMap, quoteOptions]);

  const groupList = useMemo(() => {
    return (
      groupOption &&
      Object.keys(groupOption).filter((o) => {
        if (customTempId) {
          // 过滤模板属性
          if (o.startsWith('templateProp_')) {
            return o.startsWith('templateProp_' + customTempId);
          }
          // 过滤模板状态属性
          if (o.startsWith('templateStatusPropCustom_')) {
            return o.startsWith('templateProp_' + customTempId);
          }
        }
        if (o === 'dayHours') return false;
        return true;
      })
    );
  }, [groupOption, customTempId]);

  const propStatConfig = useMemo(() => {
    const temp = customTemplateInfo;

    if (!temp) {
      return {};
    }

    const stat: Record<
      string,
      { config: ApiResponse.CurrentUser.TemplateProp; stat: UseStatType[] }
    > = {};

    temp.prop.map((x, i) => {
      if (x.hide || !x.type) {
        return;
      }

      // 引用数字求和等数字能力
      let canCount = false;
      if (x.type === 'quote')
        canCount = isQuotePropCanCount(x, getQuoteOriginProp(x, tempMap));

      stat['prop_' + i] = {
        config: x,
        stat: getStatByPropType(canCount ? 'number' : x.type),
      };
    });

    return stat;
  }, [customTemplateInfo, tempMap]);

  const allowTempIds = useMemo(() => {
    if (customAllowTempIds) return customAllowTempIds;
    if (!orgInfo || (!node && !templateId)) return [];

    return getAllowTemplates(
      orgInfo,
      (node?.value?.tempInfo?.id || templateId) as string,
      node?.value.id,
    );
  }, [customAllowTempIds, orgInfo, node, templateId]);

  const genCustomView = () => {
    // 编辑时 可以编辑复杂的
    return (
      <>
        <ConditionFilter
          defaultConditions={conditionConvertConditionV2(
            componentForm.conditions,
            componentForm.parentId,
            orgInfo.rootId,
            tempMap,
          )}
          entry="view"
          editable
          getTreeData={getTreeData}
          onChange={(e) => {
            console.log('ConditionFilter change', e);
            setComponentFromItems({
              conditions: e.conditions,
              parentId: e.parentId,
              group: undefined,
              statType: 'count',
              statProp: null,
            });
          }}
          displayHeader={false}
          disableParentId={editType === 'add'}
          displayTemplate
          displayParentId
          allowTempIds={allowTempIds}
          nodeSelectExtraCurrent={node ? node.value.id : undefined}
          templateUsage="fullAccess"
        />
        <IndicatorMatchingSetting
          conditions={componentForm.conditions}
          matchings={componentForm.matchings || []}
          onChange={(e: any) => {
            setComponentFromItems({ matchings: e });
          }}
        />
      </>
    );
  };

  const LayoutNumber = (
    <span className="text-[14px] font-bold text-[#3D3D3D]">100</span>
  );
  const LayoutText = <span className="text-[#3D3D3D]">统计指标</span>;

  const LayoutList: {
    value: IDashboardComponentFrom['layout'];
    childrenRender: (active?: boolean) => React.ReactNode;
    className?: string;
  }[] = [
    {
      value: 'no-photo',
      childrenRender: () => (
        <>
          {LayoutNumber}
          {LayoutText}
        </>
      ),
      className: 'flex-col',
    },
    {
      value: 'photo-col',
      childrenRender: (active) => (
        <>
          <RegularIcon
            type={active ? 'icona-Indexpic_A' : 'icona-Indexpic_N'}
            size={30}
          />
          <div className="flex flex-col ml-2">
            {LayoutText}
            {LayoutNumber}
          </div>
        </>
      ),
      className: 'flex-row',
    },
    {
      value: 'photo-row',
      childrenRender: (active) => (
        <>
          <RegularIcon
            type={active ? 'icona-Indexpic_A' : 'icona-Indexpic_N'}
            size={24}
          />
          {React.cloneElement(LayoutText, {
            className: cn('ml-2 mr-2', LayoutText.props.className),
          })}
          {LayoutNumber}
        </>
      ),
      className: 'flex-row',
    },
  ];

  const groupImgLeftRender = (
    { key }: { key: string; url: string },
    index: number,
  ) => {
    if (key === DEFAULT_IMG_KEY) {
      return (
        <div className="flex items-center px-3 flex-1 h-10 rounded-[8px] border border-solid border-[#EBEDF0] text-[14px]">
          <span>默认</span>
          <span className="text-[#EBEDF0]">（未设置时显示）</span>
        </div>
      );
    }
    if (!groupOption[componentForm.group!]) {
      return null;
    }
    const data = groupOption[componentForm.group!].data;
    const type = groupOption[componentForm.group!].data?.type;

    const selectProps: SelectProps = {
      className: 'w-full',
      size: 'large',
      placeholder: '请选择',
      value: key,
      onChange: (e) => handleUpdateGroupImgKey(e, index),
    };
    if (componentForm.group === 'templateStatus' && customTemplateInfo) {
      const statusOptions = customTemplateInfo.task_status.map(
        (status: { name: string }) => ({
          label: status.name,
          value: status.name,
          disabled:
            componentForm.groupImgList
              ?.filter((g, i) => index !== i)
              .some((g) => g.key === status.name) ?? false,
        }),
      );

      return <Select {...selectProps} options={statusOptions} />;
    }

    if (
      componentForm.group === 'sysCreator' ||
      componentForm.group === '_sys_creator' ||
      type === 'user'
    ) {
      const userOptions = orgUserList.map((user) => ({
        label: user.nick_name,
        value: user.account_id,
        disabled:
          componentForm.groupImgList
            ?.filter((g, i) => index !== i)
            .some((g) => g.key === user.account_id) ?? false,
      }));

      return <Select {...selectProps} options={userOptions} />;
    }

    if (type === 'enum' || type === 'tag') {
      const enumOptions = data.extend.map((key: string) => ({
        label: key,
        value: key,
        disabled:
          componentForm.groupImgList
            ?.filter((g, i) => index !== i)
            .some((g) => g.key === key) ?? false,
      }));

      return (
        <Select
          mode={data.multiple ? 'multiple' : undefined}
          {...selectProps}
          options={enumOptions}
        />
      );
    }

    if (type === 'number') {
      return (
        <InputNumber
          className="w-full"
          size="large"
          value={key}
          onChange={(e) => handleUpdateGroupImgKey(e ?? '', index)}
        />
      );
    }

    return (
      <Input
        className="w-full"
        size="large"
        placeholder="请输入"
        value={key}
        onChange={(e) => handleUpdateGroupImgKey(e.target.value, index)}
      />
    );
  };

  const groupImgCenterRender = (
    item: { key: string; url: string },
    index: number,
  ) => {
    return item.url ? (
      <div className="group w-full h-full relative">
        <img src={item.url} className="w-full h-full object-cover" />
        <div
          className={cn(
            'absolute w-full h-full top-0 left-0 hidden items-center justify-center bg-[rgba(0,0,0,0.3)]',
            { ['group-hover:flex']: item.url !== DEFAULT_IMG },
          )}
        >
          <span
            className="text-white cursor-pointer"
            onClick={() => {
              handleUpdateGroupImgUrl(
                item.key === DEFAULT_IMG_KEY ? DEFAULT_IMG : '',
                index,
              );
            }}
          >
            删除
          </span>
        </div>
      </div>
    ) : (
      <CustomUpload
        showUploadList={false}
        className={cn('', styles['custom-upload'])}
        onSuccess={(_url) => handleUpdateGroupImgUrl(_url, index)}
      >
        <div className="w-10 flex-col flex items-center justify-center">
          <PlusOutlined size={18} />
        </div>
      </CustomUpload>
    );
  };

  const groupImgList = componentForm.groupImgList ?? [
    {
      key: DEFAULT_IMG_KEY,
      url: DEFAULT_IMG,
    },
  ];

  const handleAddGroupImg = () => {
    setComponentFromItems({
      groupImgList: [
        ...groupImgList,
        {
          key: '',
          url: '',
        },
      ],
    });
  };

  const handleUpdateGroupImgUrl = (url: string, index: number) => {
    setComponentFromItems({
      groupImgList: groupImgList.map((g, _index) => {
        if (index === _index) {
          return {
            ...g,
            url,
          };
        }
        return g;
      }),
    });
  };

  const handleUpdateGroupImgKey = (key: string, index: number) => {
    setComponentFromItems({
      groupImgList: groupImgList.map((g, _index) => {
        if (index === _index) {
          return {
            ...g,
            key,
          };
        }
        return g;
      }),
    });
  };

  const handleDeleteGroupImg = (index: number) => {
    setComponentFromItems({
      groupImgList: groupImgList.filter((_, _index) => index !== _index),
    });
  };

  const setImgRender = (
    <>
      <div>
        {groupImgList.map((item, index) => (
          <div key={index} className="flex items-center mb-2">
            <div className="mr-3 flex-1">{groupImgLeftRender(item, index)}</div>
            <div className="mr-2 w-10 h-10 rounded-[8px] overflow-hidden">
              {groupImgCenterRender(item, index)}
            </div>
            <div className="flex items-center justify-center">
              {item.key === DEFAULT_IMG_KEY ? (
                <CustomUpload
                  listType="text"
                  showUploadList={false}
                  onSuccess={(_url) => handleUpdateGroupImgUrl(_url, index)}
                >
                  <Button type="link">替换</Button>
                </CustomUpload>
              ) : (
                <Button
                  className="w-[60px]"
                  type="link"
                  onClick={() => handleDeleteGroupImg(index)}
                >
                  <RegularIcon type="iconshanchu2" size={18} color="#FF5C64" />
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <Button type="link" onClick={handleAddGroupImg}>
          <PlusOutlined size={12} />
          自定义分组图片
        </Button>
      </div>
    </>
  );

  const viewInfo = relationViewInfo?.view_info as ViewList.ViewInfo | undefined;

  /**
   * 指标展示样式
   */
  const indicatorViewType = match(componentForm.filterType)
    .with('custom', () => componentForm.viewType)
    .with('relation', () => relationViewInfo?.view_type)
    .exhaustive();

  /**
   * 指标分组
   */
  const group = match(componentForm.filterType)
    .with('custom', () => componentForm.group)
    .with('relation', () => viewInfo?.group)
    .exhaustive();

  /**
   * 判断是否允许展示所有分组
   */
  const allowGetEmptyGroup = useMemo(() => {
    if (componentForm.type === 'chart') return false;
    if (!group) return false;
    if (!group.includes('templateProp_')) return false;
    const propIndex = parseInt(group.split('_')[2]);

    // 只有选值属性才可以开启这项配置
    if (customTemplateInfo?.prop[propIndex]?.type === 'enum') return true;

    return false;
  }, [componentForm.type, customTemplateInfo?.prop, group]);

  /**
   * 不满足时，自动关闭该配置
   */
  useEffect(() => {
    if (!allowGetEmptyGroup) {
      setComponentFormItem('getEmptyGroup', false);
    }
  }, [allowGetEmptyGroup, setComponentFormItem]);

  const setImgNoGroupRender = groupImgList[0].url ? (
    <div className="group w-[60px] h-[60px] rounded-[8px] relative overflow-hidden">
      <img src={groupImgList[0].url} className="w-full h-full object-cover" />
      <div
        className={cn(
          'absolute w-full h-full top-0 left-0 hidden items-center justify-center bg-[rgba(0,0,0,0.3)] group-hover:flex',
        )}
      >
        <span
          className="text-white cursor-pointer"
          onClick={() => {
            handleUpdateGroupImgUrl('', 0);
          }}
        >
          删除
        </span>
      </div>
    </div>
  ) : (
    <CustomUpload
      showUploadList={false}
      className={cn('', styles['custom-sign-upload'])}
      onSuccess={(_url) => handleUpdateGroupImgUrl(_url, 0)}
    >
      <div className="w-[60px] h-[60px] flex-col flex items-center justify-center">
        <PlusOutlined style={{ fontSize: 24, color: 'rgba(0,0,0,0.65)' }} />
      </div>
    </CustomUpload>
  );

  let formDom = null;
  if (componentForm.type === 'more') {
    formDom = (
      <>
        <div className={styles.group}>
          <div className={styles.groupHead}>名称</div>
          <Input
            value={componentForm.name}
            onChange={(e) => setComponentFormItem('name', e.target.value)}
            placeholder={componentTypeInfo.namePlaceholder}
          />
        </div>
        <div className={styles.group}>
          <div className={styles.groupHead}>
            筛选数据
            {componentForm.filterType === 'custom' ? (
              <div className={styles.groupHeadButton} onClick={onRelation}>
                <i className="iconfont iconProp_link" />
                关联已有视图
              </div>
            ) : (
              <div
                className={styles.groupHeadButton}
                onClick={() => {
                  setComponentFromItems({
                    filterType: 'custom',
                    statType: 'count',
                    statProp: null,
                    group: undefined,
                  });
                }}
              >
                <i className="iconfont iconparticipants_add_h" />
                自定义
              </div>
            )}
          </div>
          {componentForm.filterType === 'relation' && componentForm.relation ? (
            // 关联视图
            <div className={styles.relationCard} onClick={onRelation}>
              <div
                className={styles.relationCardIcon}
                style={{
                  backgroundColor:
                    getViewIcon(componentForm.relation.view_type).color + '30',
                }}
              >
                <i
                  className={getViewIcon(componentForm.relation.view_type).icon}
                  style={{
                    fontSize: 12,
                    color: getViewIcon(componentForm.relation.view_type).color,
                  }}
                />
              </div>
              <div className={cn(styles.relationCardName, 'text-omit')}>
                {componentForm.relation.view_name}
              </div>
              <div className={cn(styles.relationCardPath, 'text-omit')}>
                {getViewPath(componentForm.relation.node_id)}
              </div>
            </div>
          ) : (
            // 自定义视图 筛选
            genCustomView()
          )}
        </div>
        <div className={styles.group}>
          <div className={styles.groupHead}>统计</div>
          <Segmented
            disabled={
              (componentForm.filterType === 'custom' && !customTempId) ||
              (componentForm.filterType === 'relation' &&
                !componentForm.relation) ||
              Object.keys(propStatConfig).length === 0
            }
            options={[
              { value: 'count', label: '统计主题总数' },
              { value: 'prop', label: '统计指定属性' },
            ]}
            value={componentForm.statType}
            onChange={(e: any) => {
              const newStat = {
                statType: e,
              } as Partial<IDashboardComponentFrom>;
              if (e === 'prop') {
                newStat.statProp = {
                  key: Object.keys(propStatConfig)[0],
                  value: Object.values(propStatConfig)[0].stat[0],
                };
              }
              setComponentFromItems(newStat);
            }}
          />
          {componentForm.statType === 'prop' && (
            <Row style={{ marginTop: 8 }}>
              <Col span={12}>
                <Select
                  placeholder="请选择属性"
                  style={{ width: '98%' }}
                  value={componentForm.statProp?.key}
                  onChange={(e) => {
                    setComponentFormItem('statProp', {
                      key: e,
                      value: propStatConfig[e].stat[0],
                    });
                  }}
                >
                  {Object.keys(propStatConfig).map((x) => (
                    <Option key={x} value={x}>
                      {propStatConfig[x].config.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              {componentForm.statProp !== null ? (
                <Col span={12}>
                  <Select
                    placeholder="请选择统计方式"
                    style={{ width: '100%' }}
                    value={componentForm.statProp.value}
                    onChange={(e) => {
                      setComponentFormItem('statProp', {
                        key: componentForm.statProp?.key || '',
                        value: e,
                      });
                    }}
                  >
                    {componentForm.statProp &&
                    propStatConfig[componentForm.statProp.key]
                      ? propStatConfig[componentForm.statProp.key].stat.map(
                          (x) => (
                            <Option key={x} value={x}>
                              {getStatName(x)}
                            </Option>
                          ),
                        )
                      : null}
                  </Select>
                </Col>
              ) : null}
            </Row>
          )}
        </div>
        {/* 分组 */}
        {editType === 'edit' && (
          <div className={styles.group}>
            <div className={styles.groupHead}>分组</div>
            <Select
              placeholder="请选择分组方式"
              value={group}
              disabled={componentForm.filterType === 'relation'}
              style={{ width: '100%' }}
              allowClear
              onChange={(value) => {
                const other: Partial<IDashboardComponentFrom> = value
                  ? {
                      layout: 'no-photo',
                      groupImgList: [
                        {
                          key: DEFAULT_IMG_KEY,
                          url: DEFAULT_IMG,
                        },
                      ],
                    }
                  : {};
                console.log('🛎️', value);
                setComponentFromItems({
                  group: value,
                  ...other,
                });
              }}
            >
              {groupList.map((x: string) => (
                <Option key={x} value={x}>
                  {groupOption[x].name}
                </Option>
              ))}
            </Select>
            {allowGetEmptyGroup && (
              <div className="mt-2">
                <Checkbox
                  checked={!!componentForm.getEmptyGroup}
                  onChange={(e) => {
                    setComponentFormItem('getEmptyGroup', e.target.checked);
                  }}
                >
                  显示统计值为0的分组
                </Checkbox>
              </div>
            )}
          </div>
        )}

        {/* 数据源展示样式 */}
        {editType === 'edit' &&
          match(editViewData?.view_type)
            .with(1, 2, () => (
              <div className={styles.group}>
                <div className={styles.groupHead}>数据源展示方式</div>
                <Select
                  className="w-full"
                  placeholder="请选择数据源展示方式"
                  disabled={componentForm.filterType === 'relation'}
                  value={indicatorViewType}
                  onChange={(value) => {
                    setComponentFromItems({
                      viewType: value,
                    });
                  }}
                >
                  <Option key={1} value={1}>
                    看板
                  </Option>
                  <Option key={2} value={2}>
                    表格
                  </Option>
                </Select>
              </div>
            ))
            .otherwise(() => null)}

        <div className={styles.group}>
          <div className={styles.groupHead}>指标样式</div>
          <div className="mb-2 mt-4 text-[#3D3D3D] text-[14px]">布局</div>
          <div className="flex justify-between flex-wrap">
            {LayoutList.map((item) => (
              <div
                key={item.value}
                className={cn(
                  'flex w-[calc(50%-8px)] h-16 bg-[#F7F7F9] rounded-[8px] items-center justify-center mb-4 cursor-pointer',
                  {
                    ['border border-solid border-[#316EF5] bg-white']:
                      componentForm.layout === item.value,
                  },
                  item.className,
                )}
                onClick={() =>
                  setComponentFromItems({
                    layout: item.value,
                  })
                }
              >
                {item.childrenRender(componentForm.layout === item.value)}
              </div>
            ))}
          </div>
          {componentForm.layout !== 'no-photo' && (
            <>
              <div className="mb-2 mt-4 text-[#3D3D3D] text-[14px]">
                设置图片
              </div>
              {componentForm.group ? setImgRender : setImgNoGroupRender}
            </>
          )}
        </div>
      </>
    );
  }

  if (componentForm.type === 'chart') {
    formDom = (
      <>
        <div className={styles.group}>
          <div className={styles.groupHead}>
            视图名称
            {componentForm.filterType === 'relation' ? (
              <div
                className={styles.groupHeadButton}
                onClick={() => {
                  setComponentFromItems({
                    filterType: 'custom',
                    statType: 'count',
                    statProp: null,
                  });
                }}
              >
                <i className="iconfont iconparticipants_add_h" />
                自定义
              </div>
            ) : (
              <div className={styles.groupHeadButton} onClick={onRelation}>
                <i className="iconfont iconProp_link" />
                关联已有视图
              </div>
            )}
          </div>
        </div>
        {componentForm.filterType === 'relation' && componentForm.relation ? (
          <div className={styles.relationCard} onClick={onRelation}>
            <div
              className={styles.relationCardIcon}
              style={{
                backgroundColor:
                  getViewIcon(componentForm.relation.view_type).color + '30',
              }}
            >
              <i
                className={getViewIcon(componentForm.relation.view_type).icon}
                style={{
                  fontSize: 12,
                  color: getViewIcon(componentForm.relation.view_type).color,
                }}
              />
            </div>
            <div className={cn(styles.relationCardName, 'text-omit')}>
              {componentForm.relation.view_name}
            </div>
            <div className={cn(styles.relationCardPath, 'text-omit')}>
              {getViewPath(componentForm.relation.node_id)}
            </div>
          </div>
        ) : (
          /**
           * 通过 form 管理数据
           */
          <ChartForm
            ref={chartFormRef}
            type={editType}
            orgInfo={orgInfo}
            node={node}
            isGetChartsForm
            curViewData={editViewData}
            visible
            templateList={orgInfo.templateList}
            auth
            enablePublic={false}
            enableTitleEdit
            userMap={userMap}
            disableChartType={disableChartType}
            onChartsInfoChange={onChartsInfoChange}
            defaultViewType={viewType}
          />
        )}
      </>
    );
  }

  return (
    <div className={cn(styles.container, styles.step2)}>
      <div className={styles.typeInfo}>
        <div
          className={styles.typeInfoIcon}
          style={{ backgroundColor: componentTypeInfo.color }}
        >
          <i className={componentTypeInfo.cardIcon} />
        </div>
        <div className={styles.typeInfoName}>{componentTypeInfo.name}</div>
        <div className={styles.typeInfoDesc}>{componentTypeInfo.desc}</div>
      </div>

      {formDom}
    </div>
  );
};

export default forwardRef(AddForm);
