import AlignWidget, { AlignWidgetName } from './Align';
import BackgroundColorWidget, {
  BackgroundColorWidgetName,
} from './BackgroundColor';
import BoldWidget, { BoldWidgetName } from './Bold';
import ColorWidget, { ColorWidgetName } from './Color';
import FontSizeWidget, { FontSizeWidgetName } from './FontSize';
import ItalicWidget, { ItalicWidgetName } from './Italic';
import LinkWidget, { LinkWidgetName } from './Link';
import PropWidget, { PropWidgetName } from './Prop';
import StrikeWidget, { StrikeWidgetName } from './Strike';
import UnderlineWidget, { UnderlineWidgetName } from './Underline';

export const ToolWidgets = {
  [FontSizeWidgetName]: FontSizeWidget,
  [BoldWidgetName]: BoldWidget,
  [ItalicWidgetName]: ItalicWidget,
  [UnderlineWidgetName]: UnderlineWidget,
  [StrikeWidgetName]: StrikeWidget,
  [ColorWidgetName]: ColorWidget,
  [BackgroundColorWidgetName]: BackgroundColorWidget,
  [AlignWidgetName]: AlignWidget,
  [LinkWidgetName]: LinkWidget,
  [PropWidgetName]: PropWidget,
} as const;

export const ToolbarWidgetText = {
  [BoldWidgetName]: '加粗',
  [FontSizeWidgetName]: '字体',
  [ItalicWidgetName]: '斜体',
  [UnderlineWidgetName]: '下划线',
  [StrikeWidgetName]: '删除线',
  [ColorWidgetName]: '颜色',
  [BackgroundColorWidgetName]: '背景色',
  [AlignWidgetName]: '对齐',
  [LinkWidgetName]: '链接',
  [PropWidgetName]: '属性',
} as const;

export type ToolWidgetType = keyof typeof ToolWidgets;
