/* eslint-disable react-refresh/only-export-components */
import { FilterOutlined } from '@ant-design/icons';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { ConditionRuleConfigV2 } from '@linkpi/core';
import { generateAddOpId } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import { useImmerState } from '@shrugsy/use-immer-state';
import { Modal, Space } from 'antd';
import { cloneDeep } from 'lodash';
import type { FC } from 'react';

import { createConditionRulesTable, Image } from '@/components';
import { ConditionFilterPreview } from '@/components/ConditionFilter';

import type { SubTabsRuleConditionResultFormProp } from '../RuleModal';
import { showTabConditionRuleModal } from '../RuleModal';

import styles from './styles.less';

export type TabRule =
  ConditionRuleConfigV2.ConditionRuleConfigV2<ConditionRuleConfigV2.TabSectionConditionResults>;

type TabSectionRulesProp = {
  initialValue?: TabRule[];
} & SubTabsRuleConditionResultFormProp;

const useTabRules = (props: TabSectionRulesProp) => {
  const { initialValue } = props;

  const [rules, setRules] = useImmerState(initialValue || []);

  // 打开多条规则
  const toggleRules = async (ruleIds: string[], checked: boolean) => {
    setRules((draft) => {
      draft?.forEach((i) => {
        if (ruleIds.includes(i.ruleId)) i.enable = checked;
      });
    });
  };

  // 删除多条规则
  const deleteRules = async (ruleIds: string[]) => {
    setRules(rules?.filter((r) => !ruleIds.includes(r.ruleId)) || []);
  };

  // 编辑规则
  const updateRule = async (record: TabRule) => {
    const res = record;

    setRules((draft) => {
      draft.forEach((i) => {
        if (i.ruleId === record.ruleId) {
          i.results = res.results;
          i.condition = res.condition;
        }
      });
    });
  };

  const addRule = async (record: TabRule, copy = false) => {
    const ruleId = generateAddOpId();
    const createTime = Date.now();
    if (copy) {
      const newRecord = { ...cloneDeep(record), ruleId, createTime };

      setRules([...rules, newRecord]);
      return;
    }

    const res = record;
    const enable = true;

    const payload = {
      ...res,
      ruleId,
      createTime,
      enable,
    };
    setRules([...rules, payload]);
  };

  return {
    loading: false,
    ruleList: rules,
    addRule,
    updateList: setRules as any,
    updateRule,
    deleteRules,
    toggleRules,
  };
};

const { ConditionRulesTable } = createConditionRulesTable(useTabRules);

const TabRules: FC<TabSectionRulesProp> = (props) => {
  const modal = useModal();
  const antdModal = antdModalV5(modal);
  const { tabConfig } = props;

  const ruleListInstance = useTabRules(props);

  const getHiddenChildThemes = (results: TabRule['results']) => {
    let resText = '';
    const tabConfigMap = toRecord<(typeof tabConfig.tabs)[number]>((tab) => ({
      [tab.id]: tab,
    }))(tabConfig.tabs);
    results.forEach((i) => {
      if (i.type === 'tabVisible')
        resText += '隐藏' + i.tabs.map((id) => tabConfigMap[id]?.name || '[已删除]').join(', ');
    });

    return resText;
  };

  const columns: any[] = [
    {
      title: '条件规则',
      key: 'config',
      render: (v: TabRule) => {
        const text = `${getHiddenChildThemes(v.results)}`;
        return (
          <Space>
            <Image
              className="h-[22px] w-[29px] object-cover"
              src="https://www.mylinkpi.com/icon/automation_then.png"
            />
            <div className={styles.ruleTitle}>
              当条件
              <ConditionFilterPreview value={v.condition} displayTemplate={false}>
                <span style={{ color: '#316EF5' }}>
                  <FilterOutlined />({v.condition.length - 1})
                </span>
              </ConditionFilterPreview>
              时，就{text}
            </div>
          </Space>
        );
      },
    },
  ];

  const onOk = () => {
    modal.resolve(ruleListInstance.ruleList);
    antdModal.onOk();
  };

  return (
    <Modal {...antdModal} onOk={onOk} okText="保存" centered width={1200} title="选项卡的条件规则">
      <ConditionRulesTable
        immediateSorting
        title="根据属性的选值，自动展示 / 隐藏相关选项卡。"
        ruleListInstance={ruleListInstance}
        columns={columns}
        onCreate={() => showTabConditionRuleModal({ mode: 'create', tabConfig })}
        onEdit={(r) => showTabConditionRuleModal({ mode: 'edit', config: r, tabConfig })}
      />
    </Modal>
  );
};

const TabRulesModal = create(TabRules);

export const getTabRules = (options: TabSectionRulesProp) =>
  show(TabRulesModal, options) as Promise<TabRule[]>;
