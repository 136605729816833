import { RegularIcon } from '@/components/IconFont';

import mulDimension from '../../assets/mulDimension.png';
import type { IStatisticSetting } from '../../components';
import {
  showStatisticSettingModal,
  StatisticPreview,
  StatisticSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IMultidimensionalStatisticWidget = IStatisticSetting<'chart'>;

const Preview: WidgetPreivew = ({ id }) => {
  return (
    <StatisticPreview id={id}>
      <div style={{ display: 'block', height: '100%' }}>
        <img src={mulDimension} style={{ display: 'block', height: 392 }} />
      </div>
    </StatisticPreview>
  );
};

const Setting: WidgetSetting = () => {
  return <StatisticSetting type="chart" viewType={1} />;
};

export const MultidimensionalStatisticWidget =
  defineSystemWidget<IMultidimensionalStatisticWidget>()({
    id: 'MultidimensionalStatistic',
    title: '多维统计',
    icon: () => {
      return (
        <WidgetIcon
          bgColor="#FF8D76"
          icon={
            <RegularIcon type="iconshitu-duoweitongjitu" color={'#ffffffcc'} />
          }
        />
      );
    },
    basic: {
      defaultHeight: 8,
      defaultWidth: 6,
      minWidth: 3,
      minHeight: 3,
      settingWidth: 540,
    },
    metadata: {} as any,
    preview: Preview,
    setting: Setting,
    onCreate: () =>
      showStatisticSettingModal<'chart'>({
        title: '添加多维统计',
        type: 'chart',
        viewType: 4,
      }),
  });
