import { useControllableValue } from 'ahooks';
import { useAtomValue } from 'jotai';
// @ts-ignore
import { Range } from 'quill/core/selection';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import type { CommonWidgetProps } from '../../constants';
import { formatsAtom, positionsAtom, rangeAtom } from '../../store';
import { Link, type LinkFormat } from './blot';
import type { LinkModalProps } from './modal';
import { openLinkModal } from './modal';
import LinkPopover from './popover';

interface LinkWidgetProps extends CommonWidgetProps {
  value?: LinkFormat;
  onChange?: (value: LinkFormat) => void;
}

const LinkWidget = (props: LinkWidgetProps) => {
  const { value, onChange, beforeChange, endChange, container, quill } = props;
  const formats = useAtomValue(formatsAtom);
  const positions = useAtomValue(positionsAtom);
  const range = useAtomValue(rangeAtom);

  const [_value, _onChange] = useControllableValue<LinkFormat | null>({
    value: formats[LinkWidgetName],
    onChange,
  });

  const selections = useMemo(() => (range?.length ?? 0) > 0, [range?.length]);

  const handleChange = useCallback(
    (v: LinkModalProps) => {
      if (v.link) {
        _onChange({
          href: v.link,
          target: v.blank ? '_blank' : '_self',
        });
      } else {
        _onChange(null);
      }
    },
    [_onChange],
  );

  const linkRange = useMemo(() => {
    if (!range) {
      return null;
    }
    let _linkRange = range;

    if (range.length === 0) {
      const [link, offset] = quill.scroll.descendant<any>(Link, range.index);
      if (link) {
        _linkRange = new Range(range.index - offset, link.length());
      }
    }
    return _linkRange;
  }, [quill.scroll, range]);

  const handleDelete = useCallback(() => {
    // _onChange(null);
    if (!linkRange) {
      return;
    }

    quill.formatText(linkRange, 'link', false);
  }, [linkRange, quill]);

  const handleClick = useCallback(async () => {
    if (!linkRange) {
      return;
    }
    // if (!range) {
    //   return;
    // }

    // let linkRange = range;

    // if (range.length === 0) {
    //   const [link, offset] = quill.scroll.descendant<any>(Link, range.index);
    //   if (link) {
    //     linkRange = new Range(range.index - offset, link.length());
    //   }
    // }

    const values = (await openLinkModal({
      link: _value?.href,
      blank: _value?.target === '_blank',
    })) as LinkModalProps;

    // handleChange(values);
    quill.formatText(linkRange, 'link', {
      href: values.link,
      target: values.blank ? '_blank' : '_self',
    });

    // beforeChange?.();
    // if (selections) {
    //   const values = (await openLinkModal({
    //     link: _value?.href,
    //     blank: _value?.target === '_blank',
    //   })) as LinkModalProps;
    //   // handleChange(values);
    // }
    // endChange?.();
  }, [_value?.href, _value?.target, linkRange, quill]);

  const [node, setNode] = useState<React.ReactNode | null>(null);
  useEffect(() => {
    if (_value) {
      if (positions && container) {
        // 仅处理屏幕右侧
        const bodyWidth = document.body.offsetWidth;
        const containerLeft = container.getBoundingClientRect().left;
        const scope = bodyWidth - containerLeft;
        let left = positions.left;
        if (scope - left < 210) {
          left = scope - 210;
        }

        const _node = createPortal(
          <LinkPopover
            positions={{
              ...positions,
              left,
            }}
            value={{
              link: _value.href,
              blank: _value.target === '_blank',
            }}
            onChange={handleChange}
            onEdit={handleClick}
            onDelete={handleDelete}
          />,
          container,
        );
        setNode(_node);
        return;
      }
    }

    setNode(null);
  }, [_value, container, handleChange, handleClick, handleDelete, positions]);
  // useEffect(() => {
  //   const root = document.getElementById('root');
  //   if (root) {
  //     if (drawerV5.open) {
  //       const _node = createPortal(cloneElement(DOM), root);
  //       root.style.display = 'flex';
  //       document.getElementById('HomeLayout')!.style.flex = '1';
  //       setNode(_node);
  //     } else {
  //       root.style.cssText = '';
  //       setNode(null);
  //     }
  //   }
  // }, [drawerV5.open, source]);

  return (
    <>
      <div
        className={cn(
          'w-8 h-8 flex items-center justify-center rounded-[8px] cursor-pointer  border border-solid border-[#ebedf0]',
          {
            'bg-[#316EF5]': _value,
          },
        )}
        onClick={handleClick}
      >
        <RegularIcon
          type="iconlianjie1"
          size={16}
          color={_value ? '#fff' : '#000'}
        />
      </div>
      {node}
    </>
  );
};

export const LinkWidgetName = 'link';
export default LinkWidget;
