import { match, P } from 'ts-pattern';

import { RegularIcon } from '@/components/IconFont';

import { WidgetIcon } from '../../components';
import { useWidgetInstance } from '../../hooks';
import type { WidgetPreivew } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';
import Setting, { type IPictureConfig } from './Setting';

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<IPictureConfig>(id);

  const url = match([widgetInstance?.config.type, widgetInstance?.config.value])
    .with(
      ['link', P.string.includes('//')],
      ['upload', P.string.includes('//')],
      () => {
        return widgetInstance?.config.value;
      },
    )
    .otherwise(() => null);

  const imgStyle: any = {};
  if (widgetInstance?.config.fit === 'fill') {
    imgStyle.width = '100%';
    imgStyle.height = '100%';
    imgStyle.objectFit = 'cover';
  }

  return (
    <div className="w-full h-full flex items-center justify-center text-[#BFC6D2] text-[14px]">
      {url ? <img style={imgStyle} src={url} alt="图片组件" /> : '暂无图片'}
    </div>
  );
};

export const PictureWidget = defineSystemWidget<IPictureConfig>()({
  id: 'Picture',
  title: '图片',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#9A8BFF"
        icon={<RegularIcon type="iconchangyong-tupian" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 4,
    defaultWidth: 3,
    minHeight: 2,
    minWidth: 4,
  },
  metadata: {
    type: 'upload',
    fit: 'fill',
    align: 'center',
  },
  preview: Preview,
  setting: Setting,
});
