import { assertExists } from '@linkpi/utils';
import { useUpdateEffect } from '@react-hookz/web';
import { Form, Select } from 'antd';
import { head } from 'ramda';
import { type FC, useEffect, useRef } from 'react';

import { useCurrentOrgId, useOrgTempMap } from '@/hook';

import { useActiveWidgetInstance, useEditor } from '../hooks';
import type { IConditionSetting } from './ConditionSetting';

export type IChooseCustomTable = IConditionSetting & {
  customTableId?: string;
};

export const ChooseCustomTable: FC = () => {
  const { Option } = Select;
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IChooseCustomTable>();
  const onceRef = useRef(true);

  assertExists(widgetInstance);

  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);
  const matchingTemplate =
    tempMap[
      head(
        widgetInstance.config.conditions.find((i: any) => i.key === 'templateId')?.input,
      ) as string
    ] || {};

  const customTables = (matchingTemplate.custom_view?.data || []).filter((t: any) => t.switch);

  const updateConfig = editor.updateActiveWidgetInstanceConfig<IChooseCustomTable>;

  useUpdateEffect(() => {
    updateConfig((config: any) => {
      config.customTableId = undefined;
    });
  }, [matchingTemplate.template_id]);

  const changeCustomTableId = (v: any) => {
    updateConfig((config: any) => {
      config.customTableId = v;
    });
  };
  return (
    <Form.Item>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>选择表格：</span>
        <Select
          value={widgetInstance.config.customTableId}
          onChange={changeCustomTableId}
          style={{ flex: 1 }}
          placeholder="请选择表格"
        >
          {customTables.map((t: any) => (
            <Option key={t.id} value={t.id}>
              {t.name}
            </Option>
          ))}
        </Select>
      </div>
    </Form.Item>
  );
};
