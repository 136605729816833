import { RegularIcon } from '@/components/IconFont';

import { WidgetIcon } from '../../components';
import { useWidgetInstance } from '../../hooks';
import type { WidgetPreivew } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';
import Setting, { type ILineTextConfig } from './Setting';

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<ILineTextConfig>(id);
  const config = widgetInstance?.config;

  if (!config) {
    return <div />;
  }

  const {
    value,
    fontSize,
    fontBold,
    fontAlign,
    color,
    backgroundColor: bgColor,
    useIcon,
    icon,
    useBgColor,
  } = config;
  const fontColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
  const backgroundColor = `rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`;
  const lineHeight = fontSize * 1.5;

  const fontStyle: React.CSSProperties = {
    fontSize,
    fontWeight: fontBold ? 'bold' : 'normal',
    textAlign: fontAlign,
    color: fontColor,
  };

  if (useBgColor) {
    fontStyle.backgroundColor = backgroundColor;
  }

  return (
    <div className="w-full h-full" style={fontStyle}>
      {useIcon && icon && (
        <img
          src={icon}
          className="mr-1"
          style={{ height: lineHeight, verticalAlign: 'middle' }}
        />
      )}
      {value}
    </div>
  );
};

export const LineTextWidget = defineSystemWidget<ILineTextConfig>()({
  id: 'LineText',
  title: '单行文本',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#5ABCB0"
        icon={<RegularIcon type="iconwenben" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 2,
    defaultWidth: 6,
    minHeight: 1,
    minWidth: 2,
  },
  metadata: {
    fontSize: 14,
    color: {
      r: 36,
      g: 45,
      b: 63,
      a: 1,
    },
    backgroundColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    useIcon: true,
    useBgColor: true,
  },
  preview: Preview,
  setting: Setting,
});
