import { atom } from 'jotai';
import { useAtomDevtools } from 'jotai-devtools';

import { widgetManagerAtom } from './atom';
// import { useEffect } from 'react';
// import { container } from 'tsyringe-neo';
// import { Store } from '../models/Store';
// import { useStore } from './useEditor';

// const widgetMapAtom = atom(container.resolve(Store).getWidgetMapForDebug());
// widgetMapAtom.debugLabel = 'widgetMapAtom';

const customWidgetsAtom = atom((get) => {
  const widgetManager = get(widgetManagerAtom);
  return widgetManager.customWidgets;
});
customWidgetsAtom.debugLabel = 'customWidgetsAtom';

export const useDebugEditor = () => {
  /**
   * 暂时隐藏
   */
  // const store = useStore();
  // const atomStore = useAtomStore();

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') return;

  //   const cb = () => {
  //     const widgetMap = store.getWidgetMapForDebug();
  //     atomStore.set(widgetMapAtom, widgetMap);
  //   };

  //   store.on('widgetInstanceChanged', cb);

  //   return () => {
  //     store.off('widgetInstanceChanged', cb);
  //   };
  // }, [atomStore, store]);

  // useAtomDevtools(widgetMapAtom);
  useAtomDevtools(customWidgetsAtom);
};
