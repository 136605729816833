import { Form, Segmented, Switch } from 'antd';
import { useState } from 'react';

import { TemplateSelect } from '@/components/LinkPiForm';

import { ArrangeSelector, NameSetting } from '../../components';
import {
  useActiveWidgetInstance,
  useSetActiveWidgetInstanceConfig,
} from '../../hooks';
import type { WidgetSetting } from '../../models/WidgetManager';
import { FilterList } from './FilterList';
import type { IGlobalConditionFilterConfig } from './types';

const BasicSetting = () => {
  const { config } = useActiveWidgetInstance<IGlobalConditionFilterConfig>()!;
  const setConfig =
    useSetActiveWidgetInstanceConfig<IGlobalConditionFilterConfig>();

  console.log('🍼', config);

  return (
    <Form
      layout="vertical"
      initialValues={config}
      onValuesChange={(_, value) => {
        setConfig((d) => {
          d.conditionList = value.conditionList;
          d.enableSubmitButton = value.enableSubmitButton;
          d.templateId = value.templateId;
        });
      }}
    >
      <NameSetting defaultName={'筛选组件'} />
      <TemplateSelect label="数据源" name={['templateId']} />
      <Form.Item label="筛选项" name={['conditionList']}>
        <FilterList templateId={config.templateId} />
      </Form.Item>
      <div className="flex justify-between items-baseline">
        <Form.Item label="查询重置" />
        <Form.Item name={['enableSubmitButton']} valuePropName="checked">
          <Switch size="small" />
        </Form.Item>
      </div>
    </Form>
  );
};
const options = [
  { value: 'one', label: '1列' },
  { value: 'two', label: '2列' },
  { value: 'three', label: '3列' },
];

const StylesSetting = () => {
  const { config } = useActiveWidgetInstance<IGlobalConditionFilterConfig>()!;
  const setConfig =
    useSetActiveWidgetInstanceConfig<IGlobalConditionFilterConfig>();

  console.log('🍼', config);

  return (
    <Form
      layout="vertical"
      initialValues={config}
      onValuesChange={(_, value) => {
        setConfig((v) => {
          v.arrangement = value.arrangement;
        });
      }}
    >
      <Form.Item label="排列方式" name={['arrangement']} initialValue={'three'}>
        <ArrangeSelector options={options} />
      </Form.Item>
    </Form>
  );
};

export const Setting: WidgetSetting = () => {
  const [type, setType] = useState<'属性设置' | '样式设置'>('属性设置');
  return (
    <>
      <Segmented
        className="mx-3 mt-3"
        onChange={(v) => setType(v as any)}
        block
        options={['属性设置', '样式设置']}
      />
      {type === '属性设置' && <BasicSetting />}
      {type === '样式设置' && <StylesSetting />}
    </>
  );
};
