import type { ProFormColumnsType } from '@ant-design/pro-form';
import ProForm, { BetaSchemaForm } from '@ant-design/pro-form';
import {
  antdModalV5,
  create,
  hide,
  show,
  useModal,
} from '@ebay/nice-modal-react';
import { Checkbox, Modal, Tooltip } from 'antd';
import type { CheckboxGroupProps } from 'antd/lib/checkbox';

import { useOrgTemplatesSettingInfo } from '@/hook';

import { StatusAttrs, SystemAttrs } from '../../constants';

import Styles from './modal.less';

export interface PropModalProps {
  tempId?: string;
  system?: string[];
  status?: string[];
  custom?: string[];
}

interface ICheckboxGroupProps {
  value?: CheckboxGroupProps['value'];
  onChange?: CheckboxGroupProps['onChange'];
  options: { label: string; value: string | number }[];
}
const CheckboxGroup = (props: ICheckboxGroupProps) => {
  const { value, onChange, options } = props;

  return (
    <Checkbox.Group style={{ width: '100%' }} value={value} onChange={onChange}>
      <div className="flex flex-wrap">
        {options.map((o) => (
          <div key={o.value} className="w-1/2 shrink-0">
            <Checkbox value={o.value}>
              <Tooltip title={o.label} placement="top">
                <div
                  className="overflow-hidden whitespace-nowrap text-ellipsis"
                  style={{
                    width: 140,
                  }}
                >
                  {o.label}
                </div>
              </Tooltip>
            </Checkbox>
          </div>
        ))}
      </div>
    </Checkbox.Group>
  );
};

export const PropModal = create((props: PropModalProps) => {
  const modal = useModal();
  const antdModal = antdModalV5(modal);
  const [form] = ProForm.useForm<PropModalProps>();

  const [temps] = useOrgTemplatesSettingInfo();
  const tempList = temps;
  // const tempList = useMemo(
  //   () =>
  //     temps.filter((t) => t.status === 0 || t.template_id === DEFAULT_TEMPLATE),
  //   [temps],
  // );

  const getTempById = (id: string) => {
    const _temp = tempList.find((t) => t.template_id === id);
    return (
      _temp?.prop
        .map((p, i) => ({ label: p.name, value: i }))
        .filter((p) => p.label) ?? []
    );
  };

  const columns: ProFormColumnsType[] = [
    {
      title: '当前主题类型',
      dataIndex: 'tempId',
      valueType: 'select',
      readonly: true,
      fieldProps: {
        options: tempList.map((t) => ({
          label: t.name,
          value: t.template_id,
        })),
      },
      render: (dom) => {
        return <div className="mb-10">{dom}</div>;
      },
    },
    {
      title: '系统属性',
      dataIndex: 'system',
      valueType: 'checkbox',
      fieldProps: {
        options: SystemAttrs,
      },
      renderFormItem: () => {
        return <CheckboxGroup options={SystemAttrs} />;
      },
    },
    {
      title: '状态属性',
      dataIndex: 'status',
      valueType: 'checkbox',
      fieldProps: {
        options: StatusAttrs,
      },
      renderFormItem: () => {
        return <CheckboxGroup options={StatusAttrs} />;
      },
    },
    {
      valueType: 'dependency',
      name: ['tempId'],
      columns: ({ tempId }) => {
        const options = getTempById(tempId);

        if (options.length) {
          return [
            {
              title: '自定义属性',
              dataIndex: 'custom',
              valueType: 'checkbox',
              fieldProps: {
                options,
              },
              renderFormItem: () => {
                return <CheckboxGroup options={options} />;
              },
            },
          ];
        }
        return [];
      },
    },
  ];

  const optionsToMap = (options: { label: string; value: string | number }[]) =>
    options.reduce<Record<string, string>>((result, item) => {
      result[item.value] = item.label;
      return result;
    }, {});

  const handleOkClick = async () => {
    const values = await form.validateFields();
    const sysMap = optionsToMap(SystemAttrs);
    const statusMap = optionsToMap(StatusAttrs);
    const customMap = optionsToMap(getTempById(values.tempId!));

    const sys = (values.system ?? []).map((s) => ({
      name: sysMap[s],
      value: s,
      type: 'system',
    }));
    const status = (values.status ?? []).map((s) => ({
      name: statusMap[s],
      value: s,
      type: 'status',
    }));
    const custom = (values.custom ?? []).map((s) => ({
      name: customMap[s],
      value: s,
      type: 'custom',
    }));
    const _values = {
      tempId: values.tempId,
      props: [...sys, ...status, ...custom],
    };

    modal.resolve(_values);
    antdModal.onCancel();
  };

  return (
    <Modal
      title="设置链接"
      width={480}
      destroyOnClose
      {...antdModal}
      onOk={handleOkClick}
    >
      <BetaSchemaForm
        className={Styles['form']}
        form={form}
        initialValues={props}
        submitter={false}
        layoutType="Form"
        layout="horizontal"
        labelAlign="left"
        columns={columns}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      />
    </Modal>
  );
});

export const PropModalId = '$pi-toolbar-link-modal';

export const openPropModal = (props?: PropModalProps) => show(PropModal, props);
export const hidePropModal = () => hide(PropModal);
