import { Form } from 'antd';
import { type FC, memo } from 'react';

import { PiButton } from '@/components/Button';

import { useActiveWidgetInstance, useEditor } from '../../../hooks';
import type { ITabSectionContentSetting } from '../ContentSetting';
import type { TabRule } from './RuleListModal';
import { getTabRules } from './RuleListModal';

export type ITabSectionConditionRuleSetting = ITabSectionContentSetting & {
  conditionRules?: TabRule[];
};

const _TabSectionConditionRuleSetting: FC = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance<ITabSectionConditionRuleSetting>()!;

  const setConditionRules = (r: TabRule[]) => {
    editor.store.updateWidgetInstanceConfig<ITabSectionConditionRuleSetting>(widgetIntance.id, {
      ...widgetIntance?.config,
      conditionRules: r,
    });
  };

  return (
    <Form.Item label="条件规则">
      <PiButton
        className="full-w"
        onClick={() =>
          getTabRules({
            initialValue: widgetIntance.config.conditionRules,
            tabConfig: widgetIntance.config,
          }).then(setConditionRules)
        }
      >
        点击配置
      </PiButton>
    </Form.Item>
  );
};

export const TabSectionConditionRuleSetting = memo(_TabSectionConditionRuleSetting);
