import type { CurrentUser } from '@linkpi/core';
import type { ViewList } from '@linkpi/core';
import type { ConditionRuleConfig, ConditionRuleConfigV2, NumberValueType } from '@linkpi/core';
import { getQuoteOriginProp } from '@linkpi/core';
import {
  AND,
  COUNT,
  EMPTY,
  EQUAL,
  GREAT,
  GREAT_EQUAL,
  INCLUDE,
  INDEX_OF,
  LESS,
  LESS_EQUAL,
  NOT,
  OR,
  PROP,
  STATUS,
} from '@linkpi/core';
import { match, P } from 'ts-pattern';

import { toArray, toRecord } from '@/utils/utils';

import type { ClientPropConditionRule, PropConditionRule } from './types';

/**
 * @deprecated
 */
export function ruleToConditionDesc(
  config: ConditionRuleConfig,
  props: CurrentUser.TemplateProp[] = [],
  statusList: CurrentUser.taskStatus[] = [],
  tempMap: Record<string, CurrentUser.TemplateInfo> = {},
) {}

export function ruleToResultDesc(
  config: PropConditionRule,
  props: CurrentUser.TemplateProp[] = [],
  groups: CurrentUser.PropGroups[] = [],
) {
  const propsGroupMap = toRecord((g: CurrentUser.PropGroups) => ({ [g.id]: g }))(groups);
  return config.results
    .map((item) => {
      return match(item)
      .with({ type: 'property_color' }, (i) => {
        const { propBg, propIndex, propColor } = i
        if (propIndex === undefined) return ''
        const propName = props[propIndex].name
        return `「${propName}」属性的背景色是${propBg || 'white'},字色是${propColor || 'black'}`
      })
        .with({ type: 'property_group_default_open' }, (i) => {
          const label = i.defaultOpen ? '展开' : '折叠';
          return (
            '折叠/展开属性分组：' +
            label +
            i.groups.map((key) => propsGroupMap[key].name).join('、')
          );
        })
        .otherwise((i) => {
          const label = (
            {
              hidden: '隐藏',
              show: '显示',
            } as const
          )[i.visible];
          return '显示/隐藏属性：' + label + i.targetProps.map((key) => props[key].name).join('、');
        });
    })
    .join('；');
}

/**
 * @deprecated
 */
export const ruleToFormula = (
  config: ConditionRuleConfig,
  props: CurrentUser.TemplateProp[] = [],
  tempMap: Record<string, CurrentUser.TemplateInfo>,
) => {
  const err = new Error('条件属性转公式失败，请联系开发人员');
  // 状态
  if (config.conditionType === '$status') {
    if (config.statusRelation === 'beIncluded')
      return OR(
        ...toArray(config.status).map((statusIndex: number) => EQUAL(STATUS(), statusIndex)),
      );

    return EQUAL(STATUS(), config.status);
  }
  // 不是属性也不是状态
  if (typeof config.conditionType !== 'number') {
    throw err;
  }
  let prop = props[config.conditionType];
  // 找不到属性
  if (!prop?.type) {
    throw err;
  }

  if (prop.type === 'quote') {
    const originProp = getQuoteOriginProp(prop, tempMap);
    if (!originProp || typeof originProp === 'string') throw err;
    prop = originProp;
  }

  const propKey = config.conditionType;
  const PROP_VALUE = PROP(propKey);
  const numberFnMap: Record<NumberValueType['relation'], Function> = {
    equal: (v: number) => EQUAL(PROP_VALUE, v),
    no_equal: (v: number) => NOT(EQUAL(PROP_VALUE, v)),
    great: (v: number) => GREAT(PROP_VALUE, v),
    great_eq: (v: number) => GREAT_EQUAL(PROP_VALUE, v),
    less: (v: number) => LESS(PROP_VALUE, v),
    less_eq: (v: number) => LESS_EQUAL(PROP_VALUE, v),
  };

  switch (prop.type) {
    case 'tag':
    case 'enum': {
      const condition = config as ConditionRuleConfig.EnumType;
      switch (condition.normalRelation) {
        case 'equal':
          return AND(
            EQUAL((condition.enum as string[]).length, COUNT(PROP_VALUE)),
            ...(condition.enum as string[]).map((i) => INCLUDE(i, PROP_VALUE)),
          );
        case 'include':
          return OR(...(condition.enum as string[]).map((i) => INCLUDE(i, PROP_VALUE)));
        case 'no_empty':
          return NOT(EMPTY(PROP_VALUE));
        case 'empty':
          return EMPTY(PROP_VALUE);
        default:
          throw err;
      }
    }
    case 'user': {
      const condition = config as ConditionRuleConfig.UserType;
      switch (condition.normalRelation) {
        case 'equal':
          return AND(
            EQUAL((condition.user as string[]).length, COUNT(PROP_VALUE)),
            ...(condition.user as string[]).map((i) => INCLUDE(i, PROP_VALUE)),
          );
        case 'include':
          return OR(...(condition.user as string[]).map((i) => INCLUDE(i, PROP(propKey))));
        case 'no_empty':
          return NOT(EMPTY(PROP_VALUE));
        case 'empty':
          return EMPTY(PROP_VALUE);
        default:
          throw err;
      }
    }
    case 'number': {
      const condition = config as ConditionRuleConfig.NumberType;

      const FORMULAS = condition.numbers.map((i) => numberFnMap[i.relation](i.value));
      if (FORMULAS.length > 1) return AND(...FORMULAS);
      return FORMULAS[0];
    }
    case 'text': {
      const condition = config as ConditionRuleConfig.TextType;

      let FORMULAS;
      switch (condition.textRelation) {
        case 'equal':
          FORMULAS = EQUAL(PROP_VALUE, condition.text);
          break;

        case 'include':
          FORMULAS = GREAT_EQUAL(INDEX_OF(PROP_VALUE, condition.text), 0);
          break;

        default:
          throw err;
      }
      return FORMULAS;
    }
    case 'formula': {
      switch (prop.formulaFormat) {
        case 0: {
          const condition = config as ConditionRuleConfig.NumberFormulaType;

          const FORMULAS = condition.formulaNumbers.map((i) => numberFnMap[i.relation](i.value));
          if (FORMULAS.length > 1) return AND(...FORMULAS);
          return FORMULAS[0];
        }
        case 1: {
          const condition = config as ConditionRuleConfig.TextFormulaType;

          let FORMULAS;
          switch (condition.textRelation) {
            case 'equal':
              FORMULAS = EQUAL(PROP_VALUE, condition.text);
              break;

            case 'include':
              FORMULAS = GREAT_EQUAL(INDEX_OF(PROP_VALUE, condition.text), 0);
              break;

            default:
              throw err;
          }
          return FORMULAS;
        }

        default:
          throw err;
      }
    }

    default:
      throw err;
  }
};

export const lagecyRuleConditionToRuleCondition = <
  T extends
    | ConditionRuleConfigV2.PropConditionResults
    | ConditionRuleConfigV2.TabSectionConditionResults
    | ConditionRuleConfigV2.TableConditionResults,
>(
  lagecyRule: ClientPropConditionRule,
  templateId: string,
): ConditionRuleConfigV2.ConditionRuleConfigV2<T>['condition'] => {
  const condition: ViewList.ViewconditionV2 = [
    {
      op: 'beIncluded',
      key: 'templateId',
      input: [templateId],
    },
  ];

  match(lagecyRule.config)
    .with({ conditionType: '$status' }, (r) => {
      condition.push({
        key: 'status',
        op: 'intersect',
        input: toArray(r.status),
      });
    })
    .with({ text: P.not(P.nullish) }, (r) => {
      const textOpMap = {
        include: 'textInclude',
        equal: 'textInclude',
      } as const;

      condition.push({
        key: 'prop',
        index: r.conditionType,
        op: textOpMap[r.textRelation],
        input: [r.text],
      });
    })
    .with({ enum: P.not(P.nullish) }, (r) => {
      const opMap: Record<typeof r.normalRelation, ViewList.ViewconditionV2Item['op']> = {
        include: 'intersect',
        equal: 'intersect',
        empty: 'empty',
        no_empty: 'notEmpty',
      } as const;

      condition.push({
        key: 'prop',
        index: r.conditionType,
        op: opMap[r.normalRelation] || r.normalRelation,
        input: r.enum,
      });
    })
    .with({ user: P.not(P.nullish) }, (r) => {
      const opMap: Record<typeof r.normalRelation, ViewList.ViewconditionV2Item['op']> = {
        include: 'intersect',
        equal: 'intersect',
        empty: 'empty',
        no_empty: 'notEmpty',
      } as const;

      condition.push({
        key: 'prop',
        index: r.conditionType,
        op: opMap[r.normalRelation],
        input: r.user,
      });
    })
    .with({ numbers: P.not(P.nullish) }, (r) => {
      const opMap: Record<
        (typeof r.numbers)[number]['relation'],
        ViewList.ViewconditionV2Item['op']
      > = {
        great: 'greater',
        great_eq: 'greaterEqual',
        less: 'less',
        less_eq: 'lessEqual',
        equal: 'equal',
        no_equal: 'notEqual',
      } as const;

      r.numbers.forEach((item) => {
        condition.push({
          key: 'prop',
          index: r.conditionType,
          op: opMap[item.relation],
          input: [item.value],
        });
      });
    })
    .with({ formulaNumbers: P.not(P.nullish) }, (r) => {
      const opMap: Record<
        (typeof r.formulaNumbers)[number]['relation'],
        ViewList.ViewconditionV2Item['op']
      > = {
        great: 'greater',
        great_eq: 'greaterEqual',
        less: 'less',
        less_eq: 'lessEqual',
        equal: 'equal',
        no_equal: 'notEqual',
      } as const;

      r.formulaNumbers.forEach((item) => {
        condition.push({
          key: 'prop',
          index: r.conditionType,
          op: opMap[item.relation],
          input: [item.value],
        });
      });
    })
    .with({ normalRelation: P.string }, (r) => {
      const opMap = {
        equal: 'equal',
        no_equal: 'notEqual',
        empty: 'empty',
        no_empty: 'notEmpty',
      } as any;

      condition.push({
        key: 'prop',
        index: r.conditionType,
        op: opMap[r.normalRelation],
      });
    })
    .run();

  return condition;
};

export const lagecyRuleToRule = <
  T extends
    | ConditionRuleConfigV2.PropConditionResults
    | ConditionRuleConfigV2.TabSectionConditionResults
    | ConditionRuleConfigV2.TableConditionResults,
>(
  lagecyRule: ClientPropConditionRule,
  templateId: string,
): ConditionRuleConfigV2.ConditionRuleConfigV2<T> => {
  console.log(lagecyRule);
  const condition = lagecyRuleConditionToRuleCondition<T>(lagecyRule, templateId);

  return {
    condition,
    results: lagecyRule.config.results as T,
    ruleId: lagecyRule.ruleId,
    enable: lagecyRule.enable,
    createTime: lagecyRule.createTime,
  };
};
