import type {
  DataTableHeaderConfig,
  TemplateDataTableHeaderMapConfig,
} from '@linkpi/core';

import request from '@/utils/request';

export const getTemplateDataTableHeader = async (
  orgId: string,
  tempId: string,
) => {
  if (tempId === 'empty') {
    return { isOpen: false, header: undefined, headerMap: undefined };
  }

  const res = await request<{
    header: DataTableHeaderConfig & { source_temp: string };
    headerMap: TemplateDataTableHeaderMapConfig;
  }>('/api/tableHeader/queryTemp', {
    method: 'POST',
    data: {
      org_id: orgId,
      temp_id: tempId,
    },
  });

  console.log(res);
  if (res.status === 'ok') {
    return { ...res.data, isOpen: true };
  }

  return { isOpen: false, header: undefined, headerMap: undefined };
};
