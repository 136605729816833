import { PlusOutlined } from '@ant-design/icons';
import { ProForm, ProFormList, ProFormSelect } from '@ant-design/pro-form';
import type { CurrentUser } from '@linkpi/core';
import type { PiConnection } from '@linkpi/core/web';
import { Col, Row, Space, Tag, Tree } from 'antd';
import type { ComponentProps, FC } from 'react';
import { Fragment, useCallback, useMemo, useState } from 'react';

import { PiButton, RegularIcon } from '@/components';
import { TemplateCustomPropSelect } from '@/components/LinkPiForm';
import CascadeSetting from '@/pages/space/components/TemplateProp/components/CascadeSetting';
import type { TreeNode } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';

import { useTempPropConfig } from '../hooks';

import Styles from './cascadeType.less';

type ProFormListProp = ComponentProps<typeof ProFormList>;
type FilterItem = {
  level?: string;
  source?: string;
};

interface ICascadeType {
  matchingNodes: TreeNode[];
  orgConnection: PiConnection;
  templates: CurrentUser.TemplateInfo[];
  currentTemplate: CurrentUser.TemplateInfo;
}

export const CascadeType: FC<ICascadeType> = (props) => {
  const { matchingNodes, orgConnection, templates, currentTemplate } = props;
  const { propParams, setPropParams } = useTempPropConfig();
  const [cascadeVisible, setCascadeVisible] = useState(false);

  const quoteTemplate = templates.find(
    (t) => t.template_id === propParams.cascade?.quoteTemp,
  );

  const filterTitles = useMemo(() => {
    if (propParams.cascade?.titles?.length) {
      return propParams.cascade?.titles;
    } else if (propParams.cascade?.quoteNode) {
      return Array.from({
        length: orgConnection.nodeManager
          .findChildren(propParams.cascade.quoteNode)
          .all_children.reduce(
            (prev, i) => (prev < i.level ? i.level : prev),
            0,
          ),
      }).map((_, i) => `第${i + 1}级`);
    } else {
      return [];
    }
  }, [
    orgConnection.nodeManager,
    propParams.cascade?.quoteNode,
    propParams.cascade?.titles,
  ]);

  const actionRender: ProFormListProp['actionRender'] = useCallback(
    (field, action, _, count) => [
      <Space key="space">
        {count < (filterTitles?.length ?? 0) && (
          <PiButton
            shape="circle"
            icon={<RegularIcon type="iconButton_add" size={12} />}
            onClick={() => action.add({})}
            type="default"
            size="small"
          />
        )}
        {count > 1 && (
          <PiButton
            shape="circle"
            icon={<RegularIcon type="iconButton_cancel" size={12} />}
            size="small"
            type="default"
            onClick={() => action.remove(field.name)}
          />
        )}
      </Space>,
    ],
    [filterTitles.length],
  );

  const [filterList, setFilterList] = useState<FilterItem[]>([]);

  const filterLevelOptions = useCallback(
    (index) => {
      if (filterTitles?.length) {
        const selectedLevel = filterList
          .filter((_, i) => i !== index)
          .reduce<
            string[]
          >((prev, f) => (f.level ? [...prev, f.level] : prev), []);
        return filterTitles
          .filter((t) => !selectedLevel.some((level) => level === t))
          .map((title) => ({
            value: title,
            label: title,
          }));
      } else {
        return [];
      }
    },
    [filterList, filterTitles],
  );

  const handleValuesChange = (
    _: { filterList: FilterItem[] },
    { filterList: _filterList }: { filterList: FilterItem[] },
  ) => {
    setFilterList(_filterList);
    const filters = filterTitles?.map(
      (title) => _filterList.find((f) => f.level === title)?.source ?? null,
    );
    if (filters?.some((f) => f) && propParams.cascade)
      setPropParams({
        ...propParams,
        cascade: {
          ...propParams.cascade,
          filters,
        },
      });
  };

  const filterListInitialValue = useMemo(
    () =>
      propParams.cascade?.filters?.reduce<FilterItem[]>(
        (prev, f, i) =>
          f && filterTitles?.[i]
            ? [...prev, { level: filterTitles[i], source: f }]
            : prev,
        [],
      ) ?? [{}],
    [propParams.cascade, filterTitles],
  );

  return (
    <Fragment>
      <div className={'prop-item'} key={'cascade'}>
        <div className={'prop-item-label'}>选项设置</div>
        <div className={'prop-item-input'}>
          <Tag
            className="site-tag-plus"
            onClick={() => setCascadeVisible(true)}
            icon={<PlusOutlined />}
          >
            添加选项
          </Tag>
        </div>
      </div>
      {propParams.cascade ? (
        propParams.cascade.type === 1 ? (
          <div className={'cascade-quote-node'}>
            <p>
              引用主题树：
              {orgConnection.nodeManager.findChildren(
                propParams.cascade.quoteNode,
              )?.title || '无标题'}
            </p>
            {propParams.cascade?.quoteTemp ? (
              <p>
                主题类型：
                {quoteTemplate?.name || '此主题类型已删除'}
              </p>
            ) : null}
            {currentTemplate.template_id ? (
              <ProForm
                className={Styles['filter-container']}
                layout="horizontal"
                submitter={false}
                onValuesChange={handleValuesChange}
              >
                <ProFormList
                  label="筛选条件："
                  name="filterList"
                  creatorButtonProps={false}
                  min={1}
                  actionRender={actionRender}
                  initialValue={filterListInitialValue}
                >
                  {(f, index) => {
                    return (
                      <Row>
                        <Col span={10}>
                          <ProFormSelect
                            name="level"
                            allowClear
                            options={filterLevelOptions(index)}
                            placeholder="请选择层级"
                          />
                        </Col>
                        <Col span={2}>
                          <ProForm.Item
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            等于
                          </ProForm.Item>
                        </Col>
                        <Col span={10}>
                          <TemplateCustomPropSelect
                            name="source"
                            templateId={currentTemplate.template_id}
                            allowPropTypes={[
                              'text',
                              'formula',
                              'tag',
                              'enum',
                              'number',
                            ]}
                          />
                        </Col>
                      </Row>
                    );
                  }}
                </ProFormList>
              </ProForm>
            ) : null}
          </div>
        ) : propParams.cascade.nodes.length ? (
          <Tree
            treeData={propParams.cascade.nodes}
            showLine={true}
            rootClassName={'cascade-nodes-tree'}
          />
        ) : null
      ) : null}
      {cascadeVisible && propParams.cascade ? (
        <CascadeSetting
          quoteNodes={matchingNodes}
          cascade={propParams.cascade}
          visible={cascadeVisible}
          setCascade={(e: CurrentUser.cascadeSetting) => {
            setPropParams({ ...propParams, cascade: e });
            setCascadeVisible(false);
          }}
          templates={templates}
        />
      ) : null}
    </Fragment>
  );
};
