import { generateHtmlAttributes } from '@linkpi/utils';
import { useMountEffect, useUnmountEffect } from '@react-hookz/web';
import cls from 'clsx';
import { GridStack } from 'gridstack';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { RegularIcon } from '@/components/IconFont';
import SimpleTooltip from '@/components/SimpleTooltip';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';
import { cn } from '@/utils/utils';

import { GridComponentProvider } from '../../components';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { LayoutPositionType } from '../../models/Store';
import { getRoot, getWidgetElement, limitWarn, setupDragIn } from '../../utils';
import { WidgetComponent } from './WidgetComponent';

import styles from './styles.less';

// 通用组件 4 * 60 - 2 * margin
export const GeneralSection: FC<{
  gridId: string;
  parentGridStack: GridStack;
  type?: LayoutPositionType;
}> = (props) => {
  const { type } = props;
  const subGridStackContainerMargin = 8;
  const subGridStackCellHeight = 40;
  const { parentGridStack, gridId } = props;
  const item = useWidgetInstance(gridId);

  const editor = useEditor();

  // 当前激活的类
  const activeWidgetInstance: any = useActiveWidgetInstance() || {};

  const comps = (item?.children || []).map((i) =>
    editor.store.getWidgetInstance(i),
  ); // 基础组件

  const compsMap = useRef<any>({}); // 确保stack只add一次
  const subGridStackContainerRef = useRef<HTMLDivElement | null>(null);
  const subGridStackRef = useRef<GridStack | null>(null);

  const selectGeneralSection = () => {
    editor.setActiveWidgetInstance(gridId);
  };

  const delGeneralSection = () => {
    editor.removeWidgetInstance(gridId, { type });
    const parentElement =
      document.getElementById(gridId)?.parentElement?.parentElement;
    if (parentElement) parentGridStack.removeWidget(parentElement, true);
  };

  const copyGeneralSection = () => {
    if (editor.cloneWidgetInstance) editor.cloneWidgetInstance(gridId);
  };

  const renderTools = () => {
    if (activeWidgetInstance.id !== gridId) return null;

    const canClone = editor.checkWidgetClonable(gridId);
    const extraClassName = canClone ? '' : ' ' + styles.notClone;

    return (
      <div className={styles.generalSectionToolsContainer}>
        <SimpleTooltip title={limitWarn} showTooltip={!canClone}>
          <div
            onClick={() => {
              if (canClone) copyGeneralSection();
            }}
            className={styles.copy + extraClassName}
          >
            <RegularIcon type="iconfuzhi2" color="#6B7A96" />
            <span className="text-[#6B7A96] pl-1">复制</span>
          </div>
        </SimpleTooltip>
        <div
          onClick={delGeneralSection}
          className={cls(styles.del, 'ml-[6px]')}
        >
          <RegularIcon type="iconshanchu2" color="#6B7A96" />
        </div>
      </div>
    );
  };

  const addSubEvents = () => {
    const subGridStack = subGridStackRef.current!;

    subGridStack.on('added change', async (event: any, items: any[]) => {
      if (!items) return;

      const item: any = items[0];
      const { x, y, w, h, el } = item;

      // 新增
      if (!('id' in item)) {
        subGridStack.removeWidget(el, true, false);
        try {
          // left
          await editor.addWidgetInstanceAsync(el.dataset.widget, {
            x,
            y,
            parentId: gridId,
          });
        } catch (error) {
          console.log('🏮 取消新建');
        }
        return;
      }

      // 移动
      if (!editor.store.getWidgetInstance(item.id)) {
        // 别的容器过去的comp
        editor.addWidgetInstance(item.widgetId, {
          id: item.id,
          x,
          y,
          w,
          h,
          parentId: gridId,
        });
        subGridStack.removeWidget(el, true, false);
        return;
      }

      // 更新
      items.forEach((i: any) => {
        editor.store.resizeWidgetInstance(i.id, {
          x: i.x,
          y: i.y,
          h: i.h,
          w: i.w,
        });
      });
    });

    // 删除事件
    subGridStack.on('removed', (event: any, items: any[]) => {
      const item = items[0];
      compsMap.current[item.id] = false;
      if (editor.getWidgetInstance(item.id)) {
        editor.removeWidgetInstance(item.id, { type });
      }
      // remove
    });
  };

  useMountEffect(() => {
    subGridStackRef.current = GridStack.addGrid(
      subGridStackContainerRef.current!,
      {
        margin: subGridStackContainerMargin,
        draggable: {
          scroll: false,
          handle: '.grid-stack-item-content',
          appendTo: 'body',
        },
        acceptWidgets: (el) => {
          const widgetElement = getWidgetElement(el);
          if (widgetElement?.dataset?.nested === 'false') return true;

          return false;
        },
        cellHeight: subGridStackCellHeight, // 120 - masterMargin * 2 - padding * 2
        disableOneColumnMode: true,
        column: 12,
      },
    );

    setupDragIn();
    addSubEvents();
  });

  useUnmountEffect(() => {
    subGridStackRef.current?.offAll();
    subGridStackRef.current?.destroy();
  });

  const currentTemplate = useCurrentTemplate();

  useEffect(() => {
    comps.forEach((i: any) => {
      if (compsMap.current[i.id]) return;

      const widgetConfig = editor.widgetManager.getWidget(i.widgetId);
      // FIXME： widgetConfig 可能存在不存在
      const basic = widgetConfig?.basic || {};

      const htmlAttrs = generateHtmlAttributes({
        'data-nested': 'false',
        class: cls('comp', styles.compContainer),
        id: i.id,
        'data-dynamic-height': basic.dynamicHeight,
      });

      // demo div
      const content = `<div ${htmlAttrs}></div>`;

      compsMap.current[i.id] = true;

      const payload = { ...i, ...i.rect, content };
      if (basic.maxHeight) payload.maxH = basic.maxHeight;
      if (basic.maxWidth) payload.maxW = basic.maxWidth;
      if (basic.minHeight) payload.minH = basic.minHeight;
      if (basic.minWidth) payload.minW = basic.minWidth;

      subGridStackRef.current!.addWidget(payload);

      setTimeout(() => {
        //@ts-ignore
        const root = getRoot(i.id);
        root.render(
          <GridComponentProvider currentTemplate={currentTemplate}>
            <WidgetComponent
              parentGridStack={subGridStackRef.current!}
              compId={i.id}
            />
          </GridComponentProvider>,
        );
      }, 120);
    });
  }, [comps.length]);

  const renderName = () => {
    const { config } = item || {};
    const { showName, name } = config || {};
    if (!showName || !name) return null;
    return (
      <div className={styles.title}>
        <div>{name}</div>
      </div>
    );
  };

  const getStyle = () => {
    const style: any = {};
    const { config } = item || {};
    const { showName, name } = config || {};
    if (!showName || !name) style.height = '100%';
    else style.height = 'calc(100% - 40px)'; // 存在title时去掉滚动条

    if (comps.length === 0) {
      style.display = 'flex';
      style.alignItems = 'center';
    }
    return style;
  };

  return (
    <div
      className={cn(
        activeWidgetInstance.id === gridId ? styles.activeGeneralSection : '',
        styles.subGridStack,
      )}
      onClick={selectGeneralSection}
      style={{ backgroundColor: (item?.config.bgColor as string) || 'white' }}
    >
      {renderTools()}
      {renderName()}
      {/* 适当的给padding */}
      {/* <ScrollArea className="h-full"> */}
      <div
        style={getStyle()}
        ref={subGridStackContainerRef}
        className="grid-stack"
      >
        {comps.length < 1 && (
          <div className={styles.tip}>请从左侧选择组件拖入</div>
        )}
      </div>
      {/* </ScrollArea> */}
    </div>
  );
};
