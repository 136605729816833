import type Delta from 'quill-delta';
import ReactQuill, { Quill } from 'react-quill-new';

import { PMTheme } from '@/components/PiQuillTheme';
import PiToolbar from '@/components/PiQuillToolbar';

Quill.register('modules/tempToolbar', PiToolbar);
Quill.register('themes/pm', PMTheme);

import { useControllableValue } from 'ahooks';
import { useRef } from 'react';

import { useCurrentTemplateSetting, useOrgTemplatesSettingInfo } from '@/hook';

import '@/components/PiQuillTheme/pm/index.less';

interface RichTextProps {
  value?: ReactQuill.Value;
  onChange?: (v: ReactQuill.Value, contents: Delta) => void;
  placeholder?: string;
  onBlur?: (v: string) => void;
}

class PMQuill extends ReactQuill {
  destroyEditor() {
    if (!this.editor) return;
    this.unhookEditor(this.editor);
    const toolbar = this.editor.getModule('tempToolbar') as InstanceType<
      typeof PiToolbar
    >;
    toolbar.destroy();
  }

  instantiateEditor() {
    if (this.editor) {
      this.hookEditor(this.editor);
    }
    super.instantiateEditor();
  }
}

const RichText = (props: RichTextProps) => {
  const { value, onChange, placeholder, onBlur } = props;
  const [_value, _onChange] = useControllableValue<ReactQuill.Value>({
    value,
    onChange,
  });
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const quillRef = useRef<Quill | null>(null);

  const [currentTemplate] = useCurrentTemplateSetting();
  const [templateList] = useOrgTemplatesSettingInfo();

  const handleReactQuillRef = (ref: ReactQuill | null) => {
    if (!ref) {
      return;
    }
    reactQuillRef.current = ref;
    quillRef.current = ref.getEditor();
  };

  const handleChange: ReactQuill.ReactQuillProps['onChange'] = (
    v,
    delta,
    mode,
    editor,
  ) => {
    _onChange(v, editor.getContents());
  };

  const handleBlur: ReactQuill.ReactQuillProps['onBlur'] = (
    range,
    source,
    editor,
  ) => {
    const v = editor.getText();
    const _v = v === '\n' ? '' : v;
    onBlur?.(_v);
  };

  return (
    <div>
      <PMQuill
        placeholder={placeholder}
        theme="pm"
        ref={handleReactQuillRef}
        value={_value}
        modules={{
          toolbar: false,
          tempToolbar: {
            tip: true,
            currentTemplate,
            templateList,
          },
        }}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default RichText;
