import { getPiSDK } from '@linkpi/sdk';
import { isEmpty } from 'ramda';

import type { IDataSourceSetting } from '@/components/PageModelEditor/src/components';

import { convertGetTempFilterNodeConditionV2, loadNodes } from './utils';

function paginate<T>(data: T[], page: number, pageSize: number): T[] {
  // 计算起始索引
  const startIndex = (page - 1) * pageSize;
  // 计算结束索引
  const endIndex = startIndex + pageSize;
  // 返回分页数据
  return data.slice(startIndex, endIndex);
}

type FetchDataSourceParam = IDataSourceSetting & {
  /**
   * 仅在 type 未 'dataSource' 时有效
   */
  pageSize: number;
  /**
   * 仅在 type 未 'dataSource' 时有效
   */
  page: number;
  orgId: string;
  currentNodeId?: string;
  group?: string;
  orderBy?: string;
  orderDesc?: boolean;
};

export const fetchDataSource = async ({
  type,
  orgId,
  currentNodeId,
  ...params
}: FetchDataSourceParam) => {
  const piSDK = getPiSDK();
  const nodeManager =
    piSDK.piConnectionManager.getPiConnection(orgId).nodeManager;
  switch (type) {
    case 'dataSource': {
      const conditions = convertGetTempFilterNodeConditionV2({
        conditionV2: params.conditions,
        currentUserId: nodeManager._accountId,
      });

      // 追加匹配条件
      if (params?.matchings?.length && currentNodeId) {
        const piNode = await nodeManager.getNode(currentNodeId);
        // 追加conditionV2
        const tempProps = piNode.tempInfo.prop;
        params.matchings.forEach((x) => {
          if (!x.target) return;
          const value = tempProps[x.target];
          const inp = Array.isArray(value) ? value : [value];
          conditions.push({
            op: 'intersect',
            key: 'prop',
            input: inp,
            index: x.origin,
          });
        });
      }

      const res = await piSDK.tableHeaderManager.queryByTemplate({
        pageSize: params.pageSize,
        page: params.page,
        orgId,
        condition: conditions,
      });

      if (res.status !== 'ok') {
        return {
          status: 'error',
          message: res.message,
          list: [] as PiNode[],
          total: 0,
          page: params.page,
        };
      }

      const nodeIdList = await nodeManager.getDbNode(
        res.thmId,
        res.originResult,
      );

      return {
        list: nodeIdList.map((id) => nodeManager.getNode(id)),
        total: res.total,
        page: params.page,
        status: 'ok',
      };
    }
    case 'temp': {
      const rootId = nodeManager._rootId;

      const piNode = await nodeManager.getNode(currentNodeId || rootId);

      /**
       * 移除移除根节点筛选
       */
      const ancestor = (params.conditions || []).find(
        (i: any) => i.key === 'ancestor',
      );
      if (ancestor && ancestor.input[0] === rootId) {
        params.conditions = params.conditions.filter(
          (i: any) => i.key !== 'ancestor',
        );
      }

      /**
       * 移除空条件
       */
      params.conditions = params.conditions.filter((i) => !isEmpty(i.input));

      const nodes = await loadNodes(piNode, {
        currentUserId: nodeManager._accountId,
        ...params,
      });

      // 更新时间排序
      if (params.orderBy === '_sys_updateTime') {
        nodes.sort((nodeA, nodeB) => nodeB.metadata.m - nodeA.metadata.m);
      }

      // 系统属性
      if (params.orderBy === '_sys_createTime') {
        nodes.sort((nodeA, nodeB) => {
          return params.orderDesc
            ? nodeA.createTime - nodeB.createTime
            : nodeB.createTime - nodeA.createTime;
        });
      }

      return {
        list: paginate(nodes, params.page, params.pageSize),
        total: nodes.length,
        page: params.page,
        status: 'ok',
      };
    }
    default:
      throw new Error('Invalid type');
  }
};
