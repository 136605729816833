import type { ViewList } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { type FC, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import ConditionFilter from '@/components/ConditionFilter';
import { cn } from '@/utils/utils';

import type { IGlobalConditionFilterTarget } from './types';

import styles from './MatchingItem.module.less';

type IMatchingItemProps = {
  templateId: string;
  value?: IGlobalConditionFilterTarget['matchingList'][number];
  onChange?: (
    value: IGlobalConditionFilterTarget['matchingList'][number],
  ) => void;
  className?: string;
  editable?: boolean;
};

const getTreeData = () => [];

/**
 * 基于 ConditionFilter 精简后隐藏 value 的组件
 *
 * 临时方案
 */
export const MatchingItem: FC<IMatchingItemProps> = ({
  templateId,
  value,
  onChange,
  className,
  editable,
}) => {
  const [_value, _onChange] = useControllableValue<IMatchingItemProps['value']>(
    { value, onChange },
  );

  const conditions = useMemo(() => {
    const result: ViewList.ViewconditionV2 = [
      {
        key: 'templateId',
        op: 'intersect',
        input: [templateId],
      },
    ];

    match(_value)
      .with({ propKey: P.when((i) => !!i) }, ({ propKey, op }) => {
        const [key, _index] = propKey.split('_');
        if (_index) {
          result.push({
            key,
            op,
            index: Number(_index),
            input: [],
          });
        } else {
          result.push({
            key,
            op,
            input: [],
          });
        }
      })
      .otherwise(() => {
        result.push({
          key: '',
          op: 'equal',
          input: [],
        });
      });

    return result;
  }, [_value, templateId]);

  const onConditionFilterChange = (v: ViewList.ViewconditionV2) => {
    const [c] = v.filter((i) => i.key !== 'templateId');

    const propKey = match(c)
      .with({ index: P.number }, ({ key, index }) => `${key}_${index}`)
      .otherwise(() => c.key);

    _onChange({
      propKey,
      op: c.op,
    });
  };

  return (
    <ConditionFilter
      className={cn(styles.MatchingItem, className)}
      templateUsage="fullAccess"
      conditions={conditions}
      onChange={(v) => onConditionFilterChange(v.conditionsV2)}
      editable={editable || true}
      getTreeData={getTreeData}
      hideDelete
      displayTemplate={false}
      displayAddBtn={false}
      experimental_disableHistoryStatus
    />
  );
};
