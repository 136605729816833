import { multiply } from 'color-blend';
import hexRgb from 'hex-rgb';
import type { FC, ReactElement } from 'react';
import { cloneElement } from 'react';

import styles from './WidgetIcon.less';

function hexToRgbA(hex: string) {
  const { red, green, blue, alpha } = hexRgb(hex);
  return { r: red, g: green, b: blue, a: alpha };
}

export const WidgetIcon: FC<{
  icon: ReactElement<{ size: number }>;
  bgColor: string;
}> = ({ icon, bgColor }) => {
  const shadowColor = multiply(hexToRgbA(bgColor), hexToRgbA(bgColor));

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundColor: bgColor,
        ['--shadow-color' as any]: `rgba(${shadowColor.r},${shadowColor.g},${shadowColor.b},${shadowColor.a})`,
      }}
    >
      {cloneElement(icon, { size: 16 })}
    </div>
  );
};
