import { Divider, Form, Radio, Space } from 'antd';
import type { FC } from 'react';

import { TemplateSelect } from '@/components/LinkPiForm';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

import { useActiveWidgetInstance, useEditor } from '../hooks';
import { NameSetting } from './NameSetting';

export type ISharedStateNodeSetting = {
  nodeSource?: 'current' | 'sharedState';
  templateId?: string;
};

const options = [
  { label: '当前节点', value: false },
  { label: '其他节点', value: true },
];

/**
 * 节点数据源的配置
 */
export const SharedStateNodeSetting: FC = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance<ISharedStateNodeSetting>()!;
  const tempInfo = useCurrentTemplate();

  return (
    <>
      <NameSetting defaultName={'普通表格'} />
      <Form.Item label={<Space>匹配源</Space>}>
        <Space direction="vertical" className="full-w">
          <Radio.Group
            options={options}
            value={widgetIntance.config.nodeSource === 'sharedState'}
            onChange={(e) => {
              editor.store.updateWidgetInstanceConfig<ISharedStateNodeSetting>(
                widgetIntance.id,
                (draft) => {
                  draft.nodeSource = e.target.value ? 'sharedState' : 'current';
                },
              );
            }}
          />
          {widgetIntance.config.nodeSource === 'sharedState' && (
            <TemplateSelect
              fieldProps={{
                value: widgetIntance.config.templateId || tempInfo.template_id,
                onChange: (id) => {
                  editor.store.updateWidgetInstanceConfig<ISharedStateNodeSetting>(
                    widgetIntance.id,
                    (draft) => {
                      draft.templateId = id || tempInfo.template_id;
                    },
                  );
                },
              }}
              noStyle
            />
          )}
        </Space>
      </Form.Item>
      <Divider dashed />
    </>
  );
};
