import cls from 'classnames';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import { reverse } from 'ramda';
import type { ForwardRefRenderFunction } from 'react';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { RegularIcon } from '@/components/IconFont';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/utils/utils';

import styles from './CollapsiblePanel.less';

const Icons = [
  <RegularIcon key="unfold" type="iconchangyong-shouqi" />,
  <RegularIcon key="fold" type="iconchangyong-shouqi2" />,
];

export type CollapsiblePanelRef = {
  setOpen: (open: boolean) => void;
};

const _CollapsiblePanel: ForwardRefRenderFunction<
  CollapsiblePanelRef,
  React.PropsWithChildren<{
    side: 'left' | 'right';
    width?: number;
    className?: string;
    defaultOpen?: boolean;
  }>
> = (
  { children, side = 'left', width = 320, className, defaultOpen = true },
  ref,
) => {
  const [visible, setVisible] = useState(defaultOpen);
  const setOpen = useCallback(() => {
    setVisible(true);
  }, []);
  const setClose = useCallback(() => {
    setVisible(false);
  }, []);
  const toggleVisible = useCallback(() => {
    setVisible((v) => !v);
  }, []);

  useImperativeHandle(ref, () => ({
    setOpen(open) {
      if (open) setOpen();
      else setClose();
    },
  }));

  const variants: Variants = useMemo(
    () => ({
      open: { opacity: 1, width },
      closed: { opacity: 0.4, width: 0 },
    }),
    [width],
  );

  const [FoldOutIcon, UnfoldOutIcon] = side === 'left' ? Icons : reverse(Icons);

  return (
    <div
      className={cn(
        styles.wrapper,
        className,
        side === 'left' ? styles.left : styles.right,
      )}
    >
      <motion.div
        className={cn(styles.panel, 'h-full')}
        layout
        variants={variants}
        transition={{
          type: 'tween',
          duration: 0.16,
        }}
        animate={visible ? 'open' : 'closed'}
      >
        <div className={cn(styles.content, 'h-full')} style={{ width }}>
          {visible && (
            <div className={styles.icon} onClick={toggleVisible}>
              {FoldOutIcon}
            </div>
          )}
          <ScrollArea className="h-full">{children}</ScrollArea>
        </div>
      </motion.div>
      {!visible && (
        <div className={cls(styles.icon, styles.open)} onClick={toggleVisible}>
          {UnfoldOutIcon}
        </div>
      )}
    </div>
  );
};

export const CollapsiblePanel = forwardRef(_CollapsiblePanel);
