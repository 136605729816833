import { assertExists } from '@linkpi/utils';
import { Radio } from 'antd';

import { useActiveWidgetInstance, useEditor } from '../hooks';

export const TableAddingBtnSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance();
  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig;

  const types = [
    { label: '显示', value: false },
    { label: '隐藏', value: true },
  ];

  let { hideAdd } = widgetInstance.config as any;
  hideAdd =!!hideAdd

  const changeNodeOpenType = (v: boolean) => {
    updateConfig((config: any) => {
      config.hideAdd = v;
    });
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <div style={{ color: '#242D3F', fontWeight: 700 }}>分组新增</div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {types.map((t) => (
          <div
            style={{
              backgroundColor: '#F8F9FB',
              borderRadius: 8,
              padding: '8px 10px 8px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
              width: 'calc(50% - 7px)',
            }}
            onClick={() => changeNodeOpenType(t.value)}
            key={t.label}
          >
            <div style={{ color: '#242D3F' }}>{t.label}</div>
            <Radio checked={hideAdd === t.value} />
          </div>
        ))}
      </div>
    </div>
  );
};
