import { type FC, Fragment } from 'react';

export const NewLineText: FC<{ value: string }> = ({ value }) => {
  // 将字符串按换行符分割成数组
  const lines = value.toString().split('\\n');

  return lines.map((line, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={index}>
      {line}
      {index < lines.length - 1 && <br />}
    </Fragment>
  ));
};
