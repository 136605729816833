import { useControllableValue } from 'ahooks';
import { Popover } from 'antd';
import cls from 'classnames';
import type { FC } from 'react';
import ColorPicker, {
  type ColorPickerProps,
} from 'react-best-gradient-color-picker';

import styles from './styles.less';

export type ColorPickerProp = {
  className?: string;
  style?: React.CSSProperties;
  itemRender?: (c?: string) => React.ReactNode;
  value?: string;
  onChange?: (value: string) => void;
} & Omit<ColorPickerProps, 'value' | 'onChange'>;

export const GradientColorPicker: FC<ColorPickerProp> = (props) => {
  const [value, setValue] = useControllableValue(props);

  const content = (
    <ColorPicker
      disableDarkMode
      hideAdvancedSliders
      hideColorGuide
      {...props}
      value={value}
      onChange={setValue}
    />
  );

  return (
    <Popover content={content} trigger="click">
      {props.itemRender?.(props.value) ?? (
        <span
          className={cls(props.className, styles.content)}
          style={{
            ...props.style,
            background: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=') left center`,
          }}
        >
          <span
            style={{
              background: value?.toString(),
              display: 'inline-flex',
              height: '100%',
              width: '100%',
            }}
          />
        </span>
      )}
    </Popover>
  );
};
