import { buildRecord, toRecord } from '@linkpi/utils';
import type {
  IWidget as ICustomWidget,
  WidgetRender,
} from '@mylinkpi/widget-core';
import type { Draft } from 'immer';
import { produce, setAutoFreeze } from 'immer';
import { cloneDeep } from 'lodash';
import { memoize } from 'proxy-memoize';
import { defaultTo, values } from 'ramda';
import type { FC } from 'react';
import { injectable } from 'tsyringe-neo';
import { JsonObject } from 'type-fest';

import * as widgets from '../widgets';
import { BaseModel } from './BaseModel';
import { CUSTOM_GROUPS, DEFAULT_GROUPS } from './widgetGroup';

export type WidgetSetting<T = unknown> = FC<
  T & {
    id: string;
  }
>;

export type WidgetPreivew<T = unknown> = FC<
  T & {
    id: string;
  }
>;

export type IWidget<P extends string, T extends JsonObject = JsonObject> = Omit<
  ICustomWidget<P, T>,
  'render'
> & {
  type: 'system' | 'custom';
  render?: WidgetRender;
};

export interface IGroup {
  id: string;
  title: string;
  layout?: 'grid' | 'list';
  /**
   * 内部是 widget id 或者 分组
   */
  items: string[];
  orgIds?: string[];
}

type IEvents = {
  groupsChanged: undefined;
};

const genWidgets = memoize(
  ({
    groups,
    widgetsMap,
  }: {
    groups: IGroup[];
    widgetsMap: Record<string, IWidget<any, any>>;
  }) => {
    return groups.map((subG) => ({
      ...subG,
      items:
        subG.items?.map((item) => {
          return widgetsMap[item];
        }) || [],
    }));
  },
);

const buildGroupMap = memoize((groups: IGroup[]) => {
  return buildRecord(groups, 'id');
});

/**
 * @todo
 * - 动态添加 group
 * - 动态添加 widget
 * - dispose 销毁
 */
@injectable()
export class WidgetManager extends BaseModel<IEvents> {
  #widgetsMap!: Record<string, IWidget<any, any>>;

  #groups!: IGroup[];

  #customGroups!: IGroup[];

  get groupMap() {
    return buildGroupMap(this.#groups);
  }

  get customGroupMap() {
    return buildGroupMap(this.#customGroups);
  }

  constructor() {
    super('widgetManager');
    this.#init();
  }

  #init() {
    this.#groups = cloneDeep(DEFAULT_GROUPS) as unknown as IGroup[];
    this.#customGroups = cloneDeep(CUSTOM_GROUPS) as unknown as IGroup[];
    this.#widgetsMap = toRecord<IWidget<any, any>>((w) => ({
      [w.id]: w,
    }))(values(widgets));
  }

  public getWidget(id: string) {
    return this.#widgetsMap[id];
  }

  public get widgets() {
    const groups = this.#groups;
    return genWidgets({
      groups,
      widgetsMap: this.#widgetsMap,
    });
  }

  public get customWidgets() {
    const groups = this.#customGroups;
    return genWidgets({
      groups,
      widgetsMap: this.#widgetsMap,
    });
  }

  public updateSystemGroups(updater: (d: Draft<IGroup[]>) => void) {
    /**
     * @NOTE
     * Error: 'get' on proxy: property 'xxx' is a read-only and non-configurable data property on the proxy target but the proxy did not return its actual value
     *
     * immer + proxy-memoize
     * issue: https://github.com/dai-shi/proxy-compare/pull/8#issuecomment-776494180
     */
    setAutoFreeze(false);
    this.#groups = produce(this.#groups, updater);
    setAutoFreeze(true);
    this.emitter.emit('groupsChanged');
  }

  public updateGroups(updater: (d: Draft<IGroup[]>) => void) {
    /**
     * @NOTE
     * Error: 'get' on proxy: property 'xxx' is a read-only and non-configurable data property on the proxy target but the proxy did not return its actual value
     *
     * immer + proxy-memoize
     * issue: https://github.com/dai-shi/proxy-compare/pull/8#issuecomment-776494180
     */
    setAutoFreeze(false);
    this.#customGroups = produce(this.#customGroups, updater);
    setAutoFreeze(true);
    this.emitter.emit('groupsChanged');
  }

  public addGroup(group: IGroup) {
    if (this.customGroupMap[group.id]) {
      return;
    }
    let defaultGroup: IGroup | undefined;

    const list = [...this.#customGroups, group]
      .sort((a, b) => -1 * a.title.localeCompare(b.title))
      .filter((g) => {
        if (g.id === 'defaultCustomWidgetGroups') {
          defaultGroup = g;
          return false;
        }
        return true;
      });

    if (defaultGroup) {
      list.push(defaultGroup);
    }

    this.#customGroups = list;

    this.emitter.emit('groupsChanged');
  }

  /**
   * 支持动态增加 widget
   */
  public addWidget(
    w: IWidget<any, any>,
    _groupId = 'defaultCustomWidgetGroups',
  ) {
    const groupId = defaultTo('defaultCustomWidgetGroups', _groupId);

    if (!this.customGroupMap[groupId]) {
      this.addGroup({
        id: groupId,
        title: groupId,
        items: [],
        layout: 'grid',
      });
      return;
    }

    this.updateGroups((d) => {
      d.forEach((group) => {
        if (group?.items && group.id === groupId) {
          if (!this.#widgetsMap[w.id]) {
            group.items.push(w.id);
          }
          this.#widgetsMap[w.id] = w;
        }
      });
    });
  }

  public getGroup(id: string) {
    return this.groupMap[id];
  }

  public getCustomGroup(id: string) {
    return this.customGroupMap[id];
  }

  public override dispose() {
    super.dispose();
    this.#init();
  }
}
