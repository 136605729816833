import { Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import previewIMG from '../../assets/statusProp.png';
import type { AlignType } from '../../components';
import { AlignRadio, WidgetIcon } from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type INodeStatusPropsConfig = { align: AlignType };

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={previewIMG} style={{ display: 'block', height: 48 }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance<INodeStatusPropsConfig>()!;

  const setAlign = (r: AlignType) => {
    editor.store.updateWidgetInstanceConfig<INodeStatusPropsConfig>(
      widgetIntance.id,
      {
        ...widgetIntance?.config,
        align: r,
      },
    );
  };

  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <Form.Item label="对齐">
        <AlignRadio
          value={widgetIntance.config.align}
          onChange={(e) => setAlign(e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};

export const NodeStatusPropsWidget =
  defineSystemWidget<INodeStatusPropsConfig>()({
    id: 'NodeStatusProps',
    title: '状态属性',
    icon: () => {
      return (
        <WidgetIcon
          bgColor="#14C0FF"
          icon={
            <RegularIcon
              type="iconneirongyepeizhi-zhuangtaishuxing"
              color={'#ffffffcc'}
            />
          }
        />
      );
    },
    basic: {
      defaultHeight: 2,
      defaultWidth: 6,
      minHeight: 2,
      minWidth: 6,
      maxCount: 1,
      maxHeight: 2,
      dynamicHeight: true,
    },
    metadata: {
      align: 'left',
    },
    preview: Preview,
    setting: Setting,
  });
