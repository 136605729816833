import { assertExists } from '@linkpi/utils';
import { Form, Radio, Switch } from 'antd';
import { type FC } from 'react';

import { useNodePageType } from '@/pages/home/Workbench/NodePageModel/hooks';

import { useActiveWidgetInstance, useEditor } from '../hooks';

import styles from './ConditionSetting.less';

export const AddSetting: FC = () => {
  const editor = useEditor();
  const widgetInstance: any = useActiveWidgetInstance();
  const type = useNodePageType();

  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig;

  const changeChildThemeRequired = (v: boolean) => {
    updateConfig((config: any) => {
      config.childThemeRequired = v;
    });
  };

  const changeAddType = (v: string) => {
    updateConfig((config: any) => {
      config.addType = v;
    });
  };

  return (
    <Form.Item>
      <div
        style={{
          display: type === 'theme' ? 'flex' : 'none',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '8px 0',
        }}
      >
        <div style={{ color: '#242d3f' }}>子主题必填</div>

        <Switch
          checked={widgetInstance.config.childThemeRequired}
          onChange={changeChildThemeRequired}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '8px 0',
        }}
        className={styles.addType}
      >
        <div style={{ color: '#242d3f' }}>子主题新增方式</div>

        <Radio.Group
          style={{ display: 'flex' }}
          value={widgetInstance.config.addType}
          onChange={(e) => changeAddType(e.target.value)}
        >
          <Radio style={{ marginRight: 12 }} value={'modal'}>
            弹窗
          </Radio>
          <Radio value={'inline'}>行内</Radio>
        </Radio.Group>
      </div>
    </Form.Item>
  );
};
