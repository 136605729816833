import type { CurrentUser } from '@linkpi/core';
import { Button, Input, Popover, Select } from 'antd';
import cls from 'classnames';
import { useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { RegularIcon } from '@/components/IconFont';
import { useOrgTemplatesSettingInfoMap } from '@/hook';
import { cn } from '@/utils/utils';

import type { IDataIndicatorWidget } from '../../widgets';
import { getDefaultIndexSubitemData } from './Form';

import styles from './SubitemsForm.less';

type SubitemType = IDataIndicatorWidget['indexes'][string]['subitems'][number];

const getPropName = (
  tempInfo?: CurrentUser.TemplateInfo,
  propIndex?: number,
) => {
  if (!tempInfo || propIndex === undefined || !tempInfo.prop[propIndex])
    return '未命名';
  return tempInfo.prop[propIndex]?.name;
};

export const SubitemsForm = (props: {
  value: SubitemType[];
  onChange: (newData: SubitemType[]) => void;
  dataSourceTempId?: string;
}) => {
  const { value: subitemListData, onChange, dataSourceTempId } = props;

  const tempMap = useOrgTemplatesSettingInfoMap();

  const tempInfo = useMemo(
    () => (dataSourceTempId ? tempMap[dataSourceTempId] : undefined),
    [dataSourceTempId, tempMap],
  );

  const propOptions = useMemo(() => {
    if (!tempInfo) return [];
    const options: { label: string; value: number }[] = [];
    tempInfo.prop.map((x, index) => {
      if (x.type) {
        options.push({
          label: x.name,
          value: index,
        });
      }
    });
    return options;
  }, [tempInfo]);

  const handleAddSubitem = () => {
    const newSubitemData = getDefaultIndexSubitemData();
    onChange([newSubitemData, ...subitemListData]);
  };
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;

    // 排序
    const newSubitemData = [...subitemListData];
    const moveItem = newSubitemData.splice(sourceIndex, 1)[0];
    newSubitemData.splice(targetIndex, 0, moveItem);
    onChange(newSubitemData);
  };

  const handleDelete = (index: number) => {
    onChange(subitemListData.filter((_, i) => i !== index));
  };

  const onSubitemDataChange = (index: number, newData: SubitemType) => {
    const newSubitemData = [...subitemListData];
    newSubitemData[index] = newData;
    onChange(newSubitemData);
  };

  const dragList = (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef}>
            {subitemListData.map((subitem, index) => (
              <Draggable key={index} draggableId={String(index)} index={index}>
                {(draggableProvided) => (
                  <div
                    key={index}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={{ ...draggableProvided.draggableProps.style }}
                  >
                    <div className={styles.indexCard}>
                      <span
                        style={{
                          color: '#6B7A96',
                          cursor: 'move',
                        }}
                      >
                        <RegularIcon type="iconchangyong-paixu" size={16} />
                      </span>
                      <div className={cls(styles.name, 'text-omit')}>
                        {getPropName(tempInfo, subitem.propIndex)}
                      </div>
                      <div className={styles.handBtns}>
                        <SubitemEditBtn
                          data={subitem}
                          propOptions={propOptions}
                          dataSourceTempId={dataSourceTempId}
                          onChange={(newData) =>
                            onSubitemDataChange(index, newData)
                          }
                        />

                        {subitemListData.length <= 1 ? null : (
                          <Button
                            onClick={() => handleDelete(index)}
                            type="link"
                            danger
                            icon={
                              <RegularIcon
                                type="iconshanchu2"
                                size={16}
                              ></RegularIcon>
                            }
                          ></Button>
                        )}

                        <a></a>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <div className={styles.SubitemsForm}>
      <div className={styles.dragList}>{dragList}</div>
      <Button
        type="dashed"
        onClick={handleAddSubitem}
        style={{ color: '#767C88' }}
        icon={
          <RegularIcon
            size={12}
            type="iconchangyong-tianjia"
            color="#767C88"
            className=" align-middle"
          />
        }
      >
        添加指标项
      </Button>
    </div>
  );
};

const SubitemEditBtn = (props: {
  data: SubitemType;
  onChange: (newData: SubitemType) => void;
  propOptions: { label: string; value: number }[];
  dataSourceTempId?: string;
}) => {
  const { data, onChange, propOptions, dataSourceTempId } = props;

  let content = null;
  if (!dataSourceTempId) {
    content = (
      <div
        className={cn(
          styles.subitemEditPopver,
          'flex items-center justify-center h-[168px] text-[#B6B8B9]',
        )}
      >
        暂无数据源，请先添加数据源
      </div>
    );
  } else {
    content = (
      <div className={styles.subitemEditPopver}>
        <div className={styles.title}>显示属性</div>
        <div className={styles.value}>
          <Select
            value={data.propIndex}
            options={propOptions}
            placeholder="请选择"
            onChange={(e) => onChange({ ...data, propIndex: e })}
          ></Select>
        </div>

        <div className={styles.title}>
          显示统计属性
          <div
            className=" cursor-pointer"
            onClick={() =>
              onChange({
                ...data,
                showStatistical: !data.showStatistical,
              })
            }
          >
            <RegularIcon
              color="#316EF5"
              type={data.showStatistical ? 'iconshanchu3' : 'icontianjia'}
            />
          </div>
        </div>

        {data.showStatistical ? (
          <div>
            <div className={styles.subTitle}>统计属性名称</div>
            <div className={styles.value}>
              <Input
                value={data.statisticalName}
                placeholder="请输入"
                onChange={(e) =>
                  onChange({
                    ...data,
                    statisticalName: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles.subTitle}>统计属性</div>
            <div className={styles.value}>
              <Select
                value={data.statisticalPropIndex}
                options={propOptions}
                placeholder="请选择"
                onChange={(e) => onChange({ ...data, statisticalPropIndex: e })}
              ></Select>
            </div>

            <div className={styles.subTitle}>
              显示统计图标
              <div
                className=" cursor-pointer"
                onClick={() =>
                  onChange({
                    ...data,
                    showSuffixIcon: !data.showSuffixIcon,
                  })
                }
              >
                <RegularIcon
                  color="#316EF5"
                  type={data.showSuffixIcon ? 'iconshanchu3' : 'icontianjia'}
                />
              </div>
            </div>

            {data.showSuffixIcon ? (
              <div>
                <div className={styles.subTitle}>图标属性</div>
                <div className={styles.value}>
                  <Select
                    value={data.suffixIconPropIndex}
                    options={propOptions}
                    placeholder="请选择"
                    onChange={(e) =>
                      onChange({ ...data, suffixIconPropIndex: e })
                    }
                  ></Select>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <Popover
      trigger={['click']}
      placement="left"
      content={content}
      overlayClassName={styles.EditSortIndexButtonPopover}
    >
      <Button
        type="link"
        icon={
          <RegularIcon type="iconshaixuanqu-bianji" size={16}></RegularIcon>
        }
      ></Button>
    </Popover>
  );
};
