import { assertExists } from '@linkpi/utils';
import { Form } from 'antd';
import React, { type FC } from 'react';

import { useActiveWidgetInstance, useEditor } from '../../hooks';
import { type IMatchingSetting, MatchingSettingCore } from './Core';

interface IProps {
  style?: React.CSSProperties;
}

export const MatchingSetting: FC<IProps> = ({ style }) => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IMatchingSetting>();

  assertExists(widgetInstance);

  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<IMatchingSetting>;
  const value = widgetInstance.config;

  const handleChange = (newMatchings: IMatchingSetting) => {
    updateConfig((config) => {
      config.matchings = newMatchings.matchings;
    });
  };

  return (
    <Form.Item>
      <MatchingSettingCore
        value={value}
        onChange={handleChange}
        style={style}
      />
    </Form.Item>
  );
};
