import type { CurrentUser } from '@linkpi/core';
import type { Quill } from 'react-quill-new';

export const FontSizes = [12, 14, 16, 18, 24, 32, 64];
export const ToolbarClassName = 'ql-pi-toolbar';

export interface Bounds {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
}

export interface Positions {
  top: number;
  left: number;
}

export interface CommonWidgetProps {
  beforeChange: () => void;
  endChange: () => void;
  container: HTMLElement;
  currentTemplate: CurrentUser.TemplateInfo;
  quill: Quill;
  templateList: CurrentUser.TemplateInfo[];
}

export const SystemAttrs = [
  { label: '标题', value: 'title' },
  { label: '系统标签', value: 'sysTag' },
  { label: '状态', value: 'status' },
  { label: '创建时间', value: 'createTime' },
  { label: '创建人', value: 'creator' },
  // { label: '正文', value: 'mainbody' },
  { label: '树层ID', value: 'treeLevelId' },
];

export const StatusAttrs = [
  { label: '负责人', value: 'statusProp_common_0' },
  { label: '参与者', value: 'statusProp_common_1' },
  { label: '开始时间', value: 'statusProp_common_2' },
  { label: '结束时间', value: 'statusProp_common_3' },
  { label: '备注', value: 'statusProp_common_4' },
];
