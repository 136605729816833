import cls from 'classnames';
import { nanoid } from 'nanoid';
import { includes } from 'ramda';
import { type FC, useEffect, useMemo } from 'react';

import { StatisticSettingModal, StatisticSettingModalSymbol } from '../components';
import { useEditor, useEditorId, usePageConfig } from '../hooks';
import { SettingPanel, WidgetPanel } from '../panels';
import { EmptyChecker } from '../panels/EmptyChecker';
import PiGridStack from '../panels/PiGridStack';

import styles from './Layout.less';

// NTOE: for hmr
const GridKey = nanoid();

export const Layout: FC<React.PropsWithChildren<unknown>> = () => {
  const pageConfig = usePageConfig();

  const hasTop = useMemo(() => {
    return includes(pageConfig.contentLayoutMode || 'default', [
      'stickyTop',
      'stickyTopAndBottom',
    ] as const);
  }, [pageConfig.contentLayoutMode]);

  const hasBottom = useMemo(() => {
    return includes(pageConfig.contentLayoutMode || 'default', [
      'stickyBottom',
      'stickyTopAndBottom',
    ] as const);
  }, [pageConfig.contentLayoutMode]);

  const editorId = useEditorId();

  return (
    <div className={cls(styles.layout, 'node-page-layout')}>
      <WidgetPanel />
      <div className="flex flex-col flex-auto">
        {hasTop && (
          <EmptyChecker type="stickyTop" className="h-fit border-b-4 border-b-slate-100">
            <PiGridStack key={GridKey + '@' + editorId} type="stickyTop" />
          </EmptyChecker>
        )}
        <EmptyChecker type="default" className="flex-auto h-0">
          <PiGridStack key={GridKey + '@' + editorId} type="default" className="pb-32" />
        </EmptyChecker>
        {hasBottom && (
          <EmptyChecker type="stickyBottom" className="h-fit border-t-4 border-t-slate-100">
            <PiGridStack key={GridKey + '@' + editorId} type="stickyBottom" />
          </EmptyChecker>
        )}
      </div>
      <SettingPanel />
      {/* @ts-ignore */}
      <StatisticSettingModal id={StatisticSettingModalSymbol} />
    </div>
  );
};
