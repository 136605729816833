import { Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import { WidgetIcon } from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

const Preview: WidgetPreivew = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        color: '#BFC6D2',
        fontSize: '14px',
      }}
    >
      点击输入当前主题描述
    </div>
  );
};

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">{/* <BasicSetting type="component" /> */}</Form>
  );
};

export const NodeMainBodyWidget = defineSystemWidget()({
  id: 'NodeMainBody',
  title: '正文',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#14C0FF"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zhengwen"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 4,
    defaultWidth: 12,
    minHeight: 4,
    minWidth: 2,
    maxCount: 1,
    dynamicHeight: true,
  },
  metadata: {},
  preview: Preview,
  setting: Setting,
});
