import { Quill } from 'react-quill-new';

import { Link } from './components/Link/blot';
import PropBlot from './components/Prop/embed';
import { FontSizes } from './constants';

export const registerFontSize = (fontSizes: number[] = FontSizes) => {
  const Size = Quill.import('attributors/style/size') as Record<string, any>;
  Size.whitelist = fontSizes.map((s) => s + 'px');
  Quill.register(Size, true);
};

export const registerLink = () => {
  Quill.register(Link, true);
};

export const registerProp = () => {
  Quill.register(PropBlot, true);
};
