import { Divider, Form, Input, Radio, Space } from 'antd';
import { type FC, memo } from 'react';

import { useActiveWidgetInstance, useEditor } from '../hooks';

const nameMap = {
  component: '组件',
  section: '版块',
};

const options = [
  { label: '显示', value: true },
  { label: '不显示', value: false },
];

const _BasicSetting: FC<{
  type: 'component' | 'section';
}> = ({ type }) => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance()!;

  const setNameVisible = (r: boolean) => {
    editor.store.updateWidgetInstanceConfig(widgetIntance.id, {
      ...widgetIntance?.config,
      showName: r,
    });
  };

  const setName = (r: string) => {
    editor.store.updateWidgetInstanceConfig(widgetIntance.id, {
      ...widgetIntance?.config,
      name: r,
    });
  };

  return (
    <>
      <Form.Item label={`显示${nameMap[type]}名`}>
        <Space direction="vertical" className="full-w">
          <Radio.Group
            options={options}
            value={widgetIntance?.config.showName}
            onChange={(e) => setNameVisible(e.target.value)}
          />
          {!!widgetIntance?.config.showName && (
            <Input
              defaultValue={widgetIntance.config.name}
              onChange={(e) => setName(e.target.value)}
              placeholder={`请输入${nameMap[type]}名`}
            />
          )}
        </Space>
      </Form.Item>
      <Divider dashed />
    </>
  );
};

export const BasicSetting = memo(_BasicSetting);
