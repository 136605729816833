import {
  antdModalV5,
  create as ModalCreate,
  show,
  useModal,
} from '@ebay/nice-modal-react';
import type { OrgGroups } from '@linkpi/core';
import { useControllableValue, useMemoizedFn } from 'ahooks';
import type { ModalProps } from 'antd';
import { Tabs } from 'antd';
import { defaultTo, groupBy, head, values } from 'ramda';
import type { FC } from 'react';
import { useMemo } from 'react';

import { SelectModal } from '@/components';
import { useOrgUserGroup, useOrgUserMap } from '@/hook';
import { useOrgStructureNode } from '@/hook/useOrgStructure';
import { toArray, toRecord } from '@/utils/utils';

import { StatusUserCustomPropSelect } from './CustomPropSelect';
import { DepartmentSelect } from './DepartmentSelect';
import { DynamicDepartmentSelect } from './DynamicDepartmentSelect';
import { GroupSelect } from './GroupSelect';
import { SupervisorSelect } from './SupervisorSelect';
import { getDefaultValueType, TagList } from './TagList';
import { UserSelect } from './UserSelect';

import styles from './Modal.module.less';

type AllowType =
  | 'prop'
  | 'department'
  | 'group'
  | 'supervisor'
  | 'user'
  | 'dynamicDepartment';
export type StatusUserPropConfigModalProp = {
  title?: string;
  mode?: 'single' | 'multiple';
  value?: any;
  defaultValue?: any;
  onChange?: (v: any) => void;
  allowTypes?: AllowType[];
  disabledIndex?: number;
  departmentCheckStrictly?: boolean;
  disabledGroupIds?: string[];
} & ModalProps;
export const StatusUserPropConfigModal: FC<StatusUserPropConfigModalProp> = ({
  mode = 'multiple',
  allowTypes,
  disabledIndex,
  departmentCheckStrictly,
  disabledGroupIds,
  ...rest
}) => {
  const { orgStructureNodeId } = useOrgStructureNode({});
  const enableOrgStruct = !!orgStructureNodeId;

  const modal = useModal();
  const [value, _onChange] = useControllableValue(rest);
  const onChange = useMemoizedFn((result?: string | string[]) => {
    // 多选
    if (mode === 'multiple') _onChange(toArray(result || []));
    // 单选
    else if (Array.isArray(result)) {
      _onChange(head(result));
    } else _onChange(result);
  });

  const userMap = useOrgUserMap();

  const groups = useOrgUserGroup();
  const groupOptionMap = useMemo(() => {
    return toRecord<OrgGroups.OrgGroupsItem>((o) => ({ [o.group_id]: o }))(
      groups,
    );
  }, [groups]);

  const valueMap = useMemo(() => {
    return groupBy(
      (v: string) =>
        getDefaultValueType(v, {
          userMap,
          groupMap: groupOptionMap,
        }),
      toArray(defaultTo([])(value)),
    );
  }, [groupOptionMap, userMap, value]);

  const onOk = useMemoizedFn(() => {
    modal.resolve(value);
    modal.hide();
  });

  const onCancel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  const onItemValueChange =
    (key: keyof typeof valueMap) => (newValue: string[]) => {
      // 多选
      if (mode === 'multiple') {
        const newValueMap = {
          ...valueMap,
          [key]: toArray(newValue),
        };

        return onChange(values(newValueMap).flat());
      }

      // 单选
      return onChange(toArray(newValue));
    };

  const items = (
    [
      {
        label: '人员属性',
        key: 'prop',
        children: (
          <StatusUserCustomPropSelect
            searchPlaceholder="输入属性名称搜索"
            allowSearch
            mode={mode}
            disabledIndex={disabledIndex}
            value={valueMap.prop}
            onChange={onItemValueChange('prop')}
          />
        ),
      },
      enableOrgStruct && {
        label: '组织架构',
        key: 'department',
        children: (
          <DepartmentSelect
            allowSearch
            checkStrictly={departmentCheckStrictly}
            mode={mode}
            value={valueMap.department}
            onChange={onItemValueChange('department')}
          />
        ),
      },
      {
        label: '空间成员',
        key: 'user',
        children: (
          <UserSelect
            allowSearch
            allowSelectAll
            searchPlaceholder="输入成员名称搜索"
            mode={mode}
            value={valueMap.user}
            onChange={onItemValueChange('user')}
          />
        ),
      },
      enableOrgStruct && {
        label: '主管',
        key: 'supervisor',
        children: (
          <SupervisorSelect
            mode={mode}
            // @ts-ignore
            value={valueMap.supervisor}
            onChange={onItemValueChange('supervisor')}
          />
        ),
      },
      enableOrgStruct && {
        label: '动态部门',
        key: 'dynamicDepartment',
        children: (
          <DynamicDepartmentSelect
            mode={mode}
            // @ts-ignore
            value={valueMap.dynamicDepartment}
            onChange={onItemValueChange('dynamicDepartment')}
          />
        ),
      },
      {
        label: '角色',
        key: 'group',
        children: (
          <GroupSelect
            disabledIds={disabledGroupIds}
            searchPlaceholder="输入角色名称搜索"
            allowSearch
            mode={mode}
            value={valueMap.group}
            onChange={onItemValueChange('group')}
          />
        ),
      },
    ] as const
  ).filter((i) => {
    if (!allowTypes) return true;
    if (!i) return false;
    return allowTypes.includes(i.key as AllowType);
  });

  return (
    <SelectModal
      {...antdModalV5(modal)}
      title="设置"
      width={860}
      pure
      {...rest}
      onOk={onOk}
      onCancel={onCancel}
      value={value}
      onChange={onChange}
      valueRender={(list) => (
        <TagList
          disabledIds={disabledGroupIds?.map((i) => 'group_' + i)}
          value={list}
          onChange={onChange}
        />
      )}
    >
      <Tabs items={items} className={styles.tabs} />
    </SelectModal>
  );
};

const _StatusUserPropConfigModal = ModalCreate(StatusUserPropConfigModal);

export const showStatusUserPropConfigModal = (
  props: StatusUserPropConfigModalProp,
) => {
  return show(_StatusUserPropConfigModal, props);
};
