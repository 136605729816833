import type { CurrentUser } from '@linkpi/core';
import type Delta from 'quill-delta';
import { Quill } from 'react-quill-new';

import { getTemplateProp } from '@/utils/utils';

import { quillAtom, toolbarStore } from '../../store';

const Embed = Quill.import('parchment').EmbedBlot;

interface Attr {
  label: string;
  index: number;
  themeAttrLink?: any;
  allowUpload?: any;
  templateId?: string;
  statusStyle?: string;
  isAttachment?: boolean;
  showAttrValueEmpty?: boolean;
  attrValueEmptyWidth?: number;
  templateList?: CurrentUser.TemplateInfo[];
  id: string;
}

// 尝试简化
// 外部处理选中\取消选中 quill.getLeaf
class PropBlot extends Embed {
  static blotName = 'ThemeAttrSpan'; // 使用了 handleQuillOps 暂时不能更换名称
  static className = 'quill-prop-blot';
  static tagName = 'span';

  static create(attr: Attr) {
    const node = super.create() as HTMLSpanElement;
    node.contentEditable = 'false';
    const s = document.createElement('span');

    if (attr.templateId && attr.templateId !== 'undefined' && attr.templateList)
      attr.label =
        '{' +
        getTemplateProp(attr.templateId, attr.index, attr.templateList).name +
        '}';
    s.innerHTML = attr.label;
    node.appendChild(s);

    // 清空选中状态
    const clearNodeStyle = () => {
      node.classList.remove('selected');
    };

    // 选中状态
    const addNodeStyle = () => {
      node.classList.add('selected');
    };

    const quill = toolbarStore.get(quillAtom);

    const findSelection = () => {
      let embedIndex = -1;
      let find = false;
      let _op: any | null = null;
      const ops: Delta['ops'] = [];
      quill?.getContents().ops.forEach((op) => {
        ops.push(op);
        if (find) {
          return;
        }
        if (typeof op.insert === 'string') {
          embedIndex += op.insert.length;
        } else if (typeof op.insert === 'object') {
          const span = op.insert.ThemeAttrSpan as Attr;
          if (span) {
            embedIndex += 1;
            if (span.id === attr.id) {
              ops.pop();
              _op = {
                ...op,
                attributes: {},
              };
              ops.push(_op);
              find = true;
            }
          }
        } else {
          // 不知道什么情况
        }
      });

      return {
        find,
        index: embedIndex,
        op: _op,
        ops,
      };
    };

    quill?.on('selection-change', (range, source) => {
      const { find, index: embedIndex, op, ops } = findSelection();
      if (embedIndex === range?.index) {
        if (ops) {
          // attr.quill.setContents(ops);
        }
        addNodeStyle();
      } else {
        clearNodeStyle();
        quill.insertText(0, '');
      }
    });

    // click
    node.addEventListener('click', (e: any) => {
      e.stopPropagation();
      // ToolbarClassName
      const { find, index: embedIndex } = findSelection();
      if (find) {
        quill?.setSelection(embedIndex, 1);
      }
      // if (embedOp) {
      //   attr.quill.setSelection(embedOp.index, 1);
      // }
      // attr.quill.setSelection(1, 1);
      // addNodeStyle();
      // window._clickNodeHandler(node);
    });

    // node.setAttribute('id', attr.id);
    node.dataset.id = attr.id;
    node.dataset.label = attr.label;
    node.dataset.index = attr.index + '';
    // node.dataset.fontColor = attr.fontColor || 'black';
    // node.dataset.fontSize = attr.fontSize + '';
    node.dataset.templateId = attr.templateId;
    node.dataset.showAttrValueEmpty = (attr.showAttrValueEmpty || 't') + '';
    node.dataset.attrValueEmptyWidth = (attr.attrValueEmptyWidth || 80) + '';

    // 附件属性是否允许上传
    if (attr.allowUpload) node.dataset.allowUpload = attr.allowUpload;

    // 状态属性样式
    if (attr.statusStyle) node.dataset.statusStyle = attr.statusStyle;

    const isAttrAttachment = (templateId: string, index: number) => {
      if (!attr.templateList) {
        return false;
      }
      const t = attr.templateList.find((_t) => _t.template_id === templateId);
      const p = t?.prop[index];
      return p?.type === 'attachment';
    };
    if (attr.templateId) {
      node.dataset.isAttachment =
        isAttrAttachment(attr.templateId, attr.index) + '';
    }
    // 是否附件属性
    if (attr.isAttachment) node.dataset.isAttachment = attr.isAttachment + '';

    // 链接
    if (attr.themeAttrLink) {
      node.style.textDecoration = 'underline';
      node.dataset.themeAttrLink = attr.themeAttrLink;
    }

    return node;
  }

  static value(domNode: any) {
    // console.log('value', domNode);
    return domNode.dataset;
  }

  static formats(node: HTMLSpanElement) {
    return {};
  }

  // updateStyle() {
  //   this.domNode
  // }

  format(name: string, value: any) {
    super.format(name, value);
    // match(name)
    //   .with(FontSizeWidgetName, () => {
    //     this.domNode.style.fontSize = value;
    //     this.domNode.dataset.fontSize = value;
    //   })
    //   .with(BoldWidgetName, () => {
    //     this.domNode.style.fontWeight = value ? 'bold' : 'normal';
    //     this.domNode.dataset.fontWeight = value ? 'bold' : 'normal';
    //   })
    //   .with(ItalicWidgetName, () => {
    //     this.domNode.style.fontStyle = value ? 'italic' : 'normal';
    //     this.domNode.dataset.fontStyle = value ? 'italic' : 'normal';
    //   })
    //   .with(UnderlineWidgetName, () => {
    //     this.domNode.style.textDecoration = value ? 'underline' : 'none';
    //     this.domNode.dataset.textDecoration = value ? 'underline' : 'none';
    //   })
    //   .with(StrikeWidgetName, () => {
    //     this.domNode.style.textDecoration = value ? 'line-through' : 'none';
    //     this.domNode.dataset.textDecoration = value ? 'line-through' : 'none';
    //   })
    //   .with(ColorWidgetName, () => {
    //     this.domNode.style.color = value ?? 'black';
    //     this.domNode.dataset.color = value ?? 'black';
    //   })
    //   .with(BackgroundColorWidgetName, () => {
    //     this.domNode.style.backgroundColor = value ?? 'white';
    //     this.domNode.dataset.backgroundColor = value ?? 'white';
    //   })
    //   .with(LinkWidgetName, () => {})
    //   .otherwise(() => null);

    // return this.domNode.style;
  }
}

export default PropBlot;

export const DefaultPropEmbed = {
  label: `{--}`,
  index: 0,
  showAttrValueEmpty: 'f',
};
