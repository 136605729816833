import { useMemoizedFn } from 'ahooks';
import type { FC } from 'react';

import { RegularIcon } from '@/components/IconFont';
import { useOrgTemplatesSettingInfoMap } from '@/hook';

import { getDataSourceSettingValue, type IDataSourceSetting } from './Modal';

export const DataSourceSettingCore: FC<{
  value?: IDataSourceSetting;
  onChange?: (value: IDataSourceSetting) => void;
}> = ({ value, onChange: _onChange }) => {
  const templateId = value?.conditions?.find((c) => c.key === 'templateId')
    ?.input[0];
  const tempMap = useOrgTemplatesSettingInfoMap();
  const template = templateId ? tempMap[templateId] : undefined;

  const onChange = useMemoizedFn(async () => {
    const newValue = await getDataSourceSettingValue(value);
    _onChange?.({
      ...value,
      ...newValue,
    });
  });

  return (
    <div
      className="flex items-center justify-between px-2 py-1 rounded-[8px] border cursor-pointer"
      onClick={onChange}
    >
      <span>{template?.name || '请选择数据源'}</span>
      <RegularIcon
        className="text-[#6B7A96] hover:text-[#316EF5] transition-colors"
        type="iconshaixuanqu-shezhi"
      />
    </div>
  );
};
