import type { ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { Col, Divider, Form, Row } from 'antd';
import { defaultTo, head } from 'ramda';

import { RegularIcon } from '@/components/IconFont';
import type { TemplatePropSelectProps } from '@/components/LinkPiForm';
import { TemplateCustomPropSelect } from '@/components/LinkPiForm';

import calendar from '../../assets/calendar.png';
import type {
  IAddingBtnSetting,
  IConditionSetting,
  INodeGroupSetting,
  INodeOrderSetting,
} from '../../components';
import {
  AddingBtnSetting,
  ConditionSetting,
  NodeGroupSetting,
  NodeOrderSetting,
  showViewSettingModal,
  WidgetIcon,
} from '../../components';
import { BasicSetting } from '../../components/BasicSetting';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type ICalendarWidget = IConditionSetting &
  IAddingBtnSetting &
  INodeGroupSetting &
  INodeOrderSetting &
  Pick<ViewList.ViewInfo, 'displayBasis'>;

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={calendar} style={{ display: 'block', height: 424 }} />
    </div>
  );
};

const genValue = (_: unknown, id: string | number) => `prop_${id}`;

const genGroupOptions: NonNullable<
  TemplatePropSelectProps['genGroupOptions']
> = (options) => [
  {
    label: '当前状态',
    options: [
      { label: '开始时间', value: 'statusStartTime' },
      { label: '结束时间', value: 'statusEndTime' },
    ],
  },
  {
    label: '自定义属性',
    options: options,
  },
  {
    label: '系统属性',
    options: [{ label: '创建时间', value: 'createTime' }],
  },
];

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ICalendarWidget>();
  assertExists(widgetInstance);

  const curTemplateId = head(
    widgetInstance.config.conditions.find((i) => i.key === 'templateId')?.input,
  );
  const updateConfig = editor.updateActiveWidgetInstanceConfig<ICalendarWidget>;
  const setDisplayBasis = (
    type: keyof NonNullable<ICalendarWidget['displayBasis']>,
    value: NonNullable<ICalendarWidget['displayBasis']>['start'],
  ) => {
    updateConfig((c) => {
      const old = defaultTo({ start: '', end: '' }, c.displayBasis);
      c.displayBasis = {
        ...old,
        [type]: value,
      };
    });
  };

  return (
    <Form layout="vertical">
      <BasicSetting type="component" />
      <ConditionSetting />
      <NodeGroupSetting />
      <NodeOrderSetting />
      <Form.Item label="展示依据">
        <Row align="middle" gutter={8}>
          <Col flex="auto">
            <TemplateCustomPropSelect
              className="full-w"
              genGroupOptions={genGroupOptions}
              genValue={genValue}
              templateId={curTemplateId}
              noStyle
              allowPropTypes={['date', 'datetime']}
              fieldProps={{
                value: widgetInstance.config.displayBasis?.start || undefined,
                onChange(v: any) {
                  setDisplayBasis('start', v);
                },
              }}
            />
          </Col>
          <Col>至</Col>
          <Col flex="auto">
            <TemplateCustomPropSelect
              className="full-w"
              genGroupOptions={genGroupOptions}
              genValue={genValue}
              templateId={curTemplateId}
              noStyle
              allowPropTypes={['date', 'datetime']}
              fieldProps={{
                value: widgetInstance.config.displayBasis?.end || undefined,
                onChange(v: any) {
                  setDisplayBasis('end', v);
                },
              }}
            />
          </Col>
        </Row>
      </Form.Item>
      <Divider dashed />
      <AddingBtnSetting />
    </Form>
  );
};

export const CalendarWidget = defineSystemWidget<ICalendarWidget>()({
  id: 'Calendar',
  title: '日历',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#FF8D76"
        icon={<RegularIcon type="iconshitu-rili" color={'#ffffffcc'} />}
      />
    );
  },
  basic: { defaultHeight: 8, defaultWidth: 6, minWidth: 6, settingWidth: 540 },
  metadata: {
    parentType: 'current',
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: ['ff'],
      },
    ],
    group: 'sysCreator',
    orderBy: '_sys_createTime',
    orderDesc: true,
    displayBasis: {
      start: 'statusStartTime',
      end: 'statusEndTime',
    },
  },
  preview: Preview,
  setting: Setting,
  onCreate: () => showViewSettingModal<ICalendarWidget>({ title: '添加日历' }),
});
