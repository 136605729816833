import { assertExists } from '@linkpi/utils';
import { Switch } from 'antd';

import { defaultTools } from '@/utils/utils';

import { useActiveWidgetInstance, useEditor } from '../hooks';

const map: any = {
  search: '搜索',
  condition: '筛选',
  group: '分组',
  sort: '排序',
  displayRow: '显示列',
  lineHeight: '行高',
  treeLevel: '展示树层',
  collapse: '折叠',
  import: '导入表格',
  export: '导出表格',
  addBtn: '新增按钮',
};
export const ToolsSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance();
  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig;

  let { toolsConfig } = widgetInstance.config as any;
  toolsConfig = toolsConfig || defaultTools.slice(0, 5);

  const getAllChecked = () => {
    return toolsConfig.length === 11;
  };

  const changeAllChecked = () => {
    updateConfig((config: any) => {
      config.toolsConfig = getAllChecked() ? [] : defaultTools;
    });
  };

  const changeChecked = (tool: string) => {
    updateConfig((config: any) => {
      config.toolsConfig = toolsConfig.includes(tool)
        ? toolsConfig.filter((t: string) => t !== tool)
        : toolsConfig.concat([tool]);
    });
  };

  return (
    <div style={{ marginBottom: 24 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ color: '#242D3F', fontWeight: 700 }}>工具栏</div>
        <Switch checked={getAllChecked()} onChange={changeAllChecked} />
      </div>
      {defaultTools.map((t) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 16,
          }}
          key={t}
        >
          <div style={{ color: '#242D3F' }}>{map[t]}</div>
          <Switch checked={toolsConfig.includes(t)} onChange={() => changeChecked(t)} />
        </div>
      ))}
    </div>
  );
};
