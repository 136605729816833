import { generateId } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import {
  Checkbox,
  Empty,
  Input,
  message,
  Radio,
  Segmented,
  Select,
} from 'antd';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SparkMD5 from 'spark-md5';

import attrWidgetNoData from '@/assets/img/attrWidgetNoData.png';
import { ColorPicker } from '@/components/ColorPicker';
import { RegularIcon } from '@/components/IconFont';
import { useCurrentOrgId, useOrgTempMap } from '@/hook';
import { checkUpload } from '@/services/spaceV2';
import request from '@/utils/request';

import type { IDataSourceSetting } from '../../components';
import {
  DataSourceSettingCore,
  getDataSourceSettingValue,
  WidgetIcon,
} from '../../components';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

import styles from './styles.less';

export type IAttributeInfoWidget = {
  name: string;
  arrange: string;
  keyFontSize: number;
  valueFontSize: number;
  keyFontColor: string;
  valueFontColor: string;
  bgColor: string;
  keyTextDecoration: string;
  valueTextDecoration: string;
  iconPosition: string;
  showIcon: boolean;
  propsMap?: {
    tempId: string;
    props: any;
  };
} & IDataSourceSetting;

const DEFAULT_CONFIG: IAttributeInfoWidget = {
  name: '属性展示组件',
  arrange: 'two',
  keyFontSize: 14,
  valueFontSize: 14,
  keyFontColor: '#242D3F',
  valueFontColor: '#242D3F',
  bgColor: '#F5F7FE',
  matchings: [],
  type: 'temp',
  conditions: [
    {
      key: 'templateId',
      op: 'intersect',
      input: [],
    },
  ],
  parentType: 'others',
  keyTextDecoration: 'none',
  valueTextDecoration: 'bold',
  iconPosition: 'left',
  showIcon: false,
};

const defaultIcon =
  'https://test-inner-oss.linkerpi.com/storage/c4d14b2617764f66ad9c0eeedbe45c81/initIcon.png?OSSAccessKeyId=LTAI4GJJjJfFS4KtHxuFw5tZ&Expires=4947164349&Signature=si9tkyFYMpmU5W3X4vhNmrEbYZY%3D';

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance(id);

  const {
    conditions,
    propsMap,
    arrange,
    keyFontSize,
    valueFontSize,
    keyFontColor,
    valueFontColor,
    bgColor,
    keyTextDecoration,
    valueTextDecoration,
    iconPosition = 'left',
    showIcon,
  } = widgetInstance?.config as any;

  const templateId = (conditions || []).find((i: any) => i.key === 'templateId')
    ?.input[0];
  if (!propsMap || propsMap.tempId !== templateId)
    return (
      <div
        className={styles.container}
        style={{ paddingTop: 12, width: '100%', justifyContent: 'center' }}
      >
        <Empty
          image={attrWidgetNoData}
          description={
            <div style={{ color: '#C9D0D9', fontSize: 12 }}>暂无数据</div>
          }
          imageStyle={{ height: 40, width: 40 }}
        />
      </div>
    );

  const props = propsMap.props.filter((p: any) => p.switch);

  let width = '100%';
  if (arrange === 'two') width = '50%';
  if (arrange === 'three') width = '33.3%';

  const renderPropName = (name: string) => (
    <div
      style={{
        fontSize: keyFontSize,
        color: keyFontColor,
        fontWeight: keyTextDecoration === 'bold' ? 700 : 400,
        fontStyle: keyTextDecoration === 'italic' ? 'italic' : 'normal',
        textDecoration:
          keyTextDecoration === 'underline' ? 'underline' : 'none',
      }}
    >
      {name}
    </div>
  );

  const renderPropValue = () => (
    <div
      style={{
        fontSize: valueFontSize,
        color: valueFontColor,
        fontWeight: valueTextDecoration === 'bold' ? 700 : 400,
        fontStyle: valueTextDecoration === 'italic' ? 'italic' : 'normal',
        textDecoration:
          valueTextDecoration === 'underline' ? 'underline' : 'none',
      }}
    >
      属性值
    </div>
  );

  const renderIcon = (prop: any, position: string) => {
    if (!showIcon) return null;
    const iconUrl = prop.iconUrl || defaultIcon;
    if (position !== iconPosition) return null;

    return (
      <div
        style={{
          backgroundImage: `url(${iconUrl})`,
          height: 24,
          width: 24,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          marginLeft: position === 'right' ? 12 : 0,
          marginRight: position === 'left' ? 12 : 0,
        }}
      ></div>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '8px 4px 0 4px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
      }}
      className={styles.container}
    >
      {props.length === 0 && (
        <div
          style={{
            paddingTop: 12,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Empty
            image={attrWidgetNoData}
            description={
              <div style={{ color: '#C9D0D9', fontSize: 12 }}>暂无数据</div>
            }
            imageStyle={{ height: 40, width: 40 }}
          />
        </div>
      )}
      {props.map((p: any) => (
        <div
          key={p.propIndex}
          style={{ padding: '0 6px', width, marginBottom: 12 }}
        >
          <div style={{ backgroundColor: bgColor }} className={styles.attr}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {renderIcon(p, 'left')}
              {renderPropName(p.name)}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {renderPropValue()}
              {renderIcon(p, 'right')}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Setting: WidgetSetting = () => {
  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IAttributeInfoWidget>();

  assertExists(widgetInstance);

  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<IAttributeInfoWidget>;
  const [type, setType] = useState('属性设置');
  const {
    name,
    conditions,
    propsMap,
    arrange,
    keyFontSize,
    valueFontSize,
    keyFontColor,
    valueFontColor,
    bgColor,
    keyTextDecoration,
    valueTextDecoration,
    iconPosition = 'left',
    showIcon = false,
  } = widgetInstance.config;

  const templateId = conditions.find((i: any) => i.key === 'templateId')
    ?.input[0];
  const template = tempMap[templateId];

  // getProps
  const genProps = () => {
    let props = template.prop
      .map((p, index) => ({ propIndex: index, ...p }))
      .filter((p) => p.type && !p.hide)
      .map((p, i) => ({ propIndex: p.propIndex, name: p.name, switch: i < 8 }));

    if (propsMap) props = cloneDeep(propsMap.props);
    return props;
  };

  const changeArrange = (v: string) => {
    updateConfig((config: any) => {
      config.arrange = v;
    });
  };
  const changeSwitch = (e: boolean, index: number) => {
    const props = genProps();
    props.forEach((p) => {
      if (p.propIndex === index) p.switch = e;
    });
    updateConfig((config: any) => {
      config.propsMap = {
        tempId: templateId,
        props,
      };
    });
  };

  const onDragEnd = (res: any) => {
    const { destination, source } = res;
    if (!destination) return;

    const { index: destinationIndex } = destination;
    const { index: sourceIndex } = source;

    const props = genProps();

    const [removed] = props.splice(sourceIndex, 1);
    props.splice(destinationIndex, 0, removed);

    updateConfig((config: any) => {
      config.propsMap = {
        tempId: templateId,
        props,
      };
    });
  };

  const uploadIcon = (prop: any) => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('style', 'visibility:hidden;');

    document.body.appendChild(input);
    input.click();

    input.onchange = async (e: any) => {
      const file: any = Array.from(e.target.files)[0];
      const originFileObj = await file.arrayBuffer();
      let dataString = '';
      let fileUrl: any = '';
      const path = `${generateId()}/${file.name}`;

      const fileData = new Uint8Array(originFileObj);
      for (let i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
      }

      const fileMd5 = SparkMD5.hash(dataString);
      // 验证是否可上传及重复上传
      const res = await checkUpload({
        org_id: orgId,
        file_length: Math.ceil(file.size / 1024),
        file_name: file.name,
        md5: fileMd5,
      });
      if (res.status === 'error') return message.error('上传失败');

      if (res.status === 'ok' && res.data) {
        fileUrl = res.data;
      } else {
        if (!res.token) return message.error('上传失败');

        // upload oss
        const Oss = await import('ali-oss').then((r) => r.default);
        const client = Oss({
          secure: true,
          bucket: res.bucket,
          region: res.region,
          accessKeyId: res.token.AccessKeyId,
          accessKeySecret: res.token.AccessKeySecret,
          stsToken: res.token.SecurityToken,
          authorizationV4: true,
        });
        const aliOssRes = await client.put('upload/' + path, file);
        if (!aliOssRes) return message.error('上传失败');

        const store_res = await request('/api/file/store', {
          method: 'POST',
          data: {
            org_id: orgId,
            path: path,
          },
        });
        if (store_res.status === 'error') return message.error('上传失败');
        fileUrl = store_res.data;
      }

      const props = genProps();
      props.forEach((p: any) => {
        if (p.propIndex === prop.propIndex) p.iconUrl = fileUrl;
      });
      updateConfig((config: any) => {
        config.propsMap = {
          tempId: templateId,
          props,
        };
      });
      // 删除input
      document.body.removeChild(input);
    };
  };
  const renderIcon = (prop: any) => {
    return (
      <div
        style={{
          backgroundImage: `url(${prop.iconUrl || defaultIcon})`,
          height: 24,
          width: 24,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      ></div>
    );
  };
  const renderMain = () => {
    if (type === '属性设置')
      return (
        <div>
          <div style={{ paddingTop: 24 }}>
            <div
              style={{ color: '#242D3F', fontWeight: 700, marginBottom: 12 }}
            >
              组件名称
            </div>
            <Input
              onChange={(e) => {
                updateConfig((w: any) => {
                  w.name = e.target.value;
                });
              }}
              defaultValue={name}
            />
          </div>
          <div style={{ paddingTop: 24 }}>
            <div
              style={{ color: '#242D3F', fontWeight: 700, marginBottom: 12 }}
            >
              数据源
            </div>
            <DataSourceSettingCore
              value={{
                thmId: widgetInstance.config.thmId,
                type: widgetInstance.config.type,
                matchings: widgetInstance.config.matchings,
                parentType: widgetInstance.config.parentType,
                conditions: widgetInstance.config.conditions,
              }}
              onChange={(v: any) => {
                updateConfig((w: any) => {
                  Object.keys(v).forEach((k) => {
                    w[k] = v[k];
                  });
                });
              }}
            />
          </div>
          <div style={{ paddingTop: 24 }}>
            <div style={{ color: '#242D3F', fontWeight: 700 }}>设置属性</div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="attrs" type="attrs">
                {(provided: any) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {genProps().map((p: any, index: number) => (
                      <Draggable
                        key={String(p.propIndex)}
                        index={index}
                        draggableId={String(p.propIndex)}
                      >
                        {(innerProvided) => (
                          <div
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                          >
                            <div className={styles.dragContainer}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <i
                                  {...innerProvided.dragHandleProps}
                                  className="iconfont icondrag"
                                  style={{ fontSize: 16, color: '#6B7A96' }}
                                />

                                {showIcon && (
                                  <div className={styles.iconContainer}>
                                    <div
                                      onClick={() => uploadIcon(p)}
                                      className={styles.mask}
                                    >
                                      <i
                                        style={{ fontSize: 20, color: 'white' }}
                                        className="icondingbulan-tianjia iconfont"
                                      />
                                    </div>
                                    {renderIcon(p)}
                                  </div>
                                )}
                              </div>
                              <div
                                onClick={() =>
                                  changeSwitch(!p.switch, p.propIndex)
                                }
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flex: 1,
                                  width: 0,
                                  cursor: 'pointer',
                                }}
                              >
                                <div
                                  style={{ color: '#242D3F', marginLeft: 4 }}
                                >
                                  {p.name}
                                </div>
                                <Checkbox checked={p.switch} />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div
            style={{
              padding: '24px 16px 12px 0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ color: '#242D3F', fontWeight: 700 }}>属性图标</div>
            <i
              onClick={() => {
                updateConfig((config: any) => {
                  config.showIcon = !showIcon;
                });
              }}
              style={{ fontSize: 20, cursor: 'pointer', color: '#316EF5' }}
              className={`${showIcon ? 'iconshanchu3' : 'icontianjia'} iconfont`}
            />
          </div>
          {showIcon && (
            <Select
              style={{ width: '100%' }}
              value={iconPosition}
              onChange={(v) => {
                updateConfig((config: any) => {
                  config.iconPosition = v;
                });
              }}
              placeholder="请选择"
            >
              {[
                { value: 'left', label: '图标居左' },
                { label: '图标居右', value: 'right' },
              ].map((i: any) => (
                <Select.Option value={i.value} key={i.value}>
                  {i.label}
                </Select.Option>
              ))}
            </Select>
          )}
          <div style={{ height: 120 }}></div>
        </div>
      );

    // 样式设置
    return (
      <div>
        <div style={{ paddingTop: 24 }}>
          <div style={{ color: '#242D3F', fontWeight: 700, marginBottom: 12 }}>
            排列方式
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {[
              { value: 'one', label: '1列' },
              { value: 'two', label: '2列' },
              { value: 'three', label: '3列' },
            ].map((t) => (
              <div
                style={{
                  backgroundColor: '#F8F9FB',
                  borderRadius: 8,
                  padding: '8px 10px 8px 16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 'calc(33.3% - 8px)',
                  cursor: 'pointer',
                }}
                onClick={() => changeArrange(t.value)}
                key={t.label}
              >
                <div style={{ color: '#242D3F' }}>{t.label}</div>
                <Radio checked={arrange === t.value} />
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            paddingTop: 24,
            color: '#242D3F',
            fontWeight: 700,
          }}
        >
          文字
        </div>
        {[
          { label: '属性名称', value: 'key' },
          { label: '属性值', value: 'value' },
        ].map((i) => (
          <div key={i.label}>
            <div style={{ color: '#242D3F', padding: '12px 0' }}>{i.label}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ marginRight: 8, width: 152 }}
                value={i.value === 'key' ? keyFontSize : valueFontSize}
                onChange={(v) => {
                  updateConfig((config: any) => {
                    config[
                      i.value === 'key' ? 'keyFontSize' : 'valueFontSize'
                    ] = v;
                  });
                }}
                placeholder="请选择"
              >
                {[14, 15, 16].map((i: any) => (
                  <Select.Option value={i} key={i}>
                    {i}
                  </Select.Option>
                ))}
              </Select>
              <ColorPicker
                itemRender={(v) => (
                  <div
                    style={{
                      height: 32,
                      width: 32,
                      borderRadius: 8,
                      border: '1px solid #EBEDF0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                  >
                    <i
                      style={{ fontSize: 16 }}
                      className="iconwenzi iconfont"
                    />
                    <div
                      style={{ backgroundColor: v }}
                      className={styles.showFontColor}
                    />
                  </div>
                )}
                value={i.value === 'key' ? keyFontColor : valueFontColor}
                onChange={(v) => {
                  updateConfig((config: any) => {
                    config[
                      i.value === 'key' ? 'keyFontColor' : 'valueFontColor'
                    ] = v.toString();
                  });
                }}
              />
              <div className={styles.textDecoration}>
                {[
                  { icon: 'icona-rongqi5', value: 'bold' },
                  { icon: 'iconqingxie', value: 'italic' },
                  { icon: 'icona-xiahuaxian1', value: 'underline' },
                ].map((t) => {
                  const v =
                    i.value === 'key' ? keyTextDecoration : valueTextDecoration;
                  return (
                    <div
                      onClick={() => {
                        updateConfig((config: any) => {
                          const k =
                            i.value === 'key'
                              ? 'keyTextDecoration'
                              : 'valueTextDecoration';
                          config[k] = config[k] === t.value ? 'none' : t.value;
                        });
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 27,
                        width: 27,
                        cursor: 'pointer',
                        backgroundColor: v === t.value ? '#316EF5' : 'white',
                        borderRadius: 8,
                      }}
                    >
                      <i
                        style={{
                          fontSize: 16,
                          color: v === t.value ? 'white' : 'black',
                        }}
                        className={`${t.icon} iconfont`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

        <div
          style={{
            paddingTop: 24,
            color: '#242D3F',
            fontWeight: 700,
            marginBottom: 12,
          }}
        >
          背景
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ color: '#242D3F' }}>背景颜色</div>
          <ColorPicker
            style={{ width: 180 }}
            value={bgColor}
            onChange={(v) => {
              updateConfig((config: any) => {
                config.bgColor = v.toString();
              });
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div style={{ padding: 12 }}>
      <Segmented onChange={setType} block options={['属性设置', '样式设置']} />
      {renderMain()}
    </div>
  );
};
export const AttributeInfoWidget = defineSystemWidget<IAttributeInfoWidget>()({
  id: 'AttributeInfoWidget',
  title: '属性组件',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#21BF46"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zidingyishuxing"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 7,
    defaultWidth: 12,
  },
  metadata: DEFAULT_CONFIG,
  preview: Preview,
  setting: Setting,
  onCreate: async () => {
    const value = await getDataSourceSettingValue();
    return {
      ...DEFAULT_CONFIG,
      ...value,
    };
  },
});
