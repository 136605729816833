import { tempValueDisplay } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'ramda';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import type { IDataIndicatorIndexType } from '@/components/PageModelEditor';
import type { RGBAColor } from '@/components/PageModelEditor/src/widgets/components/ColorInput';
import {
  useOrgInfo,
  useOrgUserMap,
  useTemplateList,
  useTemplateMap,
} from '@/hook';
import { useGlobalConditions } from '@/hook/useGlobalConditions';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { fetchDataSource } from '@/utils/dataManager';
import { cn, getTemplateProp } from '@/utils/utils';

import Styles from './card.less';

export type CardProps = {
  data: IDataIndicatorIndexType;
  getterPiNode: GetterPiNode;
  id: string;
};

const IndicatorIcon =
  'https://ljp-static.oss-cn-hangzhou.aliyuncs.com/defaultIndexDataIcon.png';

const Card = (props: CardProps) => {
  const { data, getterPiNode, id } = props;

  const size = data.styles.spacingType;

  const [orgInfo] = useOrgInfo();

  assertExists(orgInfo);

  const globalConditions = useGlobalConditions(id);
  const conditions = useMemo(() => {
    if (!globalConditions) return data.dataSource.conditions;
    if (!globalConditions?.length) return data.dataSource.conditions;
    return [...data.dataSource.conditions, ...globalConditions];
  }, [data.dataSource.conditions, globalConditions]);

  const { data: nodeList } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'data-indicator',
      orgInfo.rootId,
      id,
      conditions,
      data.dataSource,
    ],
    queryFn: async () => {
      const res = await fetchDataSource({
        orgId: orgInfo.orgId,
        currentNodeId: getterPiNode.value.id,
        page: 1,
        pageSize: 1,
        ...data.dataSource,
        conditions,
      });

      if (res.status !== 'ok') {
        return [];
      }
      return res.list;
    },
  });

  const [node] = nodeList ?? [];

  const rgbaToString = (color: RGBAColor) =>
    `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

  const isNumeric = (v: string | undefined | null | number) => {
    if (isNil(v)) {
      return false;
    }
    if (typeof v === 'number') {
      return true;
    }
    return !isNaN(parseFloat(v)) && isFinite(Number(v));
  };

  const getStyleByConfig = (config: {
    fontSize: number;
    color: RGBAColor;
    bold: boolean;
    italic: boolean;
    underline: boolean;
  }) => {
    const { fontSize, color } = config;

    const style: React.CSSProperties = {
      fontSize,
      color: rgbaToString(color),
      lineHeight: 1.5,
    };

    if (config.bold) {
      style.fontWeight = 'bold';
    }

    if (config.italic) {
      style.fontStyle = 'italic';
    }

    if (config.underline) {
      style.textDecoration = 'underline';
    }

    return style;
  };

  const templateList = useTemplateList();
  const userMap = useOrgUserMap();
  const tempMap = useTemplateMap();
  const departmentNodeMap = useOrgDepartmentNodesMap();

  const itemRender = (
    item: IDataIndicatorIndexType['subitems'][number],
    { isFirst, isLast }: { isFirst: boolean; isLast: boolean },
  ) => {
    const nodeProps = node?.template?.prop;
    const nodePropValues = node?.tempInfo?.prop;
    const propIndex = item.propIndex;
    const statisticalIndex = item.statisticalPropIndex;

    const title = match(nodeProps)
      .with(
        P.when((p) => !!p?.[propIndex ?? -1]),
        (_props) => _props![propIndex!].name,
      )
      .otherwise(() => '未命名');

    const value = match(nodePropValues)
      .with(
        P.when((v) => !isNil(v?.[propIndex ?? -1])),
        (_values) =>
          tempValueDisplay({
            propConfig: getTemplateProp(
              node.template!.template_id,
              propIndex,
              templateList,
            ),
            propValue: _values![propIndex!],
            propIndex,
            userMap,
            tempMap,
            departmentMap: departmentNodeMap,
          }),
      )
      .otherwise(() => '—');

    const statistical = nodePropValues?.[statisticalIndex ?? -1];
    const statisticalValue =
      nodePropValues && !isNil(statisticalIndex)
        ? tempValueDisplay({
            propConfig: getTemplateProp(
              node.template!.template_id,
              statisticalIndex,
              templateList,
            ),
            propValue: nodePropValues![statisticalIndex!],
            propIndex: statisticalIndex,
            userMap,
            tempMap,
            departmentMap: departmentNodeMap,
          })
        : null;

    const suffixIconValue = nodePropValues?.[item.suffixIconPropIndex!];

    const Icon = (
      <img
        className="w-12 h-12"
        src={data.iconSrc ?? IndicatorIcon}
        alt="图标"
      />
    );
    const Content = (
      <div className={cn('flex flex-col shrink-0')}>
        <span
          className="mb-1 leading-normal"
          style={getStyleByConfig(data.styles.propName)}
        >
          {title}
        </span>
        <div className="flex items-baseline" style={{ lineHeight: 1.5 }}>
          <span style={getStyleByConfig(data.styles.propValue)}>{value}</span>
          {item.showStatistical && (
            <span
              className="flex items-center ml-4"
              style={getStyleByConfig(data.styles.statisticalProp)}
            >
              <span className="mr-2">{item.statisticalName}</span>
              {!isNil(statisticalValue)
                ? [
                    <span key="statistical">{statisticalValue}</span>,
                    item.showSuffixIcon && suffixIconValue,
                  ]
                : '—'}
            </span>
          )}
        </div>
      </div>
    );

    if (!data.showIcon) {
      return Content;
    }

    return match(data.iconPlacement)
      .with('left', () => [isFirst ? Icon : null, Content])
      .with('right', () => [Content, isLast ? Icon : null])
      .exhaustive();
  };
  const contentRender = () => {
    return (
      <div className={cn('flex items-center', Styles['card-content'])}>
        {data.subitems.map((item, index) => [
          <div
            key={index}
            className={cn(
              'flex items-center px-6',
              Styles['item'],
              Styles[`item-${size}`],
              {
                'px-2': size === 'small',
              },
            )}
          >
            {itemRender(item, {
              isFirst: index === 0,
              isLast: index === data.subitems.length - 1,
            })}
          </div>,
          // <div key={index} className="w-0.5 h-6 bg-white opacity-20" />,
        ])}
      </div>
    );
  };

  return (
    <div
      className={cn(
        'w-fit rounded-[12px] flex items-center py-5 shrink-0 mr-4',
        Styles['card'],
        Styles[`card-${size}`],
        {
          'mr-2': size === 'small',
        },
      )}
      style={{
        // background: 'linear-gradient(135deg, #7547F3 0%, #AD8BFF 100%)',
        background: data.styles.background,
      }}
    >
      {contentRender()}
    </div>
  );
};

export default Card;
