import { Button, Form, Popover } from 'antd';
import cls from 'classnames';
import { useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { RegularIcon } from '@/components/IconFont';

import type { IDataIndicatorWidget } from '../../widgets';

import styles from './EditSortIndexButton.less';

export const EditSortIndexButton = () => {
  const form = Form.useFormInstance<IDataIndicatorWidget>();
  const indexes = Form.useWatch('indexes', form);
  const indexSort = Form.useWatch('indexSort', form);

  const indexWithSort = useMemo(() => {
    if (!indexSort || !indexes) return [];
    return indexSort
      .map((indexId) => ({ id: indexId, data: indexes[indexId] }))
      .filter((x) => !!x.data);
  }, [indexSort, indexes]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;
    const newSortIds = indexWithSort.map((x) => x.id);

    // 移动
    const moveItem = newSortIds.splice(sourceIndex, 1)[0];
    newSortIds.splice(targetIndex, 0, moveItem);

    form.setFieldValue('indexSort', newSortIds);
  };

  const content = (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef}>
            {indexWithSort.map((indexData, index) => (
              <Draggable
                key={indexData.id}
                draggableId={indexData.id}
                index={index}
              >
                {(draggableProvided) => (
                  <div
                    key={indexData.id}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={{ ...draggableProvided.draggableProps.style }}
                  >
                    <div className={styles.indexCard}>
                      <span
                        style={{
                          color: '#6B7A96',
                          cursor: 'move',
                        }}
                      >
                        <RegularIcon type="iconchangyong-paixu" size={16} />
                      </span>
                      <div className={cls(styles.name, 'text-omit')}>
                        {indexData.data.name}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <Popover
      trigger={['click']}
      placement="bottom"
      content={content}
      overlayClassName={styles.EditSortIndexButtonPopover}
    >
      <Button
        icon={
          <RegularIcon
            size={16}
            type="iconpaixu"
            color="#242D3F"
            className=" align-middle"
          />
        }
      >
        指标排序
      </Button>
    </Popover>
  );
};
