import { MinusCircleOutlined } from '@ant-design/icons';
import type { PropMathcingConfig } from '@linkpi/core';
import { generateAddOpId } from '@linkpi/core';
import { Select } from 'antd';
import { head } from 'ramda';
import React, { type FC, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { PiButton } from '@/components/Button';
import { selectSearchFn } from '@/components/LinkPiForm/utils';
import { useOrgTemplatesSettingInfoMap } from '@/hook';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

import type { IConditionSetting } from '../ConditionSetting';
import type { ISharedStateNodeSetting } from '../SharedStateNodeSetting';

const { Option } = Select;

export type IMatchingSetting = IConditionSetting &
  ISharedStateNodeSetting & {
    matchings: PropMathcingConfig;
  };

interface IProps {
  value: IMatchingSetting;
  onChange: (value: IMatchingSetting) => void;
  style?: React.CSSProperties;
}

export const MatchingSettingCore: FC<IProps> = ({ value, onChange, style }) => {
  const tempMap = useOrgTemplatesSettingInfoMap();

  const _currentTemplate = useCurrentTemplate();
  const currentTemplate = useMemo(
    () =>
      match(value)
        .with(
          { nodeSource: 'sharedState', templateId: P.select() },
          (id) => tempMap[id!] || _currentTemplate,
        )
        .otherwise(() => _currentTemplate),
    [_currentTemplate, tempMap, value],
  );

  const matchingTemplate =
    tempMap[
      head(
        value.conditions?.find((i: any) => i.key === 'templateId')?.input || [],
      ) as string
    ] || {};

  const targetThemeProps = (matchingTemplate.prop || [])
    .map((p: any, index: number) => ({ ...p, index }))
    .filter((p: any) => p.name);
  const nowThemeProps = (currentTemplate?.prop || [])
    .map((p: any, index: number) => ({ ...p, index }))
    .filter((p: any) => p.name);

  const addMatchingItem = () => {
    onChange({
      ...value,
      matchings: [
        ...value.matchings,
        {
          origin: undefined,
          target: undefined,
          key: generateAddOpId(),
        },
      ],
    });
  };

  const changeTargetThemeProp = (e: any, key: string) => {
    onChange({
      ...value,
      matchings: value.matchings.map((m) =>
        m.key === key ? { ...m, origin: e } : m,
      ),
    });
  };

  const changeNowThemeProp = (e: any, key: string) => {
    onChange({
      ...value,
      matchings: value.matchings.map((m) =>
        m.key === key ? { ...m, target: e } : m,
      ),
    });
  };

  const del = (key: string) => {
    onChange({
      ...value,
      matchings: value.matchings.filter((i) => i.key !== key),
    });
  };

  return (
    <div
      className="flex flex-col gap-3 rounded-[8px] p-3"
      style={{
        backgroundColor: '#fafafa',
        ...style,
      }}
    >
      {value.matchings?.map((matching: any) => (
        <div className="flex items-center gap-2" key={matching.key}>
          <Select
            placeholder="请选择属性"
            showSearch
            onChange={(e) => changeTargetThemeProp(e, matching.key)}
            filterOption={selectSearchFn}
            style={{ width: 130 }}
            value={matching.origin}
          >
            {targetThemeProps.map((p: any) => (
              <Option key={p.index} value={p.index}>
                {p.name}
              </Option>
            ))}
          </Select>
          <div
            className="rounded-[8px] bg-white px-2 flex items-center border h-8"
            style={{
              color: '#242d3f',
            }}
          >
            等于
          </div>
          <Select
            placeholder="当前主题类型下的属性"
            showSearch
            onChange={(e) => changeNowThemeProp(e, matching.key)}
            filterOption={(input, option) =>
              // @ts-ignore
              option?.children
                ?.toLowerCase?.()
                ?.indexOf(input?.toLowerCase()) !== -1
            }
            style={{ flex: 1, width: 0 }}
            value={matching.target}
          >
            {nowThemeProps.map((p: any) => (
              <Option key={p.index} value={p.index}>
                {p.name}
              </Option>
            ))}
          </Select>
          <MinusCircleOutlined
            onClick={() => del(matching.key)}
            style={{
              color: '#B9BBBC',
              cursor: 'pointer',
            }}
          />
        </div>
      ))}
      <div>
        <PiButton
          className="w-full text-[#242D3F] shadow-none"
          onClick={addMatchingItem}
          type="dashed"
        >
          添加匹配条件
        </PiButton>
      </div>
    </div>
  );
};
