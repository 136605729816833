import type { TemplateNodeData } from '@linkpi/core';
import { createContext, useContext } from 'react';

export const CurrentNodeContext = createContext<{ node?: TemplateNodeData }>(
  {},
);

export const useCurrentNodeContext = () => {
  return useContext(CurrentNodeContext);
};
