import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import AddRow from '../components/addRow';
import GroupRow from '../components/groupRow';
import NodeRow from '../components/nodeRow';
import { getValidDateScope } from '../helper/utils';
import BaseModule from './_baseModule';

type nodeRowDataType = 'node' | 'group' | 'addRow';
interface baseRowData {
  type: nodeRowDataType;
  index: number;
  y: number;
  height: number;
  groupKey: string;
  groupInfo: ViewGroupDataItem;
}
interface baseRowDataWithNode extends baseRowData {
  node: PiNode;
  indexInGroup: number;
  validDateScope: [Dayjs, Dayjs] | [];
}
interface baseRowDataWithGroup extends baseRowData {
  groupDetail: {
    minDate: Dayjs;
    maxDate: Dayjs;
  } | null;
}
type baseRowDataWithAddRow = baseRowData;

export type nodeRowData<U extends nodeRowDataType> = U extends 'node'
  ? baseRowDataWithNode
  : U extends 'group'
    ? baseRowDataWithGroup
    : baseRowDataWithAddRow;

type collapseType = Record<string, boolean>;

type NodeId = string;
type StartDay = Dayjs;
type EndDay = Dayjs;
type LocalDateChangeRecord = [Dayjs, Dayjs];

const NODE_HEIGHT = 44;
const GROUP_HEIGHT = 44;

export default class NodeRowManager extends BaseModule {
  private tempGroupData: ViewGroupDataType = {};
  private virtualList: nodeRowData<any>[] = [];
  public virtualNodeIdList: string[] = [];
  public collapseState: collapseType = {};
  private totalHeight: number = 0;
  // 本地的时间拖拽状态
  public cellLocalDateChangeRecord = new Map<NodeId, LocalDateChangeRecord>();
  // 记录拖拽的状态
  public setCellLocalDateChangeRecord(
    nodeId: NodeId,
    startDay: StartDay,
    endDay: EndDay,
  ) {
    this.cellLocalDateChangeRecord.set(nodeId, [startDay, endDay]);
  }
  // 获取cell的local record
  public getCellLocalDateChangeRecord(
    nodeId: NodeId,
    validDateScope: baseRowDataWithNode['validDateScope'],
  ): undefined | LocalDateChangeRecord {
    const record = this.cellLocalDateChangeRecord.get(nodeId);
    // 数据更新后，清空local record
    if (record) {
      // 状态被转了 导致没有时间范围
      if (!validDateScope.length) {
        this.cellLocalDateChangeRecord.delete(nodeId);
        return undefined;
      }
      // 数字更新为当前记录值时，清空local record
      if (
        record[0].isSame(validDateScope[0]) &&
        record[1].isSame(validDateScope[1])
      ) {
        this.cellLocalDateChangeRecord.delete(nodeId);
        return undefined;
      }
    }
    return record;
  }

  /**
   *
   * 记录百分比操作
   *
   */
  public cellProgressRecord = new Map<NodeId, number>();
  public setCellProgressRecord(nodeId: NodeId, progress: number) {
    this.cellProgressRecord.set(nodeId, progress);
  }
  public getCellProgressRecord(nodeId: NodeId) {
    return this.cellProgressRecord.get(nodeId);
  }

  private get hasGroup() {
    return !(
      Object.keys(this.tempGroupData).length === 1 &&
      this.tempGroupData.default &&
      !this.tempGroupData.default.name
    );
  }

  public onCollapse(key: string) {
    this.collapseState[key] = !this.collapseState[key];

    let f = true;

    Object.values(this.collapseState).forEach((b) => {
      if (!b) f = false;
    });

    this.config.afterCollapseGroup(f);
    this.countVirtualList();
  }

  public ganttCollapse(collapse: boolean) {
    if (collapse) {
      this.collapseState = Object.keys(this.tempGroupData).reduce(
        (map, key) => {
          map[key] = true;
          return map;
        },
        {} as any,
      );
    }

    if (!collapse) {
      this.collapseState = {};
    }

    this.countVirtualList();
    this.moduleInstances.Render.render();
  }

  public updateData() {
    // 确保数据一致
    this.tempGroupData = this.config.groupData;
    // set collapseState
    if (!this.hasGroup) {
      this.collapseState = {};
    } else {
      this.collapseState = {
        ...Object.keys(this.tempGroupData).reduce((a, b) => {
          a[b] = true;
          return a;
        }, {} as collapseType),
        ...this.collapseState,
      };
    }

    this.countVirtualList();

    this.moduleInstances.VerticalScrollbar.setInner(this.totalHeight);
    this.moduleInstances.Render.render();
  }

  // 转换到虚拟列表
  private countVirtualList() {
    const rowList: nodeRowData<any>[] = [];
    const nodeIds: string[] = [];
    let index = 0;
    let y = 80;

    Object.keys(this.tempGroupData).map((groupKey) => {
      let group: null | nodeRowData<'group'> = null;
      const others: nodeRowData<any>[] = [];

      // group row
      if (this.hasGroup) {
        group = {
          type: 'group',
          groupKey: groupKey,
          groupInfo: this.tempGroupData[groupKey],
          index: index,
          y: y,
          height: GROUP_HEIGHT,
          groupDetail: null,
        };
        index++;
        y += GROUP_HEIGHT;
      }

      // node row
      const tempConfig = this.moduleInstances.NodeRowManager.config.tempConfig;

      this.tempGroupData[groupKey].list.map(
        (data: PiNode, indexInGroup: number) => {
          const validDateScope = getValidDateScope(data, tempConfig);
          nodeIds.push(data.id);

          // 未分组 或 分组展开
          if (!this.hasGroup || this.collapseState[groupKey]) {
            const node: baseRowDataWithNode = {
              type: 'node',
              node: data,
              index: index,
              y: y,
              height: NODE_HEIGHT,
              groupKey: groupKey,
              groupInfo: this.tempGroupData[groupKey],
              validDateScope,
              indexInGroup,
            };
            index++;
            y += NODE_HEIGHT;
            others.push(node);
          }

          if (validDateScope.length && group) {
            if (!group.groupDetail) {
              group.groupDetail = {
                minDate: validDateScope[0],
                maxDate: validDateScope[1],
              };
            } else {
              // @ts-ignore
              const min = dayjs.min(
                group.groupDetail.minDate,
                validDateScope[0],
              ) as Dayjs;
              // @ts-ignore
              const max = dayjs.max(
                group.groupDetail.maxDate,
                validDateScope[1],
              ) as Dayjs;
              group.groupDetail = {
                minDate: min,
                maxDate: max,
              };
            }
          }
        },
      );

      // add row
      const { curViewData } = this.config;
      const { view_info } = curViewData || {};
      const hideAdd = view_info ? view_info.hideAdd : null;
      if ((!this.hasGroup || this.collapseState[groupKey]) && !hideAdd) {
        const add: baseRowDataWithAddRow = {
          type: 'addRow',
          groupInfo: this.tempGroupData[groupKey],
          index: index,
          y: y,
          height: NODE_HEIGHT,
          groupKey: groupKey,
        };
        index++;
        y += NODE_HEIGHT;
        others.push(add);
      }

      if (group) {
        rowList.push(group);
      }

      rowList.push(...others);
    });

    this.virtualList = rowList;
    this.virtualNodeIdList = nodeIds;
    this.totalHeight = y - 80 + 300;
  }

  public getRowByIndex(index: number) {
    return this.virtualList[index];
  }
  public getRowByPositionY(y: number) {
    const { VerticalScrollbar } = this.moduleInstances;
    const yInScroll = y + VerticalScrollbar.scrollTop;
    let row: nodeRowData<any> | null = null;
    let index = 0;
    let relation: 'next' | 'prev' = 'prev';
    while (!row && index < this.virtualList.length) {
      const item = this.virtualList[index];
      if (yInScroll > item.y && yInScroll < item.y + item.height) {
        row = item;
        relation = yInScroll < item.y + item.height / 2 ? 'prev' : 'next';
      }
      index++;
    }
    return row ? { row, relation } : null;
  }

  public render() {
    const { Draw, VerticalScrollbar, Render, Event } = this.moduleInstances;

    const scrollTop = VerticalScrollbar.scrollTop;

    // render list
    let hasTitleHoverNodeRow: NodeRow | null = null;

    // 通过scrollTop 过滤需要显示的row
    this.virtualList.map((x) => {
      // TODO nodeRow 来render left
      const y = x.y - scrollTop;
      let height;
      if (x.type === 'node') {
        height = NODE_HEIGHT;
      } else if (x.type === 'group') {
        height = GROUP_HEIGHT;
      } else {
        height = NODE_HEIGHT;
      }

      const isDraggedCell =
        Event.isDragCell &&
        Event.isDragCell.dragCell.data.node.id ===
          (x as baseRowDataWithNode)?.node?.id;

      if ((y + height < 80 || y > Draw.canvasHeight) && !isDraggedCell) return;

      let row;
      if (x.type === 'node') {
        row = new NodeRow(
          {
            x: 0,
            y: y,
            width: Draw.canvasWidth,
            height: height,
          },
          this.moduleInstances,
          x as baseRowDataWithNode,
        );
        row.render();

        // 标题Hover 显示新增按钮
        if (
          row.isHover &&
          window.__PI__GANTT.mousePosition.x < Render.leftPanelStyle.width
        ) {
          hasTitleHoverNodeRow = row;
        }
      } else if (x.type === 'group') {
        row = new GroupRow(
          {
            x: 0,
            y: y,
            width: Draw.canvasWidth,
            height: height,
          },
          this.moduleInstances,
          x as baseRowDataWithGroup,
        );
        row.render();
      } else {
        row = new AddRow(
          {
            x: 0,
            y: y,
            width: Draw.canvasWidth,
            height: height,
          },
          this.moduleInstances,
          x,
        );
        row.render();
      }

      return row;
    });

    const onDisplayAdd =
      this.moduleInstances.NodeRowManager.config.onDisplayAddBtn;
    if (hasTitleHoverNodeRow) {
      onDisplayAdd(hasTitleHoverNodeRow as NodeRow);
    } else {
      onDisplayAdd(false);
    }
  }
}
