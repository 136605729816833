import { assertExists } from '@linkpi/utils';
import { Form, Radio } from 'antd';
import { cloneDeep } from 'lodash';
import { type FC } from 'react';

import { useCurrentOrgId, useOrgTempMap } from '@/hook';
import { getCustomTableChildTemplateIds, getRealPropConfig } from '@/utils/utils';

import { useActiveWidgetInstance, useEditor } from '../hooks';

import styles from './ConditionSetting.less';
// 子主题排序
const customTableChildNodesOrders = [
  {
    name: '主题树顺序',
    key: '_child_treeLevel',
  },
  {
    name: '创建时间',
    key: '_child_createTime',
  },
  {
    name: '更新时间',
    key: '_child_updateTime',
  },
];

// 父主题排序
const parentOrders = [
  { key: 'treeLevel', name: '主题树顺序' },
  { key: '_sys_createTime', name: '创建时间' },
  { key: '_sys_updateTime', name: '更新时间' },
];

export const CustomTableOrder: FC = () => {
  const editor = useEditor();
  const widgetInstance: any = useActiveWidgetInstance();

  assertExists(widgetInstance);
  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);
  const updateConfig = editor.updateActiveWidgetInstanceConfig;

  const templateId = widgetInstance.config.conditions.find((i: any) => i.key === 'templateId')
    .input[0];
  const customTableChildTemplateIds = getCustomTableChildTemplateIds(
    widgetInstance.config.customTableId as string,
    tempMap[templateId],
  );

  const customTableChildTemplates: any[] = [];

  customTableChildTemplateIds.forEach((i) => {
    const t = tempMap[i];
    if (!t) return;

    const { name } = t;

    const prop = (t.prop || [])
      .map((p: any, index: number) => ({ ...p, index }))
      .filter((p: any) => p.type);

    const payload: any = { name, props: [], tempId: i };

    prop.forEach((p) => {
      //日期属性、数字属性、数字公式、引用（数字-原值）、引用（数字-统计）、引用（全部-计数）
      if (['datetime', 'date'].includes(p.type)) return payload.props.push(p);
      if (p.type === 'number') return payload.props.push(p);
      if (p.type === 'formula' && p.formulaFormat === 0) return payload.props.push(p);
      if (p.type === 'quote') {
        const realPropConfig = getRealPropConfig(p, tempMap);
        if (typeof realPropConfig !== 'string' && realPropConfig?.type === 'number')
          return payload.props.push(p);
      }
    });

    customTableChildTemplates.push(payload);
  });

  const changeOrderBy = (v: any) => {
    updateConfig((config: any) => {
      config.orderBy = v;
      config.orderDesc = true;
    });
  };

  const changeOrderDesc = (v: boolean) => {
    updateConfig((config: any) => {
      config.orderDesc = v;
    });
  };

  const changeChildNodesOrderBy = (v: any) => {
    updateConfig((config: any) => {
      config.childOrderBy = v;
    });
  };

  const isPropActive = (tempId: string, prop: any) => {
    const childOrderBy = widgetInstance.config.childOrderBy || 'treeLevel';
    if (Object.prototype.toString.call(childOrderBy) !== '[object Object]') return false;

    return childOrderBy[tempId] && childOrderBy[tempId].pIndex == prop.index;
  };

  const getPropSort = (tempId: string, prop: any) => {
    const childOrderBy = widgetInstance.config.childOrderBy || 'treeLevel';
    if (Object.prototype.toString.call(childOrderBy) !== '[object Object]') return 'desc';

    if (!childOrderBy[tempId]) return 'desc';

    if (childOrderBy[tempId].pIndex != prop.index) return 'desc';

    return childOrderBy[tempId].sort;
  };

  const changeCustomTableChildNodesOrder = (tempId: string, prop: any) => {
    let newChildOrderBy = cloneDeep(widgetInstance.config.childOrderBy || 'treeLevel');
    if (Object.prototype.toString.call(newChildOrderBy) !== '[object Object]') {
      newChildOrderBy = {};
      newChildOrderBy[tempId] = { pIndex: prop.index, sort: 'desc', pType: prop.type };
    } else {
      if (!newChildOrderBy[tempId])
        newChildOrderBy[tempId] = { pIndex: prop.index, sort: 'desc', pType: prop.type };
      else {
        if (newChildOrderBy[tempId].pIndex == prop.index) return;
        newChildOrderBy[tempId] = { pIndex: prop.index, sort: 'desc', pType: prop.type };
      }
    }

    updateConfig((config: any) => {
      config.childOrderBy = newChildOrderBy;
    });
  };

  const changePropSort = (tempId: string) => {
    const newChildOrderBy = cloneDeep(widgetInstance.config.childOrderBy);
    const nowSort = newChildOrderBy[tempId].sort;
    newChildOrderBy[tempId].sort = nowSort === 'desc' ? 'asc' : 'desc';
    updateConfig((config: any) => {
      config.childOrderBy = newChildOrderBy;
    });
  };

  return (
    <Form.Item>
      <div style={{ color: '#B7BAC0', fontWeight: 'bold', marginBottom: 8 }}>父主题排序</div>
      {parentOrders.map((i) => {
        const _orderBy = widgetInstance.config.orderBy || 'treeLevel';
        const _orderDesc =
          widgetInstance.config.orderDesc === undefined ? true : widgetInstance.config.orderDesc;
        return (
          <div
            style={{
              height: 40,
              backgroundColor: '#F8F9FB',
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px 12px 8px 16px',
              marginBottom: 12,
            }}
            key={i.key}
          >
            <span>{i.name}</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {_orderBy === i.key && _orderBy === '_sys_createTime' && (
                <i
                  onClick={() => changeOrderDesc(!_orderDesc)}
                  style={{ marginRight: 12, color: '#99A1B3', cursor: 'pointer' }}
                  className={`iconfont ${_orderDesc ? 'iconDescending' : 'iconAscending'}`}
                />
              )}
              <Radio onChange={() => changeOrderBy(i.key)} checked={_orderBy === i.key} />
            </div>
          </div>
        );
      })}
      <div style={{ color: '#B7BAC0', fontWeight: 'bold', marginBottom: 8 }}>子主题排序</div>
      {customTableChildNodesOrders.map((i) => {
        const _childOrderBy = widgetInstance.config.childOrderBy || 'treeLevel';
        return (
          <div
            style={{
              height: 40,
              backgroundColor: '#F8F9FB',
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px 12px 8px 16px',
              marginBottom: 12,
            }}
            key={i.key}
          >
            <span>{i.name}</span>
            <Radio
              onChange={() => changeChildNodesOrderBy(i.key)}
              checked={_childOrderBy === i.key}
            />
          </div>
        );
      })}
      {customTableChildTemplates.map((i) => {
        const { name, props, tempId } = i;

        return (
          <>
            <div style={{ color: '#B7BAC0', fontWeight: 'bold', marginBottom: 8 }}>{name}</div>
            {props.map((p: any) => (
              <div
                key={p.index}
                style={{
                  height: 40,
                  backgroundColor: '#F8F9FB',
                  borderRadius: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '8px 12px 8px 16px',
                  marginBottom: 12,
                }}
              >
                <span>{p.name}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isPropActive(tempId, p) && (
                    <i
                      style={{ marginRight: 12, color: '#99A1B3', cursor: 'pointer' }}
                      className={`iconfont ${
                        getPropSort(tempId, p) === 'desc' ? 'iconDescending' : 'iconAscending'
                      }`}
                      onClick={() => changePropSort(tempId)}
                    />
                  )}
                  <Radio
                    onChange={() => changeCustomTableChildNodesOrder(tempId, p)}
                    checked={isPropActive(tempId, p)}
                  />
                </div>
              </div>
            ))}
          </>
        );
      })}
    </Form.Item>
  );
};
