import type { ApiResponse, CurrentUser } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import { memoize } from 'lodash';
import { useMemo } from 'react';

import { useOrgInfo } from './useOrg';

export const getFilterTemplateList = memoize(
  (orgInfo: ApiResponse.CurrentUser.OrgInfo | null) => {
    // 当前用户的分组
    if (!orgInfo || !Object.keys(orgInfo).length) return [];

    const { role } = orgInfo;

    const groupList = (orgInfo.groupList || []).slice();
    if (role === 1 || role === 3) {
      // 管理员
      groupList.push('-2');
    }

    return orgInfo.templateList.filter((i) => {
      const { groups, status } = i;
      // 停用的 或者 被删除的主题
      if (status !== 0 && status !== 1) return false;

      // 全部用户分组可见
      if (groups === null || groups.includes('-1')) return true;

      let flag = false;

      // 用户分组可见
      groupList.forEach((group) => {
        if (groups.includes(group)) flag = true;
      });

      return flag;
    });
  },
);

// 根据可见分组过滤主题类型
export const useFilterTemplateList = (
  orgInfo: ApiResponse.CurrentUser.OrgInfo | null,
) => {
  return useMemo(() => {
    return getFilterTemplateList(orgInfo);
  }, [orgInfo]);
};

const buildTempMap = memoize(
  toRecord((t: CurrentUser.TemplateInfo) => ({ [t.template_id]: t })),
);

/**
 * 获取根据当前用户过滤后的主题类型
 */
export const useFilterTemplateMap = (orgId?: string) => {
  const [orgInfo] = useOrgInfo(orgId);
  const tempList = useFilterTemplateList(orgInfo);

  const map = useMemo(() => buildTempMap(tempList), [tempList]);

  return map;
};

export default useFilterTemplateList;
