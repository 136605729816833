import type { SelectProps } from 'antd';
import { Select } from 'antd';
import clsx from 'clsx';
import type { FC } from 'react';
import { memo } from 'react';

import { selectSearchFn } from '../utils';
import { useTableHeaderMapOptions } from './hook';

type TableHeaderMapSelectProps = {
  className?: string;
  disabled?: boolean;
  templateId?: string;
} & SelectProps;

export const TableHeaderMapSelect: FC<TableHeaderMapSelectProps> = memo(
  ({ templateId, className, ...rest }) => {
    const { isLoading, options } = useTableHeaderMapOptions(templateId);

    return (
      <Select
        placeholder={templateId ? '请选择表头映射' : '请先选择主题类型'}
        className={clsx(className)}
        options={options}
        showSearch
        filterOption={selectSearchFn}
        loading={isLoading}
        {...rest}
      />
    );
  },
);
