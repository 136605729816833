import { useControllableValue } from 'ahooks';
import type { InputProps } from 'antd';
import { Select } from 'antd';
import { useAtomValue } from 'jotai';
import React from 'react';

import { cn } from '@/utils/utils';

import type { CommonWidgetProps } from '../../constants';
import { FontSizes } from '../../constants';
import { formatsAtom } from '../../store';

interface FontSizeWidgetProps extends CommonWidgetProps {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  style?: React.CSSProperties;
  onFocus?: InputProps['onFocus'];
}

const options = FontSizes.map((f) => ({
  label: f,
  value: `${f}px`,
}));

const FontSizeWidget = (props: FontSizeWidgetProps) => {
  const {
    value,
    onChange,
    defaultValue = '12px',
    className,
    style,
    beforeChange,
    endChange,
  } = props;
  const formats = useAtomValue(formatsAtom);
  const [_value, _onChange] = useControllableValue<string>({
    value: formats[FontSizeWidgetName] ?? '12px',
    onChange,
  });

  return (
    <div className={cn('w-[110px]', className)} style={style}>
      <Select
        value={_value}
        onChange={_onChange}
        className="w-full"
        onDropdownVisibleChange={(open) => {
          if (open) {
            beforeChange?.();
          } else {
            endChange?.();
          }
        }}
        options={options}
        getPopupContainer={(triggerNode) => triggerNode.parentNode!}
        // getPopupContainer={() => document.getElementById('font-size-widget')!}
      />
    </div>
  );
};

export const FontSizeWidgetName = 'size';
export default FontSizeWidget;
