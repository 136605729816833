import type { ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { Divider, Form } from 'antd';
import { head } from 'ramda';

import { RegularIcon } from '@/components/IconFont';
import { TemplateCustomPropSelect } from '@/components/LinkPiForm';

import gantt from '../../assets/gantt.png';
import type {
  IAddingBtnSetting,
  IConditionSetting,
  INodeGroupSetting,
} from '../../components';
import {
  AddingBtnSetting,
  ConditionSetting,
  MatchingSetting,
  NodeGroupSetting,
  showViewSettingModal,
  ViewerSetting,
  WidgetIcon,
} from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IGanttWidget = IConditionSetting &
  IAddingBtnSetting &
  INodeGroupSetting &
  Pick<ViewList.ViewInfo, 'showProgressByProp'>;

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={gantt} style={{ display: 'block', height: 424 }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IGanttWidget>();
  assertExists(widgetInstance);

  const curTemplateId = head(
    widgetInstance.config.conditions.find((i) => i.key === 'templateId')?.input,
  );
  const updateConfig = editor.updateActiveWidgetInstanceConfig<IGanttWidget>;

  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <ConditionSetting />
      <MatchingSetting />
      <NodeGroupSetting />
      <TemplateCustomPropSelect
        genValue={(_, id) => `prop-${id}`}
        templateId={curTemplateId}
        label="显示进度"
        allowPropTypes={['number', 'currency']}
        fieldProps={{
          value: widgetInstance.config.showProgressByProp || undefined,
          onChange(v: any) {
            updateConfig((c) => {
              c.showProgressByProp = v;
            });
          },
        }}
      />
      <Divider dashed />
      <AddingBtnSetting />
      <ViewerSetting />
    </Form>
  );
};

export const GanttWidget = defineSystemWidget<IGanttWidget>()({
  id: 'Gantt',
  title: '甘特图',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#21BF46"
        icon={<RegularIcon type="iconshitu-gantetu" color={'#ffffffcc'} />}
      />
    );
  },
  basic: { defaultHeight: 8, defaultWidth: 12, minWidth: 4, settingWidth: 540 },
  metadata: {
    // @ts-ignore
    viewable: true,
    parentType: 'current',
    matchings: [],
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: ['ff'],
      },
    ],
    group: 'sysCreator',
  },
  preview: Preview,
  setting: Setting,
  onCreate: () => showViewSettingModal<IGanttWidget>({ title: '添加甘特图' }),
});
