import { getWidgetSettingContext } from '@mylinkpi/widget-react';
import type { FC } from 'react';
import { createElement, useEffect, useMemo, useRef } from 'react';
import { match } from 'ts-pattern';

import {
  CustomWidgeUtilsProvider,
  ErrorBoundary,
  ViewJSONButton,
} from '@/components';

import { GroupTitle } from '../components';
import {
  useActiveWidgetConfig,
  useActiveWidgetInstance,
  useEditor,
} from '../hooks';
import type { CollapsiblePanelRef } from './CollapsiblePanel';
import { CollapsiblePanel } from './CollapsiblePanel';

import styles from './SettingPanel.less';

export const SettingPanel: FC = () => {
  const editor = useEditor();
  const widgetConfig = useActiveWidgetConfig();
  const widgetInstance = useActiveWidgetInstance();
  const collapsiblePanelRef = useRef<CollapsiblePanelRef>(null);
  const defaultOpen = !!widgetConfig;

  useEffect(() => {
    if (widgetConfig) collapsiblePanelRef.current?.setOpen(true);
  }, [widgetConfig]);

  const WidgetSettingContext = getWidgetSettingContext();
  const WidgetSettingContextValue = useMemo(
    () => ({
      value: widgetInstance?.config || {},
      setValue: editor.updateActiveWidgetInstanceConfig as any,
    }),
    [editor.updateActiveWidgetInstanceConfig, widgetInstance?.config],
  );

  const isDev = process.env.NODE_ENV === 'development';

  return (
    <CollapsiblePanel
      side="right"
      width={widgetConfig?.basic.settingWidth || 320}
      className={styles.wrapper}
      defaultOpen={defaultOpen}
      ref={collapsiblePanelRef}
    >
      <GroupTitle className={'flex items-center gap-[2px]'}>
        {widgetConfig?.title || '配置'}
        {isDev && (
          <ViewJSONButton size="tiny" type="text" data={widgetInstance || {}} />
        )}
      </GroupTitle>
      <ErrorBoundary type="settingFailed" key={widgetInstance?.id}>
        <CustomWidgeUtilsProvider>
          {match(widgetConfig)
            .with({ type: 'system' }, ({ setting }) => (
              <div className={styles.setting}>{createElement(setting)}</div>
            ))
            .with({ type: 'custom' }, ({ setting }) => (
              <WidgetSettingContext.Provider value={WidgetSettingContextValue}>
                <div className={styles.setting}>{createElement(setting)}</div>
              </WidgetSettingContext.Provider>
            ))
            .otherwise(() => null)}
        </CustomWidgeUtilsProvider>
      </ErrorBoundary>
    </CollapsiblePanel>
  );
};
