import { PaperClipOutlined } from '@ant-design/icons';

import { WidgetIcon } from '../../components';
import { defineSystemWidget } from '../utils';

export const GrowFiles = defineSystemWidget()({
  id: 'GrowFiles',
  title: '成长档案',
  icon: () => {
    return (
      <WidgetIcon bgColor="#FF962C" icon={<PaperClipOutlined style={{ color: '#ffffffcc' }} />} />
    );
  },
  basic: {
    defaultHeight: 10,
    defaultWidth: 12,
  },
  metadata: {},
  preview: () => <div>成长档案</div>,
  setting: () => null,
});
