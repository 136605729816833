import { Divider, Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import kanban from '../../assets/kanban.png';
import type {
  IAddingBtnSetting,
  IConditionSetting,
  IMatchingSetting,
  INodeGroupSetting,
  INodeOrderSetting,
} from '../../components';
import {
  AddingBtnSetting,
  ConditionSetting,
  NodeGroupSetting,
  NodeOrderSetting,
  showViewSettingModal,
  ViewerSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IKanbanWidget = IConditionSetting &
  IAddingBtnSetting &
  INodeGroupSetting &
  IMatchingSetting &
  INodeOrderSetting;

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={kanban} style={{ display: 'block', height: 424 }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <ConditionSetting />
      {/* <MatchingSetting /> */}
      <NodeGroupSetting />
      <NodeOrderSetting />
      <Divider dashed />
      <AddingBtnSetting />
      <ViewerSetting />
    </Form>
  );
};

export const KanbanWidget = defineSystemWidget<IKanbanWidget>()({
  id: 'Kanban',
  title: '看板',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#FF62A7"
        icon={<RegularIcon type="iconView_kanban" color={'#ffffffcc'} />}
      />
    );
  },
  basic: { defaultHeight: 8, defaultWidth: 12, minWidth: 3, settingWidth: 540 },
  metadata: {
    viewable: true,
    parentType: 'current',
    matchings: [],
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: [],
      },
    ],
    group: 'sysCreator',
    orderBy: '_sys_createTime',
    orderDesc: true,
  },
  preview: Preview,
  setting: Setting,
  onCreate: () => showViewSettingModal<IKanbanWidget>({ title: '添加看板' }),
});
