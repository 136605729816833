import { EditOutlined } from '@ant-design/icons';

import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import type { Positions } from '../../constants';
import type { LinkModalProps } from './modal';
import { openLinkModal } from './modal';

import Styles from './index.less';

interface LinkPopoverProps {
  value?: LinkModalProps;
  positions?: Positions;
  onDelete?: () => void;
  onChange?: (v: LinkModalProps) => void;
  onEdit?: () => void;
}

const LinkPopover = (props: LinkPopoverProps) => {
  const { positions, onDelete, value, onChange, onEdit } = props;

  const handleEdit = async () => {
    const values = (await openLinkModal(value)) as LinkModalProps;
    onChange?.(values);
  };

  const handleDelete = () => {
    onDelete?.();
  };

  return (
    <div
      className={cn(
        'absolute bg-white w-[200px] rounded-[8px] z-[1000] overflow-hidden',
        Styles['link-popover'],
      )}
      style={{ top: positions?.top, left: positions?.left }}
    >
      <div className="h-8 flex items-center justify-between bg-[#FBFBFB] px-3">
        <span>链接地址</span>
        <div>
          <EditOutlined
            style={{ color: '#316EF5', fontSize: 16 }}
            className="mr-3 cursor-pointer"
            // onClick={handleEdit}
            onClick={onEdit}
          />
          <RegularIcon
            type="iconshanchu2"
            color="#F0665E"
            size={16}
            className="cursor-pointer"
            onClick={handleDelete}
          />
        </div>
      </div>
      <div className="px-3 py-3 flex items-center break-all">{value?.link}</div>
    </div>
  );
};

export default LinkPopover;
