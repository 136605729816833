import { Segmented } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { GroupTitle } from '../../components';
import { setupDragIn } from '../../utils';
import { CollapsiblePanel } from '../CollapsiblePanel';
import { CustomWidgets } from './CustomWidgets';
import { SystemWidgets } from './SystemWidgets';

const options = ['通用组件', '自定义组件'];

/**
 * 左侧组件面板
 *
 * 有通用和自定义组件
 */
export const WidgetPanel: FC = () => {
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    setupDragIn();
  }, [selected]);

  return (
    <CollapsiblePanel side="left">
      <div className="pb-12 flex flex-col gap-3">
        <GroupTitle>添加组件</GroupTitle>
        <div className="px-2">
          <Segmented
            options={options}
            onChange={setSelected as any}
            value={selected}
            block
          />
        </div>
        <div className={selected === '通用组件' ? 'visible' : 'hidden'}>
          <SystemWidgets />
        </div>
        <div className={selected === '自定义组件' ? 'visible' : 'hidden'}>
          <CustomWidgets />
        </div>
      </div>
    </CollapsiblePanel>
  );
};
