import { RegularIcon } from '@/components/IconFont';

import bar from '../../assets/horBar.png';
import type { IStatisticSetting } from '../../components';
import {
  showStatisticSettingModal,
  StatisticPreview,
  StatisticSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type IHorizontalBarChartWidget = IStatisticSetting<'chart'>;

const Preview: WidgetPreivew = ({ id }) => {
  return (
    <StatisticPreview id={id}>
      <div style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
        <img src={bar} style={{ display: 'block', height: '100%' }} />
      </div>
    </StatisticPreview>
  );
};

const Setting: WidgetSetting = () => {
  return <StatisticSetting type="chart" viewType={2} />;
};

export const HorizontalBarChartWidget =
  defineSystemWidget<IHorizontalBarChartWidget>()({
    id: 'HorizontalBarChart',
    title: '条形图',
    icon: () => {
      return (
        <WidgetIcon
          bgColor="#14C0FF"
          icon={<RegularIcon type="iconshitu-tiaoxingtu" color={'#ffffffcc'} />}
        />
      );
    },
    basic: {
      defaultHeight: 6,
      defaultWidth: 6,
      minWidth: 3,
      minHeight: 3,
      settingWidth: 540,
    },
    metadata: {} as any,
    preview: Preview,
    setting: Setting,
    onCreate: () =>
      showStatisticSettingModal<'chart'>({
        title: '添加条形图',
        type: 'chart',
        viewType: 2,
      }),
  });
