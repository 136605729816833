import { Modal, Typography } from 'antd';
import { match } from 'ts-pattern';

import type { diffStatisticWidget, StatisticWidgetType } from '.';

const getViewName = (data: StatisticWidgetType) => {
  return match(data.config.statistic)
    .with({ type: 'more' }, (c) => c.name)
    .with({ type: 'chart' }, (c) => c.chartInfo.viewName)
    .exhaustive();
};

export const showDiff = (patchList: ReturnType<typeof diffStatisticWidget>) => {
  return new Promise((resolve, reject) =>
    Modal.confirm({
      width: 700,
      title: '检查统计视图的相关变更',
      content: (
        <div style={{ height: 300, overflowY: 'auto' }}>
          <Typography.Paragraph type="secondary">
            如果有不正常的「增」「删」「改」，请终止操作，并且练习 1-week
          </Typography.Paragraph>
          {patchList.map((patch) => (
            <div key={patch.widgetId}>
              <Typography.Paragraph type="secondary">{patch.widgetId}</Typography.Paragraph>
              <Typography.Paragraph>新增:</Typography.Paragraph>
              <ol>
                {patch.custom.added.map((i) => (
                  <li key={i.id}>{getViewName(i)}</li>
                ))}
                {!!patch.custom.added.length || '无'}
              </ol>
              <Typography.Paragraph>编辑:</Typography.Paragraph>
              <ol>
                {patch.custom.updated.map((i) => (
                  <li key={i.id}>{getViewName(i)}</li>
                ))}
                {!!patch.custom.updated.length || '无'}
              </ol>
              <Typography.Paragraph>删除:</Typography.Paragraph>
              <ol>
                {patch.custom.removed.map((i) => (
                  <li key={i.id}>{getViewName(i)}</li>
                ))}
                {!!patch.custom.removed.length || '无'}
              </ol>
            </div>
          ))}
        </div>
      ),
      onOk: () => {
        resolve(null);
        return Promise.resolve();
      },
      onCancel: () => {
        reject('haha');
        return Promise.resolve();
      },
    }),
  );
};
