import ProForm from '@ant-design/pro-form';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { useMemoizedFn } from 'ahooks';
import { Modal } from 'antd';
import type { FC } from 'react';
import { createElement } from 'react';

type SettingModalProps = {
  formContent: FC;
  title?: string;
  initialValues?: unknown;
  width?: number;
};
const _SettingModal: FC<SettingModalProps> = ({ formContent, title, initialValues, width }) => {
  const modal = useModal();

  const [form] = ProForm.useForm();

  const onOk = useMemoizedFn(async () => {
    try {
      const result = await form.validateFields();
      modal.resolve(result);
      modal.hide();
    } catch (error) {
      console.warn(error);
    }
  });

  const onCanel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <Modal
      {...antdModalV5(modal)}
      onOk={onOk}
      onCancel={onCanel}
      centered
      title={title}
      width={width}
    >
      <ProForm
        submitter={false}
        form={form}
        layout="horizontal"
        initialValues={initialValues as any}
      >
        {createElement(formContent, {})}
      </ProForm>
    </Modal>
  );
};

const SettingModal = create(_SettingModal);

export const showSettingModal = <T extends unknown = unknown>(o: SettingModalProps) => {
  return show(SettingModal, o) as Promise<T>;
};
