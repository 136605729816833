import { Divider, Form, Radio } from 'antd';
import { type FC, memo } from 'react';

import { useActiveWidgetInstance, useEditor } from '../hooks';

const options = [
  { label: '显示', value: true },
  { label: '不显示', value: false },
];

const _ViewerSetting: FC = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance()!;

  const setNameVisible = (r: boolean) => {
    editor.store.updateWidgetInstanceConfig(widgetIntance.id, {
      ...widgetIntance?.config,
      viewable: r,
    });
  };

  return (
    <>
      <Divider dashed />
      <Form.Item label="查看详情">
        <Radio.Group
          options={options}
          value={widgetIntance?.config.viewable}
          onChange={(e) => setNameVisible(e.target.value)}
        />
      </Form.Item>
    </>
  );
};

export const ViewerSetting = memo(_ViewerSetting);
