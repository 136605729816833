import { SettingOutlined } from '@ant-design/icons';
import { assertExists } from '@linkpi/utils';
import type { WidgetSetting } from '@mylinkpi/widget-core';
import { Button } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import type { IDataSourceSetting } from '../../components';
import { WidgetIcon } from '../../components';
import {
  showAddDataIndicatorModal,
  showEditDataIndicatorModal,
} from '../../components/DataIndicatorSettingModal';
import { PreviewCards } from '../../components/DataIndicatorSettingModal/PreviewCards';
import { useWidgetInstance } from '../../hooks';
import type { WidgetPreivew } from '../../models/WidgetManager';
import type { RGBAColor } from '../components/ColorInput';
import { defineSystemWidget } from '../utils';
import settingIcon from './setting.png';

type IndexIdType = string;
export type IDataIndicatorIndexType = {
  name: string;
  dataSource: IDataSourceSetting;
  subitems: {
    propIndex?: number;

    showStatistical: boolean;
    statisticalName: string;
    statisticalPropIndex?: number;

    showSuffixIcon: boolean;
    suffixIconPropIndex?: number;
  }[];
  /** 是否显示Icon */
  showIcon: boolean;
  /** icon位置 左/右 */
  iconPlacement: 'left' | 'right';
  /** icon 图片地址 */
  iconSrc: string;

  styles: {
    propName: {
      fontSize: number;
      color: RGBAColor;
      bold: boolean;
      italic: boolean;
      underline: boolean;
    };
    propValue: {
      fontSize: number;
      color: RGBAColor;
      bold: boolean;
      italic: boolean;
      underline: boolean;
    };
    statisticalProp: {
      fontSize: number;
      color: RGBAColor;
      bold: boolean;
      italic: boolean;
      underline: boolean;
    };
    background: string;
    spacingType: 'normal' | 'small';
  };
};
export type IDataIndicatorWidget = {
  indexes: Record<IndexIdType, IDataIndicatorIndexType>;
  indexSort: IndexIdType[];
};

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<IDataIndicatorWidget>(id);
  assertExists(widgetInstance);

  return <PreviewCards data={widgetInstance.config} />;
};

const Setting: WidgetSetting = () => {
  return (
    <div className=" pt-[80px] flex flex-col items-center">
      <img src={settingIcon} className=" w-[120px] mb-[70px]" />

      <Button
        onClick={showEditDataIndicatorModal}
        icon={<SettingOutlined color="#fff" />}
        type="primary"
      >
        配置指标组件
      </Button>
    </div>
  );
};

export const DataIndicatorWidget = defineSystemWidget<IDataIndicatorWidget>()({
  id: 'dataIndicator',
  title: '指标',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#598CFD"
        icon={<RegularIcon type="iconshitu-zhibiao" color={'#ffffffcc'} />}
      />
    );
  },
  basic: { defaultHeight: 8, defaultWidth: 12, minWidth: 3, settingWidth: 320 },
  // 在oncreate里去初始化
  metadata: {
    indexSort: [],
    indexes: {},
  },
  preview: Preview,
  setting: Setting,
  onCreate: () => showAddDataIndicatorModal(),
});
