import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ProFormUploadDragger } from '@ant-design/pro-form';
import { assertExists } from '@linkpi/utils';
import type { UploadFile } from 'antd';
import { Form, Input, message } from 'antd';
import { nanoid } from 'nanoid';
import { head } from 'ramda';
import { type FC, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { PiButton } from '@/components/Button';
import { useCurrentOrgId } from '@/hook';
import { cn, uploadFileToOss } from '@/utils/utils';

import { useActiveWidgetInstance, useEditor } from '../../hooks';

import styles from './ContentSetting.less';

export type ITabSectionContentSetting = {
  tabs: {
    name: string;
    children: string[];
    id: string;
    iconImg?: string;
  }[];
};

export const TabSectionContentSetting: FC = () => {
  const orgId = useCurrentOrgId();
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ITabSectionContentSetting>();

  const [edittingTabpaneId, setEdittingTabpaneId] = useState<string>('');
  const inputRef = useRef<any>(null);

  assertExists(widgetInstance);

  const tabs = widgetInstance.config.tabs || [];
  const updateConfig = editor.updateActiveWidgetInstanceConfig<ITabSectionContentSetting>;

  const editTabpane = (id: string) => setEdittingTabpaneId(id);

  useEffect(() => {
    if (edittingTabpaneId && inputRef.current) inputRef.current.focus();
  }, [edittingTabpaneId]);

  const onDragEnd = (res: any) => {
    const { destination, source } = res;
    if (!destination) return;

    const { index: destinationIndex } = destination;
    const { index: sourceIndex } = source;

    // inner js 需要先 copy 一份；
    const _tabs = tabs.map((i: any) => i);

    const [removed] = _tabs.splice(sourceIndex, 1);
    _tabs.splice(destinationIndex, 0, removed);

    updateConfig((config: any) => {
      config.tabs = _tabs;
    });
  };

  const changeTabpaneName = (e: any) => {
    const value = e.target.value.trim();

    updateConfig((config: any) => {
      config.tabs.forEach((t: any) => {
        if (t.id === edittingTabpaneId) t.name = value;
      });
    });
    setEdittingTabpaneId('');
  };

  const changeTabpaneIconImg = (url: string | undefined, id: string) => {
    updateConfig((config) => {
      config.tabs.forEach((t) => {
        if (t.id === id) t.iconImg = url;
      });
    });
  };

  const del = (id: string) => {
    updateConfig((config: any) => {
      config.tabs = config.tabs.filter((t: any) => t.id !== id);
    });
  };

  const addTabpane = () => {
    updateConfig((config: any) => {
      config.tabs.push({
        name: `标签名称${tabs.length + 1}`,
        children: [],
        id: nanoid(),
      });
    });
  };

  return (
    <Form.Item label="板块内容">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tabpanes" type="tabpanes">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {tabs.map((tabpane, index: number) => (
                <Draggable key={tabpane.id} index={index} draggableId={tabpane.id}>
                  {(innerProvided) => (
                    <div ref={innerProvided.innerRef} {...innerProvided.draggableProps}>
                      <div className={cn(styles.tabpane, 'flex items-center gap-3')}>
                        <i
                          {...innerProvided.dragHandleProps}
                          className="iconfont icondrag"
                          style={{ fontSize: 16, color: '#6B7A96' }}
                        />

                        {edittingTabpaneId === tabpane.id ? (
                          <Input
                            className="h-10 w-60"
                            ref={inputRef}
                            defaultValue={tabpane.name}
                            onPressEnter={changeTabpaneName}
                            onBlur={changeTabpaneName}
                            maxLength={20}
                          />
                        ) : (
                          <div
                            onClick={() => editTabpane(tabpane.id)}
                            className={styles.tabpaneName}
                          >
                            {tabpane.name}
                          </div>
                        )}

                        <div className={styles.uploader}>
                          <ProFormUploadDragger
                            onChange={(_, result: string[] = []) => {
                              if (!result.length) changeTabpaneIconImg(undefined, tabpane.id);
                            }}
                            noStyle
                            value={
                              tabpane.iconImg
                                ? [
                                    {
                                      url: tabpane.iconImg,
                                      name: '',
                                    },
                                  ]
                                : undefined
                            }
                            icon={<PlusOutlined size={11} />}
                            title=""
                            description={null}
                            accept=".png,.jpg,.jpeg,.svg,.gif"
                            max={1}
                            transform={(fileList: UploadFile<any>[]) => head(fileList)?.response}
                            fieldProps={{
                              listType: 'picture-card',
                              customRequest: async (o) => {
                                const res = await uploadFileToOss(o.file, orgId);
                                if (!res) {
                                  return message.error('上传失败');
                                }
                                o.onSuccess?.(res);
                                changeTabpaneIconImg(res, tabpane.id);
                              },
                            }}
                          />
                        </div>

                        <MinusCircleOutlined
                          className="cursor-pointer color-[#B9BBBC]"
                          onClick={() => del(tabpane.id)}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <PiButton onClick={addTabpane} className={styles.addTabpane}>
        <i style={{ color: '#242D3F', fontSize: 16 }} className="iconfont iconadd" />
        <div style={{ color: '#242D3F' }}>添加标签</div>
      </PiButton>
    </Form.Item>
  );
};
