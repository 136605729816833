import { Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';
import StatusProcess from '@/components/StatusProcess';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

import { WidgetIcon } from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

const Preview: WidgetPreivew = () => {
  const currentTemplate = useCurrentTemplate();
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          padding: '0 12px',
        }}
      >
        <StatusProcess data={currentTemplate.task_status} />
      </div>
    </div>
  );
};

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">{/* <BasicSetting type="component" /> */}</Form>
  );
};

export const NodeStatusLineWidget = defineSystemWidget()({
  id: 'NodeStatusLine',
  title: '状态流转线',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#598CFD"
        icon={<RegularIcon type="iconzhuangtailiuzhuan" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 2,
    defaultWidth: 12,
    minHeight: 2,
    minWidth: 3,
    maxCount: 1,
  },
  metadata: {},
  preview: Preview,
  setting: Setting,
});
