import type { GetterPiNode } from '@linkpi/core/web';

import type { IDataIndicatorWidget } from '@/components/PageModelEditor';

import Card from './card';

interface DataIndicatorProps {
  config: IDataIndicatorWidget;
  getterPiNode: GetterPiNode;
  id: string;
}

export const DataIndicator = (props: DataIndicatorProps) => {
  const { config, getterPiNode } = props;

  return (
    <div className="w-full h-full flex items-center overflow-x-auto">
      {config.indexSort.map((index) => {
        const data = config.indexes[index];
        return (
          <Card
            key={index}
            getterPiNode={getterPiNode}
            data={data}
            id={index}
          />
        );
      })}
    </div>
  );
};
