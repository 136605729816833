import type { CurrentUser, ViewList } from '@linkpi/core';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import clsx from 'clsx';
import type { FC } from 'react';
import { memo } from 'react';

import { useConditionOptions } from '@/components/ConditionFilter/hook/useConditionOptions';
import { useOrgTemplatesSettingInfoMap } from '@/hook';

const filterOption: SelectProps['filterOption'] = (inputValue, option) => {
  let filterText = '';
  // 忽略分组
  if (option?.label && !option?.options) {
    filterText = option?.label as string;
  }
  if (!filterText) return false;

  if (option?.options) return false;

  return (
    (filterText as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  );
};

type DataPropSelectProps = {
  className?: string;
  disabled?: boolean;
  templateId?: string;
  allowKey?: ViewList.ViewconditionV2Item['key'][];
  allowPropTypes?: CurrentUser.propType[];
} & SelectProps;

export const DataPropSelect: FC<DataPropSelectProps> = memo(
  ({ templateId, allowKey, allowPropTypes, className, ...rest }) => {
    const tempMap = useOrgTemplatesSettingInfoMap();

    const curTemplate = templateId ? tempMap[templateId] : undefined;

    const { filterConditionOptions } = useConditionOptions({
      curTemplate,
      curTemplateList: undefined,
      tempMap,
      allowKey,
      allowPropTypes,
    });

    return (
      <Select
        className={clsx(className)}
        options={filterConditionOptions}
        showSearch
        filterOption={filterOption}
        {...rest}
      />
    );
  },
);
