import type { ApiResponse } from '@linkpi/core';

import request from '@/utils/request';

export interface CommonParams {
  org_id: string;
}

export interface TableHeaderItem {
  name: string;
  type: string;
  headers: TableHeader[];
  th_id: string;
  create_time: string;
  source_temp: any;
  creator: string;
}

export interface TableHeader {
  id: string;
  name: string;
  type: string;
  multi?: boolean;
  default?: string;
}

export const getTableHeaderList = async (params: CommonParams) => {
  return request<TableHeaderItem[]>('/api/tableHeader/query', {
    method: 'POST',
    data: params,
  });
};

export interface TableHeaderMapListParams {
  org_id: string;
  th_id: string;
}

export interface TableHeaderMapItem {
  source_type: 'table' | 'api';
  temp?: null;
  thm_id: string;
  table: {
    db_id: string;
    table_id: string;
    header_map: Record<
      string,
      {
        column?: string;
      }
    >;
    unique_columns?: string[];
  };
  api?: Api;
}

export const getTableHeaderMapList = async (
  params: TableHeaderMapListParams,
) => {
  return request<TableHeaderMapItem[]>('/api/tableHeaderMap/query', {
    method: 'POST',
    data: params,
  });
};

export interface TableHeaderMapListParams {
  org_id: string;
  th_id: string;
}

export interface TableViewListParams {
  limit: number;
  offset: number;
  org_id: string;
  view_id: string;
  keyWords?: string[];
}

export const getTableViewList = async (params: TableViewListParams) => {
  return request('/docapi/tableView/query', {
    method: 'POST',
    data: params,
  });
};

export interface TableViewHeaderParams {
  org_id: string;
  view_id: string;
}

export interface HeaderItem {
  id: string;
  name: string;
  // 类型不确定是否正确
  type:
    | Exclude<ApiResponse.CurrentUser.propType, 'tag' | 'formula' | 'quote'>
    | 'tag_single'
    | 'tag_multiple'
    | 'enum_single'
    | 'enum_multiple'
    | 'formula_number'
    | 'formula_text'
    | 'quote_number'
    | 'quote_other'
    | 'status'
    | 'uuid'
    | 'rich_text';
  note?: string;
  default: string | number;
  multi?: boolean;
  readonly?: boolean;
  tempInfo?:
    | {
        type: 'status';
      }
    | {
        type: 'prop' | 'status_prop';
        index: number;
      }
    | {
        type: 'sys_prop';
        column: string;
      };
}

export interface FromTemp {
  source: 'temp';
  source_temp: string;
}

export interface FromUser {
  source: 'user';
  source_temp: null;
}

type TableViewHeaderFrom = FromTemp | FromUser;

export type TableViewHeaderRes = {
  headers: HeaderItem[];
} & TableViewHeaderFrom;

export const getTableViewHeader = async (params: TableViewHeaderParams) => {
  return request<TableViewHeaderRes>('/api/tableView/getHeader', {
    method: 'POST',
    data: params,
  });
};

export interface AddTableParams {
  org_id: string;
  view_id: string;
  row_value: Record<string, string | number>;
}

export const addTableData = async (params: AddTableParams) => {
  return request<TableViewHeaderRes>('/docapi/tableView/insert', {
    method: 'POST',
    data: params,
  });
};

export interface EditTableParams {
  org_id: string;
  view_id: string;
  row_value: Record<string, string | number>;
  uk_value: (string | number)[];
}

export const editTableData = async (params: EditTableParams) => {
  return request('/docapi/tableView/update', {
    method: 'POST',
    data: params,
  });
};

export interface DeleteTableParams {
  org_id: string;
  view_id: string;
  uk_value: (string | number)[];
}

export const deleteTableData = async (params: DeleteTableParams) => {
  return request('/docapi/tableView/delete', {
    method: 'POST',
    data: params,
  });
};

export interface TableColSelectData {
  r: string;
}

export interface TableColSelectParams {
  db_id: string;
  table_id: string;
  column_name: string;
}

export const getTableColSelectData = async (params: TableColSelectParams) => {
  return request('/api/dbProxy/selectDistinct', {
    method: 'POST',
    data: params,
  });
};

export interface DBConfigListParams {
  org_id: string;
}

export interface DBConfigItem {
  db_id: string;
  type: string;
  is_local_db: number;
  name: string;
  host: string;
  port: number;
  user: string;
  default_db: string;
  useable: any;
  tables: DBTable[];
}

export interface DBTable {
  table_name: string;
  comment: string;
  creator: string;
  createtime: number;
  table_id: string;
  creator_id: string;
  db_id: string;
  inner_tname: string;
  is_imported: number;
  columns: DBColumn[];
}

export interface DBColumn {
  column_name: string;
  comment: string;
  type_code: string;
  type_name: string;
  nullable: boolean;
  default_value?: string;
  column_id: string;
  type_map: DBTypeMap;
}

export interface DBTypeMap {
  mysql: string;
  ots?: string;
  'tree table'?: string;
  influxdb?: string;
}

export const getDBConfig = (params: DBConfigListParams) => {
  return request<{ data: DBConfigItem[] }>('/docapi/db/config', {
    method: 'POST',
    data: {
      org_id: params.org_id,
      option: 'select',
      getTable: true,
      getColumn: true,
      config: {
        name: '',
      },
    },
  });
};

export const getDBConfigList = (params: DBConfigListParams) => {
  return getDBConfig(params).then((res) => {
    if (res.status === 'ok') {
      return {
        ...res,
        data: res.data.data,
      };
    }
    return res;
  });
};

export interface CreateTableHeaderMapParams extends CommonParams {
  api?: Api;
  source_type: 'temp' | 'table' | 'api';
  table?: Table;
  temp?: Temp;
  th_id: string;
}

export interface Api {
  delete: Delete;
  insert: Insert;
  query: Query;
  update: Update;
}

export interface Delete {
  api_id: string;
  header_map: { [key: string]: any };
}

export interface Insert {
  api_id: string;
  header_map: { [key: string]: any };
}

export interface Query {
  api_id: string;
  header_map: { [key: string]: any };
}

export interface Update {
  api_id: string;
  header_map: { [key: string]: any };
}

export interface Table {
  db_id: string;
  header_map: { [key: string]: any };
  status_map?: StatusMap;
  table_id: string;
  unique_columns?: string[];
}

export interface StatusMap {
  index: string;
  prop: string[];
}

export interface Temp {
  header_map: { [key: string]: any };
  temp_id: string;
}

export const createTableHeaderMap = (params: CreateTableHeaderMapParams) => {
  return request<string>('/api/tableHeaderMap/insert', {
    method: 'POST',
    data: params,
  });
};

export interface UpdateTableHeaderMapParams extends CreateTableHeaderMapParams {
  thm_id: string;
}

export const updateTableHeaderMap = (params: UpdateTableHeaderMapParams) => {
  return request<string>('/api/tableHeaderMap/update', {
    method: 'POST',
    data: params,
  });
};

export interface DeleteTableHeaderMapParams extends CommonParams {
  thm_id: string;
}

export const deleteTableHeaderMap = (params: DeleteTableHeaderMapParams) => {
  return request<string>('/api/tableHeaderMap/delete', {
    method: 'POST',
    data: params,
  });
};

export interface CreateTemplateByDataTableParams extends CommonParams {
  name: string;
  prop?: any[];
  task_status?: any[];
  temp_option?: any;
  groups?: string[];
  source_config:
    | {
        type: 'table';
        table: {
          db_id: string;
          table_id?: string;
        };
      }
    | {
        type: 'api';
        api: any;
      }
    | {
        type: 'node';
      };
}

export const createTemplateByDataTable = (
  params: CreateTemplateByDataTableParams,
) => {
  return request<string>('/api/template/create', {
    method: 'POST',
    data: params,
  });
};

export interface GetDataTableTemplateMapListParams extends CommonParams {
  table_ids: string[];
}

export type GetDataTableTemplateMapListData = Record<
  string,
  {
    table_header_name: string;
    table_id: string;
    temp_id: string;
    template_name: string;
    th_id: string;
    thm_id: string;
  }[]
>;
/**
 * 查询数据表映射的主题类型生成的表头
 * POST /api/tableHeaderMap/temp
 * 接口ID：233661923
 * 接口地址：https://app.apifox.com/link/project/1480992/apis/api-233661923
 */
export const getDataTableTemplateMapList = (
  params: GetDataTableTemplateMapListParams,
) => {
  return request<GetDataTableTemplateMapListData>('/api/tableHeaderMap/temp', {
    method: 'POST',
    data: params,
  });
};
