import type { DisplayAttrsKey } from '@linkpi/core';
import { Divider, Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';
import { defaultTools } from '@/utils/utils';

import normalTable from '../../assets/normalTable.png';
import type {
  IAddingBtnSetting,
  IConditionSetting,
  IDisplaySetting,
  IMatchingSetting,
  INodeGroupSetting,
  INodeOrderSetting,
  IPaginationSetting,
  ISharedStateNodeSetting,
  ITableConditionRuleSetting,
  ITableRowSetting,
} from '../../components';
import {
  AddSetting,
  ConditionSetting,
  DisplaySetting,
  HideTableCheckbox,
  MatchingSetting,
  NodeGroupSetting,
  NodeOpenTypeSetting,
  NodeOrderSetting,
  PaginationSetting,
  SharedStateNodeSetting,
  showViewSettingModal,
  TableAddingBtnSetting,
  TableConditionRuleSetting,
  TableRowSetting,
  ToolsSetting,
  ViewerSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type ITableWidget = ISharedStateNodeSetting &
  IConditionSetting &
  IAddingBtnSetting &
  IDisplaySetting &
  ITableConditionRuleSetting &
  INodeGroupSetting &
  INodeOrderSetting &
  IMatchingSetting &
  ITableRowSetting &
  IPaginationSetting & {
    childThemeRequired: boolean;
    addType: string;
    displayAttrs: {
      key: DisplayAttrsKey;
      name: string;
      fixed?: boolean;
      disable: boolean;
      index: number;
    }[];
  };

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <img src={normalTable} style={{ display: 'block', height: 424 }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <SharedStateNodeSetting />
      <ConditionSetting />
      <MatchingSetting />
      {/* 子主题必填 子主题新增方式 */}
      <AddSetting />
      <TableConditionRuleSetting />
      <NodeOpenTypeSetting />
      <TableRowSetting />
      <DisplaySetting allowShowActions />
      <NodeGroupSetting />
      <NodeOrderSetting />
      <Divider dashed />
      <ToolsSetting />
      <TableAddingBtnSetting />
      <ViewerSetting />
      <PaginationSetting />
      <HideTableCheckbox />
    </Form>
  );
};

export const TableWidget = defineSystemWidget<ITableWidget>()({
  id: 'Table',
  title: '普通表格',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#598CFD"
        icon={<RegularIcon type="iconshaixuanqu-biaoge" color={'#ffffffcc'} />}
      />
    );
  },
  basic: { defaultHeight: 8, defaultWidth: 12, minWidth: 3, settingWidth: 540 },
  metadata: {
    // @ts-ignore
    viewable: true,
    parentType: 'current',
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: [],
      },
    ],
    group: undefined,
    orderBy: '_sys_createTime',
    orderDesc: true,
    matchings: [],
    childThemeRequired: false,
    addType: 'modal',
    displayAttrs: [],
    pagination: false,
    toolsConfig: defaultTools.slice(0, 5).concat(['addBtn']),
    nodeOpenType: 'modal', // content newWindow
    tableDisplayConfig: {
      icon: true,
      requiredMark: true,
    },
    hideTableCheckbox: false,
  },
  preview: Preview,
  setting: Setting,
  onCreate: () => showViewSettingModal<ITableWidget>({ title: '添加普通表格' }),
});
