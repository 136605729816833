import type { ApiResponse } from '@linkpi/core';
import type { OrgUser } from '@linkpi/core';
import { STATUS_ICON } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import { Avatar, Popover } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';

import logo from '@/assets/logo.svg';
import { useOrgUserMap } from '@/hook';

import Styles from './index.less';

interface StatusProcessProps {
  data: (ApiResponse.CurrentUser.taskStatus & {
    isFuture?: boolean;
    props?: {
      label: string;
      value: React.ReactNode;
      type: ApiResponse.CurrentUser.statusPropType;
    }[];
  })[];
  hover?: boolean;
  allowWrap?: boolean;
}

const StatusProcess = (props: StatusProcessProps) => {
  const { data, hover, allowWrap } = props;
  // TODO SCROLL into View

  const userMap = useOrgUserMap();
  const valueRender = (type: ApiResponse.CurrentUser.statusPropType, value: any) => {
    const timeFormat = 'YY/MM/DD HH:mm';

    if (propIsNull(value)) return '/';
    const userIds = (Array.isArray(value) ? value : [value]).filter(
      (userId: string) => userMap![userId],
    );
    switch (type) {
      case 'user':
        return userIds.map((userId: string, index: number) => {
          return (
            <div key={userId}>
              <Avatar
                style={{ margin: '-2px 4px 0 0' }}
                src={userMap![userId].avatar || logo}
                size={18}
              />
              {userMap![userId].nick_name}
              {index < userIds.length - 1 ? ' 、' : ''}
            </div>
          );
        });
      case 'datetime':
        return dayjs(value).format(timeFormat);
      case 'text':
        return value;
      default:
        return '/';
    }
  };

  const popContent = (status: StatusProcessProps['data'][number]) => {
    return (
      <div className={Styles['status-pop-content']}>
        <div
          style={{ color: STATUS_ICON[status.icon as keyof typeof STATUS_ICON].color }}
          className={Styles['pop-title']}
        >
          <i className={classNames('iconfont', status.icon, Styles['pop-icon'])} />
          <span className={Styles['pop-text']}>{status.name}</span>
        </div>

        <div className={Styles['pop-content']}>
          {status.props!.map(({ label, value, type }, index) => (
            <div key={index} className={Styles['pop-content-item']}>
              <div className={Styles['pop-content-item-value']}>
                <span className={Styles['pop-content-item-label']}>{label}：</span>
                {valueRender(type, value)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const statusItemRender = (status: StatusProcessProps['data'][number], index: number) => {
    const children = (
      <div
        key={status.index}
        className={classNames(Styles['status-item'], {
          [Styles.future]: status.isFuture,
        })}
        style={{
          marginBottom: allowWrap ? 8 : '',
          backgroundColor: status.isFuture
            ? undefined
            : STATUS_ICON[status.icon as keyof typeof STATUS_ICON].color,
        }}
      >
        {/* {index === 0 ? null : <span className={Styles['status-item-tail']} />} */}
        <span className={Styles['status-item-title']}>{status.name}</span>
        <i className={classNames('iconfont', status.icon, Styles['status-item-icon'])} />
      </div>
    );
    return hover && userMap ? (
      <Popover
        key={status.index}
        content={status.props?.length ? popContent(status) : null}
        trigger="hover"
        placement="bottom"
        overlayClassName={Styles['status-pop']}
        // getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      >
        {children}
      </Popover>
    ) : (
      children
    );
  };

  return (
    <div
      className={Styles['status-process-container']}
      style={{
        flexWrap: allowWrap ? 'wrap' : undefined,
      }}
    >
      {data.map(statusItemRender)}
    </div>
  );
};

export default StatusProcess;
