import type { ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import { Checkbox, Form, Switch } from 'antd';
import { type FC } from 'react';

import { useActiveWidgetInstance, useEditor } from '../hooks';
import type { IConditionSetting } from './ConditionSetting';

const UNIT_OPTIONS = [
  { label: '表头', value: 'showHeaderUnit' },
  { label: '单元格', value: 'showCellUnit' },
];

export type ITableRowSetting = Pick<ViewList.ViewInfo, 'tableDisplayConfig'>;

const INITIAL_VALUE: ITableRowSetting['tableDisplayConfig'] = {
  icon: true,
  requiredMark: true,
  showCellUnit: true,
  openIcon: true,
};

export const TableRowSetting: FC = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IConditionSetting & ITableRowSetting>();
  assertExists(widgetInstance);

  const tableDisplayConfig = widgetInstance.config.tableDisplayConfig || INITIAL_VALUE;

  assertExists(widgetInstance);

  const updateConfig = editor.updateActiveWidgetInstanceConfig<ITableRowSetting>;

  const changeTableDisplayConfig = useMemoizedFn(
    (key: keyof NonNullable<ITableRowSetting['tableDisplayConfig']>, value: boolean) => {
      updateConfig((config) => {
        if (!config.tableDisplayConfig) {
          config.tableDisplayConfig = INITIAL_VALUE;
        }
        config.tableDisplayConfig[key] = value;
      });
    },
  );

  return (
    <Form.Item label="显示设置">
      <div className="flex flex-col gap-2 ">
        <div className="flex justify-between items-center">
          <span>显示icon</span>
          <Switch
            checked={tableDisplayConfig.icon}
            onChange={(e) => changeTableDisplayConfig('icon', e)}
          />
        </div>
        <div className="flex justify-between items-center">
          <span>显示必填标识</span>
          <Switch
            checked={tableDisplayConfig.requiredMark}
            onChange={(e) => changeTableDisplayConfig('requiredMark', e)}
          />
        </div>
        <div className="flex justify-between items-center">
          <span>显示打开主题按钮</span>
          <Switch
            checked={tableDisplayConfig.openIcon}
            onChange={(e) => changeTableDisplayConfig('openIcon', e)}
          />
        </div>
        <div className="flex justify-between items-center">
          <span>单位显示位置</span>
          <Checkbox.Group
            options={UNIT_OPTIONS}
            value={
              [
                (tableDisplayConfig.showCellUnit ||
                  typeof tableDisplayConfig.showCellUnit === 'undefined') &&
                  'showCellUnit',
                tableDisplayConfig.showHeaderUnit && 'showHeaderUnit',
              ].filter(Boolean) as any
            }
            onChange={(e) => {
              changeTableDisplayConfig('showCellUnit', e.includes('showCellUnit'));
              changeTableDisplayConfig('showHeaderUnit', e.includes('showHeaderUnit'));
            }}
          />
        </div>
      </div>
    </Form.Item>
  );
};
