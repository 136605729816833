import { RegularIcon } from '@/components/IconFont';

import line from '../../assets/line.png';
import type { IStatisticSetting } from '../../components';
import {
  showStatisticSettingModal,
  StatisticPreview,
  StatisticSetting,
  WidgetIcon,
} from '../../components';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type ILineChartWidget = IStatisticSetting<'chart'>;

const Preview: WidgetPreivew = ({ id }) => {
  return (
    <StatisticPreview id={id}>
      {/* <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'hidden',
          justifyContent: 'flex-start',
        }}
      >
        <img src={line} style={{ display: 'block', height: '100%' }} />
      </div> */}
      <div style={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
        <img src={line} style={{ display: 'block', height: '100%' }} />
      </div>
    </StatisticPreview>
  );
};

const Setting: WidgetSetting = () => {
  return <StatisticSetting type="chart" viewType={3} />;
};

export const LineChartWidget = defineSystemWidget<ILineChartWidget>()({
  id: 'LineChart',
  title: '折线图',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#598CFD"
        icon={<RegularIcon type="iconshitu-zhexiantu" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 7,
    defaultWidth: 6,
    minWidth: 6,
    minHeight: 7,
    settingWidth: 540,
  },
  metadata: {} as any,
  preview: Preview,
  setting: Setting,
  onCreate: () =>
    showStatisticSettingModal<'chart'>({
      title: '添加条形图',
      type: 'chart',
      viewType: 3,
    }),
});
